import {
    NOT_LOGGED_IN,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    RECEIVED_USER_DATA,
    RECEIVED_USER_METRICS,
    UPDATE_ACCOUNT_DATA,
    UPDATE_ENERCOINS,
    REQUEST_APPROVE_NOTIFICATIONS,
    REQUEST_APPROVE_NOTIFICATIONS_BLOCKED,
    LOGIN_2_STEP_AUTH,
    RECEIVED_USER_PREFERENCES, REQUEST_APPROVE_NOTIFICATIONS_UNSUPPORTED
} from "../actions/auth";
import {RECEIVE_AUTO_SCHEDULE} from "../actions/activities";

//import { loadUserProfile } from "../utils/apiUtils";

const initialState = {
    user: null,
    password: null,
    loggingIn: true,
    login2StepAuth: null,
    loggingOut: false,
    loginError: null,
    notificationApproval: false,
    userData: null,
    sessionData: null,
    userMetrics: null
};

function initializeState() {
    const userProfile = {}; //loadUserProfile();
    return Object.assign({}, initialState, userProfile);
}

export default function auth(state = initializeState(), action = {}) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {loggingIn: true});
        case NOT_LOGGED_IN:
            return Object.assign({}, state, {
                loggingIn: false
            });
        case LOGIN_2_STEP_AUTH:
            return {
                ...state,
                login2StepAuth: action.authData,
                loggingIn: false
            };
        case LOGIN_SUCCESS:
            let is_manager = action.sessionData && action.sessionData.is_manager;
            return Object.assign({}, state, {
                loggingIn: false,
                sessionData: action.sessionData,
                is_manager: is_manager,
                permissions: action.sessionData && action.sessionData.additionalData ? action.sessionData.additionalData.permissions : null,
                accountData: action.sessionData && action.sessionData.additionalData ? action.sessionData.additionalData.accountData : null,
                loginError: null
                //user: action.user,
                //role: action.role
            });
        case LOGIN_FAILURE:
            return {
                ...state,
                loggingIn: false,
                loginError: action.reason
            };
        case REQUEST_APPROVE_NOTIFICATIONS:
            return {
                ...state,
                notificationApproval: 'request'
            };
        case REQUEST_APPROVE_NOTIFICATIONS_BLOCKED:
            return {
                ...state,
                notificationApproval: 'blocked'
            };
        case REQUEST_APPROVE_NOTIFICATIONS_UNSUPPORTED:
            return {
                ...state,
                notificationApproval: 'unsupported'
            };
        case RECEIVED_USER_DATA:
            return {
                ...state,
                userData: action.userData
            };
        case RECEIVED_USER_PREFERENCES:
            return {
                ...state,
                accountData: {
                    ...state.accountData,
                    preferences: action.preferences
                }
            };
        case RECEIVED_USER_METRICS:
            return {
                ...state,
                userMetrics: action.userMetrics
            };
        case UPDATE_ENERCOINS:
            let userData = Object.assign(state.userData, {enercoins: action.payload.enercoinsAmount});
            return {
                ...state,
                userData
            };
        case UPDATE_ACCOUNT_DATA:
            let {notificationPreferences, ...newData} = action.accountData
            if (newData.image_uri === '')
                delete newData.image_uri;
            delete newData.new_password;
            delete newData.imageDataURI;
            let accountData = Object.assign(state.accountData, {...newData});
            accountData.preferences = {...(accountData.preferences || {}), notifications: notificationPreferences};
            return {
                ...state,
                accountData
            };
        case RECEIVE_AUTO_SCHEDULE:
            let nextSessionData = state.sessionData;
            nextSessionData.additionalData.is_schedule_enabled = Boolean(action.nextAutoSchedule);
            return {
                ...state,
                sessionData: nextSessionData
            };
        default:
            return state;
    }
}
