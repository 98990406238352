import {callApi} from "../utils/apiUtils";
import {FORCE_UPDATE_FEED} from "./feeds";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_SAVING, API_REQUEST_ERROR, API_REQ_SUCCESS_INFO} from "./global";
import {DISPATCH_UI_ACTION} from "./controls";
import React from 'react';
import Icon from "@material-ui/core/Icon";

import {t} from 'react-i18nify'
const _ = require('lodash');

export const RECEIVED_ACTIVITIES_LIST = "RECEIVED_ACTIVITIES_LIST";
export const RECEIVED_ACTIVITIES_STATS_LIST = "RECEIVED_ACTIVITIES_STATS_LIST";
export const RECEIVED_HISTORY_ACTIVITIES_LIST = "RECEIVED_HISTORY_ACTIVITIES_LIST";
export const CHECK_IN_ACTIVITY_SUCCESS = "CHECK_IN_ACTIVITY_SUCCESS";
export const CHECK_IN_REMINDER_SENT = "CHECK_IN_REMINDER_SENT";
export const RECEIVED_ACTIVITY_WORK_PLAN = "RECEIVED_ACTIVITY_WORK_PLAN";
export const RECEIVE_AUTO_SCHEDULE = "RECEIVE_AUTO_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const CANCEL_ACTIVITIES = "CANCEL_ACTIVITIES";
export const SET_CHALLENGES_SETTINGS = "SET_CHALLENGES_SETTINGS";
export const SET_ALL_USERS_BY_CENTER = "SET_ALL_USERS_BY_CENTER";

export function receivedHistoryActivities(activities) {
    return {type: RECEIVED_HISTORY_ACTIVITIES_LIST, activities};
}

export function receivedActivities(activities) {
    return {type: RECEIVED_ACTIVITIES_LIST, activities};
}

export function receivedActivitiesStats(activities_stats) {
    return {type: RECEIVED_ACTIVITIES_STATS_LIST, activities_stats};
}

export function receivedActivityWorkPlan(activityWorkPlans, deletedActivityWorkPlan) {
    return {type: RECEIVED_ACTIVITY_WORK_PLAN, activityWorkPlans, deletedActivityWorkPlan};
}

export function receiveAutoSchedule(nextAutoSchedule) {
    return {type: RECEIVE_AUTO_SCHEDULE, nextAutoSchedule};
}

export function updateSchedule(nextScheduleUpdate) {
    return {type: UPDATE_SCHEDULE, nextScheduleUpdate};
}

export function cancelActivities(activity_ids) {
    return {type: CANCEL_ACTIVITIES, activity_ids};
}

export function setChallengesSettings({challengesSettings, primary_lead_types}) {
    return {type: SET_CHALLENGES_SETTINGS, challengesSettings, primary_lead_types};
}

export function setAllUsersByCenter(allUsers) {
    return {type: SET_ALL_USERS_BY_CENTER, allUsers};
}

export function checkInActivity(activityId, postId, data, callback) {
    if (!data)
        data = {};
    return callApi('checkInActivity', {activityId, data}, null, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: CHECK_IN_ACTIVITY_SUCCESS, activityId, data});
        if (callback) {
            callback(responseData);
        }
    });
}

export function sendCheckInActivityReminder(centerId, activityId, postId, userId) {
    return callApi('sendCheckInActivityReminder', {
        centerId,
        activityId,
        postId,
        userId
    }, null, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: CHECK_IN_REMINDER_SENT, postId});
    });
}

export function sendShortFeedback(shortFeedbackType, centerId, activityId, postId, userIds, users) {
    return callApi('sendShortFeedback', {
        centerId,
        activityId,
        postId,
        userIds,
        shortFeedbackType
    }, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status !== "SUCCESS") {
            return;
        }
        const {feedbackStats} = responseData;
        const total_feedbacks = _.sumBy(feedbackStats, 'feedbacks_sent');
        dispatch({
            type: API_REQ_SUCCESS_INFO,
            customMsg: (
                <div className={'feedbackSuccessMsg'}>
                    <Icon className={'icon- feedbackIcon'}>clap</Icon>
                    <div className={'content'}>
                        <div className={'title'}>{t('feeds.feedback_sent_successfully')}</div>
                        <div className={'body'}>
                            {t('feeds.you_sent_x_feedbacks_this_month', {total_feedbacks})}
                            {userIds.length === 1 && [
                                <br/>
                                , t('feeds.x_of_them_sent_to_y',
                                    feedbackStats.filter(userFeedbacks => userFeedbacks.receiver_id === userIds[0].user_id)
                                        .map(({feedbacks_sent, receiver_id}) => ({
                                            feedbacks_sent,
                                            employee_name: users[receiver_id].first_name + ' ' + users[receiver_id].last_name
                                        }))[0])
                            ]}
                        </div>
                    </div>
                </div>
            )
        })
        setTimeout(() => dispatch({type: FORCE_UPDATE_FEED, feed_types: ['private']}), 10 * 1000);
    });
}

export function getSchedules(sessionData, startDate, endDate, callback) {
    return callApi('getSchedules', {sessionData, startDate, endDate}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedHistoryActivities(responseData.activity_schedules));
            callback()
        }
    });
}

export function createCustomSchedule(newActivity) {
    return callApi('createCustomSchedule', newActivity, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedActivityWorkPlan(responseData.activity_schedules));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        }
    });
}

export function deleteSingleScheduledEvent(actionData, callback) {
    return callApi('deleteSingleScheduledEvent', actionData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedActivityWorkPlan(responseData.activity_schedules));
            dispatch({type: API_REQ_SAVE_SUCCESS});
            callback();
        }
    });
}

export function removeSchedule(deleteActivities) {
    return callApi('removeSchedule', deleteActivities, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedActivityWorkPlan(responseData.activity_schedules, deleteActivities));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        }
    });
}

export function toggleAutoSchedule(AutoSchedule, callback) {
    return callApi('toggleAutoSchedule', AutoSchedule, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receiveAutoSchedule(responseData.nextAutoSchedule));
            dispatch({type: API_REQ_SAVE_SUCCESS});
            callback();
        }
    });
}

export function createOrUpdateChallengeEvent(challengeEventData, callback) {
    return callApi('createOrUpdateChallengeEvent', challengeEventData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(updateSchedule(responseData.challengeData));
            dispatch({type: API_REQ_SAVE_SUCCESS, prefix: 'challenges'});
            dispatch({type: DISPATCH_UI_ACTION, key: 'changeTab'})
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        callback();
    });
}

export function onRemoveChallenge(challengeId, callback) {
    return callApi('onRemoveChallenge', challengeId, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(updateSchedule({challengeId: responseData.challengeId}));
            dispatch({type: API_REQ_SAVE_SUCCESS});
            if (callback) {
                callback();
            }
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
    });
}

export function cancelStartedChallenge(activity_ids, callback) {
    return callApi('cancelStartedChallenge', {activity_ids}, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(cancelActivities({activity_ids}));
            dispatch({type: API_REQ_SAVE_SUCCESS});
            if (callback) {
                callback();
            }
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
    });
}

export function getChallengesSettings() {
    return callApi('getChallengesSettings', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(setChallengesSettings(responseData));
        }
    });
}

export function getAllUsersByCenter() {
    return callApi('getAllUsersByCenter', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(setAllUsersByCenter(responseData.allUsers));
        }
    });
}

export function updatePrimaryLeadTypes(primary_lead_types, isRefreshExistingPlan, callback) {
    return callApi('updatePrimaryLeadTypes', {
        primary_lead_types,
        isRefreshExistingPlan
    }, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(getChallengesSettings());
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        callback();
    });
}