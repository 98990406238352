import {
    RECEIVED_TRIGGERS_DATA
} from "../actions/triggers";

const initialState = {
    triggers: [],
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function generalSettings(state = initializeState(), action = {}) {
    switch (action.type) {
        case RECEIVED_TRIGGERS_DATA:
            return {
                ...state,
                triggers: action.payload.triggers
            };
        default:
            return state;
    }
}
