import React, {Component} from 'react';
import {connect} from "react-redux";
import {getUserImg} from "erj-common/generalUtils";
import {Link} from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import CurrentTime from "../misc/CurrentTime";
import ViewPort from "../misc/Viewport";
import PointsSummaryWidget from "./PointsSummaryWidget";
import CoinsSummaryWidget from "./CoinsSummaryWidget";

import {t} from 'react-i18nify'
import PersonalCommissionSummaryWidget from "./PersonalCommissionSummaryWidget";

class PersonalSummaryWidget extends Component {

    render() {
        const {first_name, last_name, availableProductsNum, image, commissions_enabled, store_enabled} = this.props;
        const numModules = (commissions_enabled ? 1 : 0) + (store_enabled ? 1 : 0);
        return (
            <ViewPort>
                {({mobile}) => {
                    return first_name ? (
                        <div className={'user-summary-widget personal-summary-widget'}>
                            {!mobile && (
                                <>
                                    <div className="greeting localText">
                                        <CurrentTime format={'dayPartGreeting'} renderIn={greeting => (
                                            t('global.' + greeting, {first_name, last_name})
                                        )}/>
                                    </div>
                                    {numModules < 2 && (
                                        <img className="personalImg" alt={first_name + '_img'}
                                             src={getUserImg(image, "xs")}/>
                                    )}
                                    {store_enabled && availableProductsNum > 0 && (
                                        <Link to={process.env.REACT_APP_ROUTE_PATH + '/store'}
                                              className={'itemsWaitingInStoreLink'}>
                                            <Icon className={'icon- cartIcon'}>cart</Icon>
                                            <span className={'inlineLocalText'}>
                                                    {t('store.items_waiting_in_store', {items: availableProductsNum})}
                                                </span>
                                        </Link>
                                    )}
                                </>
                            )}
                            {store_enabled && (
                                <div className={'moduleRow'}>
                                    <div className={'moduleContent incentivesModule'}>
                                        <PointsSummaryWidget mobile={mobile}/>
                                        <CoinsSummaryWidget mobile={mobile}/>
                                    </div>
                                </div>
                            )}
                            {commissions_enabled && (
                                <PersonalCommissionSummaryWidget mobile={mobile}/>
                            )}
                        </div>
                    ) : (
                        <div className={'user-summary-widget personal-summary-widget'}>&nbsp;</div>
                    );
                }}
            </ViewPort>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {auth, store} = state;
    let userData = auth.userData;
    let personalSummaryData = {};
    if (auth.userMetrics && userData) {
        personalSummaryData = {
            first_name: userData.first_name,
            last_name: userData.last_name,
            image: userData.user_img,
            availableProductsNum: Object.keys(store.products).reduce((prev, product_id) => (
                !store.products[product_id].deleted && store.products[product_id].status === 1 && store.products[product_id].stock_quantity && store.products[product_id].price <= userData.enercoins ? prev + 1 : prev
            ), 0),
            commissions_enabled: auth.sessionData.commissions_enabled === 1,
            store_enabled: auth.sessionData.store_enabled === 1
        };
    }

    return personalSummaryData;
};

export default connect(mapStateToProps)(PersonalSummaryWidget);