import {
    RECEIVE_NOTIFICATION_CLICKED,
    RECEIVED_NOTIFICATIONS_LIST
} from "../actions/notifications";
import {RESET_LIVE_DATA} from "../actions/auth";

const initialState = {
    notifications: {},
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function feeds(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_NOTIFICATIONS_LIST:
            return {
                ...state,
                notifications: action.notifications
            };
        case RECEIVE_NOTIFICATION_CLICKED:
            if (!state.notifications[action.notifications.id].users_views)
                state.notifications[action.notifications.id].users_views = {};
            state.notifications[action.notifications.id].users_views[action.notifications.userId] = 1;
            return {
                ...state,
                notifications: state.notifications
            };
        default:
            return state;
    }
}
