import {base64ToFile, callApi} from "../utils/apiUtils";
import {
    API_REQUEST_SAVING,
    API_REQ_SAVE_SUCCESS, API_CLEAR_REQUEST_FEEDBACK, API_REQUEST_ERROR
} from "./global";
import {loginSuccess, updateAccountData} from "./auth";


export const DECODE_IMG = "DECODE_IMG";
export const SAVE_PROFILE_RESPONSE = "SAVE_PROFILE_RESPONSE";

export function createDecodedImg(decodedImg) {
    return {type: DECODE_IMG, img: decodedImg};
}

export function saveAccountResponse(data) {
    return {type: SAVE_PROFILE_RESPONSE, response: data};
}

export function saveAccount(data, callback) {
    return callApi('updateMyAccount', data, (dispatch)=> {
        if (data.image_uri) {
            delete data.imageDataURI;
            data.image_uri = base64ToFile(data.image_uri);
        }
        dispatch({ type:  API_REQUEST_SAVING});
    }, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            const {data, status, ...sessionData} = responseData;
            dispatch(loginSuccess(sessionData));
            setTimeout(() => { //give some extra time for things like the user img to be ready
                dispatch(updateAccountData(data));
                dispatch(saveAccountResponse(data));
                dispatch({type: API_REQ_SAVE_SUCCESS});
            }, 1000);
        } else if (responseData.status === "ERR_OLD_PASSWORD") {
            dispatch({type: API_REQUEST_ERROR, prefix: "wrongOldPassword"});
        } else if (responseData.status === "ERR_REPEATED_PASSWORD") {
            dispatch({type: API_REQUEST_ERROR, prefix: "reusedPassword"});
        } else {
            dispatch({type: API_CLEAR_REQUEST_FEEDBACK});
        }

        callback(status);
    });
}
export function decodeImg(img) {
    return (dispatch) =>{
        if(!img) return;
        const fileReader = new FileReader();
        let decodedImg;
        fileReader.onload = (fileLoadedEvent) => {
            decodedImg = fileLoadedEvent.target.result;
            dispatch(createDecodedImg(decodedImg));
        };
        fileReader.readAsDataURL(img);
    }
}
