import {
    RECEIVED_ACCESS_CONTROL_DATA,
    RECEIVED_DELETED_PERMISSION,
    RECEIVED_CREATE_OR_UPDATE_ROLE,
    RECEIVED_CREATE_OR_UPDATE_MANAGER,
    RECEIVED_DELETED_MANAGER,
    RECEIVED_USERS_DATA,
    RECEIVED_UPDATED_USER_DATA,
    RECEIVED_UNITS_DATA,
    RECEIVED_UPDATED_UNIT_DATA,
    RECEIVED_REMOVED_UNIT,
    RECEIVED_UPDATED_USER_CHALLENGES_ENABLED_STATUS,
    RECEIVED_BLOCKED_LOGINS
} from "../actions/accessControl";

const initialState = {
    permissionsTemplate: {},
    accessControlData: [],
    managers: [],
    usersData: [],
    unitsData: [],
    blockedLogins: []
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function accessControl(state = initializeState(), action = {}) {
    switch (action.type) {
        case RECEIVED_ACCESS_CONTROL_DATA:
            return {
                ...state,
                permissionsTemplate: action.payload.accessControlData.permissionsTemplate,
                accessControlData: action.payload.accessControlData.accessControlData,
                managers: action.payload.accessControlData.managers
            };
        case RECEIVED_CREATE_OR_UPDATE_ROLE:
            let productIndex = state.accessControlData.findIndex((product) => {
                if (product.id === action.payload.accessControlData.id) {
                    Object.assign(product, action.payload.accessControlData);
                    return true;
                }
                return false;
            });
            if (productIndex === -1) {
                state.accessControlData.unshift(action.payload.accessControlData);
            }
            return {
                ...state,
                accessControlData: [...state.accessControlData.splice(0)],
                editMode: false
            };
        case RECEIVED_DELETED_PERMISSION:
            return {
                ...state,
                accessControlData: state.accessControlData.filter((data) => {
                    return data.id !== action.payload.id;
                })
            };
        case RECEIVED_CREATE_OR_UPDATE_MANAGER:
            let managerIndex = state.managers.findIndex(manager => {
                if (manager.id === action.payload.managerData.id) {
                    Object.assign(manager, action.payload.managerData);
                    return true;
                }
                return false;
            });

            if (managerIndex === -1) {
                state.managers.unshift(action.payload.managerData);
            }
            return {
                ...state,
                managers: [...state.managers.splice(0)],
                editMode: false
            };
        case RECEIVED_DELETED_MANAGER:
            return {
                ...state,
                managers: state.managers.filter((manager) => {
                    return manager.id !== action.payload.id;
                })
            };
        case RECEIVED_USERS_DATA:
            return {
                ...state,
                usersData: action.payload.usersData
            };
        case RECEIVED_UPDATED_USER_CHALLENGES_ENABLED_STATUS:
            return {
                ...state,
                usersData: state.usersData.map(user => {
                    if (user.id === action.payload.challengesStatus.userId) {
                        user.challenges_enabled_from = action.payload.challengesStatus.challenges_enabled_from;
                    }
                    return user;
                })
            };
        case RECEIVED_UPDATED_USER_DATA:
            let userIndex = state.usersData.findIndex(user => {
                return user.id === action.payload.userData.id;
            });
            let userData = [...state.usersData];
            if (userIndex === -1) {
                userData.unshift(action.payload.userData);
            } else {
                userData[userIndex] = (action.payload.userData);
            }
            return {
                ...state,
                usersData: userData
            };
        case RECEIVED_UNITS_DATA:
            return {
                ...state,
                unitsData: action.payload.unitsData
            };
        case RECEIVED_UPDATED_UNIT_DATA:
            let unitIndex = state.unitsData.findIndex(unit => {
                return unit.id === action.payload.unitsData.id;
            });
            let unitData = [...state.unitsData];
            if (unitIndex === -1) {
                unitData.unshift(action.payload.unitsData);
            } else {
                unitData[unitIndex] = (action.payload.unitsData);
            }

            return {
                ...state,
                unitsData: unitData
            };
        case RECEIVED_REMOVED_UNIT:
            return {
                ...state,
                unitsData: state.unitsData.filter(unit => {
                    return unit.id !== action.payload.removedUnit.unitId;
                })
            };
        case RECEIVED_BLOCKED_LOGINS:
            return {
                ...state,
                blockedLogins: action.payload.blocked_list
            };
        default:
            return state;
    }
}
