import {
    createSelectorWithDependencies as createSelector
} from 'reselect-tools'
import {metricSort} from "../utils/metrics";
import {getUserImg} from "erj-common/generalUtils";

const _ = require('lodash');

const getUsers = (state, props) => state.users.users[props.data.userId] ? state.users.users : state.users.org_users[props.data.centerId || state.auth.sessionData.challenges_controlling_center];
const getChallengesUsers = (state, props) => [state.auth.sessionData.challenges_controlling_center, props.data.centerId].indexOf(state.auth.sessionData.attached_center_id) > -1 ? state.users.users : state.users.org_users[props.data.centerId || state.auth.sessionData.challenges_controlling_center];
const getCenterUsers = (state, props) => state.users.users;
const getSelectedUser = (state, props) => state.users.users[props.data.userId] || state.users.org_users[props.data.centerId || state.auth.sessionData.challenges_controlling_center][props.data.userId];
const getMetrics = (state, props) => state.users.profile_data.metrics.isLoaded() && state.users.profile_data.user_id === props.data.userId ? state.users.profile_data.metrics : state.metrics.metrics;
const getLeadTypes = (state, props) => state.users.profile_data.lead_types.isLoaded() && state.users.profile_data.user_id === props.data.userId ? state.users.profile_data.lead_types : state.metrics.lead_types;
const getLastOrders = (state, props) => state.users.profile_data.orders;
const getIsManager = (state, props) => state.auth.is_manager;
const getCurrentUserId = (state, props) => state.auth.sessionData.user_id;
const getSelectedUserId = (state, props) => props.data.userId;
const getAttachedCenterId = (state, props) => state.auth.sessionData.attached_center_id;
const getFeedbackStats = (state, props) => state.feeds.feedback_stats;

export const makeGetEmployeeProfile = () => {
    let getEmployeeProfile = createSelector(
        [getUsers, getCenterUsers, getChallengesUsers, getSelectedUser, getMetrics, getLeadTypes, getSelectedUserId, getIsManager, getCurrentUserId, getAttachedCenterId, getFeedbackStats, getLastOrders],
        (users, centerUsers, challengesUsers, selectedUser, metrics, lead_types, selectedUserId, is_manager, currentUserId, attached_center_id, feedback_stats, orders) => {
            let compareUsersView = !is_manager && currentUserId !== selectedUser.id;
            const pointsUserId = Object.values(challengesUsers).filter(user => user.in_crm_id === selectedUser.in_crm_id)[0].id;
            const centerSelectedUser = Object.values(centerUsers).filter(user => user.in_crm_id === selectedUser.in_crm_id)[0];
            const isMetricsLoaded = selectedUser.center_id === attached_center_id || (metrics.isLoaded && metrics.isLoaded());
            const points = isMetricsLoaded && metrics["0"].leaderBoards['this_month'].reduce((result, {user_id, leads}) => user_id === pointsUserId ? leads : result, 0);
            const metricRows = isMetricsLoaded && ['today', 'yesterday', 'this_week', 'this_month'].reduce((result, period) => {
                result[period] = Object.entries(metrics).filter(([metric_id]) => metric_id !== '0' && lead_types[metric_id]).map(([metric_id, {
                    leaderBoards,
                    goals
                }]) => {
                    let myLead = 0, myGoal = 0, userLead = 0, userGoal = 0;
                    for (let {user_id, leads} of leaderBoards[period]) {
                        if (user_id === currentUserId) {
                            myLead = leads;
                            myGoal = goals[user_id][period];
                        }
                        if (user_id === selectedUserId) {
                            userLead = leads;
                            userGoal = goals[user_id][period];
                        }
                    }
                    return {
                        title: lead_types[metric_id].title,
                        myLead,
                        myGoal,
                        myPercentage: (myGoal ? myLead / myGoal * 100 : 0).toFixed(1) + '%',
                        userLead: userLead,
                        userGoal: userGoal,
                        userPercentage: (userGoal ? userLead / userGoal * 100 : 0).toFixed(1) + '%',
                        unitType: lead_types[metric_id].unit_type,
                        display_decimal_digits: lead_types[metric_id].display_decimal_digits
                    };
                });

                return result;
            }, {});

            const feedbackCount = Object.values(feedback_stats).reduce((result, row) => row.receiver_id === pointsUserId ? result + 1 : result, 0);

            return {
                attached_center_id,
                is_manager,
                selectedUser,
                centerSelectedUser,
                points,
                metricRows,
                isMetricsLoaded,
                feedbackCount,
                orders,
                vsModeShow: compareUsersView,
            };
        }
    );

    getEmployeeProfile.selectorName = 'getEmployeeProfile';
    return getEmployeeProfile;
};