import React, {Component} from 'react';
import {connect} from "react-redux";
import {getUserImg} from "erj-common/generalUtils";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import CurrentTime from "../../containers/misc/CurrentTime";
import {MEASURE_METHOD, ORIGIN_TYPE, UNIT_TYPE} from "../../utils/metrics";
import MetricValueDisplay from "../../containers/misc/MetricValueDisplay";
import EmployeeProfileLink from "../../containers/widgets/EmployeeProfileLink";
import GoalRelationDisplay from "../../containers/misc/GoalRelationDisplay";
import PopOverLink from "../controls/PopOverLink";
import {equal} from 'mathjs';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BarChartIcon from '@material-ui/icons/BarChart';
import IconButton from "@material-ui/core/IconButton";


import {t} from 'react-i18nify';
const _ = require('lodash');

class MetricSummary extends Component {
    state = {
        last_transaction_index: 0,
        isLoading: false
    };
    handlePaginateLastTransactions = (event, direction) => {
        if (direction === 'prev')
            this.setState({last_transaction_index: this.state.last_transaction_index - 1});
        else if (direction === 'next' && this.props.metric.last_transactions[this.state.last_transaction_index + 1])
            this.setState({last_transaction_index: this.state.last_transaction_index + 1});
        else {
            this.setState({isLoading: true});
            this.props.getPrevTransaction(this.props.metric.id, this.props.metric.last_transactions[this.state.last_transaction_index].id);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.metric.last_transactions) {
            let listSize = nextProps.metric.last_transactions.length;
            if (!this.props.metric.last_transactions || listSize > this.props.metric.last_transactions.length)
                this.setState({
                    last_transaction_index: (this.state.last_transaction_index > 0 ? this.state.last_transaction_index + 1 : this.state.last_transaction_index),
                    isLoading: false
                });
            else if (this.props.metric.last_transactions && listSize === this.props.metric.last_transactions.length && nextProps.metric.last_transactions[listSize - 1].isLast && !this.props.metric.last_transactions[listSize - 1].isLast)
                this.setState({isLoading: false}); //no more transactions to show
        }
    }

    render() {
        const {metric, users, is_manager, center_is_parent, goalRelationDisplay, lead_types, mobile,history} = this.props;
        let maxVal = Math.max(metric.leads, metric.goals, 1);
        const goalPercent = metric.measure_method === MEASURE_METHOD.DESCENDING ? (metric.goals / metric.leads) : (metric.leads / metric.goals);
        let currentProgress = Math.round((goalPercent * 100) * 100) / 100;
        let currentPosition = 0;
        let targetPosition = 0;
        if (goalRelationDisplay === 2) {
            targetPosition = metric.ideal_pace ? Math.round((metric.measure_method === MEASURE_METHOD.DESCENDING ? (maxVal / metric.ideal_pace) : (metric.ideal_pace / maxVal)) * 100) : 0;
            currentPosition = currentProgress;
        } else if (metric.measure_method === MEASURE_METHOD.DESCENDING) {
            targetPosition = metric.leads;
            currentPosition = metric.goals;
        } else {
            targetPosition = metric.goals;
            currentPosition = metric.leads;
        }
        let last_transaction = metric.last_transactions && metric.last_transactions[this.state.last_transaction_index];
        const metricPerformance = (
            <div className={'metricPerformance'}>
                <span className={'leads'}>
                    <MetricValueDisplay key={metric.id + '_leads'} value={metric.leads || 0}
                                        unitType={metric.unit_type}
                                        shortNumOpts={{decimals: 1, maxLength: 5, shortThreshold: 10000}}
                                        roundOpts={{decimals: metric.display_decimal_digits}}/>
                </span>
                {!equal(metric.goals || 0, 0) && [
                    <span className={'slash'} key={'slash'}>/</span>,
                    <span className={'goal'} key={'goal'}>
                        <MetricValueDisplay key={metric.id + '_goals'} value={metric.goals}
                                            unitType={metric.unit_type}
                                            shortNumOpts={{decimals: 1, maxLength: 5, shortThreshold: 10000}}
                                            roundOpts={{decimals: metric.display_decimal_digits}}/>
                    </span>
                ]}
            </div>
        );
        const progressBar = (
            <div className={'progressBar'} key={'progressBar'}>
                <div className={'currentProgress'} style={{width: Math.min(currentProgress, 100) + '%'}}/>
            </div>
        );

        const weighedMetricHelpContent = metric.origin_type === ORIGIN_TYPE.WEIGHED && (
            <div className={'weight_table'}>
                {Object.entries(_.get(lead_types[metric.id], 'settings.child_metrics', {})).map(([lead_type_id, {
                    unit_value,
                    weight
                }]) => lead_types[lead_type_id] && (
                    <div className={'leadType_item'} key={lead_type_id}>
                                                     <span>
                                                         <MetricValueDisplay
                                                             unitType={lead_types[lead_type_id].unit_type}
                                                             value={unit_value} key={lead_type_id}/>
                                                         {' ' + lead_types[lead_type_id].title}
                                                     </span>
                        <span>{' = '}</span>
                        <span>
                                                        <MetricValueDisplay unitType={metric.unit_type} value={weight}
                                                                            key={metric.id}/>
                            {' ' + metric.title}
                                                     </span>
                    </div>
                ))}
            </div>
        );

        return (
            <div className={'metricCol'}>
                <div className={'metricBox'}>
                    <div className={'metricTitle'}>
                        {metric.title}
                    </div>
                    {!mobile && (
                        <div className={'drillDownIcons'}>
                            <Tooltip title={t('global.team_chart')}>
                                <IconButton onClick={(e) => {
                                    this.props.handleDrillDownMetric(metric.id, 'team-chart');
                                }}>
                                    <BarChartIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('global.timeline_chart')}>
                                <IconButton onClick={(e) => {
                                    this.props.handleDrillDownMetric(metric.id, 'timeline-chart');
                                }}>
                                    <TrendingUpIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                    {!mobile && metricPerformance}
                    <div className={'progressBarContainer'}>
                        {!center_is_parent && metric.ideal_pace > 0 && (
                            <PopOverLink body={(
                                <>
                                    {t('performance.keep_this_pace_to_reach')}
                                    <MetricValueDisplay value={(metric.leads / metric.ideal_pace) * metric.goals}
                                                        roundOpts={{decimals: metric.display_decimal_digits}}
                                                        unitType={metric.unit_type}/>
                                </>
                            )}>
                                {targetPosition && targetPosition > currentPosition ? (
                                    <div className={'leadsIndicator bad'}>!</div>
                                ) : (
                                    <div className={'leadsIndicator good'}>
                                        <Icon className="icon- checkMarkIcon">check_mark</Icon>
                                    </div>
                                )}
                            </PopOverLink>
                        )}
                        {!mobile ? progressBar : (
                            <div className={'metricPerformanceArea'}>
                                {metricPerformance}
                                {progressBar}
                            </div>
                        )}

                    </div>
                    {!center_is_parent && metric.ideal_pace > 0 && (
                        <div className={'fromPaceText localText'} key={'fromPaceText'}>
                            <GoalRelationDisplay {...metric}/>
                        </div>
                    )}
                    <div className={'metaInfo'}>
                        {metric.userPos && (
                            <Tooltip className={'myRank'} title={t('global.your_rank_relative_to_team')}
                                     classes={{popper: 'myRankTooltip'}}><span>#{metric.userPos}</span></Tooltip>
                        )}
                        {metric.origin_type === ORIGIN_TYPE.WEIGHED && (
                            mobile ? (
                                <PopOverLink body={weighedMetricHelpContent} className={'weightMetricHelp'}
                                             classNameSpace={'weightMetricHelp'} placement={'top-end'}
                                             disablePortal={false}>
                                    <span>?</span>
                                </PopOverLink>
                            ) : (
                                <Tooltip className={'weightMetricHelp'}
                                         title={weighedMetricHelpContent}
                                         classes={{popper: 'weightMetricHelpContent'}}>
                                    <span>?</span>
                                </Tooltip>
                            )
                        )}
                    </div>
                </div>
                {!center_is_parent && last_transaction && users[last_transaction.user_id] && (
                    <div className={'lastTransaction'}>
                        {!this.state.isLoading && (
                            is_manager ? (
                                <>
                                    <img className={'userImg'}
                                         alt={`userImg_${users[last_transaction.user_id].first_name}`}
                                         src={getUserImg(users[last_transaction.user_id].user_img, 'xs')}/>
                                    <div className={'details'}>
                                        <EmployeeProfileLink className={'name'}
                                                             userId={Number(last_transaction.user_id)}>{users[last_transaction.user_id].first_name}</EmployeeProfileLink>
                                        <span className={'time_ago'}>
                                            {' - '}<CurrentTime format={'timeAgo'}
                                                                compareTime={last_transaction.insert_time}/>
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <div className={'details'}>
                                    <span className={'time_ago'}>
                                        <span className={'time_ago'}>
                                            {t('performance.last_update') + ': '}<CurrentTime format={'timeAgo'}
                                                                                              compareTime={last_transaction.insert_time}/>
                                        </span>
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default MetricSummary;