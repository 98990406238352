import React, {useRef, useContext, useState, useEffect} from 'react';
import {t} from "react-i18nify";
import IconButton from "@material-ui/core/IconButton";
import Xarrow from 'react-xarrows';
import Draggable from 'react-draggable';
import ErjButtonMenu from "../controls/ErjButtonMenu";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import {
    ComponentLabel,
    LeadTypesContext,
    UnitsContext,
    ChangesContext,
    SavedComponentsContext,
    functionTypes,
    functionDefinitions, stringDataTypes, stringComparisonFunctionTypes, unitComparisonFunctionTypes, unitDataTypes
} from "./commission-context";
import {metricSort} from "../../utils/metrics";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import EditComponentModal from "./EditComponentModal";
import {Tooltip} from "@material-ui/core";


const dataTypes = [
    "monthly_performance",
    "goal_percent",
    "team_average_monthly_performance",
    "employee_data"
];

const functionsByType = _.groupBy(
    Object.entries(functionDefinitions).map(([name, funcDef]) => {
        funcDef.name = name;
        return funcDef;
    })
    , 'type');

const logicTestValues = ["=", "!=", ">", ">=", "<", "<="];

const stepTypes = {
    condition: {
        functions: ["logical"]
    },
    math: {
        functions: ["math"]
    }
};

export const AddButton = ({title, optionTypes, onClick, className, buttonProps, path_prefix = []}) => {
    optionTypes = {...optionTypes};
    if (optionTypes.buttonLabel) {
        title = optionTypes.buttonLabel;
        delete optionTypes.buttonLabel;
    }
    const isSingleLevelMenu = Object.keys(optionTypes).length === 1 && (Object.values(optionTypes)[0] === '*' || Object.values(optionTypes)[0].length === 1);
    if (!title) {
        title = isSingleLevelMenu ? t('commissions.add_' + Object.keys(optionTypes)[0] + (Object.values(optionTypes)[0] === '*' ? '' : '_' + Object.values(optionTypes)[0][0])) : t('global.add');
    }
    const units = useContext(UnitsContext);
    const lead_types = useContext(LeadTypesContext);
    const components = useContext(SavedComponentsContext);

    const changes = useContext(ChangesContext);
    const isChangedFromOriginal = isPathIncludedInChanges(changes, path_prefix);

    const getOptions = (isReIncluded) => (isSingleLevelMenu || Object.keys(optionTypes)[0] === 'logic_test')
        ? (
            Object.keys(optionTypes)[0] === 'data' && optionTypes.data[0] === 'units' ? (
                units.menu
            ) : (
                Object.keys(optionTypes)[0] === 'functions' ? (
                    functionsByType[Object.values(optionTypes)[0][0]].map(item => ({
                        id: item.name,
                        title: Object.keys(optionTypes)[0] === 'functions' ? t('commissions.functions.' + item.name) : item.name,
                        path: ['functions']
                    }))
                ) : (Object.values(optionTypes)[0] !== '*' ? Object.values(optionTypes)[0] : logicTestValues).map(item => ({
                    id: item,
                    title: item,
                    path: ['logic_test']
                }))
            )
        ) : Object.entries(optionTypes).reduce((list, [argType, optionValues]) => {
            let item = {id: argType, title: t('commissions.arg_types.' + argType)};
            switch (argType) {
                case "step": {
                    if (isReIncluded) {
                        return list;
                    }
                    item.children = Object.keys(stepTypes).map(item => ({
                        id: item,
                        title: t('commissions.step_types.' + item)
                    }));
                    break;
                }
                case "functions": {
                    if (isReIncluded) {
                        return list;
                    }
                    item.children = functionsByType[optionValues[0]].map(({name}) => ({
                        id: name,
                        title: t('commissions.functions.' + name)
                    }));
                    break;
                }
                case "data": {
                    const leadTypesList = _.toArray(lead_types).sort(metricSort);
                    item.children = dataTypes.map((name) => {
                        return {
                            id: name,
                            title: (
                                <ComponentLabel type={name} label={t('commissions.data_types.' + name)}/>
                            ),
                            children: (() => {
                                switch (name) {
                                    case 'monthly_performance':
                                    case 'goal_percent':
                                    case 'team_average_monthly_performance':
                                        return leadTypesList.map(({id, title}) => ({id, title}));
                                    case 'employee_data':
                                        let options = [
                                            {
                                                id: 'employed_since_months',
                                                title: t('commissions.employee_data.employed_since_months')
                                            }
                                        ];
                                        if (optionValues && optionValues.isAllowStringValue) {
                                            options.push({
                                                id: 'role',
                                                title: t('commissions.employee_data.role'),
                                                type: 'string'
                                            });
                                            options.push({
                                                id: 'group',
                                                title: t('commissions.employee_data.group'),
                                                type: 'string'
                                            });
                                            options.push({
                                                id: 'sub_group',
                                                title: t('commissions.employee_data.sub_group'),
                                                type: 'string'
                                            });
                                            if (units.menu.length > 0) {
                                                options.push({
                                                    id: 'assigned_center_ids',
                                                    title: t('commissions.employee_data.assigned_center_ids'),
                                                    type: 'string'
                                                });
                                            }
                                        }
                                        return options;
                                }
                            })()
                        };
                    });
                    break;
                }
                case "components": {
                    const componentsList = Object.values(components).filter(({type}) => type !== 'tier_table' || !isReIncluded).sort((a, b) => a.order - b.order);
                    if (!componentsList.length) {
                        return list;
                    }

                    item.children = componentsList.map(({key, type, title}) => {
                        const subItem = {
                            id: key,
                            title: (
                                <ComponentLabel type={type} label={title}/>
                            )
                        };
                        if (type === 'tier_table') {
                            subItem.children = getOptions(true);
                        }
                        return subItem;
                    });
                    break;
                }
            }
            list.push(item);
            return list;
        }, []);

    const options = getOptions();

    return (
        <ErjButtonMenu onClick={onClick} title={title} options={options}
                       isSkipMenuForSingleOption={options.length === 1 && (!options[0].children || !options[0].children.length)}
                       className={'addButton ' + (className || '') + (isChangedFromOriginal ? ' changed' : '')}
                       menuClassName={'commission-wizard-menu commission-wizard-designer'} {...buttonProps} />
    );
}

export const ArgValueContent = ({name, type, onEditComponent, getPlainText}) => {
    switch (type[0]) {
        case 'logic_test':
            return name;
        case 'data': {
            switch (type[1]) {
                case 'monthly_performance':
                case 'goal_percent':
                case 'team_average_monthly_performance':
                    const lead_types = useContext(LeadTypesContext);
                    if (!lead_types[name]) {
                        return '';
                    }
                    return getPlainText ? lead_types[name].title : (
                        <ComponentLabel type={type[1]} label={lead_types[name].title}/>
                    );
                case 'employee_data':
                    return getPlainText ? t('commissions.employee_data.' + name) : (
                        <ComponentLabel type={type[1]} label={t('commissions.employee_data.' + name)}/>
                    );
                case 'units':
                    const units = useContext(UnitsContext).list;
                    if (!units[name]) {
                        return '';
                    }
                    return getPlainText ? units[name].title : (
                        <ComponentLabel type={type[1]} label={units[name].title}/>
                    );
            }
            break;
        }
        case 'components': {
            const components = useContext(SavedComponentsContext);
            let subType, subName;
            if (type[1] && type[1].indexOf('tier_table') > -1 && type.length > 2) {
                subName = name;
                subType = type.slice(2);
                name = type[1];
            }
            return (
                <>
                    {subType && (
                        <>
                            <ArgValueContent name={subName} type={subType} onEditComponent={onEditComponent}
                                             getPlainText={getPlainText}/>
                            <span className={'tierTableSepArrow'}>
                                <DoubleArrowIcon/>
                            </span>
                        </>
                    )}
                    {getPlainText ? components[name].title : (
                        <ComponentLabel type={components[name].type} label={components[name].title}
                                        onEditComponent={() => onEditComponent(components[name])}/>
                    )}
                </>
            );
            break;
        }
        case 'string':
        case 'number': {
            return name;
            break;
        }
    }
}

const ArgValue = ({arg, handleRemoveArg, onEditComponent, path_prefix}) => {
    let {name, type} = arg;
    const isChangedFromOriginal = isPathIncludedInChanges(useContext(ChangesContext), path_prefix);
    return (
        <div className={'argValue' + (isChangedFromOriginal ? ' changed' : '')}>
            <div className={'argName'}>
                <ArgValueContent name={name} type={type} onEditComponent={onEditComponent}/>
            </div>
            <IconButton className={'removeArgBtn'} onClick={handleRemoveArg}>
                <DeleteIcon/>
            </IconButton>
        </div>
    );
}

const Func = ({
                  funcName,
                  funcArgs,
                  funcErrors,
                  handleAddArg,
                  handleRemove,
                  handleRemoveFunc,
                  onEditComponent,
                  path_prefix
              }) => {
    let args = [...funcArgs];
    let funcDef = {...functionTypes[functionDefinitions[funcName].type], ...functionDefinitions[funcName]};
    const min_args = funcDef.min_args || funcDef.num_args || funcDef.args.length;
    const max_args = funcDef.num_args || (funcDef.args && funcDef.args.length) || Infinity;
    for (let i = args.length; i < Math.min(Math.max(funcArgs.length, min_args) + 1, max_args); i++) {
        args.push(null); //add space for available args
    }

    const changes = useContext(ChangesContext);
    const isChangedFromOriginal = isPathIncludedInChanges(changes, path_prefix);
    const isStringComparison = functionDefinitions[funcName].type === 'condition' && args[0] && stringDataTypes.includes(args[0].name);
    if (isStringComparison) {
        funcDef = {...stringComparisonFunctionTypes[functionDefinitions[funcName].type], ...functionDefinitions[funcName]};
    }
    const isUnitComparison = functionDefinitions[funcName].type === 'condition' && args[0] && unitDataTypes.includes(args[0].name);
    if (isUnitComparison) {
        funcDef = {...unitComparisonFunctionTypes[functionDefinitions[funcName].type], ...functionDefinitions[funcName]};
    }
    return (
        <div className={'function' + (isChangedFromOriginal ? ' changed' : '')}>
            <IconButton className={'removeFuncBtn'} onClick={handleRemoveFunc}>
                <DeleteIcon/>
            </IconButton>
            <div className={'name'}>{t('commissions.functions.' + funcName)}</div>
            <div className={'args' + (funcErrors.indexOf('args') > -1 ? ' hasError' : '')}>
                {args.map((arg, index) => {
                    const argTypes = funcDef.args ? funcDef.args[index] : funcDef.arg_types;
                    return (
                        <>
                            {index !== 0 && (
                                <div className={'sep'}>{funcDef.sep}</div>
                            )}
                            <div className={'arg'}>
                                {arg ? (
                                    arg.type[0] === 'functions' ? (
                                        <Func funcName={arg.name} funcArgs={arg.args} funcErrors={arg.errors || []}
                                              path_prefix={path_prefix.concat(['args', index])}
                                              handleAddArg={handleAddArg}
                                              handleRemove={handleRemove}
                                              onEditComponent={onEditComponent}
                                              handleRemoveFunc={() => handleRemove(funcArgs, index, path_prefix.concat(['args', index]))}/>
                                    ) : (
                                        <ArgValue arg={arg}
                                                  handleRemoveArg={() => handleRemove(funcArgs, index, path_prefix.concat(['args', index]))}
                                                  onEditComponent={onEditComponent}
                                                  path_prefix={path_prefix.concat(['args', index])}/>
                                    )
                                ) : (
                                    <AddButton optionTypes={argTypes} path_prefix={path_prefix.concat(['args', index])}
                                               onClick={(name, path) => {
                                                   handleAddArg(path || [name], name, funcArgs, index, path_prefix.concat(['args', index]))
                                               }}/>
                                )}
                            </div>
                        </>
                    );
                })}
            </div>
        </div>
    );
}

const Step = ({
                  step,
                  actionHandlers,
                  removeStep,
                  addPrevStep,
                  moveToSavedComponent,
                  arrowRef,
                  step_key,
                  path_prefix
              }) => {
    const primaryStepBox = {id: step.key, ref: arrowRef || useRef(null)};
    const stepBoxArrowOrigin = {id: step.key + '_step', ref: useRef(null)};
    const stepBoxes = Object.fromEntries(['step_true', 'step_false'].map(step_key => [step_key, {
        id: step[step_key] ? step[step_key].key : step.key + '_' + step_key,
        ref: useRef(null)
    }]));

    const {
        handleAddStep,
        handleAddArg,
        handleRemove,
        onEditComponent,
        handleSwapConditionSteps,
        handleAddPrevStep,
        handleRemoveStep
    } = actionHandlers;

    const changes = useContext(ChangesContext);
    const isStepChangedFromOriginal = isPathIncludedInChanges(changes, path_prefix);

    return (
        <div className={'stepContainer'} ref={primaryStepBox.ref} id={primaryStepBox.id}>
            {['step_true', 'step_false'].indexOf(step_key) > -1 && (
                <div className={'conditionOutStepLabel ' + step_key}>
                    {t('global.' + (step_key === 'step_true' ? 'yes' : 'no'))}
                </div>
            )}
            {Object.keys(stepTypes).indexOf(step.type) > -1 ? (
                <>
                    <div
                        className={'step' + (isStepChangedFromOriginal ? ' changed' : '') + (!step.func && step.errors && step.errors.indexOf('func') > -1 ? ' hasError' : '')}
                        ref={stepBoxArrowOrigin.ref} id={stepBoxArrowOrigin.id}>
                        {(step.type !== 'condition' || !step.step_true || !step.step_false) && (
                            <IconButton className={'removeStepBtn'} onClick={removeStep}>
                                <DeleteIcon/>
                            </IconButton>
                        )}
                        <Tooltip title={t('commissions.add_preliminary_condition')}>
                            <IconButton className={'addPrevStepBtn'} onClick={addPrevStep}>
                                <AddIcon/>
                            </IconButton>
                        </Tooltip>
                        {moveToSavedComponent && (
                            <Tooltip title={t('commissions.save_as_component')}>
                                <IconButton className={'moveToSavedComponentBtn'} onClick={moveToSavedComponent}>
                                    <SaveAltIcon/>
                                </IconButton>
                            </Tooltip>
                        )}
                        <div className={'stepTitle'}>{t('commissions.step_types.' + step.type)}</div>
                        {!step.func ? (
                            <AddButton optionTypes={{functions: stepTypes[step.type].functions}}
                                       onClick={(funcName) => handleAddArg(['functions'], funcName, step, 'func', path_prefix.concat(['func']))}/>
                        ) : (
                            <Func funcName={step.func.name} funcArgs={step.func.args}
                                  funcErrors={step.func.errors || []} handleAddArg={handleAddArg}
                                  onEditComponent={onEditComponent}
                                  path_prefix={path_prefix.concat(['func'])}
                                  handleRemove={handleRemove}
                                  handleRemoveFunc={() => handleRemove(step, 'func', path_prefix.concat(['func']))}
                            />
                        )}
                    </div>
                    {step.type === 'condition' && (
                        <>
                            <div className={'conditionOutSteps'}>
                                {['step_true', 'step_false'].map(step_key => (
                                    <>
                                        {!step[step_key] ? (
                                            <div
                                                className={'stepContainer' + (step.errors && step.errors.indexOf(step_key) > -1 ? ' hasError' : '')}
                                                ref={stepBoxes[step_key].ref}
                                                id={stepBoxes[step_key].id}>
                                                <AddButton title={t('commissions.add_' + step_key)}
                                                           optionTypes={{
                                                               "step": "*",
                                                               "components": "*",
                                                               "data": "*",
                                                               "number": true
                                                           }}
                                                           path_prefix={path_prefix.concat([step_key])}
                                                           onClick={(name, path) => handleAddStep(name, path, step, step_key, path_prefix.concat([step_key]))}/>
                                            </div>
                                        ) : (
                                            <Step
                                                step={step[step_key]}
                                                actionHandlers={{
                                                    handleAddStep,
                                                    handleAddPrevStep,
                                                    handleAddArg,
                                                    handleRemove,
                                                    handleRemoveStep,
                                                    onEditComponent,
                                                    handleSwapConditionSteps
                                                }}
                                                removeStep={() => handleRemoveStep(step, step_key, path_prefix.concat([step_key]))}
                                                addPrevStep={() => handleAddPrevStep(step[step_key], step, step_key, path_prefix.concat([step_key]))}
                                                moveToSavedComponent={() => {
                                                    onEditComponent({
                                                        type: 'process',
                                                        step: step[step_key]
                                                    }, true, (componentKey) => {
                                                        console.log({componentKey});
                                                        handleRemoveStep(step, step_key, path_prefix.concat([step_key]));
                                                        handleAddStep(componentKey, ["components"], step, step_key, path_prefix.concat([step_key]))
                                                    });
                                                }}
                                                arrowRef={stepBoxes[step_key].ref}
                                                step_key={step_key}
                                                path_prefix={path_prefix.concat([step_key])}
                                            />
                                        )}
                                        <Xarrow start={stepBoxArrowOrigin.id} end={stepBoxes[step_key].id}
                                                startAnchor={"right"}
                                                color={step_key === 'step_true' ? '#609f60' : '#9f6060'}/>
                                    </>
                                ))}
                                {(step["step_true"] || step["step_false"]) && (
                                    <Tooltip title={t('commissions.swap_steps')}>
                                        <IconButton className={'swapConditionStepsBtn'}
                                                    onClick={() => handleSwapConditionSteps(step, path_prefix.concat([step_key]))}>
                                            <SwapVertIcon/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>

                        </>
                    )}
                </>
            ) : (
                <ArgValue arg={step} handleRemoveArg={removeStep} onEditComponent={onEditComponent}
                          path_prefix={path_prefix}/>
            )}
        </div>
    );
};

const isPathIncludedInChanges = (changes, path) => {
    const pathVal = path.join('.')
    return (changes || []).filter(change => change.join('.') === pathVal)[0];
}

export const Designer = ({selectedComponentKey, components, onSave, setConfirmAction, onEditComponent}) => {
    const selectedComponent = components[selectedComponentKey];
    const otherComponents = {...components};
    delete otherComponents[selectedComponentKey];
    delete otherComponents['main'];

    const handleAddStep = (name, path, stepNode, stepPath, path_prefix) => {
        const stepType = name;
        if (!stepNode) {
            stepNode = selectedComponent;
            stepPath = 'step';
        }
        if (path && path[0] === 'step') {
            stepNode[stepPath] = {
                key: stepType + '_' + Math.floor(Date.now()),
                type: stepType,
                func: null
            }
            onSave(selectedComponent, path_prefix);
        } else {
            handleAddArg(path || [name], name, stepNode, stepPath, path_prefix);
        }
    }

    const handleAddPrevStep = (currentNode, stepNode, stepPath, path_prefix) => {
        const stepType = 'condition';
        if (!stepNode) {
            stepNode = selectedComponent;
            stepPath = 'step';
        }
        stepNode[stepPath] = {
            key: stepType + '_' + Math.floor(Date.now()),
            type: stepType,
            func: null
        }
        stepNode[stepPath]["step_true"] = currentNode;
        onSave(selectedComponent, path_prefix);
    }

    const handleSwapConditionSteps = (stepNode, path_prefix) => {
        const step_true = stepNode["step_true"];
        stepNode["step_true"] = stepNode["step_false"];
        stepNode["step_false"] = step_true;
        onSave(selectedComponent, path_prefix);
    }

    const handleAddArg = (type, name, addToObject, path, path_prefix) => {
        if (type[0] === 'condition') {
            type = ['functions'];
            name = '$cond';
        }

        if (type[0] === 'number') {
            setEditNumberModal({addToObject, path, path_prefix});
            return;
        }

        if (type[0] === 'string') {
            setEditTextModal({addToObject, path, path_prefix});
            return;
        }

        if (type[0] === 'components' && type.length === 1 && components[name] && components[name].type === 'process' && findComponentRecursively(selectedComponentKey, components[name]).length) {
            //will cause circular reference => reject
            window.alert(t('commissions.recursive_error', {selected_component: components[name].title}));
            return;
        }

        addToObject[path] = {
            key: type[0] + '_' + Math.floor(Date.now()),
            type,
            name
        };

        if (stringDataTypes.includes(name)) { //reset other args in case it's a condition (currently on relevant for role)
            if (addToObject[1]) {
                addToObject[1] = null;
            }
            if (addToObject[2]) {
                addToObject[2] = null;
            }
        }

        if (type[0] === 'functions') {
            addToObject[path].args = [];
        }
        onSave(selectedComponent, path_prefix);
    };

    const handleRemoveStep = (removeFromObject, path, path_prefix) => {
        removeFromObject[path] = removeFromObject[path] ? (removeFromObject[path]["step_true"] || removeFromObject[path]["step_false"] || null) : null;
        onSave(selectedComponent, path_prefix);
    };

    const handleRemove = (removeFromObject, path, path_prefix) => {
        removeFromObject[path] = null
        onSave(selectedComponent, path_prefix);
    };

    const findComponentRecursively = (key, component, parentProcessComponent) => {
        if (!parentProcessComponent) {
            parentProcessComponent = component;
        }
        let results = [];
        if (component.type === 'process' && component.step) {
            results = results.concat(findComponentRecursively(key, component.step, component));
        } else if (component.type === 'condition') {
            for (let path of ['func', 'step_true', 'step_false']) {
                if (component[path]) {
                    results = results.concat(findComponentRecursively(key, component[path], parentProcessComponent));
                }
            }
        } else if (component.type === 'math' && component.func) {
            results = results.concat(findComponentRecursively(key, component.func, parentProcessComponent));
        } else if (component.args) {
            for (let i = 0; i < component.args.length; i++) {
                if (component.args[i]) {
                    results = results.concat(findComponentRecursively(key, component.args[i], parentProcessComponent));
                }
            }
        } else if (((component.type[0] === 'components' && !component.type[2]) || component.type[2] === 'components') && components[component.name].type === 'process') {
            if (component.name === key) {
                return parentProcessComponent;
            } else {
                results = results.concat(findComponentRecursively(key, components[component.name]));
            }
        }

        return results.filter(parentProcessComponent => parentProcessComponent);
    }

    const [editNumberModalData, setEditNumberModal] = useState(null);
    const [editTextModalData, setEditTextModal] = useState(null);

    const [dragPosition, setDragPosition] = useState({x: 0, y: 0});

    useEffect(() => setDragPosition({x: 0, y: 0}), [selectedComponentKey]);

    const designerElement = useRef(null);

    const DESIGNER_PADDING = 30;

    const parentComponents = Object.values(_.keyBy(findComponentRecursively(selectedComponentKey, components.main), 'key')); //to prevent duplicates
    console.log(parentComponents);

    return (
        <div className={'designer'} ref={designerElement}>
            {parentComponents.length > 0 && (
                <Tooltip title={t('commissions.back_to_parent_component')}>
                    <ErjButtonMenu className={'parentComponentsMenuBtn'}
                                   onClick={(val) => onEditComponent(components[val])}
                                   title={(
                                       <MergeTypeIcon className={'parentComponentsMenuIcon'}/>
                                   )}
                                   isSkipMenuForSingleOption={true}
                                   options={parentComponents.map(({key, title}) => ({
                                       id: key,
                                       title: key !== 'main' ? title : t('commissions.final_commission')
                                   }))}/>
                </Tooltip>
            )}
            <Draggable position={dragPosition} onStop={(e, dragData) => {
                let {x, y} = dragData;
                console.log(x, y, dragData.node.offsetHeight, designerElement.current.offsetHeight);
                if (x < 0 && (dragData.node.offsetWidth + x - DESIGNER_PADDING) < designerElement.current.offsetWidth) {
                    x = designerElement.current.offsetWidth - dragData.node.offsetWidth;
                } else if (x > 0) {
                    x = 0;
                }

                if (y < 0 && (dragData.node.offsetHeight + y - DESIGNER_PADDING) < designerElement.current.offsetHeight) {
                    y = designerElement.current.offsetHeight - dragData.node.offsetHeight;
                } else if (y > 0) {
                    y = 0;
                }

                setDragPosition({x, y});
            }}>
                <div className={'diagram'}>
                    <SavedComponentsContext.Provider value={otherComponents}>
                        {selectedComponent.step ? (
                            <Step
                                step={selectedComponent.step}
                                actionHandlers={{
                                    handleAddStep,
                                    handleAddArg,
                                    onEditComponent,
                                    handleRemove,
                                    handleSwapConditionSteps,
                                    handleAddPrevStep,
                                    handleRemoveStep
                                }}
                                removeStep={() => handleRemoveStep(selectedComponent, 'step', [selectedComponent.key, 'step'])}
                                addPrevStep={() => handleAddPrevStep(selectedComponent.step, selectedComponent, 'step', [selectedComponent.key, 'step'])}
                                path_prefix={[selectedComponent.key, 'step']}
                            />
                        ) : (
                            <AddButton path_prefix={[selectedComponent.key, 'step']} optionTypes={{
                                "step": "*",
                                "components": "*",
                                "data": "*",
                                "number": true
                            }}
                                       onClick={(name, path) => handleAddStep(name, path, null, null, [selectedComponent.key, 'step'])}/>
                        )}
                    </SavedComponentsContext.Provider>
                </div>
            </Draggable>
            {editNumberModalData && (
                <EditComponentModal component={{type: 'numberOnly'}} onSave={(result) => {
                    if (result) {
                        editNumberModalData.addToObject[editNumberModalData.path] = {
                            key: 'number_' + Math.floor(Date.now()),
                            type: ['number'],
                            name: result.value
                        };
                        onSave(selectedComponent, editNumberModalData.path_prefix);
                    }
                    setEditNumberModal(null);
                }}/>
            )}
            {editTextModalData && (
                <EditComponentModal component={{type: 'textValue'}} onSave={(result) => {
                    if (result) {
                        editTextModalData.addToObject[editTextModalData.path] = {
                            key: 'string_' + Math.floor(Date.now()),
                            type: ['string'],
                            name: result.value
                        };
                        onSave(selectedComponent, editTextModalData.path_prefix);
                    }
                    setEditTextModal(null);
                }}/>
            )}
        </div>
    );
}