import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment-timezone';
import {getSplitTimeDiff} from "../../utils/generalUtils";

class CurrentTime extends Component {
    render() {
        const {currentTime, format, renderIf, compareTime} = this.props;
        const children = this.props.renderIn || this.props.children;

        let result = '';
        if (format === 'dayPartGreeting')
            result = currentTime.hour() > 12 ? 'good_afternoon' : 'good_morning';
        else if (format === 'splitTimeDiff')
            result = getSplitTimeDiff(currentTime, compareTime);
        else if (format === 'timeAgo')
            result = compareTime ? currentTime.to(compareTime) : '';
        else if (format === 'duration')
            result = currentTime.to(compareTime, true);
        else if (format === 'currentTime')
            result = moment(currentTime);
        else
            result = currentTime.format(format);

        return children ? children(result) : result;
    }
}


function mapStateToProps(state) {
    const {global} = state;
    return { currentTime: global.currentTime };
}

export default connect(mapStateToProps)(CurrentTime);