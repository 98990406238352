import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import {getPrevTransaction} from "../../actions/metrics";

import {IsUserWorkedThen, metricSort, ORIGIN_TYPE, PERFORMANCE_DISPLAY_METHOD} from "../../utils/metrics";
import MetricSummary from "../../components/widgets/MetricSummary";
import {calculateIdealPace, getStartNEndDatesOfMonth} from "erj-common/generalUtils";

import moment from 'moment';
import {sum, fraction, divide} from 'mathjs';
import ViewPort from "../misc/Viewport";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Button from "@material-ui/core/Button";

import {t} from 'react-i18nify';
import {RECEIVED_USER_PREFERENCES, updateUserPreferences} from "../../actions/auth";

const _ = require('lodash');

class MetricsWidget extends Component {

    state = {
        timeFilter: this.props.preferences.default_timeframe || 'today',
        isTeamMetrics: '0',
        metricsPage: 0,
        isMetricFilterOpen: false,
    };
    


    handleTabChange = (event, activeTab) => {
        if (this.props.disabledFeatures.changeMetricsTimeFilter) {
            this.setState({isMetricFilterOpen: false});
            return;
        }

        this.setState({timeFilter: activeTab, isMetricFilterOpen: false});
        const preferences = {...this.props.preferences, default_timeframe: activeTab};
        this.props.dispatch(updateUserPreferences(preferences));
        this.props.dispatch({type: RECEIVED_USER_PREFERENCES, preferences});
    };

    handleDrillDownMetric = (metric_id, page) => {
        const preferences = {...this.props.preferences, default_metric: metric_id};
        this.props.dispatch(updateUserPreferences(preferences));
        this.props.dispatch({type: RECEIVED_USER_PREFERENCES, preferences});
        this.props.history.push(process.env.REACT_APP_ROUTE_PATH + "/performance/" + page);
    }

    handleGroupByChange = (event, activeTab) => {
        if (!this.props.disabledFeatures.teamPerformanceTab) {
            this.setState({isTeamMetrics: activeTab});
        }
    };

    getPrevTransaction = (metric_id, last_lead_id) => {
        setImmediate(() => this.props.dispatch(getPrevTransaction({metric_id, last_lead_id})));
    };

    isHideMetric = is_show => (!is_show || (is_show === 1 && this.state.timeFilter === 'this_month') || (is_show === 2 && this.state.timeFilter !== 'this_month'));

    changeMetricFilter(e, key) {
        e.preventDefault();
        const isTeamMetrics = String(Number(key === 3 || key === 4));
        const timeFilter = key === 1 || key === 3 ? 'today' : 'this_month';
        this.setState({isTeamMetrics, timeFilter, isMetricFilterOpen: false});
    }

    handleMetricsFilterClick = (e) => {
        e.preventDefault();
        this.setState({isMetricFilterOpen: !this.state.isMetricFilterOpen});
    };

    render() {
        const {
            center_shifts, userMetrics, userData, currentDateTimeMinute, users, metrics, lead_types, last_transactions, is_manager, goalDisplayMethod, performanceDisplayMethod
            , goalRelationDisplay, first_day_of_month, months, center_is_parent, center_title, show_inactive_users
        } = this.props;

        const {timeFilter, isTeamMetrics} = this.state;
        const groupBy = (is_manager || isTeamMetrics === '1') ? 'team' : 'personal';

        let relevantMetrics = [];
        let currentTime = moment(currentDateTimeMinute);
        if (groupBy === 'team') {
            if (Object.keys(metrics).length && Object.keys(lead_types).length) {
                Object.keys(metrics).forEach(key => {
                    if (Number(key)) {
                        if (!lead_types[key] || !lead_types[key].is_active || this.isHideMetric(lead_types[key].is_show_in_home))
                            return;
                        let leaderboards = metrics[key].leaderBoards[timeFilter].filter(row => users[row.user_id]);
                        let goals = metrics[key].goals;
                        let userPos;
                        leaderboards.sort((a, b) => {
                            return performanceDisplayMethod === PERFORMANCE_DISPLAY_METHOD.PERCENT_OF_GOAL ? (b.leads / b.goals) - (a.leads / a.goals) : b.leads - a.leads;
                        });
                        for (let i = 0; i < leaderboards.length; i++) {
                            const row = leaderboards[i];
                            if (!is_manager && row.user_id === userData.id) {
                                userPos = i + 1;
                            }
                        }

                        let metric = {
                            id: key,
                            origin_type: lead_types[key].origin_type,
                            unit_type: lead_types[key].unit_type,
                            display_decimal_digits: lead_types[key].display_decimal_digits,
                            measure_method: lead_types[key].measure_method,
                            is_primary: lead_types[key].is_primary,
                            title: lead_types[key].title,
                            leads: metrics[key].leaderBoards[timeFilter].filter(curr => !curr.user_id)[0] ? metrics[key].leaderBoards[timeFilter].filter(curr => !curr.user_id)[0].leads : 0,
                            last_transactions: last_transactions[key],
                            order: lead_types[key].order,
                            userPos
                        };
                        const usersStats = _.keyBy(metrics[key].leaderBoards[timeFilter], 'user_id');
                        let idealPace = 0;
                        metric.goals = Object.keys(goals).reduce((prev, user_id) => {
                            if (user_id === '0' || metrics[key].ug_displayStatus[user_id][timeFilter] === 2) {
                                return prev;
                            }
                            if ((users[user_id] && users[user_id].isActive && IsUserWorkedThen(users[user_id].daysWorked, timeFilter, goalDisplayMethod)) || (usersStats[user_id] && usersStats[user_id].leads && show_inactive_users)) {
                                const goal = goals[user_id][timeFilter];
                                idealPace += calculateIdealPace({
                                    moment,
                                    currentTime,
                                    timeframe: timeFilter !== 'this_month' ? 'daily' : 'monthly',
                                    goal,
                                    work_days_goal: (goals[user_id] && goals[user_id].work_days) || 0,
                                    actual_work_days: users[user_id] ? users[user_id].daysWorked : [],
                                    last_shift_start: users[user_id] ? users[user_id].last_shift_start : new Date(0),
                                    center_shifts,
                                    first_day_of_month,
                                    months,
                                    origin_type: metric.origin_type
                                });

                                if (goal) {
                                    return prev.concat(goal);
                                }
                            }
                            return prev;
                        }, []);

                        const sumGoals = metric.goals.length ? sum(metric.goals.map(goal => fraction(goal))) : 0;

                        if ([ORIGIN_TYPE.RATIO, ORIGIN_TYPE.AVERAGE, ORIGIN_TYPE.SCORE].indexOf(metric.origin_type) !== -1) {
                            metric.goals = metric.goals.length && sumGoals ? divide(sumGoals, metric.goals.length) : 0;
                            metric.ideal_pace = metric.goals;
                        } else {
                            metric.ideal_pace = idealPace;
                            metric.goals = sumGoals;
                        }

                        relevantMetrics.push(metric);
                    }
                });
            }
        } else {
            if (userMetrics && userData) {
                Object.keys(userMetrics).forEach(key => {
                    if (!Number(key) || !lead_types[key] || this.isHideMetric(lead_types[key].is_show_in_home))
                        return;

                    let metric = {
                        id: key,
                        title: lead_types[key].title,
                        origin_type: lead_types[key].origin_type,
                        unit_type: lead_types[key].unit_type,
                        display_decimal_digits: lead_types[key].display_decimal_digits,
                        measure_method: lead_types[key].measure_method,
                        leads: (userMetrics[key].leaderBoard && userMetrics[key].leaderBoard[timeFilter] && userMetrics[key].leaderBoard[timeFilter].leads) || 0,
                        last_transactions: last_transactions[key],
                        goals: IsUserWorkedThen(userData.daysWorked, timeFilter, goalDisplayMethod) ? (userMetrics[key].goals && userMetrics[key].goals[timeFilter] || 0) : 0,
                        order: lead_types[key].order,
                        is_primary: lead_types[key].is_primary
                    };
                    const {last_shift_start, shift_length} = userData;
                    metric.ideal_pace = calculateIdealPace({
                        moment,
                        currentTime,
                        timeframe: timeFilter !== 'this_month' ? 'daily' : 'monthly',
                        goal: metric.goals,
                        last_shift_start,
                        actual_work_days: userData.daysWorked,
                        work_days_goal: (userMetrics[key].goals && userMetrics[key].goals.work_days) || 0,
                        center_shifts,
                        first_day_of_month,
                        months,
                        shift_length,
                        origin_type: lead_types[key].origin_type
                    });

                    relevantMetrics.push(metric);
                });
            }
        }

        relevantMetrics.sort(metricSort);
        let metricsPerPage = 6;
        const numMetricsPages = Math.ceil(relevantMetrics.length / metricsPerPage);
        let metricsPage = Math.min(this.state.metricsPage + 1, Math.ceil(relevantMetrics.length / metricsPerPage)) - 1;
        const metricsFromIndex = metricsPage * metricsPerPage;
        const metricsData = relevantMetrics.slice(metricsFromIndex, metricsFromIndex + metricsPerPage);

        let metricsFilterOptions = [];
        metricsFilterOptions.push({title: `${t('global.my_performance')} ${t('global.today')}`, key: 1});
        metricsFilterOptions.push({title: `${t('global.my_performance')} ${t('global.this_month')}`, key: 2});
        metricsFilterOptions.push({title: `${t('global.my_team_performance')} ${t('global.today')}`, key: 3});
        metricsFilterOptions.push({
            title: `${t('global.my_team_performance')} ${t('global.this_month')}`,
            key: 4
        });

        console.log("metrics widget");
        let metricFilterTitle = this.state.isTeamMetrics === '0' ? t('global.my_performance') : t('global.my_team_performance');
        metricFilterTitle = metricFilterTitle + ' ' + (this.state.timeFilter === 'today' ? t('global.today') : t('global.this_month'));
        return Object.keys(metricsData).length !== 0 && (
            <ViewPort>
                {({mobile}) => (
                    <div
                        className={'metrics-summary-widget ' + groupBy + '-metrics-widget'}>
                        <div className={'header-line'}>
                            {is_manager ? (
                                <div
                                    className={"teamMetricsTitle localText"}>{t('performance.team_performance') + ` (${center_title})`}</div>
                            ) : (
                                mobile ? (
                                    <div className={'metrics-filter-container'}>
                                        <Icon className="icon- dropdownIcon dropdownIcon-erjMenu-menu arrow-down">dropdown_arrow</Icon>
                                        <Button onClick={this.handleMetricsFilterClick} className="metrics-filter"
                                                ref={ref => this.anchorEl = ref}>
                                            <span>{metricFilterTitle}</span>
                                        </Button>
                                        <Popper className={'menu'} open={this.state.isMetricFilterOpen}
                                                placement="bottom" anchorEl={this.anchorEl} transition
                                                disablePortal={true}>
                                            {({TransitionProps, placement}) => (
                                                <Grow {...TransitionProps}>
                                                    <Paper className="userProfileMenu">
                                                        <ClickAwayListener
                                                            onClickAway={(e) => this.setState({isMetricFilterOpen: false})}>
                                                            <MenuList role="menu">
                                                                {metricsFilterOptions.map(item => (
                                                                    <MenuItem key={item.key}
                                                                              onClick={(e) => this.changeMetricFilter(e, item.key)}>
                                                                            <span
                                                                                className={"menuItemLabel"}>{t(item.title)}</span>
                                                                    </MenuItem>
                                                                ))}
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </div>
                                ) : (
                                    <Tabs value={isTeamMetrics} onChange={this.handleGroupByChange}
                                          className="tabs metrics-widget-groupby" classes={{indicator: "indicator"}}>
                                        <Tab disableRipple={true} key="personal" value="0"
                                             label={<span
                                                 className={'tabLabel'}>{t('global.my_performance')}</span>}/>
                                        <Tab disableRipple={true} key="team" value="1"
                                             label={<span
                                                 className={'tabLabel'}>{t('global.my_team_performance')}</span>}/>
                                    </Tabs>
                                )
                            )}
                            {!mobile && (
                                <div className={'metrics-filter-container'}>
                                    <Icon
                                        className="icon- dropdownIcon dropdownIcon-erjMenu-menu arrow-down">dropdown_arrow</Icon>
                                    <Button onClick={this.handleMetricsFilterClick} className="metrics-filter"
                                            ref={ref => this.anchorEl = ref}>
                                        <span>{t('global.' + this.state.timeFilter)}</span>
                                    </Button>
                                    <Popper className={'menu'} open={this.state.isMetricFilterOpen}
                                            placement="top-start" anchorEl={this.anchorEl} transition
                                            disablePortal={true}>
                                        {({TransitionProps, placement}) => (
                                            <Grow {...TransitionProps}>
                                                <Paper className="userProfileMenu">
                                                    <ClickAwayListener
                                                        onClickAway={(e) => this.setState({isMetricFilterOpen: false})}>
                                                        <MenuList role="menu">
                                                            <MenuItem key={'today'}
                                                                      onClick={(e) => this.handleTabChange(e, 'today')}>
                                                                <span
                                                                    className={"menuItemLabel"}>{t('global.today')}</span>
                                                            </MenuItem>
                                                            <MenuItem key={'yesterday'}
                                                                      onClick={(e) => this.handleTabChange(e, 'yesterday')}>
                                                                <span
                                                                    className={"menuItemLabel"}>{t('global.yesterday')}</span>
                                                            </MenuItem>
                                                            <MenuItem key={'this_month'}
                                                                      onClick={(e) => this.handleTabChange(e, 'this_month')}>
                                                                <span
                                                                    className={"menuItemLabel"}>{t('global.this_month')}</span>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </div>
                        <div className={'metrics'}>
                            {metricsData.map((metric, key) => (
                                <MetricSummary key={key} metric={metric} users={users} lead_types={lead_types}
                                               mobile={mobile}
                                               getPrevTransaction={this.getPrevTransaction} is_manager={is_manager}
                                               center_is_parent={center_is_parent}
                                               goalRelationDisplay={[ORIGIN_TYPE.RATIO, ORIGIN_TYPE.AVERAGE, ORIGIN_TYPE.SCORE].indexOf(metric.origin_type) !== -1 ? 1 : goalRelationDisplay}
                                               handleDrillDownMetric={this.handleDrillDownMetric}/>
                            ))}
                            {!mobile && [
                                metricsPage > 0 && (
                                    <IconButton className={'prevButton'} key={'prevButton'}
                                                onClick={() => this.setState({metricsPage: metricsPage - 1})}>
                                        <NavigateBeforeIcon/>
                                    </IconButton>
                                ),
                                (metricsFromIndex + metricsPerPage) < relevantMetrics.length && (
                                    <IconButton className={'nextButton'} key={'nextButton'}
                                                onClick={() => this.setState({metricsPage: metricsPage + 1})}>
                                        <NavigateNextIcon/>
                                    </IconButton>
                                ),
                                numMetricsPages > 1 && (
                                    <div className={'paginationDots'} key={'paginationDots'}>
                                        {_.fill(Array(numMetricsPages), 1).map((element, index) => (
                                            <IconButton key={index}
                                                        className={'dot' + (index === metricsPage ? ' active' : '')}
                                                        onClick={() => this.setState({metricsPage: index})}>&#9679;</IconButton>
                                        ))}
                                    </div>
                                )
                            ]}
                        </div>
                        {!mobile && !center_is_parent && (
                            <div className={'advancedLink-container'}>
                                <Link className={'advancedLink'}
                                      to={process.env.REACT_APP_ROUTE_PATH + '/performance/team-chart'}>{t('global.advanced_performance')}</Link>
                                <Icon className={'icon- bold_arrow_right'}>bold_arrow_right</Icon>
                            </div>
                        )}
                    </div>
                )}
            </ViewPort>
        )
    }
}

function mapStateToProps(state) {
    const {users, metrics, auth, global} = state;
    let center_shifts = auth.sessionData.additionalData.center_shifts;
    let userMetrics = auth.userMetrics;
    let userData = auth.userData;
    let currentDateTimeMinute = global.currentDateTimeMinute;
    let goalRelationDisplay = auth.sessionData.goal_relation_display;
    let goalDisplayMethod = auth.sessionData.goal_display_method;
    let performanceDisplayMethod = auth.sessionData.performance_display_method;
    let center_currency_symbol = auth.sessionData.center_currency_symbol;
    return {
        preferences: (auth.accountData && auth.accountData.preferences) || {},
        center_shifts,
        userMetrics,
        userData,
        users: users.users,
        lead_types: metrics.lead_types,
        metrics: metrics.metrics,
        last_transactions: metrics.last_transactions,
        center_currency_symbol,
        currentDateTimeMinute,
        is_manager: Boolean(auth.is_manager),
        center_is_parent: auth.sessionData && auth.sessionData.additionalData && Boolean(auth.sessionData.additionalData.center_is_parent),
        goalDisplayMethod,
        performanceDisplayMethod,
        goalRelationDisplay,
        first_day_of_month: auth.sessionData.additionalData.first_day_of_month,
        months: auth.sessionData.additionalData.months,
        center_title: auth.sessionData.center_title,
        show_inactive_users: auth.sessionData.show_inactive_users,
        disabledFeatures: global.disabledFeatures
    };
}

export default connect(mapStateToProps)(MetricsWidget);