import {
    RECEIVED_GENERAL_SETTINGS_DATA,
    RECEIVED_UPDATED_GENERAL_SETTINGS_DATA,
    RECEIVED_SECURITY_SETTINGS_DATA,
    RECEIVED_CHALLENGES_SETTINGS_DATA
} from "../actions/generalSettings";

const initialState = {
    generalSettingsData: {},
    securitySettingsData: {},
    challengesSettingsData: {},
    currencies: []
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function generalSettings(state = initializeState(), action = {}) {
    switch (action.type) {
        case RECEIVED_GENERAL_SETTINGS_DATA:
            if (action.payload.generalSettingsData.generalSettings[0].schedule_modifies_users && typeof (action.payload.generalSettingsData.generalSettings[0].schedule_modifies_users) === 'string') {
                action.payload.generalSettingsData.generalSettings[0].schedule_modifies_users = JSON.parse(action.payload.generalSettingsData.generalSettings[0].schedule_modifies_users);
            }
            return {
                ...state,
                generalSettingsData: action.payload.generalSettingsData.generalSettings[0],
                currencies: action.payload.generalSettingsData.currencies
            };
        case RECEIVED_UPDATED_GENERAL_SETTINGS_DATA:
            return {
                ...state,
                generalSettingsData: action.payload.generalSettingsData
            };
        case RECEIVED_SECURITY_SETTINGS_DATA:
            return {
                ...state,
                securitySettingsData: action.payload.securitySettingsData
            };
        case RECEIVED_CHALLENGES_SETTINGS_DATA:
            return {
                ...state,
                challengesSettingsData: action.payload.challengesSettingsData
            };
        default:
            return state;
    }
}
