import React from 'react';
import PropTypes from "prop-types";

const ErjCheckBox = ({title, name, checked, onChange, disabled, className}) => {
    return <label
        className={className ? "checkbox-container " + className : "checkbox-container"}><span>{title || ''}</span>
        <input name={name} type={'checkbox'}
               checked={Boolean(checked)}
               onChange={(e) => onChange && onChange(e)}
               disabled={disabled}/>
        <span className={"checkmark"}/>
    </label>
};

ErjCheckBox.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.any,
    checked: PropTypes.any.isRequired,
    onChange: PropTypes.func
};

export default ErjCheckBox;