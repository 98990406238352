import React from 'react';

export default () => <svg className="earlyWinnersIcon" version="1.1" viewBox="0 0 416 259"
                          xmlns="http://www.w3.org/2000/svg">
    <path id="star"
          d="m257.67 74.763-32.56-2.3566-12.269-30.165c-2.2808-5.6559-10.382-5.6559-12.662 0l-12.269 30.165-32.56 2.3566c-6.1346 0.47133-8.5726 8.0911-3.9324 12.019l24.931 21.053-7.7862 31.657c-1.4943 5.9701 5.0335 10.683 10.224 7.4627l27.684-17.203 27.684 17.203c5.1908 3.2207 11.719-1.4925 10.224-7.4627l-7.7862-31.657 24.931-21.053c4.7189-3.9277 2.2808-11.547-3.8538-12.019z"
          fill="#3dde90"/>
    <g id="branches" fill="#703dc2">
        <path
            d="m35.817 92.668s10.119-13.096 3.5749-31.285c-6.5673-18.177-21.86-25.081-21.86-25.081s-11.444 15.608-5.0482 33.326c6.3845 17.73 23.334 23.039 23.334 23.039z"/>
        <path
            d="m63.902 101.97s17.692-1.422 27.822-17.305c10.131-15.872 2.3871-33.624 2.3871-33.624s-16.424 3.3487-26.829 19.645c-10.393 16.285-3.3807 31.285-3.3807 31.285z"/>
        <path
            d="m65.695 122.95c-8.1891 17.512 0.70812 31.48 0.70812 31.48s17.372-3.7386 25.344-20.791c7.9607-17.076-2.0216-33.659-2.0216-33.659s-15.841 5.4588-24.03 22.97z"/>
        <path
            d="m105.68 137.06s-15.27 6.9496-21.78 25.138c-6.5102 18.223 3.6434 31.273 3.6434 31.273s16.926-5.3556 23.277-23.097c6.3388-17.741-5.1396-33.315-5.1396-33.315z"/>
        <path
            d="m118.4 232.84s14.688-10.034 15.681-28.853c0.98224-18.83-14.494-30.413-14.494-30.413s-12.632 11.067-13.648 30.379c-1.0051 19.301 12.461 28.888 12.461 28.888z"/>
        <path
            d="m58.374 57.622s15.659-5.2523 20.25-24.025c4.6028-18.773-4.2944-33.039-4.2944-33.039s-18.137 6.6056-22.626 24.92c-4.4772 18.303 6.6701 32.145 6.6701 32.145z"/>
        <path
            d="m35.806 132.59s5.8135-15.516-5.7792-30.941c-11.615-15.436-28.245-17.512-28.245-17.512s-6.3503 18.303 4.9569 33.349c11.307 15.057 29.067 15.103 29.067 15.103z"/>
        <path
            d="m48.78 173.53s2.2272-16.422-12.484-28.876c-14.734-12.466-31.409-10.768-31.409-10.768s-2.1472 19.266 12.198 31.411c14.368 12.133 31.694 8.234 31.694 8.234z"/>
        <path
            d="m68.539 207.38s-2.7982-16.33-20.593-23.716c-17.806-7.4083-33.179-0.72249-33.179-0.72249s3.769 19.014 21.107 26.239c17.338 7.1904 32.665-1.8005 32.665-1.8005z"/>
        <path
            d="m77.174 221.44c-18.548-5.2065-33.03 3.2684-33.03 3.2684s5.9962 18.418 24.065 23.498c18.091 5.0803 32.231-5.6881 32.231-5.6881s-4.717-15.883-23.265-21.078z"/>
        <path
            d="m60.305 68.814-8.2462-4.0711c-0.43401 0.90597-42.853 91.377 76.34 193.68l5.9619-7.007c-113.48-97.421-75.723-179.18-74.056-182.6z"/>
        <path
            d="m379.42 92.382s-10.282-13.08-3.6284-31.235c6.6424-18.166 22.176-25.061 22.176-25.061s11.604 15.577 5.1122 33.285c-6.4569 17.708-23.66 23.011-23.66 23.011z"/>
        <path
            d="m350.91 101.67s-17.956-1.4317-28.25-17.284c-10.282-15.852-2.4228-33.583-2.4228-33.583s16.67 3.3445 27.242 19.609c10.549 16.276 3.4313 31.258 3.4313 31.258z"/>
        <path
            d="m349.09 122.62c8.3117 17.49-0.71872 31.441-0.71872 31.441s-17.632-3.734-25.735-20.766c-8.0682-17.055 2.0518-33.617 2.0518-33.617s16.09 5.4635 24.402 22.942z"/>
        <path
            d="m308.51 136.72s15.487 6.9296 22.107 25.13c6.6076 18.189-3.7095 31.223-3.7095 31.223s-17.168-5.3604-23.614-23.08c-6.4453-17.719 5.2165-33.273 5.2165-33.273z"/>
        <path
            d="m295.57 232.37s-14.896-10.011-15.905-28.818c-0.99694-18.807 14.722-30.387 14.722-30.387s12.798 11.053 13.841 30.353c1.0317 19.3-12.659 28.852-12.659 28.852z"/>
        <path
            d="m356.5 57.379s-15.87-5.2459-20.53-23.996c-4.6717-18.75 4.3703-33.01 4.3703-33.01s18.409 6.5974 22.953 24.901c4.5442 18.269-6.7931 32.105-6.7931 32.105z"/>
        <path
            d="m379.43 132.25s-5.9005-15.497 5.8541-30.903c11.789-15.417 28.679-17.49 28.679-17.49s6.4569 18.28-5.0311 33.308c-11.488 15.039-29.502 15.085-29.502 15.085z"/>
        <path
            d="m366.26 173.14s-2.2721-16.402 12.67-28.864c14.954-12.439 31.879-10.744 31.879-10.744s2.1794 19.254-12.392 31.372-32.157 8.2353-32.157 8.2353z"/>
        <path
            d="m346.2 206.95s2.8517-16.322 20.913-23.71c18.084-7.3992 33.676-0.72159 33.676-0.72159s-3.8255 18.99-21.423 26.195c-17.609 7.2159-33.166-1.7639-33.166-1.7639z"/>
        <path
            d="m337.43 221c18.826-5.1886 33.537 3.2644 33.537 3.2644s-6.0744 18.383-24.425 23.492c-18.351 5.0512-32.702-5.704-32.702-5.704s4.776-15.852 23.59-21.052z"/>
        <path
            d="m354.55 68.558 8.3928-4.0661c0.44051 0.90486 43.494 91.264-77.495 193.43l-6.0396-6.9983c115.16-97.278 76.834-178.96 75.141-182.37z"/>
    </g>
</svg>


