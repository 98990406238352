import {
    createSelectorWithDependencies as createSelector
} from 'reselect-tools'
import {metricSort, ORIGIN_TYPE} from "../utils/metrics";

const _ = require('lodash');

const getUsers = (state, props) => state.users.users;
const getLeadTypes = (state, props) => state.metrics.lead_types;
const getLeadTypesFields = (state, props) => state.performance.leadTypeFields;
const getRecordsRows = (state, props) => state.performance.records;
const getMetrics = (state, props) => state.metrics.metrics;

export const getLeadTypesData = createSelector(
    [getMetrics, getLeadTypes, getLeadTypesFields],
    (metrics, lead_types, leadTypeFields) => {
        let fieldsByLeadId = _.keyBy(leadTypeFields, 'lead_type_id');
        let extraFields = _.keyBy(leadTypeFields.reduce((prev, leadType) => {
            return prev.concat(Object.values(leadType.id));
        }, []), 'id');
        return {
            lead_types_list: Object.values(lead_types).filter(lead_type => {
                if (fieldsByLeadId[lead_type.id]) {
                    lead_types[lead_type.id].extraFields = fieldsByLeadId[lead_type.id];
                }
                return (metrics[lead_type.id] && [ORIGIN_TYPE.ACTION, ORIGIN_TYPE.TIME, ORIGIN_TYPE.SCORE].indexOf(lead_type.origin_type) > -1) || lead_type.origin_type === ORIGIN_TYPE.POINTS;
            }).sort(metricSort),
            lead_types,
            extraFields
        };
    }
);

export const makeGetRecords = () => {
    let getRecords = createSelector(
        [getUsers, getLeadTypesData, getLeadTypesFields, getRecordsRows],
        (users, leadTypesData, leadTypeFields, records) => {
            let fieldsByLeadId = _.keyBy(leadTypeFields, 'lead_type_id');
            let total = {
                amount: 0,
                value: 0
            };
            for (let record of records) {
                if (record.in_crm_id === null) {
                    record.in_crm_id = '0';
                }
                total.amount += record.amount;
                total.value += record.value;
                if (record.fields) {
                    for (let [field_id, value] of Object.entries(record.fields)) {
                        if (fieldsByLeadId[record.lead_type_id] && fieldsByLeadId[record.lead_type_id].id[field_id] && [1, 5].includes(fieldsByLeadId[record.lead_type_id].id[field_id].field_type)) { //is number
                            if (!total[field_id])
                                total[field_id] = 0;
                            total[field_id] += Number(value);
                        }
                    }
                }
            }
            return {
                users_list: Object.values(users).sort((a, b) => (a.first_name + ' ' + a.last_name).localeCompare(b.first_name + ' ' + b.last_name)),
                users,
                records,
                total,
                ...leadTypesData
            };
        }
    );

    getRecords.selectorName = 'getRecords';
    return getRecords;
};