import React, {Component} from "react";
import {reportError} from "../../actions/global";

import {t} from 'react-i18nify'

class ErrorBoundary extends Component {
    state = {
        hasError: false
    };

    componentDidCatch(error, info) {
        const hasError = reportError({error, info});
        if (hasError) {
            this.setState({hasError});
        }
    }

    render() {
        return this.state.hasError ? (
            <div className={'appError'}>{t('global.app_error_msg')}</div>
        ) : this.props.children;
    }
}

export default ErrorBoundary;
