import {callApi} from "../utils/apiUtils";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_ERROR, API_REQUEST_SAVING} from "./global";

export const RECEIVED_VISITS_REPORT = "RECEIVED_VISITS_REPORT";

function receivedVisitsReport(visitsReport) {
    return {type: RECEIVED_VISITS_REPORT, payload: {visitsReport}};
}

export function getVisitsReport(center_id, callback) {
    return callApi('getVisitsReport', {center_id}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedVisitsReport(responseData.data));
            if (callback) {
                callback();
            }
        }
    });
}
