const MIN_WIN_REQ_TO_GOAL_RATIO = 0.5;

const MAX_CHECK_IN_TIME_PERCENT = 0.5;
const COIN_TO_MONEY_RATIO = 7;
const ORIGIN_TYPE = {
    POINTS: 0,
    ACTION: 1,
    MONEY: 2,
    DE_TIME: 3,
    NUM_OF_DES: 4,
    RATIO: 5,
    AVERAGE: 6,
    WEIGHED: 7,
    TIME: 8,
    SCORE: 9
};
const UNIT_TYPE = {
    NUMBER: 1,
    MONEY: 2,
    SECONDS: 3,
    PERCENTAGE: 4
};
const SHIFT_STATUS_TYPE_MAP = {
    1: 'on_shift', 2: 'vacation', 3: 'sick'
};

const translations = {
    ...require("./translations/en.json"),
    ...require("./translations/he.json"),
    ...require("./translations/sk.json"),
    ...require("./translations/es.json"),
    ...require("./translations/pt.json")
};

const round = (num, decimals = 0) => {
    const multiplier = Math.pow(10, decimals);
    return Math.round(num * multiplier) / multiplier;
};

const getStaticFileURL = (path) => {
    let static_file_domain = process.env.REACT_APP_STATIC_FILE_DOMAIN ? process.env.REACT_APP_STATIC_FILE_DOMAIN : (
        process.env.NODE_ENV === 'development' ? 'd2yw0k45vhuw5a.cloudfront.net' : (process.env.ERJ_ENV === 'rc' ? 'd2uf8qwcutgn1b.cloudfront.net' : 'static.enerjoy.co')
    );
    let prefix = 'https://' + static_file_domain + '/';
    return path ? prefix + path.replace('public/', 'site/') : '';
};

const getUserImg = (user_img, size, isCenterImg) => {
    if (typeof size !== "string")
        size = 'xxs';
    return (
        user_img ? getStaticFileURL(user_img) + "?d=" + size : (
            isCenterImg ? getStaticFileURL('site/img/teamNoImg.png') : getStaticFileURL('site/img/userNoImg.png')
        )
    );
};

const getNotificationIcon = (post, activity) => {
    const imgPrefix = 'site/app/notif_icons/';
    switch (post.key) {
        case "activity":
        case "activity_ended":
        case "activity_canceled":
        case "auto_checkin_reminder":
        case "activity_will_end_soon":
        case "reached_win_req_val":
            switch (activity.activity_def_id) {
                case 2:
                    return getStaticFileURL(imgPrefix + 'spinner.png');
                case 8:
                    return getStaticFileURL(imgPrefix + 'early_winners.png');
                case 9:
                    return getStaticFileURL(imgPrefix + 'booster.png');
                case 13:
                    return getStaticFileURL(imgPrefix + 'contest.png');
                case 14:
                    return getStaticFileURL(imgPrefix + 'teamwork.png');
                case 15:
                    return getStaticFileURL(imgPrefix + (activity.data && activity.data.is_allow_declare_val ? 'daily_goal.png' : 'personal_challenge.png'));
                default:
                    return '';
            }
        case "shifts_schedule_updated":
            return getStaticFileURL(imgPrefix + 'bell.png');
        case "change_points_pos":
            return getStaticFileURL(imgPrefix + 'ranking.png');
        default:
            return '';
    }
};
const generateNotificationContent = ({post, activity, userDetails, notificationDef, sender, notificationMedium}) => {
    let translation = translations[userDetails.lang || userDetails.primary_lang] || translations['en'];
    let row = {post};
    let notification = {title: '', body: '', icon: ''};
    switch (post.key) {
        case "activity":
        case "activity_ended":
        case "activity_canceled":
        case "activity_check_in_reminder":
        case "start_Activity_Feedback":
        case "end_Activity_Feedback":
        case "Booster_Feedback":
        case "personal_Feedback":
        case "auto_checkin_reminder":
        case "activity_will_end_soon":
        case "reached_win_req_val":
            if (!activity || !activity.user || !activity.leadType)
                return false;
            let currentTeam = activity.participant_team_id;
            let numInTeam = (activity.data && activity.data.participants && Object.values(activity.data.participants)[0].length) || '';
            let secondary_def_id = activity.secondary_def_id && activity.secondary_def_id.substr(0, activity.secondary_def_id.lastIndexOf('_'));
            let isAllowDeclare = activity.data && activity.data.is_allow_declare_val;
            if (!translation.challenges["activity_def_title_" + activity.activity_def_id + (secondary_def_id ? '_' + secondary_def_id : '') + (isAllowDeclare ? '_declare' : '')]) {
                console.info('translation missing', "activity_def_title_" + activity.activity_def_id + (secondary_def_id ? '_' + secondary_def_id : '') + (isAllowDeclare ? '_declare' : ''));
                return false;
            }
            let challenge_name = translation.challenges["activity_def_title_" + activity.activity_def_id + (secondary_def_id ? '_' + secondary_def_id : '') + (isAllowDeclare ? '_declare' : '')]
                .replace(/%{numInTeam}/g, numInTeam).replace(/%{metricTitle}/g, activity.leadType.title);
            if (userDetails.is_manager) {
                notification.title = activity.win_req_val > 0 ? challenge_name : translation.challenges.congratulate_employee.replace(/%{employee_name}/g, activity.user.first_name + ' ' + activity.user.last_name);
                if (activity.win_req_val > 0) {
                    notification.body = !numInTeam ? activity.user.first_name + ' ' + activity.user.last_name + ' ' + translation.challenges.employee_was_invited_to.replace(/%{challenge_name}/g, challenge_name) : translation.challenges.challenge_started;
                    notification.icon = getNotificationIcon(post, activity) || getStaticFileURL('site/img/logoIcon.png');
                } else {
                    notification.body = translation.challenges.click_to_view_the_achievement_and_send_a_feedback;
                    notification.icon = getUserImg(activity.user.user_img);
                }
            } else {
                let manager = activity.manager_id && userDetails.managers ? userDetails.managers[activity.manager_id] : Object.values(userDetails.managers || {}).reduce(
                    (prev, manager) => manager.challengesManager ? manager : prev
                    , {});
                if (post.key !== 'activity' && post.origin_sender_type === 2 && userDetails.managers && userDetails.managers[post.origin_sender_id])
                    manager = userDetails.managers[post.origin_sender_id];
                let manager_name = (manager && manager.first_name) || '';
                if (!manager_name) {
                    console.info('no manager found', {userDetails, activity, post});
                }
                notification.title = ['Booster_Feedback', 'personal_Feedback', 'end_Activity_Feedback'].indexOf(post.key) > -1 ? translation.challenges.feedback_from_manager.replace(/%{manager_name}/g, manager_name) : (
                    activity.win_req_val > 0 ? challenge_name : translation.challenges.congrats_for_your_achievement
                );
                if (post.key === 'activity_check_in_reminder') {
                    notification.body = manager_name + ' ' + translation.challenges.manager_reminded_you_checkin.replace(/%{challenge_name}/g, challenge_name);
                } else if (post.key === 'activity_ended') {
                    notification.body = translation.challenges.challenge_ended;
                } else if (post.key === 'activity_canceled') {
                    notification.body = translation.challenges.challenge_canceled;
                } else if (post.key === 'reached_win_req_val') {
                    notification.body = translation.challenges.challenge_reached_goal;
                } else if (post.key === 'start_Activity_Feedback') {
                    notification.body = translation.challenges.good_luck_everybody_on.replace(/%{challenge_name}/g, challenge_name);
                } else if (post.key === 'end_Activity_Feedback') {
                    notification.body = translation.challenges.good_job_everybody_on_winning.replace(/%{challenge_name}/g, challenge_name);
                } else if (post.key === 'Booster_Feedback') {
                    notification.body = translation.challenges.good_job_user_for_the_challenge;
                } else if (post.key === 'personal_Feedback') {
                    notification.body = translation.challenges.good_job_user_on_winning.replace(/%{user_name}/g, activity.user.first_name).replace(/%{challenge_name}/g, challenge_name);
                } else if (activity.win_req_val > 0) {
                    notification.body = manager_name + ' ' + translation.challenges.manager_invited_you_to_the_challenge;
                    if (post.key === 'auto_checkin_reminder') {
                        notification.title = translation.challenges.checkin_reminder.replace(/%{challenge_name}/g, challenge_name);
                    } else if (post.key === 'activity_will_end_soon') {
                        notification.body = translation.challenges.challenge_is_about_to_end;
                    }
                } else if (activity.activity_def_id === 2) {
                    notification.body = translation.challenges.auto_feedback_text_short_wheel_of_fortune.replace(/%{percentages}/g, Math.floor(activity.goal_step * 100)).replace(/%{metric}/g, challenge_name);
                } else
                    notification.body = translation.challenges.click_to_view_your_achievement_and_get_your_points;
                notification.icon = getNotificationIcon(post, activity) || (manager && getUserImg(manager.user_img)) || '';
            }
            break;
        case "activityHubChatMsg": {
            if (!activity)
                return false;
            let numInTeam = (activity.data && activity.data.participants && Object.values(activity.data.participants)[0].length) || '';
            let challenge_name = translation.challenges["activity_def_title_" + activity.activity_def_id].replace(/%{numInTeam}/g, numInTeam);
            notification.title = translation.feeds.new_chat_msg_notif_title.replace(/%{challenge_name}/g, challenge_name);
            notification.body = translation.feeds.new_chat_msg_notif_body.replace(/%{challenge_name}/g, challenge_name).replace(/%{sender_name}/g, sender ? sender.first_name : 'Enerjoy');
            notification.icon = getUserImg(sender && sender.user_img);
            break;
        }
        case "announcement": {
            notification.title = translation.feeds.new_announcement_notif_title;
            notification.body = translation.feeds.new_announcement_notif_body.replace(/%{sender_name}/g, sender ? sender.first_name : 'Enerjoy');
            notification.icon = getUserImg(sender && sender.user_img);
            break;
        }
        case "shifts_schedule_updated": {
            let postData = post.data;
            notification.title = postData.isUpdate ? translation.work_schedule.manager_updated_the_work_schedule : translation.work_schedule.manager_published_the_work_schedule;
            notification.body = translation.work_schedule.a_work_schedule_was_updated_for_start_end_week.replace(/%{start_date}/g, postData.startDate).replace(/%{end_date}/g, postData.endDate).replace(/%{shift_name}/g, postData.shiftName);
            break;
        }
        case "commission_limit_alert": {
            let postData = post.data;
            notification.title = translation.commissions.exceeded_limit_notif_title[`${postData.limit_type}_${postData.exceeded}`];
            notification.body = translation.commissions.exceeded_limit_notif_body[`${postData.limit_type}_${postData.exceeded}`].replace(/%{employee_name}/g, postData.first_name + ' ' + postData.last_name);
            if (postData.limit_type === 'per_user') {
                notification.icon = getUserImg(postData.user_img);
            }
            break;
        }
        case "on_new_order": {
            let postData = post.data;
            notification.title = translation.manager_store.new_order_in_store;
            notification.body = translation.manager_store.employee_ordered_product.replace(/%{employee_name}/g, postData.employee.first_name + ' ' + postData.employee.last_name).replace(/%{product_title}/g, postData.product_title);
            notification.icon = getUserImg(postData.employee.image_uri);
            break;
        }
        case "login_reminder": {
            notification.title = translation.global.login_reminder_title;
            notification.body = translation.global.login_reminder_body;
            break;
        }
        // case 'activity_end_could_win_points_if_checked_in':
        //     if(!activity)
        //         return false;
        //
        //     break;
        // case 'daily_points_update':
        // case 'change_points_pos':
        //     // let activity = row.a.id ? row.a : (row.aa.id ? row.aa : row.aaa);
        //     break;
        // case 'user_received_coins_reminder':
        //     post.data.store_products = row.sp;
        //     break;
        // case 'periodic_team_points_update':
        // case 'team_received_coins':
        //     // we have all the data we need -> do nothing
        //     break;
        default:
            return false;
    }

    return notification;
};

const getGrandPrizeByLength = length => {
    const maxDailyLength = 9;
    const baseHourPrize = 10;

    let lengths = [length];
    for (let i = 0; i < lengths.length; i++) {
        if (lengths[i] > maxDailyLength) {
            lengths.push(lengths[i] - maxDailyLength);
            lengths[i] = maxDailyLength;
        }

        lengths[i] = Math.min(maxDailyLength, lengths[i]);
    }

    let totalPrize = 0;

    for (let length of lengths) {
        if (length <= 0) {
            return 0;
        }
        let prize = baseHourPrize;

        for (let l = 2; l <= Math.ceil(length); l++) {
            let prevLength = l - 1;
            prize = prize + ((prize / prevLength) * (1 - (prevLength * 0.06)));
        }

        totalPrize += Math.round(prize / 5) * 5;
    }

    return totalPrize;
};

/**
 * calc props for new activities
 * Required Data:
 * 1. Either activityDef.settings.win_req_source OR activity_override.win_req_source
 * 2. Either activityDef.settings.length OR activity_override.length
 * 3. activityDef.settings.win_req_val
 * 4. centerProps as listed below. dailyGoal is the average of all the center's active users daily goals.
 * 5. participants as listed bellow (array of team arrays, each containing an object for evey participant).
 * 6. activity_override, used in most cases, but can be optional, and taken usually from scheduled activity.
 *
 * @param activityDef {object} - { settings }
 * @param centerProps {object} - { shift_length, avg_goals: { dailyGoal } }
 * @param participants {Array[]} - [...,[...,{id, hourly_avg, dailyGoal}]]
 * @param math - mathjs package
 * @param {object} [activity_override=null] - {win_req_source, length, win_req_deviation}
 * @param win_req_type - lead_type object object of the activity win req type.
 * @returns {{hourlyWinReq: Array, win_req_deviation: (*|number), offered_prize: number, prize_distribution: (string|number), win_req_val: number, inShift_length: (*|number), length: *, team: Array, win_req_source: *, win_req_unit_type: *}}
 */
const calcNewActivityProps = function (activityDef, centerProps, participants, math, activity_override, win_req_type) {
    console.log('calcNewActivityProps', {activityDef, centerProps, participants, activity_override, win_req_type});

    let groupParticipants = !Array.isArray(participants[0]) ? [participants] : participants;
    let numInTeam = !Array.isArray(participants[0]) ? participants.length : participants.reduce((maxInTeam, team) => Math.max(maxInTeam, team.length), 0);

    let props = {
        team: [],
        hourlyWinReq: [],
        win_req_val: 0,
        offered_prize: 0,
        prize_distribution: centerProps.activity_prize_distribution || 1,
        length: activity_override.length,
        inShift_length: activity_override.inShift_length || activity_override.length,
        win_req_source: activity_override.win_req_source ? activity_override.win_req_source : activityDef.settings.win_req_source,
        win_req_deviation: activity_override.win_req_deviation || 1,
        win_req_unit_type: win_req_type.unit_type
    };

    if (!activityDef) {
        return props;
    }

    //console.log('prize_distribution_methods',activityDef.settings.prize_distribution_methods);
    if (activityDef.settings.prize_distribution_methods)
        props.prize_distribution = activityDef.settings.prize_distribution_methods[Math.floor(Math.random() * activityDef.settings.prize_distribution_methods.length)];
    //console.log(props.prize_distribution);

    props.offered_prize = activity_override.offered_prize || getGrandPrizeByLength(props.inShift_length) * numInTeam;

    if (!activity_override.win_req_vals) {
        // assemble win req val and personal win req vals
        for (let t = 0; t < groupParticipants.length; t++) {
            props.team[t] = {hourlyWinReq: []};
            groupParticipants[t].forEach(participant => {

                let {personal_hourly_win_req, hourGoal: deviationLimit} = getPersonalHourlyWinReq({
                    centerAvgDailyGoal: centerProps.avg_goals.dailyGoal,
                    dailyGoal: participant.dailyGoal,
                    hourly_avg: participant.hourly_avg,
                    origin_type: win_req_type.origin_type,
                    shift_length: centerProps.shift_length,
                    goals_origin: participant.goals_origin,
                    hourlyGoal: participant.hourlyGoal
                });

                // if win_req_deviation is bigger then 1 and increases win_req_val above deviationLimit than decrease win_req_deviation to max possible ratio, but not less than 1.
                let win_req_deviation = (props.win_req_deviation > 1 && deviationLimit < (personal_hourly_win_req * props.win_req_deviation)) ? Math.max(deviationLimit / personal_hourly_win_req, 1) : props.win_req_deviation;
                personal_hourly_win_req = personal_hourly_win_req * win_req_deviation;

                participant.personal_win_req = round(
                    [ORIGIN_TYPE.AVERAGE, ORIGIN_TYPE.RATIO, ORIGIN_TYPE.SCORE].indexOf(win_req_type.origin_type) > -1 ? personal_hourly_win_req : personal_hourly_win_req * props.inShift_length,
                    props.win_req_unit_type === UNIT_TYPE.PERCENTAGE ? 2 : 0
                ); //4 = percentage
                props.team[t].hourlyWinReq.push(participant.personal_win_req);
                props.hourlyWinReq.push(participant.personal_win_req);
            });
            props.team[t].hourlyWinReq = [ORIGIN_TYPE.AVERAGE, ORIGIN_TYPE.RATIO, ORIGIN_TYPE.SCORE].indexOf(win_req_type.origin_type) > -1 ? math.mean(props.team[t].hourlyWinReq) : math.sum(props.team[t].hourlyWinReq);
        }

        props.hourlyWinReq = round(math.mean(Object.values(props.team).map(team => team.hourlyWinReq)), props.win_req_unit_type === UNIT_TYPE.PERCENTAGE ? 2 : 0);

        //assemble win_req_vals
        for (let team_index = 0; team_index < groupParticipants.length; team_index++) {
            for (let participant of groupParticipants[team_index]) {
                if (['groupAvg', 'groupGoal'].indexOf(props.win_req_source) > -1) {
                    participant.win_req_val = props.hourlyWinReq || 1;
                } else {
                    participant.win_req_val = props.team[team_index].hourlyWinReq || 1;
                }
            }
        }

        //console.log('props 1',props);
        props.win_req_val = groupParticipants[0][0].win_req_val; //for backward compatibility
    } else {
        //assemble win_req_vals
        let win_req_val = activity_override.win_req_vals.sort((a, b) => a.val - b.val)[0];
        for (let team_index = 0; team_index < groupParticipants.length; team_index++) {
            for (let participant of groupParticipants[team_index]) {
                participant.win_req_val = participant.activity_override.win_req_vals.sort((a, b) => a.val - b.val)[0].val;
            }
        }
        props.win_req_val = win_req_val.val; //for backward compatibility
        if (['store_product', 'store_product_from_team_wallet', 'coins_from_team_wallet'].indexOf(activity_override.prizeType) > -1) {
            props.offered_prize = -1;
        } else if (activity_override.win_req_vals.length > 1) {
            props.offered_prize = win_req_val.amount;
        }
    }

    console.log('props', props);

    return props; //groupParticipants is a pointer so the changes made on it are automatically returned
};

const getPersonalHourlyWinReq = ({
                                     origin_type,
                                     dailyGoal,
                                     centerAvgDailyGoal,
                                     shift_length = 6,
                                     goals_origin,
                                     hourlyGoal,
                                     hourly_avg
                                 }) => {
    let hourGoal = [ORIGIN_TYPE.AVERAGE, ORIGIN_TYPE.RATIO, ORIGIN_TYPE.SCORE].indexOf(origin_type) > -1 ? dailyGoal : (
        goals_origin === 3 ? (hourlyGoal || 0) : (dailyGoal / shift_length)
    );
    if (!hourGoal)
        hourGoal = [ORIGIN_TYPE.AVERAGE, ORIGIN_TYPE.RATIO, ORIGIN_TYPE.SCORE].indexOf(origin_type) > -1 ? centerAvgDailyGoal : centerAvgDailyGoal / shift_length;
    let personal_hourly_win_req = hourly_avg;
    if (!personal_hourly_win_req) {
        personal_hourly_win_req = hourGoal;
    }
    if (hourGoal) {
        if (personal_hourly_win_req > hourGoal) {
            personal_hourly_win_req = hourGoal;
        } else if ((personal_hourly_win_req / hourGoal) < MIN_WIN_REQ_TO_GOAL_RATIO) {
            personal_hourly_win_req = hourGoal * MIN_WIN_REQ_TO_GOAL_RATIO;
        }
    }

    return {personal_hourly_win_req, hourGoal};
}

const getActivityProps = (activity, users, usersHourlyGoals, metrics, lead_types, challengesSettings, math) => {
    // -1 for personalized
    if (activity.win_req_source === 'teamAvg') {
        return {win_req_val: -1};
    }

    let challengeMetric = metrics[activity.win_req_type];
    let hourlyAvgByUserId = _.keyBy(challengeMetric.leaderBoards.this_month, 'user_id');
    let activity_override = {
        win_req_source: activity.win_req_source,
        length: activity.length,
        inShift_length: activity.inShift_length,
        win_req_deviation: activity.win_req_deviation
    };

    let usersByTeams = [];
    let usersAlreadySet = {};

    let shift_length = 0;
    let dailyGoal = 0;
    let userCounter = 0;
    Object.keys(activity.teams).forEach(teamId => {
        let team = [];
        Object.keys(activity.teams[teamId].users).forEach(userId => {
            if (!users[userId])
                return;
            if (!shift_length) {
                shift_length = users[userId].shift_length;
            }
            usersAlreadySet[userId] = userId;
            userCounter++;
            dailyGoal += challengeMetric.goals[userId] ? challengeMetric.goals[userId].today : 0;
            team.push({
                id: userId,
                hourly_avg: hourlyAvgByUserId[userId] ? hourlyAvgByUserId[userId].hourly_avg : 0,
                dailyGoal: challengeMetric.goals[userId] ? challengeMetric.goals[userId].today : 0,
                hourlyGoal: usersHourlyGoals[activity.win_req_type + '_' + userId] ? usersHourlyGoals[activity.win_req_type + '_' + userId].hourlyGoal : 0,
                goals_origin: users[userId].goals_origin
            })
        });
        usersByTeams.push(team);
    });

    Object.keys(challengeMetric.goals).forEach(userId => {
        if (!usersAlreadySet[userId]) {
            dailyGoal += challengeMetric.goals[userId] ? challengeMetric.goals[userId].today : 0;
            usersAlreadySet[userId] = userId;
            userCounter++;
        }
    });

    dailyGoal = dailyGoal / userCounter;

    let currentChallengeSettings = challengesSettings[activity.activityTypeId];
    return calcNewActivityProps(
        currentChallengeSettings,
        {shift_length, avg_goals: {dailyGoal}},
        usersByTeams,
        math,
        activity_override,
        lead_types[activity.win_req_type]
    );
};

const getCurrentlyActiveShift = function ({moment, currentTime, center_shifts, last_shift_start}) {
    if (moment(currentTime).diff(last_shift_start, 'hours') >= 24) {
        return null;
    }
    let last_shift_start_time = moment(last_shift_start);
    let last_shift_start_hour = last_shift_start_time.hour();
    let last_shift_start_day = last_shift_start_time.day() + 1;
    // const avg_shift_length = Math.round( (center_shifts.reduce((prev, {start_hour,end_hour}) => prev + (start_hour > end_hour ? 24 - (start_hour-end_hour) : end_hour-start_hour),0) / center_shifts.length) * 2 ) / 2;
    // let shift_end = last_shift_start_hour + avg_shift_length;
    return center_shifts.reduce((prev, center_shift) => {
        const {days, start_hour, end_hour} = center_shift;
        let shift = prev;
        if (days.indexOf(last_shift_start_day) > -1) {
            if (end_hour > start_hour && last_shift_start_hour >= start_hour && last_shift_start_hour < end_hour && (!prev || last_shift_start_hour - start_hour < prev.distance)) {
                shift = {...center_shift, distance: last_shift_start_hour - start_hour};
            } else if (end_hour < start_hour && last_shift_start_hour >= start_hour && last_shift_start_hour < end_hour + 24 && (!prev || last_shift_start_hour - start_hour < prev.distance)) {
                shift = {...center_shift, distance: last_shift_start_hour - start_hour};
            }
        } else if (days.indexOf(moment(last_shift_start).subtract(1, 'days').day() + 1) > -1 && end_hour < start_hour) { //last shift might have started yesterday
            if (last_shift_start_hour < end_hour && (!prev || (24 - (start_hour - last_shift_start_hour)) < prev.distance)) { //last shift started today but still is in yesterday's shift
                shift = {...center_shift, distance: (24 - (start_hour - last_shift_start_hour))};
            }
        }

        return shift;
    }, null);
};

const getTimeframePassedPercent = ({
                                       moment,
                                       currentTime,
                                       timeframe,
                                       center_shifts,
                                       first_day_of_month,
                                       months,
                                       last_shift_start,
                                       shift_length,
                                       actual_work_days,
                                       work_days_goal
                                   }) => {
    let result;
    switch (timeframe) {
        case 'custom':
            let numDaysWorked = !Array.isArray(actual_work_days) ? actual_work_days : actual_work_days.reduce((prev, current) => {
                const currentDate = new Date(current);
                const firstDayCurrMonth = getStartNEndDatesOfMonth(moment, currentDate, first_day_of_month, months)[0];
                const currDay = moment(currentTime).hours(0).minutes(0).seconds(0).milliseconds(0);
                return currentDate >= firstDayCurrMonth.toDate() && !currDay.isSame(currentDate, "day") ? ++prev : prev;
            }, 0);
            result = work_days_goal ? (numDaysWorked / work_days_goal) : 1;
            break;
        case 'monthly':
            result = getDaysTillEndOfMonth({
                moment,
                currentTime,
                center_shifts,
                first_day_of_month,
                months,
                is_manager: false,
                workDays_goal: work_days_goal,
                daysWorked: actual_work_days.this_month,
                isForIdealPace: true
            }).percentDaysPassed;
            break;
        case 'daily':
        case 'hourly':
            let activeCenterShift = getCurrentlyActiveShift({
                moment,
                currentTime,
                center_shifts,
                last_shift_start
            });
            let user_shift_start = moment(last_shift_start).hour();
            let shift_end = activeCenterShift ? activeCenterShift.end_hour : user_shift_start + shift_length;
            let shift_start = activeCenterShift ? activeCenterShift.start_hour : user_shift_start;
            let currTime = moment(currentTime);
            let curr_hour = currTime.hour() + (currTime.minute() / 60);
            if (shift_end < shift_start && curr_hour < shift_start && curr_hour < shift_end) {
                curr_hour += 24;
                shift_end += 24;
            }
            if (curr_hour < shift_end && curr_hour >= shift_start && moment(currentTime).diff(last_shift_start, 'hours', true) < (shift_end - shift_start)) {
                result = (curr_hour - shift_start) / (shift_end - shift_start);
            } else {
                result = 1;
            }
            break;
        default:
            result = 1;
    }
    return isFinite(result) ? result : 0;
};

const calculateIdealPace = function ({
                                         moment,
                                         currentTime,
                                         timeframe,
                                         center_shifts,
                                         first_day_of_month,
                                         months,
                                         last_shift_start,
                                         shift_length,
                                         goal,
                                         actual_work_days,
                                         work_days_goal,
                                         origin_type
                                     }) {
    let timePercent = getTimeframePassedPercent({
        moment,
        currentTime,
        timeframe,
        center_shifts,
        first_day_of_month,
        months,
        last_shift_start,
        shift_length,
        actual_work_days,
        work_days_goal
    });
    if ([ORIGIN_TYPE.RATIO, ORIGIN_TYPE.AVERAGE, ORIGIN_TYPE.SCORE].indexOf(origin_type) > -1) {
        timePercent = 1;
    }
    return Math.round((goal * timePercent) * 100) / 100;
};

const predictLeads = function ({
                                   moment,
                                   currentTime,
                                   timeframe,
                                   center_shifts,
                                   first_day_of_month,
                                   months,
                                   last_shift_start,
                                   shift_length,
                                   leads,
                                   actual_work_days,
                                   work_days_goal,
                                   origin_type
                               }) {
    let timePercent = getTimeframePassedPercent({
        moment,
        currentTime,
        timeframe,
        center_shifts,
        first_day_of_month,
        months,
        last_shift_start,
        shift_length,
        actual_work_days,
        work_days_goal
    });
    if ([ORIGIN_TYPE.RATIO, ORIGIN_TYPE.AVERAGE, ORIGIN_TYPE.SCORE].indexOf(origin_type) > -1) {
        timePercent = 1;
    }
    return timePercent > 0 ? leads / timePercent : leads;
};

const getDaysTillEndOfMonth = ({
                                   moment,
                                   currentTime,
                                   center_shifts,
                                   first_day_of_month,
                                   months,
                                   is_manager,
                                   workDays_goal,
                                   daysWorked,
                                   isForIdealPace
                               }) => {
    let currDay = moment(currentTime).hours(0).minutes(0).seconds(0).milliseconds(0);
    let currMonthDates = getStartNEndDatesOfMonth(moment, currDay, first_day_of_month, months);
    let firstDayCurrMonth = currMonthDates[0];
    let firstDayNextMonth = moment(currMonthDates[1]).add(1, 'days');
    let days_till_end_of_month;
    let percentDaysPassed;

    let workWeekDays = new Set(center_shifts ? center_shifts.reduce((prev, center_shift) => prev.concat(center_shift.days), []) : []);
    let workDaysTillEndOfMonth = 0;
    for (let workDay = moment(currDay); workDay.toDate() < firstDayNextMonth.toDate(); workDay.add(1, 'days')) {
        if (workWeekDays.has(workDay.day() + 1)) {
            workDaysTillEndOfMonth++;
        }
    }
    let totalDaysInMonth = 0;
    for (let workDay = moment(firstDayCurrMonth); workDay.toDate() < firstDayNextMonth.toDate(); workDay.add(1, 'days')) {
        if (workWeekDays.has(workDay.day() + 1)) {
            totalDaysInMonth++;
        }
    }

    // manager => show days to end of the month, employee => work days left
    if (is_manager) {
        days_till_end_of_month = Math.min(firstDayNextMonth.diff(currDay, 'days'), workDaysTillEndOfMonth);
        percentDaysPassed = 1 - (days_till_end_of_month / totalDaysInMonth);
    } else {
        let numDaysWorked = !Array.isArray(daysWorked) ? daysWorked : daysWorked.reduce((prev, current) => {
            let currentDate = new Date(current);
            return currentDate >= firstDayCurrMonth.toDate() && (isForIdealPace || !currDay.isSame(currentDate, "day")) ? ++prev : prev;
        }, 0);
        let deltaDays = (workDays_goal || 0) - numDaysWorked;
        days_till_end_of_month = Math.min(deltaDays < 0 ? 0 : deltaDays, workDaysTillEndOfMonth);
        percentDaysPassed = 1 - (days_till_end_of_month / workDays_goal);
    }

    return {percentDaysPassed, days_till_end_of_month};
};

const getStartNEndDatesOfMonth = (moment, date, first_day_of_month, months) => {
    let matchingMonth = [];
    let momentDate = moment(date);
    if (months) {
        matchingMonth = months.filter(month => momentDate.isSameOrAfter(month.start) && momentDate.isBefore(moment(month.end).add(1, 'days')));
    }
    let startDate = matchingMonth[0] ? moment(matchingMonth[0].start) : moment(date).subtract(first_day_of_month - 1, 'days').startOf('month').add(first_day_of_month - 1, 'days');
    let endDate = matchingMonth[0] ? moment(matchingMonth[0].end) : moment(startDate).add(1, 'months').subtract(1, 'days');
    return [startDate, endDate];
};

module.exports = {
    getStartNEndDatesOfMonth,
    getDaysTillEndOfMonth,
    predictLeads,
    calculateIdealPace,
    getCurrentlyActiveShift,
    getActivityProps,
    getPersonalHourlyWinReq,
    calcNewActivityProps,
    getGrandPrizeByLength,
    generateNotificationContent,
    getUserImg,
    getStaticFileURL,
    round,
    translations,
    SHIFT_STATUS_TYPE_MAP,
    UNIT_TYPE,
    ORIGIN_TYPE,
    COIN_TO_MONEY_RATIO,
    MAX_CHECK_IN_TIME_PERCENT
};