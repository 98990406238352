import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Modal from '@material-ui/core/Modal';
import ErjTable from '../erj-table/ErjTable';

import {ErjNumberInput} from "../controls/Counter";

import {t} from 'react-i18nify';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

const _ = require('lodash');

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: 'white',
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
});

class Popup extends React.Component {
    constructor(props) {
        super(props);
        const {key, type, title, value, tiers} = props.component;
        this.state = {
            key,
            type,
            title,
            value,
            tiers: tiers ? _.cloneDeep(tiers) : [{
                range: ['', ''],
                value: ''
            }],
            error: ''
        };
        this.titleRef = React.createRef('title');
        this.numberRef = React.createRef('number');
    }

    componentDidMount() {
        setTimeout(() => (this.titleRef.current || this.numberRef.current).focus(), 100);
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleTierInputChange = ({name, value, id: index}) => {
        let tiers = [...this.state.tiers];
        if (name === 'value') {
            tiers[index].value = value;
        } else {
            tiers[index].range[name === 'start' ? 0 : 1] = value;
            if (tiers[index + 1] && name === 'end') {
                tiers[index + 1].range[0] = value;
            }
        }
        this.setState({tiers});
    };

    handleRemoveTier = (index) => {
        let tiers = [...this.state.tiers];
        tiers.splice(index, 1);
        this.setState({tiers});
    };

    handleAddTier = () => {
        let tiers = [...this.state.tiers];
        tiers.push({
            range: [tiers[tiers.length - 1].range[1], ''],
            value: ''
        });
        this.setState({tiers});
    };

    handleCloseModal = () => {
        this.props.onSave(null);
    }

    handleSaveComponent = (e) => {
        e.preventDefault();
        let {component} = this.props;
        const {key, type, title, value, tiers} = this.state;
        if (!component.key) {
            component.key = component.type + '_' + Math.floor(Date.now() / 1000);
        }

        let error = '';

        if (component.type === 'number' || component.type === 'numberOnly' || component.type === 'textValue') {
            component.value = value;
        } else if (component.type === 'tier_table') {
            for (let i = 0; i < tiers.length; i++) {
                let tier = tiers[i];
                if (tier.range[0] >= tier.range[1] && i !== (tiers.length - 1)) {
                    tier.hasError = true;
                    error = t('commissions.err_range_order');
                } else if (tier.hasError) {
                    delete tier.hasError;
                }
            }

            this.setState({error, tiers});

            if (!error) {
                component.tiers = tiers;
            }
        }

        if (!error) {
            component.title = title;
            this.props.onSave(component);
        }
    }

    render() {
        const {classes} = this.props;
        const {type, title, value, tiers, error} = this.state;
        const columnData = [
            {
                accessor: 'range',
                Header: t("commissions.tier"),
                Cell: props => (
                    <span className={'tier-range'}>
                        <ErjNumberInput className={'form-control' + (props.original.hasError ? ' hasError' : '')}
                                        name={'start'}
                                        keyId={props.index} hideArrows={true}
                                        value={props.value[0]}
                                        placeholder={'...'}
                                        onChange={this.handleTierInputChange}
                                        required={true}
                                        disabled={true}
                                        autoComplete={'off'}
                                        step={0.0001}
                        />
                        <span className={'range-sep'}>-</span>
                        <ErjNumberInput className={'form-control' + (props.original.hasError ? ' hasError' : '')}
                                        name={'end'}
                                        keyId={props.index} hideArrows={true}
                                        value={props.value[1]}
                                        placeholder={props.value[0] !== '' ? '...' : ''}
                                        onChange={this.handleTierInputChange}
                                        required={props.index === 0}
                                        autoComplete={'off'}
                                        step={0.0001}
                        />
                    </span>
                )
            },
            {
                accessor: 'value',
                Header: t("performance.value"),
                Cell: props => (
                    <ErjNumberInput className={'form-control tier-value-input'} name={'value'}
                                    keyId={props.index} hideArrows={true}
                                    value={props.value}
                                    onChange={this.handleTierInputChange}
                                    required={true}
                                    autoComplete={'off'}
                                    step={0.0001}
                    />
                )
            },
            {
                accessor: '',
                Header: '',
                Cell: props => props.index > 0 && props.index === tiers.length - 1 ? (
                    <IconButton onClick={() => this.handleRemoveTier(props.index)}>
                        <DeleteIcon/>
                    </IconButton>
                ) : <span/>
            }
        ];

        return (
            <Modal
                aria-labelledby="edit_component"
                aria-describedby="edit_component"
                className="editComponentModal"
                open={true}
                disablePortal={true}
            >
                <div style={getModalStyle()} className={classes.paper + ' paper'}>
                    <Icon className="icon- closeIcon" onClick={() => this.props.onSave(null)}>
                        close icon
                    </Icon>
                    <Typography variant="title" id="modal-title">
                        {t('commissions.component') + ' ' + t('commissions.component_types.' + type.replace('numberOnly', 'number'))}
                    </Typography>
                    <form className="contentWrapper" onSubmit={this.handleSaveComponent}>
                        {type !== 'numberOnly' && type !== 'textValue' && (
                            <div className="form-row">
                                <span className="textbox-title">{t('commissions.component_name')}</span>
                                <input onChange={this.handleInputChange} name={"title"} autoComplete={'off'}
                                       ref={this.titleRef}
                                       value={title}
                                       type="text" className="form-control component-name-input" required={true}/>
                            </div>
                        )}
                        {(type === 'number' || type === 'numberOnly' || type === 'textValue') && (
                            <div className="form-row">
                                <span className="textbox-title">{t('performance.value')}</span>
                                <input onChange={this.handleInputChange} name={"value"} autoComplete={'off'}
                                       step={0.0001} ref={this.numberRef}
                                       value={value}
                                       type={type === 'textValue' ? 'text' : "number"}
                                       className="form-control component-value-input" required={true}/>
                            </div>
                        )}
                        {type === 'tier_table' && (
                            <div className="form-row">
                                <div className="scrollableTable">
                                    <ErjTable columns={columnData} data={tiers}/>
                                    <Button color="primary" onClick={this.handleAddTier}
                                            disabled={tiers[tiers.length - 1].range[1] === ''}>
                                        <AddCircleOutlineIcon className={'addTierIcon'}/>
                                        {t('commissions.add_tier')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {error && (
                            <div className={'error'}>{error}</div>
                        )}
                        <div className={'buttons popup-buttons'}>
                            <button className={'btn btn-gray btn-cancel-popup'}
                                    type="reset"
                                    onClick={() => this.props.onSave(null)}>{t('global.cancel')}</button>
                            <button className={'btn btn-green btn-save-popup'}
                                    type="submit">{t('global.save')}</button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}

Popup.propTypes = {
    classes: PropTypes.object.isRequired,
};
// Popup.whyDidYouRender = true;

const EditComponentModal = withStyles(styles)(Popup);

export default EditComponentModal;
