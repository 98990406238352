import {createStore, applyMiddleware, combineReducers, compose} from "redux";
import thunkMiddleware from "redux-thunk";
import {createLogger} from "redux-logger";
import auth from "../reducers/auth";
import global from "../reducers/global";
import users from "../reducers/users";
import account from "../reducers/account";
import activities from "../reducers/activities";
import feeds from "../reducers/feeds";
import notifications from "../reducers/notifications";
import alerts from "../reducers/alerts";
import metrics from "../reducers/metrics";
import controls from "../reducers/controls";
import triggers from "../reducers/triggers";
import store from "../reducers/store";
import accessControl from "../reducers/accessControl";
import generalSettings from "../reducers/generalSettings";
import reports from "../reducers/reports";
import managerStore from "../reducers/managerStore";
import commissions from "../reducers/commissions";
import insights from "../reducers/insights";
import shifts from "../reducers/shifts";
import tvs from "../reducers/tvs";
import workSchedule from '../reducers/workSchedule';
import performance from "../reducers/performance";
import {translations} from "erj-common/generalUtils";
import {setTranslations, setLocale} from 'react-i18nify';
import moment from 'moment';
import 'moment/locale/he';
import 'moment/locale/es';
import 'moment/locale/sk';
import {UPDATE_CURRENT_TIME, START_TEMP_DELAY_ACTIONS, STOP_TEMP_DELAY_ACTIONS} from "../actions/global";
import cookies from '../utils/cookies';
import {SUPPORTED_LOCALES} from '../utils/generalUtils';

console.log('translations', translations);
const translationsObject = translations;

const _ = require('lodash');

const logger = createLogger();
const rootReducer = combineReducers({
    auth,
    account,
    global,
    users,
    activities,
    feeds,
    notifications,
    alerts,
    metrics,
    controls,
    triggers,
    store,
    managerStore,
    commissions,
    accessControl,
    generalSettings,
    insights,
    shifts,
    tvs,
    performance,
    workSchedule,
    reports
});

const tempActionDelayer = store => next => action => {
    if (!store.getState().global.tempDelayActions || [START_TEMP_DELAY_ACTIONS, STOP_TEMP_DELAY_ACTIONS].indexOf(action.type) > -1)
        return next(action);

    let timeoutId;
    const checker = () => !store.getState().global.tempDelayActions ? next(action) : (timeoutId = setTimeout(() => checker(), 500));
    console.log('delaying action...', action);
    checker();

    return function cancel() {
        clearTimeout(timeoutId)
    };
};

export default async function configureStore() {
    const initialState = {};
    let store;

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    // const store = createStore(reducers, composeEnhancers(applyMiddleware()))
    if (module.hot) {
        store = createStore(
            rootReducer,
            initialState,
            compose(
                composeEnhancers(
                applyMiddleware(thunkMiddleware, tempActionDelayer, logger)),
                window.devToolsExtension ? window.devToolsExtension() : f => f
            )
        );
    } else {
        store = createStore(
            rootReducer,
            initialState,
            compose(applyMiddleware(thunkMiddleware, tempActionDelayer), f => f)
        );
    }

    if (window.location.search.indexOf('?scrnm=') === 0) {
        const {appendedState} = await import("../scripts/" + window.location.search.replace("?scrnm=", '') + ".js");
        const currentState = store.getState();
        store = createStore(
            rootReducer,
            _.mergeWith(currentState, appendedState, (objValue, srcValue, key, object, source, stack) => {
                if (Array.isArray(srcValue) && srcValue[0] === '#dateOps#') {
                    let date = moment();
                    for (let [func, ...args] of srcValue.slice(1)) {
                        date = date[func](...args);
                    }
                    return date;
                }
            }),
            compose(applyMiddleware(thunkMiddleware, tempActionDelayer), f => f)
        );
    }

    setTranslations(translationsObject);
    const supportedLocals = Object.keys(SUPPORTED_LOCALES);
    if (localStorage.getItem('LANG')) { // for backwards compatibility - can remove in future
        cookies.setItem('lang', localStorage.getItem('LANG'), Infinity, '/', undefined, true);
        localStorage.removeItem('LANG');
    }
    let locale = cookies.getItem('lang') || navigator.language.split('-')[0] || 'en';
    if (supportedLocals.indexOf(locale) === -1) {
        locale = 'en';
    }
    setLocale(locale);
    moment.locale(locale);
    store.dispatch({type: UPDATE_CURRENT_TIME});
    return store;
}
