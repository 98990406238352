import {
    EDIT_COMMISSION_PLAN,
    RECEIVED_COMMISSION_PLAN,
    RECEIVED_COMMISSION_PLANS,
    RECEIVED_COMMISSION_SETTINGS,
    RECEIVED_DATA_FOR_COMMISSION_SIM,
    RECEIVED_MONTH_COMMISSION_DATA,
    RESET_EDIT_COMMISSION_PLAN,
    RECEIVED_PREVIOUS_COMMISSIONS
} from "../actions/commissions";

import {RESET_LIVE_DATA} from "../actions/auth";

const _ = require('lodash');

const initialState = {
    editParams: {},
    commission_settings: {},
    commission_plans: {},
    commission_plan: null,
    data_for_commission_sim: null,
    month_commission_plans: {},
    month_commissions: {},
    month: null,
    previous_commissions: []
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function commissions(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_COMMISSION_SETTINGS:
            return {
                ...state,
                commission_settings: action.commission_settings
            }
        case RECEIVED_COMMISSION_PLANS:
            return {
                ...state,
                commission_plans: action.commission_plans
            }
        case RESET_EDIT_COMMISSION_PLAN:
            return {
                ...state,
                editParams: {},
                commission_plan: null
            };
        case EDIT_COMMISSION_PLAN:
            return {
                ...state,
                editParams: action.editParams
            };
        case RECEIVED_COMMISSION_PLAN:
            return {
                ...state,
                commission_plan: action.commission_plan
            };
        case RECEIVED_MONTH_COMMISSION_DATA:
            return {
                ...state,
                month_commission_plans: action.plans,
                month_commissions: action.commissions,
                month: action.month
            };
        case RECEIVED_DATA_FOR_COMMISSION_SIM:
            return {
                ...state,
                data_for_commission_sim: action.data_for_commission_sim
            };
        case RECEIVED_PREVIOUS_COMMISSIONS:
            return {
                ...state,
                previous_commissions: action.commissions
            };
        default:
            return state;
    }
}
