import {
    API_STATUS_SUCCESS,
    AUTH_TOKEN,
    loginSuccess,
    loginRequest,
    login2StepAuth,
    receivedUserData,
    receivedUserMetrics,
    loginFailure,
    STAGE_TWO_STEP_CODE,
    ERR_NO_SESSION_ID,
    NOT_LOGGED_IN,
    ERR_INVALID_AUTH_REQ,
    LOGIN_FAILURE,
    ERR_INVALID_JWT, API_STATUS_EXPIRED_SESSION, RECAPTCHA_RETRY_W_CHECKBOX
} from "../actions/auth";
import {receivedOrgUsers, receivedUsers, receivedUsersShiftStatus} from "../actions/users";
import {receivedMetrics, receivedLeadTypes, receivedOrgLeadTypes} from "../actions/metrics";
import {
    receivedActivities,
    receivedActivitiesStats,
    receivedActivityWorkPlan,
    receivedHistoryActivities
} from "../actions/activities";
import {receivedFeeds, receivedFeedbackStats} from "../actions/feeds";
import {receivedStoreProducts} from "../actions/store";
import {receivedNotifications} from "../actions/notifications";
import {receivedAlerts} from "../actions/alerts";

import {
    API_REQUEST_START,
    API_REQUEST_END,
    API_REQUEST_ERROR,
    GENERAL_ERROR, NO_PERMISSION
} from "../actions/global";

import io from "socket.io-client";
import {registerToNotifications} from "./notification";
import {receivedPendingStoreOrders} from "../actions/managerStore";
import {receivedTvsSettings} from "../actions/tvs";
import cookies from "./cookies";
import {getLocale} from "react-i18nify";
import {receivedCommissionPlans} from "../actions/commissions";


let socket = null;
let store = null;

const api_endpoint = '//' + window.location.host;

export function initComm(reduxStore) {
    store = reduxStore;
    store.dispatch(login({}));
}

const processNewVersion = (newVersion, beforeReload) => {
    const currentVersion = cookies.getItem('clientVersion');
    if (!currentVersion || currentVersion !== newVersion) {
        cookies.setItem('clientVersion', newVersion, Infinity, '/');
        if (currentVersion && currentVersion !== newVersion) {
            if (beforeReload) {
                beforeReload();
            }
            window.location.reload(true);
            console.log('old version, reloading...');
            throw new Error('old version, reloading...');
        }
    }
}

export function initSocket(preferred_protocols) {
    if (store.getState().global.scriptId) {
        return false;
    }

    if (!socket) {
        console.log('going to init socket', {preferred_protocols});
        socket = io(api_endpoint, {
            path: '/app/socket.io',
            transports: preferred_protocols && preferred_protocols.length ? preferred_protocols : ['websocket', 'polling']
        });
        socket.on("connect", () => {
            console.log("Socket.io connection success");
            // const wasLoggedIn = store.getState().auth.sessionData;
            // store.dispatch(login({lang: store.getState().i18n.locale, wasLoggedIn}));
        });

        socket.on("connect_error", error => {
            console.log("Socket.io connection error:", error);
            const code = error.message || error;
            if ([ERR_NO_SESSION_ID, ERR_INVALID_JWT, ERR_INVALID_AUTH_REQ, STAGE_TWO_STEP_CODE, API_STATUS_EXPIRED_SESSION].indexOf(code) > -1) {
                const wasLoggedIn = store.getState().auth.sessionData;
                if (wasLoggedIn) {
                    window.location.reload();
                } else {
                    store.dispatch({type: NOT_LOGGED_IN});
                }
            }
            //socket.disconnect();
            //store.dispatch(connectError());
            //socket = null; // set to null
        });

        socket.on("disconnect", reason => {
            console.log("Socket.io disconnect reason:", reason);
            setTimeout(() => socket.connect(), 1000);
            //store.dispatch(connectError());
            //socket = null; // set to null
        });

        socket.on("error", error => {
            console.log("Socket.io error:", error);
            if ([ERR_NO_SESSION_ID, ERR_INVALID_JWT, ERR_INVALID_AUTH_REQ, STAGE_TWO_STEP_CODE, API_STATUS_EXPIRED_SESSION].indexOf(error) > -1) {
                const wasLoggedIn = store.getState().auth.sessionData;
                if (wasLoggedIn) {
                    window.location.reload();
                } else {
                    store.dispatch({type: NOT_LOGGED_IN});
                }
            }
            //socket.disconnect();
            //store.dispatch(connectError());
            //socket = null; // set to null
        });

        socket.on('sessionExpired', payload => {
            window.location.reload();
        });

        socket.on('loginSuccess', async payLoad => {
            console.log('loginSuccess', payLoad);
            try {
                processNewVersion(payLoad.version, () => {
                    if (store.getState().tvs.watchingTvId) {
                        cookies.setItem('watchingTvId', store.getState().tvs.watchingTvId, undefined, '/');
                    }
                });

                const wasLoggedIn = store.getState().auth.sessionData;
                const lang = getLocale();
                if (payLoad.login_to_ui !== 'tv') {
                    console.log({registerToNotificationsResult: await registerToNotifications(store.dispatch, lang)});
                }
                store.dispatch(loginSuccess(payLoad, wasLoggedIn));
            } catch (e) {
            }
        });

        socket.on("newDataUpdate", payload => {
            if (window.erj && window.erj.stopNewData)
                return;
            console.log('newDataUpdate received from server: ', payload, JSON.stringify(payload).length);
            if (payload.userData)
                store.dispatch(receivedUserData(payload.userData));
            if (payload.users)
                store.dispatch(receivedUsers(payload.users));
            if (payload.org_users)
                store.dispatch(receivedOrgUsers(payload.org_users));
            if (payload.users_shift_status)
                store.dispatch(receivedUsersShiftStatus(payload.users_shift_status));
            if (payload.org_lead_types)
                store.dispatch(receivedOrgLeadTypes(payload.org_lead_types));
            if (payload.metrics)
                store.dispatch(receivedMetrics(payload.metrics));
            if (payload.lead_types)
                store.dispatch(receivedLeadTypes(payload.lead_types));
            if (payload.userMetrics)
                store.dispatch(receivedUserMetrics(payload.userMetrics));
            if (payload.activities)
                store.dispatch(receivedActivities(payload.activities));
            if (payload.activities_stats)
                store.dispatch(receivedActivitiesStats(payload.activities_stats));
            if (payload.historyActivities)
                store.dispatch(receivedHistoryActivities(payload.historyActivities));
            if (payload.store_products)
                store.dispatch(receivedStoreProducts(payload.store_products));
            if (payload.store_orders)
                store.dispatch(receivedPendingStoreOrders(payload.store_orders));
            if (payload.feeds)
                store.dispatch(receivedFeeds(payload.feeds));
            if (payload.feedback_stats)
                store.dispatch(receivedFeedbackStats(payload.feedback_stats));
            if (payload.notifications)
                store.dispatch(receivedNotifications(payload.notifications));
            if (payload.alerts)
                store.dispatch(receivedAlerts(payload.alerts));
            if (payload.activity_schedules)
                store.dispatch(receivedActivityWorkPlan(payload.activity_schedules));
            if (payload.commission_plans)
                store.dispatch(receivedCommissionPlans(payload.commission_plans));
            if (payload.tvs_settings)
                store.dispatch(receivedTvsSettings(payload.tvs_settings));
            //store.dispatch(alert(payload));
        });
    }
}

export function login({
                          username,
                          password,
                          login_to_ui,
                          auth_code,
                          lang,
                          recaptcha_token,
                          recaptcha_callback,
                          is_checkbox_token
                      }) {
    const loginFunc = dispatch => {
        if (store.getState().global.scriptId) {
            return false;
        }
        dispatch(loginRequest);
        window.fetch(api_endpoint + '/app', {
            method: 'POST',
            mode: 'same-origin',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
                password,
                login_to_ui,
                two_step_auth_code: auth_code,
                recaptcha_token,
                is_checkbox_token
            }),
        }).then(async response => {
            if (response.status !== 200) {
                dispatch({type: API_REQUEST_ERROR, prefix: 'network', retryFunc: () => loginFunc(dispatch)});
                return;
            }
            const responseData = await response.json();
            console.log('login responseData', responseData);
            if (responseData.version) {
                try {
                    processNewVersion(responseData.version);
                } catch (e) {
                }
            }
            if (responseData.status === API_STATUS_SUCCESS) {
                if (responseData.stage === STAGE_TWO_STEP_CODE) {
                    dispatch(login2StepAuth({image_url: responseData.image_uri}));
                } else {
                    console.log('socket.connected', socket && socket.connected);
                    socket = null;
                    initSocket(responseData.preferred_protocols);
                    console.log('socket.connected', socket && socket.connected);
                    if (responseData.login_to_ui !== 'tv') {
                        console.log({registerToNotificationsResult: await registerToNotifications(dispatch, lang)});
                    }
                }
            } else if (responseData.status === RECAPTCHA_RETRY_W_CHECKBOX) {
                dispatch({type: NOT_LOGGED_IN});
                recaptcha_callback();
            } else if (responseData.status === API_STATUS_SUCCESS) {
                window.location.href = responseData.authorizationUrl;
            } else {
                dispatch(loginFailure(((username && password) || auth_code) && responseData.status));
                if (((username && password) || auth_code) && responseData.status) {
                    dispatch({type: API_REQUEST_ERROR, prefix: responseData.status.toLowerCase().replace('err_', '')});
                }
            }
        }).catch(err => {
            console.error(err);
            dispatch(loginFailure(LOGIN_FAILURE));
        });
    };

    return loginFunc;
}

export function logout() {
    if (store.getState().global.scriptId) {
        return false;
    }
    window.fetch(api_endpoint + '/app', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({logout: true}),
    }).then(() => window.location.reload());
}

export function callApi(actionKey, actionData, onRequestStart, onRequestSuccess) {
    console.log('actionKey', actionKey, 'actionData', actionData);
    return dispatch => {
        if ((window.erj && window.erj.stopApiCalls) || store.getState().global.scriptId)
            return false;
        dispatch({type: API_REQUEST_START});
        if (onRequestStart)
            onRequestStart(dispatch, actionData);
        console.log("callApi", actionKey, actionData);
        let numRetries = 0;
        const send = () => {
            if (socket.disconnected) {
                console.log("socket is disconnected");
                if (numRetries < 15) {
                    setTimeout(() => send(), 1000);
                    numRetries++;
                    return;
                }

                if (['updateLastActivity', 'setReadAlerts'].indexOf(actionKey) === -1) {
                    dispatch({type: API_REQUEST_ERROR, prefix: 'network', retryFunc: send});
                }
            } else {
                const auth = store && store.getState().auth;
                const attached_center_id = Number(auth.sessionData && auth.sessionData.attached_center_id);
                let reqData = ['appException', 'appDebug'].includes(actionKey) ? actionData : {
                    attached_center_id,
                    data: actionData
                };
                console.log("socket.emit", actionKey, reqData);
                socket.emit(actionKey, reqData, (responseData) => {
                    dispatch({type: API_REQUEST_END});
                    if (responseData.status === GENERAL_ERROR) {
                        dispatch({type: API_REQUEST_ERROR});
                    } else if (responseData.status === NO_PERMISSION) {
                        dispatch({type: API_REQUEST_ERROR, prefix: 'no_permission'});
                    }
                    console.log("callApi response", actionKey, responseData);
                    if (onRequestSuccess)
                        onRequestSuccess(dispatch, responseData);
                });
            }
        };

        send();
    };
}

export function base64ToFile(base64String, fileName = 'image') {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type: mime});
}

export class Loadable {
    #isLoading;
    #isLoaded;

    constructor(data = {}, isLoading = false, isLoaded = false) {
        Object.assign(this, data);
        this.#isLoading = isLoading;
        this.#isLoaded = isLoaded;
    }

    isLoading() {
        return this.#isLoading;
    }

    isLoaded() {
        return this.#isLoaded;
    }
}