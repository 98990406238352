import {callApi} from "../utils/apiUtils";

export const RECEIVED_ALERTS_LIST = "RECEIVED_ALERTS_LIST";
export const RECEIVE_READ_ALERTS = "RECEIVE_READ_ALERTS";
export const RECEIVE_DISMISSED_ALERTS = "RECEIVE_DISMISSED_ALERTS";

export function receivedAlerts(alerts) {
    return {type: RECEIVED_ALERTS_LIST, alerts};
}

export function setReadAlerts(alerts_ids, user_id) {
    return callApi('setReadAlerts', {alerts_ids}, (dispatch, requestData) => {
        dispatch({type: RECEIVE_READ_ALERTS, alerts_ids, user_id});
    }, (dispatch, responseData) => {
    });
}

export function setDismissAlerts(alerts_ids) {
    return callApi('setDismissAlerts', {alerts_ids}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: RECEIVE_DISMISSED_ALERTS, alerts_ids});
        }
    });
}

