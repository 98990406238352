import {
    RECEIVED_ACTIVITIES_LIST,
    RECEIVED_ACTIVITIES_STATS_LIST,
    CHECK_IN_ACTIVITY_SUCCESS,
    RECEIVED_ACTIVITY_WORK_PLAN,
    UPDATE_SCHEDULE,
    SET_CHALLENGES_SETTINGS,
    SET_ALL_USERS_BY_CENTER,
    RECEIVED_HISTORY_ACTIVITIES_LIST,
    CANCEL_ACTIVITIES
} from "../actions/activities";
import {RESET_LIVE_DATA} from "../actions/auth";

const initialState = {
    by_ids: {},
    by_groups: {},
    activities_stats: {},
    activityWorkPlans: {},
    challengesSettings: {},
    primary_lead_types: {},
    allUsers: {},
    history_by_ids: {},
    history_by_groups: {}
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function activities(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_HISTORY_ACTIVITIES_LIST:
            let historyActivitiesByGroups = {};
            Object.keys(action.activities).forEach(activityId => {
                let activity = action.activities[activityId];
                let groupId = activity.participant_group_id || (activity.title && activity.data.schedule_id);
                if (groupId) {
                    if (!historyActivitiesByGroups[groupId])
                        historyActivitiesByGroups[groupId] = [];
                    historyActivitiesByGroups[groupId].push(activity); //push reference to activity object
                }
            });
            return {
                ...state,
                history_by_ids: {...state.history_by_ids,  ...action.activities},
                history_by_groups: {...state.history_by_groups,  ...historyActivitiesByGroups},
            };
        case RECEIVED_ACTIVITIES_LIST:
            let activitiesByGroups = {};
            Object.keys(action.activities).forEach(activityId => {
                let activity = action.activities[activityId];
                let groupId = activity.participant_group_id || (activity.title && activity.data.schedule_id);
                if (groupId) {
                    if (!activitiesByGroups[groupId])
                        activitiesByGroups[groupId] = [];
                    activitiesByGroups[groupId].push(activity); //push reference to activity object
                }
            });
            return {
                ...state,
                by_ids: action.activities,
                by_groups: activitiesByGroups
            };
        case RECEIVED_ACTIVITIES_STATS_LIST:
            return {
                ...state,
                activities_stats: action.activities_stats
            };
        case CANCEL_ACTIVITIES:
            const activities_by_ids = state.by_ids;
            for (let activity_id of action.activity_ids) {
                activities_by_ids[activity_id].status = 4;
                activities_by_ids[activity_id].end_time = new Date();
                activities_by_ids[activity_id].data.canceled = true;
            }
            return {
                ...state,
                by_ids: {...activities_by_ids}
            };
        case CHECK_IN_ACTIVITY_SUCCESS:
            let activity = {...state.by_ids[action.activityId]};
            activity.checked_in = 1;
            activity.checked_in_time = new Date().getTime();
            if (action.data && action.data.isEndActivity)
                activity.end_time = new Date();

            let by_ids = {...state.by_ids, [action.activityId]: activity};
            if (activity.win_req_val !== 0) {
                let checkInBoostingActivity = Object.values(state.by_ids).filter(boostingActivity => boostingActivity.user_id === activity.user_id && boostingActivity.activity_def_id === 16 && boostingActivity.status === 2)[0];
                if (checkInBoostingActivity) {
                    by_ids[checkInBoostingActivity.id] = {...checkInBoostingActivity,
                        data: {
                            ...checkInBoostingActivity.data,
                            num_checked_in: checkInBoostingActivity.data.num_checked_in + 1
                        }
                    };
                }
            }

            return {
                ...state,
                by_ids
            };
        case SET_ALL_USERS_BY_CENTER: {
            return {
                ...state,
                allUsers: action.allUsers
            };
        }
        case RECEIVED_ACTIVITY_WORK_PLAN:
            if (action.deletedActivityWorkPlan) {
                delete state.activityWorkPlans[action.deletedActivityWorkPlan];
            }
            return {
                ...state, activityWorkPlans: {...state.activityWorkPlans, ...action.activityWorkPlans, refreshDate: new Date()}
            };
        case UPDATE_SCHEDULE:
            if (action.nextScheduleUpdate.challengeId) { // event to remove
                delete state.activityWorkPlans[action.nextScheduleUpdate.challengeId];
            } else if (action.nextScheduleUpdate.schedule) { //event to add
                state.activityWorkPlans[action.nextScheduleUpdate.id] = action.nextScheduleUpdate;
            }
            return {...state, activityWorkPlans: {...state.activityWorkPlans, refreshDate: new Date()}};
        case SET_CHALLENGES_SETTINGS:
            return {
                ...state,
                challengesSettings: action.challengesSettings,
                primary_lead_types: action.primary_lead_types
            };
        default:
            return state;
    }
}
