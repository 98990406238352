import {
    RECEIVED_WORK_SCHEDULE,
    RECEIVED_PUBLISHED_WORK_SCHEDULE,
    RECEIVED_UPDATED_WORK_SCHEDULE, RECEIVED_DELETED_SHIFT, ADD_USER_SHIFT_ROW, SET_SELECTED_WEEK,
} from "../actions/workSchedule";
import {RESET_LIVE_DATA} from "../actions/auth";

const _ = require('lodash');

const initialState = {
    selectedWeek: '',
    workSchedule: [],
    shifts: [],
    users: {}
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function workSchedule(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case SET_SELECTED_WEEK:
            return {
                ...state,
                selectedWeek: action.selectedWeek
            };
        case RECEIVED_WORK_SCHEDULE:
            return {
                ...state,
                workSchedule: action.workSchedule.workSchedule,
                shifts: action.workSchedule.shifts,
                users: action.workSchedule.users
            };
        case RECEIVED_PUBLISHED_WORK_SCHEDULE:
            let workSchedule = [...state.workSchedule];
            for (let plan of workSchedule) {
                if (action.shiftIdToUpdate.indexOf(plan.id) > -1) {
                    plan.status = 2;
                }
            }
            return {
                ...state,
                workSchedule
            };
        case RECEIVED_UPDATED_WORK_SCHEDULE:
            let nextUpdated = state.workSchedule.map(shift => {
                let currentUpdatedShift = action.updatedShiftsData[shift.id];
                if (currentUpdatedShift) {
                    shift = {...currentUpdatedShift};
                    delete action.updatedShiftsData[shift.id];
                }
                return shift;
            });

            return {
                ...state,
                workSchedule: nextUpdated.concat(_.toArray(action.updatedShiftsData))
            };
        case RECEIVED_DELETED_SHIFT:
            return {
                ...state,
                workSchedule: state.workSchedule.filter(shift => {
                    return !action.deletedShiftsData.shiftIdsToDelete.includes(shift.id);
                })
            };
        case ADD_USER_SHIFT_ROW:
            let addedUserShiftRows = action.userShiftRowData.shiftIdsToAdd.map(shiftId => ({
                center_id: action.userShiftRowData.center_id,
                center_shift_id: shiftId,
                date: new Date(action.userShiftRowData.weekDate).toISOString(),
                end_hour: 0,
                id: Infinity,
                last_update: new Date().toISOString(),
                notes: "",
                start_hour: 0,
                status: 0,
                type: -1,
                user_id: action.userShiftRowData.userNew.id,
                user_in_crm_id: action.userShiftRowData.userNew.in_crm_id
            }));
            return {
                ...state,
                workSchedule: [
                    ...state.workSchedule,
                    ...addedUserShiftRows
                ]
            };
        default:
            return state;
    }
}