import {
    RECEIVED_FEATURES_SCORES
} from "../actions/insights";

const initialState = {
    features_scores: {}
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function tvs(state = initializeState(), action = {}) {
    switch (action.type) {
        case RECEIVED_FEATURES_SCORES:
            return {
                ...state,
                features_scores: action.payload.features_scores
            };
        default:
            return state;
    }
}