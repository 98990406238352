import React, {Component} from "react";
import {getLeadTypesData} from "../../selectors/recordsSelector";
import {connect} from "react-redux";
import {Fab, Modal} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import {Field, Form, Formik} from "formik";
import {ErjNumberInput} from "../../components/controls/Counter";
import Slide from '@material-ui/core/Slide';
import ReactTimeout from "react-timeout";
import {addRecord} from "../../actions/performance";
import {API_REQ_SAVE_SUCCESS} from "../../actions/global";
import {metricValueRepresentation, numberWithCommas} from "../../utils/generalUtils";
import ErjConfirm from "../../components/controls/ErjConfirm";

import {t} from 'react-i18nify'

class FloatingAddRecordWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            lastSentRecord: null,
            formDataToSubmit: null
        };
        this.setSubmitting = null;
    }

    openForm = (e) => {
        this.setState({isModalOpen: !this.state.isModalOpen});
    };

    addRecord = () => {
        const formDataToSubmit = {...this.state.formDataToSubmit};
        this.setSubmitting(true);
        this.setState({formDataToSubmit: null});
        this.props.dispatch(
            addRecord(formDataToSubmit, true, () => {
                this.props.dispatch({
                    type: API_REQ_SAVE_SUCCESS,
                    customMsg: (
                        <div className={'localText'}>
                            {t('performance.added_record_success', {
                                amount: numberWithCommas(formDataToSubmit.amount),
                                value: metricValueRepresentation(numberWithCommas(formDataToSubmit.value), 2, this.props.currency).join(''),
                                metricTitle: this.props.lead_types[formDataToSubmit.lead_type_id].title
                            })}
                        </div>
                    )
                });
                this.setSubmitting(false);
                this.setSubmitting = null;
                this.setState({lastSentRecord: {...formDataToSubmit}, isModalOpen: false});
            })
        );
    };

    submitForm = (formDataToSubmit, {setSubmitting}) => {
        setSubmitting(false);
        this.setSubmitting = setSubmitting;
        console.log(formDataToSubmit);
        this.setState({formDataToSubmit});
    };

    render() {
        const {lead_types_list, lead_types} = this.props;
        const {isModalOpen, lastSentRecord} = this.state;
        return (
            <Formik onSubmit={this.submitForm} initialValues={{lead_type_id: '', amount: 1, value: 0}}
                    validate={values => {
                        let errors = {};
                        if (!values.lead_type_id) {
                            errors.lead_type_id = true;
                        }
                        if (values.amount === '') {
                            errors.amount = true;
                        }
                        if (values.value === '') {
                            errors.value = true;
                        }
                        return errors;
                    }}>
                {({setFieldValue, values, handleSubmit, isSubmitting, handleReset}) => (
                    <div
                        className={'floatingAddRecordWidget mui-fixed' + (isModalOpen ? ' formOpen' : '') + (isSubmitting ? ' isSubmitting' : '')}>
                        <Modal disableBackdropClick={true} open={isModalOpen} disablePortal={true}>
                            <Slide in={isModalOpen} direction={'left'}>
                                <div className={'formContainer'}>
                                    <Form className={'add-record-form'}>
                                        <div className={'field'}>
                                            <div
                                                className={'input-label localText'}>{t('performance.select_metric')}:
                                            </div>
                                            <Field name={'lead_type_id'}
                                                   component={({field, form: {touched, errors}, ...props}) => {
                                                       const {onChange, name, value} = field;
                                                       return (
                                                           <div
                                                               className={'leadTypesSelector' + (touched[name] && errors[name] ? ' error' : '')} {...props}>
                                                               {lead_types_list.map(lead_type => lead_type.id > 0 && (
                                                                   <button type={'button'}
                                                                           className={'leadTypeOption' + (lead_type.id === Number(value) ? ' active' : '')}
                                                                           name={name} value={lead_type.id}
                                                                           onClick={onChange}>
                                                                       {lead_type.title}
                                                                   </button>
                                                               ))}
                                                           </div>
                                                       );
                                                   }}/>
                                        </div>
                                        <div className={'fieldRow'}>
                                            <div className={'field'}>
                                                <div
                                                    className={'input-label localText'}>{t('performance.amount')}:
                                                </div>
                                                <Field name="amount"
                                                       render={({field: {onChange, name, value, ...fieldProps}, form: {touched, errors}, ...props}) => (
                                                           <ErjNumberInput className={errors[name] ? 'error' : ''}
                                                                           key={name} keyId={name} name={name}
                                                                           value={value} min={-999999999}
                                                                           max={999999999} interval={1}
                                                                           appendProps={{...fieldProps, ...props}}
                                                                           onChange={({value}) => setFieldValue(name, value)}/>
                                                       )}/>
                                            </div>
                                            <div className={'field'}>
                                                <div
                                                    className={'input-label localText'}>{t('performance.value')}:
                                                </div>
                                                <Field name="value"
                                                       render={({field: {onChange, name, value, ...fieldProps}, form: {touched, errors}, ...props}) => (
                                                           <ErjNumberInput className={errors[name] ? 'error' : ''}
                                                                           key={name} keyId={name} name={name}
                                                                           value={value} min={-999999999}
                                                                           max={999999999} interval={1}
                                                                           appendProps={{...fieldProps, ...props}}
                                                                           onChange={({value}) => setFieldValue(name, value)}
                                                                           currency={this.props.currency}/>
                                                       )}/>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Slide>
                        </Modal>
                        <Fab aria-label={t(isModalOpen ? 'global.cancel' : 'global.add')}
                             className={'formButton addIcon'} disabled={isSubmitting} onClick={e => {
                            this.openForm(e);
                            handleReset();
                        }}>
                            <AddIcon/>
                        </Fab>
                        <Fab aria-label={t('global.save')} className={'formButton saveIcon'}
                             disabled={isSubmitting} onClick={handleSubmit}>
                            <DoneIcon/>
                        </Fab>
                        <ErjConfirm open={Boolean(this.state.formDataToSubmit)} confirmCB={this.addRecord}
                                    close={() => {
                                        this.setState({formDataToSubmit: null});
                                        this.setSubmitting(false);
                                    }}
                                    title={t('erj_confirm.are_you_sure')}
                                    body={this.state.formDataToSubmit ? t('performance.add_record_confirm', {
                                        amount: numberWithCommas(this.state.formDataToSubmit.amount),
                                        value: metricValueRepresentation(numberWithCommas(this.state.formDataToSubmit.value), 2, this.props.currency).join(''),
                                        metricTitle: this.props.lead_types[this.state.formDataToSubmit.lead_type_id].title
                                    }) : ''}/>
                    </div>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...getLeadTypesData(state, props),
        currency: state.auth && state.auth.sessionData && state.auth.sessionData.center_currency_symbol
    };
};

export default connect(mapStateToProps)(ReactTimeout(FloatingAddRecordWidget));