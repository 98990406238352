import {
    RECEIVED_VISITS_REPORT
} from "../actions/reports";

const initialState = {
    visitsReport: []
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function reports(state = initializeState(), action = {}) {
    switch (action.type) {
        case RECEIVED_VISITS_REPORT:
            return {
                ...state,
                visitsReport: action.payload.visitsReport
            };
        default:
            return state;
    }
}
