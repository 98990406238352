import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {MEASURE_METHOD, UNIT_TYPE} from "../../utils/metrics";
import MetricValueDisplay from "../../containers/misc/MetricValueDisplay";

import {t} from 'react-i18nify'

class GoalRelationDisplay extends Component {
    render() {
        let {
            goalRelationDisplay,
            measure_method,
            unit_type,
            goals,
            leads,
            ideal_pace,
            display_decimal_digits
        } = this.props;
        const goalPercent = measure_method === MEASURE_METHOD.DESCENDING ? (goals / leads) : (leads / goals);
        const pacePercent = measure_method === MEASURE_METHOD.DESCENDING ? (ideal_pace / leads) : (leads / ideal_pace);
        const percentFromPace = ideal_pace ? Math.round(pacePercent * 100) : 0;
        const currentProgress = Math.round((goalPercent * 100) * 100) / 100;
        switch (goalRelationDisplay) {
            case 1:
                return (t(`global.metric_of_goal`, {
                    leads: (Math.round((Math.abs(goalPercent) * 100) * 100)) / 100 + '%'
                }));
            case 2:
                return ideal_pace > 0 && t('global.percent_' + (percentFromPace < 100 ? 'behind' : 'on') + '_pace', {percent: percentFromPace + '%'});
            case 3:
                return [
                    <MetricValueDisplay value={Math.abs(goals - leads)}
                                        unitType={unit_type}
                                        roundOpts={{decimals: display_decimal_digits}}
                                        shortNumOpts={{
                                            decimals: 1,
                                            maxLength: 4
                                        }}/>,
                    ' ' + t(`global.metric_${currentProgress < 100 ? 'less_then' : 'more_then'}_goal`)
                ];
        }
    }
}

GoalRelationDisplay.propTypes = {
    leads: PropTypes.number.isRequired,
    goals: PropTypes.number.isRequired,
    measure_method: PropTypes.number.isRequired,
    ideal_pace: PropTypes.number,
    unit_type: PropTypes.number
};

function mapStateToProps(state) {
    return {goalRelationDisplay: state.auth.sessionData.goal_relation_display};
}

export default connect(mapStateToProps)(GoalRelationDisplay);