import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";

import configureStore from "./store/configureStore";
import {initComm} from "./utils/apiUtils";
//import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import App from "./containers/app/App";
///////////////////////////////////////////
// alternative is to link them in index.html
import "./assets/lib/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import {initNotifications} from "./utils/notification";
window.Popper = require("popper.js");
/////////////////////////////////////////////

if (process.env.NODE_ENV !== 'production') {
    // const includeList = new RegExp("^"+window.location.hash.substring(13).split(',').join('|')+"");
    // console.log({includeList});
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {collapseGroups: true});
}

configureStore().then(store => {
    initComm(store);
    initNotifications(store);
    ReactDOM.render(
        <Provider store={store}>
            <App/>
        </Provider>,
        document.getElementById("root")
    );
});

//registerServiceWorker();
