export const CHANGE_SELECTED_TAB = 'CHANGE_SELECTED_TAB';
export const TOGGLE_DISABLED_BUTTONS = 'TOGGLE_DISABLED_BUTTONS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const DISPATCH_UI_ACTION = 'DISPATCH_UI_ACTION';

export function changeSelectedTab(selectedTab, tabNamespace) {
    return {
        type: CHANGE_SELECTED_TAB,
        tab: selectedTab,
        namespace: tabNamespace
    };
}

export function disabledButtons(btnNamespace, isDisable) {
    return {
        type: TOGGLE_DISABLED_BUTTONS,
        disabledButtons: btnNamespace,
        isDisable: isDisable
    };
}

export function showModal(modalType, modalData) {
    return {
        type: SHOW_MODAL,
        modalType: modalType,
        modalData: modalData,
        modalShow: true
    };
}

export function hideModal() {
    return {
        type: HIDE_MODAL,
        modalShow: false
    };
}