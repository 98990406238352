import {
    RECEIVED_SHIFTS_DATA,
    RECEIVED_UPDATED_SHIFTS_DATA,
    RECEIVED_DELETED_SHIFT_DATA
} from "../actions/shifts";
import {RESET_LIVE_DATA} from "../actions/auth";

const initialState = {
    shifts: []
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function shifts(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_SHIFTS_DATA:
            return {
                ...state,
                shifts: action.payload.shiftsData.shifts
            };
        case RECEIVED_UPDATED_SHIFTS_DATA:
            let managerIndex = state.shifts.findIndex(shift => {
                if (shift.id === action.payload.shiftsData.id) {
                    Object.assign(shift, action.payload.shiftsData);
                    return true;
                }
                return false;
            });

            if (managerIndex === -1) {
                state.shifts.unshift(action.payload.shiftsData);
            }
            return {
                ...state,
                editMode: false,
                shifts: [...state.shifts.splice(0)]
            };
        case RECEIVED_DELETED_SHIFT_DATA:
            return {
                ...state,
                shifts: state.shifts.filter((shift) => {
                    return shift.id !== action.payload.shiftsData.id;
                })
            };
        default:
            return state;
    }
}