import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CircularProgress from '@material-ui/core/CircularProgress';

class LoadingOverlay extends Component {
  render() {
    const { isLoggingIn } = this.props;

    return (
        isLoggingIn && <div className="loadingOverlay"><CircularProgress className="progressCircle" size={100} /></div>
    );
  }
}

LoadingOverlay.propTypes = {
    isLoggingIn: PropTypes.bool
};

export default withRouter(LoadingOverlay);
