import {callApi} from "../utils/apiUtils";

export const RECEIVED_NOTIFICATIONS_LIST = "RECEIVED_NOTIFICATIONS_LIST";
export const RECEIVE_READ_NOTIFICATIONS = "RECEIVE_READ_NOTIFICATIONS";
export const RECEIVE_NOTIFICATION_CLICKED = "RECEIVE_NOTIFICATION_CLICKED";

export function receivedNotifications(notifications) {
    return {type: RECEIVED_NOTIFICATIONS_LIST, notifications};
}

function receiveNotificationClicked(notifications) {
    return {type: RECEIVE_NOTIFICATION_CLICKED, notifications};
}

export function setReadNotifications(notifications_ids) {
    return callApi('setReadNotifications', {notifications_ids}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            setTimeout(() => dispatch({type: RECEIVE_READ_NOTIFICATIONS, notifications_ids}), 30 * 1000);
        }
    });
}

export function notificationClickedByUser(notificationParams) {
    return callApi('notificationClickedByUser', notificationParams, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receiveNotificationClicked(notificationParams));
        }
    });
}

