import React, {Component} from 'react';
import PropTypes from "prop-types";
import {t} from "react-i18nify";
import moment from 'moment';
import TextareaAutosize from "react-autosize-textarea";
import ReactHtmlParser from 'react-html-parser';
import {deleteAnnouncement, getRichUrlData, saveAnnouncement} from "../../actions/feeds";
import {connect} from "react-redux";
import {Button, CircularProgress, Icon, IconButton} from "@material-ui/core";
import {ReactComponent as CloseIcon} from "../../assets/icons/X.svg";
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErjDateRangePicker from "../../components/controls/ErjDateRangePicker";
import {base64ToFile} from "../../utils/apiUtils";
import {getStaticFileURL, getUserImg} from "erj-common/generalUtils";
import CurrentTime from "../misc/CurrentTime";
import ErjConfirm from "../../components/controls/ErjConfirm";

function FileRichItem({richItem}) {
    const url = (richItem.url && richItem.url.href) || richItem.url;
    const fileUrl = url && url.slice(url.lastIndexOf('?') + 1);
    const fileExtension = (richItem.file && richItem.file.type) ? richItem.file.type.slice(6) : fileUrl && fileUrl.slice(fileUrl.lastIndexOf('.') + 1);
    if (richItem.file && richItem.file.id) {
        return (
            <a className={'richItemImage'} href={getStaticFileURL(richItem.file.path)} target={'_blank'}>
                <img src={getStaticFileURL(richItem.file.path)} alt={'image'}/>
            </a>
        );
    } else if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'tiff'].indexOf(fileExtension) > -1) {
        return (
            <a className={'richItemImage'} href={url} target={'_blank'}>
                <img src={url} alt={'image from url'}/>
            </a>
        );
    } else {
        const fileName = decodeURIComponent(fileUrl.slice(fileUrl.lastIndexOf('/') + 1));
        return (
            <a className={'richItemContent'} href={url} target={'_blank'}>
                <div className={'titleArea'}>
                    <InsertDriveFileIcon className={'fileIcon'}/>
                    <div className={'title'}>{fileName}</div>
                </div>
                <div className={'description'}>{fileUrl}</div>
            </a>
        )
    }
}

class Announcement extends Component {
    dragCounter = 0;

    state = {
        selectedDateRange: [moment().startOf('day'), moment().startOf('day').add(1, 'week')],
        messageText: '',
        richItem: null,
        isShowFileDropZone: false,
        isSaving: false,
        editMode: false,
        isRequestDelete: false
    };

    handleBodyDragEnter = (e) => {
        this.dragCounter++;
        if (this.dragCounter === 1) {
            this.setState({isShowFileDropZone: true});
        }
    }
    handleBodyDragLeave = (e) => {
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({isShowFileDropZone: false});
        }
    }
    handleBodyDrop = (e) => {
        e.preventDefault();
        this.dragCounter = 0;
        this.setState({isShowFileDropZone: false});
    }
    handleBodyDragOver = (e) => {
        e.preventDefault();
    }

    componentDidMount() {
        if (this.textarea) {
            this.textarea.focus();

            window.addEventListener('dragenter', this.handleBodyDragEnter);
            window.addEventListener('dragleave', this.handleBodyDragLeave);
            window.addEventListener('drop', this.handleBodyDrop);
            window.addEventListener('dragover', this.handleBodyDragOver);
        }
    }

    componentWillUnmount() {
        if (this.textarea) {
            window.removeEventListener('dragenter', this.handleBodyDragEnter);
            window.removeEventListener('dragleave', this.handleBodyDragLeave);
            window.removeEventListener('drop', this.handleBodyDrop);
            window.removeEventListener('dragover', this.handleBodyDragOver);
        }
    }

    handleEdit = () => {
        this.setState({
            editMode: true,
            messageText: this.props.announcement.data.messageText,
            richItem: this.props.announcement.data.richItem,
            selectedDateRange: [moment(this.props.announcement.active_from, 'YYYY-MM-DD'), moment(this.props.announcement.active_until, 'YYYY-MM-DD')],
        })
    }

    handleDelete = () => {
        this.setState({isRequestDelete: true});
    }

    handleChangeMessageText = (e) => {
        this.setState({messageText: e.target.value});
    }

    handlePasteMessage = (e) => {
        const clipboardData = e.clipboardData;
        console.log(clipboardData, clipboardData.items[0], clipboardData.files[0], clipboardData.types, clipboardData.getData('text'));
        const item = clipboardData.items[0];
        if (!item) {
            return;
        }
        console.log(item, item.type, item.type === 'text/plain');
        console.log(item.getAsFile());
        item.getAsString(str => console.log(str));
        if (item.type === 'text/plain') {
            try { //check if is a url
                let url = new URL(clipboardData.getData('text'));
                e.preventDefault();
                console.log(url);
                // due to cors limits, using server to fetch data from the url
                this.setState({richItem: {isLoading: true}})
                this.props.dispatch(getRichUrlData(url.href, 350, result => this.setState({richItem: {url, ...result}})));
            } catch (e) {
                //not a url
                console.error(e);
            }
        } else if (item.type === 'text/html') {
            item.getAsString(str => {
                const url = str.match(/<img src="([^"]+)"/)[1];
                if (url) {
                    console.log(url);
                    if (url.indexOf('data:image/') === 0) {
                        this.handleDropFile(base64ToFile(url));
                    } else {
                        this.setState({richItem: {url: new URL(url), type: 'image', source: 'url'}});
                    }
                }
            });
        } else if (item.type.indexOf('image/') === 0) {
            this.handleDropFile(item.getAsFile());
        }
    }

    handleAttachImage = (e) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
            this.handleDropFile(e.target.files[0]);
        };
        input.click();
    };

    handleDropFile = (file) => {
        if (file.type.indexOf('image/') === 0) {
            if (file.size > (4 * 1024 * 1024)) {
                alert(t('global.max_size_error'));
                return;
            }
            this.setState({
                richItem: {
                    type: 'image',
                    extension: file.type.slice(6),
                    file,
                    url: {
                        href: URL.createObjectURL(file)
                    }
                }
            });
        }
    }

    closeForm = () => {
        if (this.props.announcement) {
            this.setState({editMode: false});
        } else {
            this.props.closeForm();
        }
    }

    saveAnnouncement = () => {
        const {messageText, richItem, selectedDateRange} = this.state;
        this.setState({isSaving: true});
        this.props.dispatch(saveAnnouncement({
            id: this.props.announcement && this.props.announcement.id,
            messageText,
            richItem,
            selectedDateRange: selectedDateRange.map(item => item.toDate())
        }, (isSuccessfull) => {
            this.setState({isSaving: false});
            if (isSuccessfull) {
                this.closeForm();
            }
        }))
    }

    cancelDeleteAnnouncement = () => {
        this.setState({isRequestDelete: false})
    }

    deleteAnnouncement = () => {
        this.props.dispatch(
            deleteAnnouncement(this.props.announcement && this.props.announcement.id, isSuccessfull => {
                this.setState({isRequestDelete: false});
            })
        );
    }

    render() {
        let {messageText, richItem, isSaving} = this.state;
        const {announcement, managers, is_manager, allowedCenterIds} = this.props;
        const editMode = this.props.editMode || this.state.editMode;
        const postProps = !editMode ? {} : {
            onDrop: (e) => {
                e.preventDefault();
                console.log(e, e.dataTransfer, e.dataTransfer.items[0]);
                this.handleDropFile(e.dataTransfer.items[0].getAsFile());
            }
        };
        if (announcement && !editMode) {
            ({messageText, richItem} = announcement.data || {});
        }
        const richItemUrl = richItem && richItem.url && (richItem.url.href || richItem.url);
        return (
            <div
                className={'announcementContainer' + (announcement && !editMode && announcement.active_from > announcement.created_on ? ' futureAnnouncement' : '')}>
                <ErjConfirm open={this.state.isRequestDelete} confirmCB={this.deleteAnnouncement}
                            close={this.cancelDeleteAnnouncement}
                            dialogProps={{onExited: () => window.document.querySelector('body').style.overflowY = 'hidden'}}/>
                {isSaving && (
                    <div className={'savingOverlay'}>
                        <CircularProgress className="pageLoadProgressCircle" size={100}/>
                    </div>
                )}
                {editMode && (
                    <ErjDateRangePicker
                        name={'dateRange'}
                        calendarClassName="erj-calendar"
                        className={'publish-date-range'}
                        onChange={(selectedDateRange) => this.setState({selectedDateRange: selectedDateRange.value})}
                        useWeekdaysShort={true}
                        value={this.state.selectedDateRange}
                        additionalProps={{minDate: moment().startOf('day')}}
                        maxDuration={[1, 'year']}
                        customInput={(value) => (
                            <button>
                                <span className={'selectedDate localText'}>
                                    <Icon className="icon- calendarIcon">calendar</Icon>
                                    {t('feeds.publish_from_to', {
                                        startDate: value[0].format('ll'),
                                        endDate: value[1].format('ll')
                                    })}
                                </span>
                            </button>
                        )}
                    />
                )}
                <div className={'post'} {...postProps}>
                    {!editMode && Boolean(is_manager) && allowedCenterIds.indexOf(announcement.center_id + '') > -1 && (
                        <div className={'hoverActions'}>
                            <IconButton className={'editIcon icon-'} onClick={this.handleEdit}>edit</IconButton>
                            <IconButton className={'deleteIcon icon-'} onClick={this.handleDelete}>bin</IconButton>
                        </div>
                    )}
                    {this.state.isShowFileDropZone && (
                        <div className={'fileDropZone'}>{t('global.drop_file_here')}</div>
                    )}
                    {editMode ? (
                        <TextareaAutosize placeholder={t('feeds.type_a_message') + '...'}
                                          disabled={this.state.isSaving}
                                          value={messageText}
                                          onChange={this.handleChangeMessageText}
                                          onPaste={this.handlePasteMessage}
                                          innerRef={ref => this.textarea = ref}/>
                    ) : (
                        <div className={'messageText'}>{messageText}</div>
                    )}
                    {richItem && (
                        <div className={'richItem'}>
                            {editMode && (
                                <IconButton className={'removeRichItemButton'}
                                            onClick={() => this.setState({richItem: null})}>
                                    <CloseIcon className={'closeIcon'}/>
                                </IconButton>
                            )}
                            {richItem.isLoading ? (
                                <CircularProgress className="pageLoadProgressCircle" size={100}/>
                            ) : (
                                richItem.embedData ? ReactHtmlParser(richItem.embedData.html) : (
                                    richItem.ogData ? (
                                        <a className={'richItemContent'} href={richItem.url.href} target={'_blank'}>
                                            <div className={'titleArea'}>
                                                <img src={richItem.ogData.favicon} alt={richItem.ogData.title}
                                                     className={'favIcon'}/>
                                                <div className={'title'}>{richItem.ogData.title}</div>
                                            </div>
                                            <div className={'description'}>{richItem.ogData.description}</div>
                                            {richItem.ogData.image && (
                                                <img className={'mainImage'} src={richItem.ogData.image}
                                                     alt={richItem.ogData.description}/>
                                            )}
                                        </a>
                                    ) : (
                                        (richItem.type === 'image' || richItemUrl.lastIndexOf('.') >= richItemUrl.length - 5) ? (
                                            <FileRichItem richItem={richItem}/>
                                        ) : (
                                            <a className={'richItemContent'} href={richItem.url.href} target={'_blank'}>
                                                <div className={'titleArea'}>
                                                    <div className={'title'}>{richItem.url.hostname}</div>
                                                </div>
                                                <div className={'description'}>{richItem.url.href}</div>
                                            </a>
                                        )
                                    )
                                )
                            )}
                        </div>
                    )}
                </div>
                {editMode ? (
                    <div className={'editActionsArea'}>
                        <div className={'editActions'}>
                            <IconButton className={'attachImageButton'} onClick={this.handleAttachImage}>
                                <ImageIcon/>
                            </IconButton>
                        </div>
                        <div className={'buttons'}>
                            <Button className={'cancelButton'} onClick={this.closeForm}>{t('global.cancel')}</Button>
                            <Button className={'saveButton'} onClick={this.saveAnnouncement}
                                    disabled={!richItem && !messageText}>{t('global.save')}</Button>
                        </div>
                        <div className={'publicInfo'}>
                            <Icon className={'icon- eye'}>eye</Icon>
                            {' ' + t('feeds.public_announcement_warning')}
                        </div>
                    </div>
                ) : (
                    <div className={'metaContent'}>
                        <div className={'createdBy'}>
                            <img className={'userImg'}
                                 src={getUserImg(managers[announcement.origin_sender_id].user_img)}
                                 alt={managers[announcement.origin_sender_id].first_name}/>
                            {managers[announcement.origin_sender_id].first_name + ' ' + managers[announcement.origin_sender_id].last_name}
                        </div>
                        <div className={'timeAgo'}>
                            {announcement.active_from > announcement.created_on ? (
                                <AccessTimeIcon className={'timeAgoIcon'}/>
                            ) : (
                                <DoneAllIcon className={'timeAgoIcon'}/>
                            )}
                            <CurrentTime format={'timeAgo'}
                                         compareTime={announcement.active_from < announcement.created_on ? announcement.created_on : announcement.active_from}/>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

//TODO: allow to load past announcements

Announcement.propTypes = {
    editMode: PropTypes.bool,
    closeForm: PropTypes.func,
    announcement: PropTypes.object
}

const mapStateToProps = (state, props) => {
    return {};
}

export default connect(mapStateToProps)(Announcement);