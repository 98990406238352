import React, {Component, Suspense,useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {getUserImg} from "erj-common/generalUtils";
import NotificationIcon from "../../containers/misc/NotificationIcon";
import CurrentTime from "../../containers/misc/CurrentTime";
import "./header.css";

import moment from 'moment';
import ErrorBoundary from "../../containers/misc/ErrorBoundary";
import {getDaysTillEndOfMonth} from "erj-common/generalUtils";
import {Dialog, Slide} from "@material-ui/core";
import ErjScroll from "../controls/ErjScroll";

import {t} from 'react-i18nify'
const _ = require('lodash');

const AllJobs = React.lazy(() => import("../../components/branding/AllJobs"));


class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastMenuEventFired: new Date().getTime(),
            menuOpen: false,
            anchorEl: null,
            ...this.initState(props)
        };
    }

    initState = (props) => {
        const {first_day_of_month, months, center_shifts, workDays, thisMonthDaysWorked} = props;

        return getDaysTillEndOfMonth({
            moment,
            currentTime: new Date(),
            center_shifts,
            first_day_of_month,
            months,
            is_manager: !props.user || props.user.is_manager,
            workDays_goal: workDays,
            daysWorked: thisMonthDaysWorked
        });
    };


    componentWillReceiveProps(nextProps) {
        this.setState(this.initState(nextProps));
    }

    componentDidMount() {
        this.updateDaysLeftGraph();
    }

    componentDidUpdate() {
        this.updateDaysLeftGraph();
    }

    onLogoutClick = event => {
        this.handleMenuClose(event, 'userProfileMenuButtonArea');
        this.props.handleLogout();
    };

    onChangeCenterClick = (event, center_id) => {
        this.handleMenuClose(event, 'userProfileMenuButtonArea');
        this.props.handleChangeCenter(center_id);
        // setTimeout(() => this.props.history.push(process.env.REACT_APP_ROUTE_PATH + "/"), 1000);
    };

    onMyAccountClick = event => {
        this.handleMenuClose(event, 'userProfileMenuButtonArea');
        this.props.history.push(process.env.REACT_APP_ROUTE_PATH + "/my_account");
    };

    goToStore = event => {
        this.props.history.push(process.env.REACT_APP_ROUTE_PATH + "/store");
    };

    handleMenuClick = (event, key) => {
        const {currentTarget} = event;
        if (new Date().getTime() > this.state.lastMenuEventFired + 500) //wait 500ms after last menu event, this is to allow closing if clicking again on menu button
            this.setState({
                menuOpen: {...this.state.menuOpen, [key]: !this.state.menuOpen[key]},
                lastMenuEventFired: new Date().getTime(),
                anchorEl: currentTarget,
                filterText:"",
            });
    };

    handleMenuClose = (event, key) => {
        if (!event || (event.target.tagName !== 'HTML' && this.state.menuOpen[key]))
            this.setState({
                menuOpen: {...this.state.menuOpen, [key]: false}, 
                lastMenuEventFired: new Date().getTime(),
                filterText:"",
            });
    };
    
    
    setFilterValue = (text) => {
        this.setState({...this.state,filterText:text});
        // console.log(text)
    }


    updateDaysLeftGraph() {
        const canvas = this.refs.daysLeft;
        if (!canvas)
            return;
        const ctx = canvas.getContext('2d');
        console.log(canvas.width, canvas.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        let x = canvas.width / 2;
        let y = canvas.height / 2;
        let lineWidth = Math.ceil(0.075 * canvas.height);
        let radius = y - (lineWidth / 2);

        let startAngle = 1.5 * Math.PI;
        let endAngle = (1.5 + (2 * this.state.percentDaysPassed)) * Math.PI;
        let counterClockwise = false;

        ctx.beginPath();
        ctx.setLineDash([Math.ceil(0.05 * canvas.height), Math.ceil(0.025 * canvas.height)]);
        ctx.arc(x, y, radius, startAngle, endAngle, counterClockwise);
        ctx.lineWidth = lineWidth;
        // line color
        ctx.strokeStyle = 'white';
        ctx.stroke();

        // draw the rest of the circle
        startAngle = endAngle;
        endAngle = 3.5 * Math.PI;
        ctx.beginPath();
        ctx.setLineDash([Math.ceil(0.05 * canvas.height), Math.ceil(0.025 * canvas.height)]);
        ctx.arc(x, y, radius, startAngle, endAngle, counterClockwise);
        ctx.lineWidth = lineWidth;

        // line color
        ctx.strokeStyle = 'rgba(255,255,255,0.25)';
        ctx.stroke();
    }

    render() {
        const {user, pages, center_is_parent, childCentersMenu, mobile, attached_center, login_to_ui} = this.props;
        const pathname = this.props.history.location.pathname;
        const isLoginPage = pathname.indexOf("login") > -1;

        const {menuOpen} = this.state;

        let main_menu = [];
        Object.keys(pages).forEach(k => {
            if (pages[k].menu && pages[k].menu === 'main') {
                pages[k].key = k;
                main_menu.push(pages[k]);
            }
        });
        main_menu.sort((a, b) => b.order - a.order);

        const activeMenuItem = main_menu.filter(page => page.path === this.props.location.pathname || (page.path !== '/' && this.props.location.pathname.indexOf(page.path) === 0))[0];

        const mainMenuItems = main_menu.map(page => (!mobile || page.showOnMobile) && (
                (page.dropdown && page.dropdown.length > 0 && !mobile) ? (
                    <span key={page.key} className={page.key + " buttonArea"} ref={page.key + " buttonArea"}>
                    <Button
                        className={"menuItem" + (page === activeMenuItem ? ' active' : '') + (page.alert ? ' hasAlert' : '')}
                        classes={{label: "menuItemLabel"}}
                        key={page.key}
                        onClick={(e) => this.handleMenuClick(e, page.key)}>
                        <Icon key={page.key + '-navButton'} color="primary"
                              className={page.key + '-navButton icon- menuIcon'}>
                            {page.icon}
                        </Icon>
                        <span className={"menuItemLabel"}>{t(page.label)}</span>
                    </Button>
                    <div ref={page.key + " menuContainer"} className={page.key + " menuContainer"}>
                        <Popper open={Boolean(menuOpen[page.key])} className={'menu'}
                                anchorEl={this.state.anchorEl} placement="bottom-start" transition
                                disablePortal={true} modifiers={{
                            preventOverflow: {enabled: false},
                            hide: {enabled: false}
                        }}>
                            {({TransitionProps, placement}) => (
                                <Grow {...TransitionProps}>
                                    <Paper className="performanceMenu">
                                        <ClickAwayListener
                                            onClickAway={(e) => this.handleMenuClose(e, page.key)}>
                                            <MenuList role="menu">
                                                {page.dropdown.map((pageKey, index) => {
                                                    let currentPage = pages[pageKey];
                                                    return currentPage &&
                                                        <MenuItem key={index}
                                                                  className={currentPage.alert ? 'hasAlert' : ''}
                                                                  onClick={(e) => {
                                                                      this.handleMenuClose(e, page.key);
                                                                      this.props.history.push(process.env.REACT_APP_ROUTE_PATH + currentPage.path);
                                                                  }}>{t(currentPage.alternativeLabel || currentPage.label)}</MenuItem>
                                                })}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </span>
                ) : (
                    <span
                        className={"menuItem" + ((page.path === this.props.location.pathname || (page.path !== '/' && this.props.location.pathname.indexOf(page.path) > -1)) ? ' active' : '') + (page.alert ? ' hasAlert' : '')}
                        key={page.key}>
                    <Link to={process.env.REACT_APP_ROUTE_PATH + page.path} className="menuIconLink"
                          id={page.key + '-navButton'}>
                        <IconButton key={page.key + '-navButton'} color="primary"
                                    className={page.key + '-navButton icon- menuIcon'} component="span">
                            {page.icon}
                        </IconButton>
                        <span className={"menuItemLabel"}>{t(page.label)}</span>
                    </Link>
                </span>)
            )
        );

        return (
            user && !isLoginPage && (
                <AppBar position="static">
                    <Toolbar className={"container"}>
                        {!mobile && (
                            <div className="flex leftMenu">
                                <span className={'menuItem'}>
                                    <Tooltip className={"localText"}
                                             title={t(`global.${user.is_manager ? 'days_till_end_of_month' : 'work_days_left'}`, {days: this.state.days_till_end_of_month})}>
                                        <Link
                                            to={pages.WorkSchedule ? process.env.REACT_APP_ROUTE_PATH + pages.WorkSchedule.path : '#'}
                                            className="daysLeftGraph">
                                            <span className="daysLeftNum">{this.state.days_till_end_of_month}</span>
                                <canvas ref="daysLeft" width="44" height="44"/>
                                        </Link>
                                    </Tooltip>
                                </span>
                                <div className="current_time"><CurrentTime format={'ddd. L | HH:mm'}/></div>
                                <div className={'currentCenterTitle'}>{attached_center.title}</div>
                            </div>
                        )}
                        <div className={"rightMenu"}>
                            {['alljobsc.enerjoy.co', 'alljobsb.enerjoy.co'].indexOf(window.location.hostname) > -1 && main_menu.length <= 5 && (
                                <Suspense fallback={<span/>}>
                                    <AllJobs/>
                                </Suspense>
                            )}
                            {!mobile && mainMenuItems}
                            {!Boolean(center_is_parent) && !mobile && login_to_ui !== 'tv' && (
                                <ErrorBoundary><NotificationIcon/></ErrorBoundary>
                            )}
                            <span className="userProfileMenuButtonArea" ref="userProfileMenuButton">
                                <Button
                                    aria-owns={menuOpen['userProfileMenuButtonArea'] ? 'menu-appbar' : null}
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                        this.handleMenuClick(e, 'userProfileMenuButtonArea')
                                    }}
                                    color="inherit"
                                    className="userProfileMenuButton defaultFont">
                                    <img className="userProfileImg" alt={'userProfileImg'}
                                         src={getUserImg(user.image_uri, "xs")}/>
                                    {!mobile && user.first_name}
                                    <Icon className="icon- dropdownIcon">dropdown_arrow</Icon>
                                </Button>
                                {mobile ? (
                                    <Dialog fullScreen open={Boolean(menuOpen['userProfileMenuButtonArea'])}
                                            className={'headerDropMenu mob'}
                                            PaperProps={{className: 'headerDropMenuBody'}}
                                            scroll={'body'}
                                            hideBackdrop={true}>
                                        <MenuList role="menu">
                                            {mainMenuItems.map((menuItem, index) => menuItem && (
                                                <MenuItem key={'menuItem_' + index}>{menuItem}</MenuItem>
                                            ))}
                                            <MenuItem key={'logout'}
                                                      onClick={this.onLogoutClick}>{t('global.logout')}</MenuItem>
                                        </MenuList>
                                    </Dialog>
                                ) : (
                                    <div ref="userProfileMenuContainer" className="userProfileMenuContainer">
                                        <Popper className={'menu'} open={Boolean(menuOpen['userProfileMenuButtonArea'])}
                                                placement="bottom-end" anchorEl={this.state.anchorEl} transition
                                                disablePortal={true}>
                                            {({TransitionProps, placement}) => (
                                                <Grow {...TransitionProps}>
                                                    <Paper className="userProfileMenu">
                                                            <ClickAwayListener
                                                                onClickAway={(e) => this.handleMenuClose(e, 'userProfileMenuButtonArea')}>
                                                                <div>
                                                                    {login_to_ui !== 'tv' && (
                                                                    <MenuList>
                                                                        <MenuItem key={'myaCcount'} class={'bg-warning'}
                                                                                  onClick={this.onMyAccountClick}>{t('global.my_account')}</MenuItem>
                                                                    </MenuList>
                                                                    )}
                                                                    {childCentersMenu.length > 1 &&  
                                                                    <MenuItem>
                                                                        <input
                                                                        onKeyDown={(e) => e.stopPropagation()}
                                                                        style={{width: "165px"}} 
                                                                        placeholder={t('global.search')} 
                                                                        type="text" 
                                                                        onChange={(e) => this.setFilterValue(e.target.value)}
                                                                        />
                                                                     </MenuItem>}
                                                        <ErjScroll autoHeight autoHeightMax={500} scrollOnOpen={true}>
                                                                <MenuList role="menu">
                                                                    {childCentersMenu.length > 1 && childCentersMenu.filter(menuItem=>
                                                                    {
                                                                        const filterCondition = `${menuItem?.title}`.toLowerCase().includes(`${this.state?.filterText}`.toLowerCase())||`${menuItem?.id}`.toLowerCase().includes(`${this.state?.filterText}`.toLowerCase());
                                                                        return filterCondition;
                                                                    })
                                                                    .map(linkData => (
                                                                        <MenuItem key={linkData.id} title={linkData.id}
                                                                                  onClick={(e) => this.onChangeCenterClick(e, Number(linkData.id))}
                                                                                  className={Number(linkData.id) === Number(this.props.attached_center_id) ? 'selectedCenter scrollOnOpen' : ''}>
                                                                            {linkData.lvl > 0 && _.fill(Array(linkData.lvl), true).map((item, index) =>
                                                                                <span
                                                                                    key={index}>&nbsp;&nbsp;&nbsp;&nbsp;</span>)}
                                                                            {linkData.title}
                                                                        </MenuItem>
                                                                    ))
                                                                    }
                                                                    { false && process.env.NODE_ENV=="development" && <div>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    <MenuItem>dummy menu item</MenuItem>
                                                                    </div>
                                                                    }
                                                                </MenuList>
                                                        </ErjScroll>
                                                        <MenuList>
                                                            <MenuItem key={'logout'} class={'bg-warning'}
                                                                      onClick={this.onLogoutClick}>{t('global.logout')}</MenuItem>
                                                        </MenuList>
                                                        </div>
                                                            </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </div>
                                )}
                            </span>
                            {mobile && activeMenuItem && (
                                <div className={'currentPageTitle'}>
                                    {t(activeMenuItem.label)}
                                </div>
                            )}
                        </div>
                    </Toolbar>
                </AppBar>
            )
        );
    }
}

Header.propTypes = {
    pages: PropTypes.object,
    user: PropTypes.object,
    handleLogout: PropTypes.func.isRequired
};

export default withRouter(Header);
