import {callApi} from "../utils/apiUtils";

import cookies from "../utils/cookies";

export const AUTH_TOKEN = "auth_token";
export const TARGET_CENTER_ID = "tcId";
export const API_STATUS_SUCCESS = 'SUCCESS';
export const STAGE_TWO_STEP_CODE = 'STAGE_TWO_STEP_CODE';
export const API_STATUS_EXPIRED_SESSION = 'ERR_EXPIRED_SESSION';

export const RECAPTCHA_RETRY_W_CHECKBOX = 'RECAPTCHA_RETRY_W_CHECKBOX';

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_2_STEP_AUTH = "LOGIN_2_STEP_AUTH";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const ERR_NO_SESSION_ID = "ERR_NO_SESSION_ID";
export const ERR_INVALID_AUTH_REQ = "ERR_INVALID_AUTH_REQ";
export const ERR_INVALID_JWT = "ERR_INVALID_JWT";
export const UPDATE_ENERCOINS = "UPDATE_ENERCOINS";
export const UPDATE_ACCOUNT_DATA = "UPDATE_ACCOUNT_DATA";
export const REQUEST_APPROVE_NOTIFICATIONS = "REQUEST_APPROVE_NOTIFICATIONS";
export const REQUEST_APPROVE_NOTIFICATIONS_BLOCKED = "REQUEST_APPROVE_NOTIFICATIONS_BLOCKED";
export const REQUEST_APPROVE_NOTIFICATIONS_UNSUPPORTED = "REQUEST_APPROVE_NOTIFICATIONS_UNSUPPORTED";

export const RECEIVED_USER_DATA = "RECEIVED_USER_DATA";
export const RECEIVED_USER_PREFERENCES = "RECEIVED_USER_PREFERENCES";
export const RECEIVED_USER_METRICS = "RECEIVED_USER_METRICS";

export const RESET_LIVE_DATA = "RESET_LIVE_DATA";

export function loginRequest(dispatch, actionData) {
    dispatch({type: LOGIN_REQUEST});
}

export function loginSuccess(sessionData, wasLoggedIn) {
    return {type: LOGIN_SUCCESS, sessionData, wasLoggedIn};
}

export function login2StepAuth(authData) {
    return {type: LOGIN_2_STEP_AUTH, authData};
}

function resetLiveData() {
    return {type: RESET_LIVE_DATA};
}

export function loginFailure(reason) {
    return {type: LOGIN_FAILURE, reason: reason};
}

export function checkPermissions(permissions, key, value, recursiveResult) {
    let selectedPermission = permissions[key];
    if (selectedPermission === undefined) {
        switch (key) {
            case 'accessControl':
                return permissions.managers || permissions.roles || permissions.units || permissions.users;
            case 'settingsPage':
                return permissions.challenges || permissions.unit_settings || permissions.managers || permissions.roles || permissions.units || permissions.users || permissions.metrics || permissions.shifts;
            default:
                return false;
        }
    } else {
        if (selectedPermission.constructor === Boolean) {
            return recursiveResult || selectedPermission;
        } else if (selectedPermission.constructor === Array) {
            return recursiveResult || permissions[key].indexOf(value) !== -1;
        } else if (selectedPermission.constructor === Object) {
            Object.keys(selectedPermission).map(key => {
                recursiveResult = checkPermissions(selectedPermission, key, value, recursiveResult);
            });
        }
        return recursiveResult;
    }
}

export function changeCenter(center_id) {
    return callApi('changeCenter', {center_id}, loginRequest, (dispatch, responseData) => {
        cookies.removeItem(TARGET_CENTER_ID, window.location.pathname, undefined, true);
        cookies.setItem(TARGET_CENTER_ID, center_id, Infinity, '/', undefined, true);
        dispatch(resetLiveData());
        dispatch(loginSuccess(responseData));
    });
}

export function receivedUserData(userData) {
    return {type: RECEIVED_USER_DATA, userData: userData};
}

export function receivedUserMetrics(userMetrics) {
    return {type: RECEIVED_USER_METRICS, userMetrics: userMetrics};
}

export function updateEnercoins(enercoinsAmount) {
    return {type: UPDATE_ENERCOINS, payload: {enercoinsAmount}};
}

export function updateAccountData(accountData) {
    return {type: UPDATE_ACCOUNT_DATA, accountData};
}

export function updateUserPreferences(preferences) {
    return callApi('updateUserPreferences', {preferences}, null, (dispatch, responseData) => {
        dispatch(loginSuccess(responseData));
    });
}
