import {
    createSelectorWithDependencies as createSelector
} from 'reselect-tools'
import {checkPermissions} from "../actions/auth";
import React from "react";

import Login from "../containers/login/Login";
import Home from "../containers/home/Home";
import {getChildCentersMenu} from "../utils/generalUtils";
import {getLocale, t} from 'react-i18nify';
import {getStartNEndDatesOfMonth} from "erj-common/generalUtils";
import moment from "moment";

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';

const MyAccount = React.lazy(() => import("../containers/account/MyAccount"));
const CommissionWizard = React.lazy(() => import("../containers/commissions/CommissionWizard"));
const CommissionManagement = React.lazy(() => import("../containers/commissions/CommissionManagement"));
const CommissionSettings = React.lazy(() => import("../containers/commissions/CommissionSettings"));
const ChangePassword = React.lazy(() => import("../containers/account/ChangePassword"));
const Settings = React.lazy(() => import("../containers/settings/Settings"));
const ChallengesSettingsPage = React.lazy(() => import("../containers/settings/ChallengesSettingsPage"));
const ModuleDisabledPage = React.lazy(() => import("../containers/misc/ModuleDisabledPage"));
const Reports = React.lazy(() => import("../containers/reports/Reports"));
const Tools = React.lazy(() => import("../containers/tools/Tools"));
const Store = React.lazy(() => import("../containers/store/Store"));
const TvSetupPage = React.lazy(() => import("../containers/settings/TvSetupPage"));
const WorkPlanPage = React.lazy(() => import("../containers/settings/WorkPlanPage"));
const GoalsWidget = React.lazy(() => import("../containers/users/GoalsWidget"));
const Records = React.lazy(() => import("../containers/users/Records"));
const ManagerStore = React.lazy(() => import("../containers/store/ManagerStore"));
const ManageChallenges = React.lazy(() => import("../containers/activities/ManageChallenges"));
const PerformanceGraph = React.lazy(() => import("../containers/users/PerformanceGraphWidget"));
const TimelineGraph = React.lazy(() => import("../containers/users/TimelineGraphWidget"));
const WorkSchedule = React.lazy(() => import("../containers/widgets/WorkScheduleWidget"));

const _ = require('lodash');

const getAuth = (state, props) => state.auth;
const getAlerts = (state, props) => state.alerts.alerts;
const getApiRequestStatusFeedback = (state, props) => state.global.apiRequestStatusFeedback;
const getScriptId = (state, props) => state.global.scriptId;
const getDisabledFeatures = (state, props) => state.global.disabledFeatures;
const getCurrentDate = (state, props) => state.global.currentDate;
const getFirstDayOfMonth = (state, props) => state.auth.sessionData.additionalData.first_day_of_month;
const getCenterMonths = (state, props) => state.auth.sessionData.additionalData.months;
const getMonthListVariant = (state, props) => props.monthListVariant;

export const getMonthsList = createSelector(
    [getCurrentDate, getFirstDayOfMonth, getCenterMonths, getMonthListVariant],
    (currentDate, first_day_of_month, months, monthListVariant) => {
        const [firstDayCurrMonth, lastDayCurrMonth] = getStartNEndDatesOfMonth(moment, currentDate, first_day_of_month, months);
        const currMonth = firstDayCurrMonth.format('YYYY-MM-01');
        const monthsMap = months && _.keyBy(months, month => moment(month.start).format('YYYY-MM-01'));
        let monthsList = [];
        for (let month = moment(currMonth).add(monthListVariant === 'commissionWizard' ? 0 : 1, 'months'); month.isAfter(moment(currMonth).subtract(11, 'months')); month.subtract(1, 'months')) {
            const monthKey = month.format('YYYY-MM-01');
            let start = moment(month).add(first_day_of_month - 1, 'days');
            let end = moment(start).add(1, 'months').subtract(1, 'days');
            let title = moment(start.date() > 15 ? end : start).format('MMMM');
            if (monthsMap && monthsMap[monthKey]) {
                start = moment(monthsMap[monthKey].start);
                end = moment(monthsMap[monthKey].end);
                title = monthsMap[monthKey].name;
            }
            const arrayMethod = monthListVariant === 'commissionWizard' ? 'unshift' : 'push';
            const isCurrentMonth = month.isSame(currMonth);
            let monthTitle = month.isAfter(currMonth) ? t('performance.next_month') : start.format('DD.MM') + ' - ' + end.format('DD.MM');
            if (monthListVariant === 'commissionWizard' && isCurrentMonth) {
                monthTitle = t('global.this_month');
            } else if (monthListVariant === 'commissionWizard' && moment(month).add(1, 'month').isSame(currMonth)) {
                monthTitle = t('global.last_month');
            }
            monthsList[arrayMethod]({
                title: title,
                key: monthTitle,
                value: monthKey,
                start: start.format('YYYY-MM-DD'),
                end: end.format('YYYY-MM-DD'),
                isCurrentMonth
            });
        }
        return monthsList;
    }
)

export const getGlobalAppData = createSelector(
    [getAuth, getApiRequestStatusFeedback, getAlerts, getScriptId, getDisabledFeatures],
    (auth, apiRequestStatusFeedback, alerts, scriptId, disabledFeatures) => {
        let is_manager = auth && auth.is_manager;
        let user = auth && auth.accountData;
        let employeeCapabilities = !is_manager && _.get(auth, 'sessionData.additionalData.employees_capabilities');
        let notification_medium_priority = _.get(auth, 'sessionData.additionalData.notification_medium_priority');
        let first_day_of_month = (auth && auth.sessionData && auth.sessionData.additionalData && auth.sessionData.additionalData.first_day_of_month || 1);
        let time_zone_offset = (auth && auth.sessionData && auth.sessionData.additionalData && auth.sessionData.additionalData.time_zone_offset || 0);
        let months = auth && auth.sessionData && auth.sessionData.additionalData && auth.sessionData.additionalData.months;
        let center_shifts = auth && auth.sessionData && auth.sessionData.additionalData && auth.sessionData.additionalData.center_shifts;
        const attached_center_id = auth && auth.sessionData && auth.sessionData.attached_center_id;
        const center_is_parent = auth && auth.sessionData && auth.sessionData.additionalData && Boolean(auth.sessionData.additionalData.center_is_parent);
        const directChildren = _.get(auth, ['sessionData', 'additionalData', 'child_centers', attached_center_id], [])
            .filter(center_id => center_id && !auth.sessionData.additionalData.child_centers[center_id]);
        const challenges_controlling_center = auth && auth.sessionData && auth.sessionData.challenges_controlling_center;
        const goals_origin = auth && auth.sessionData && auth.sessionData.goals_origin;
        const login_to_ui = auth && auth.sessionData && auth.sessionData.login_to_ui;
        const notificationApproval = auth && auth.notificationApproval;
        const commissions_enabled = auth && auth.sessionData && auth.sessionData.commissions_enabled;
        const challenges_enabled = auth && auth.sessionData && auth.sessionData.challenges_enabled;
        const store_enabled = auth && auth.sessionData && auth.sessionData.store_enabled;
        const commissions_controlling_center = auth && auth.sessionData && auth.sessionData.commissions_controlling_center
        const origin_center_id = auth && auth.sessionData && auth.sessionData.origin_center_id;

        let extraProps = {};
        if (is_manager) {
            user = {...user, is_manager: is_manager, permissions: auth.permissions};
        } else {
            extraProps.thisMonthDaysWorked = auth.userData ? auth.userData.daysWorked.this_month : 0;
            extraProps.workDays = auth.userMetrics && Object.values(auth.userMetrics)[1] && Object.values(auth.userMetrics).reduce((prev, metric) => prev || (!prev && metric.goals.work_days), 0);
        }

        alerts = _.groupBy(_.filter(alerts, alert => !alert.is_dismissed && (!alert.users_views || (auth.sessionData && alert.users_views.indexOf(auth.sessionData.user_id) === -1))), 'section');

        let pages;

        pages = {
            "my_account": {
                label: 'global.my_account',
                path: '/my_account',
                component: MyAccount,
                order: 2
            },
            "login": {
                label: 'login',
                path: '/login',
                component: Login,
                order: 3,
                showOnMobile: true,
                isAllowedInTv: true
            }
        };

        if (attached_center_id && user && !user.isPasswordExpired) {
            pages.performance = {
                label: 'global.performance',
                path: (is_manager || (employeeCapabilities && employeeCapabilities.indexOf('records') > -1)) ? '/performance' : '/performance/team-chart',
                component: (!is_manager && (!employeeCapabilities || employeeCapabilities.indexOf('records') === -1)) && PerformanceGraph,
                menu: 'main',
                icon: 'graph',
                order: Object.keys(pages).length + 1
            };
            if (is_manager || (employeeCapabilities && employeeCapabilities.indexOf('records') > -1)) {
                pages.performance.dropdown = is_manager ? ['PerformanceGraph', 'TimelineGraph'] : ['PerformanceGraph', 'records'];
                if (!center_is_parent && is_manager) {
                    pages.performance.dropdown.push('records', 'goals');
                }

                if (pages.performance.dropdown.indexOf('records') > -1) {
                    pages.records = {
                        label: 'global.records',
                        path: '/performance/records',
                        component: Records,
                        order: Object.keys(pages).length + 1
                    };
                }

                pages.PerformanceGraph = {
                    label: 'global.team_chart',
                    path: '/performance/team-chart',
                    component: PerformanceGraph,
                    order: Object.keys(pages).length + 1
                };
                pages.TimelineGraph = {
                    label: 'global.timeline_chart',
                    path: '/performance/timeline-chart',
                    component: TimelineGraph,
                    order: Object.keys(pages).length + 1
                };
            }
            if (is_manager) {
                if (checkPermissions(user.permissions, 'challenges', 'view') && (!center_is_parent || challenges_controlling_center === attached_center_id)) {
                    pages.challenges = {
                        label: 'global.challenges',
                        path: '/challenges',
                        menu: 'main',
                        icon: 'flag',
                        order: Object.keys(pages).length + 1,
                        isModuleDisabled: !challenges_enabled
                    };

                    if (challenges_enabled) {
                        let challengesDropDown = [];
                        pages.manageChallenges = {
                            label: 'global.manual_challenges',
                            path: '/challenges/manual-challenges',
                            component: ManageChallenges,
                            icon: 'flag',
                            order: Object.keys(pages).length + 1
                        };
                        challengesDropDown.push('manageChallenges');
                        pages.WorkPlanPage = {
                            label: 'global.motivation_plan',
                            path: '/challenges/motivation-planning',
                            component: WorkPlanPage,
                            order: Object.keys(pages).length + 1
                        };
                        challengesDropDown.push('WorkPlanPage');
                        if (checkPermissions(user.permissions, 'unit_settings', 'edit') && challenges_controlling_center === attached_center_id) {
                            pages.ChallengesSettingsPage = {
                                label: 'settings.settings',
                                path: '/challenges/settings',
                                component: ChallengesSettingsPage,
                                order: Object.keys(pages).length + 1
                            };
                            challengesDropDown.push('ChallengesSettingsPage');
                        }
                        pages.challenges.dropdown = challengesDropDown;
                    } else if (challenges_controlling_center === attached_center_id) {
                        pages.challenges.component = ModuleDisabledPage;
                    } else {
                        delete pages.challenges;
                    }
                }

                if (checkPermissions(user.permissions, 'store', 'view')) {
                    pages.ManagerStore = {
                        label: 'global.joystore',
                        path: '/manage-store',
                        component: !store_enabled ? ModuleDisabledPage : ManagerStore,
                        menu: 'main',
                        icon: 'cart',
                        alert: store_enabled && alerts['store'],
                        order: Object.keys(pages).length + 1,
                        isModuleDisabled: !store_enabled
                    };
                }

                const isCommissionEnabled = ([1, 2].indexOf(commissions_enabled) > -1 || (commissions_enabled === 3 && user.permissions.accessCustomersCenters));
                const isShowCommissionsMenu = checkPermissions(user.permissions, 'commissions', 'view') && commissions_controlling_center === Number(attached_center_id);

                if (isShowCommissionsMenu) {
                    const commissionsDropDown = [];
                    pages.commissions = {
                        label: 'settings.commissions',
                        path: '/commissions',
                        menu: 'main',
                        icon: <AccountBalanceIcon/>,
                        order: Object.keys(pages).length + 1
                    };
                    if (isCommissionEnabled) {
                        if (checkPermissions(user.permissions.commissions, 'users', 'view')) {
                            pages.monthlyManagement = {
                                label: 'commissions.monthly_management',
                                path: '/commissions/management',
                                component: CommissionManagement,
                                order: Object.keys(pages).length + 1
                            };
                            commissionsDropDown.push('monthlyManagement');
                        }
                        if (checkPermissions(user.permissions.commissions, 'settings', 'view')) {
                            pages.commissionsPlan = {
                                label: 'commissions.commissions_plan',
                                path: '/commissions/wizard',
                                component: CommissionWizard,
                                order: Object.keys(pages).length + 1
                            };
                            commissionsDropDown.push('commissionsPlan');
                        }
                        if (checkPermissions(user.permissions.commissions, 'settings', 'view')) {
                            pages.commissionsSettings = {
                                label: 'global.settings',
                                path: '/commissions/settings',
                                component: CommissionSettings,
                                order: Object.keys(pages).length + 1
                            };
                            commissionsDropDown.push('commissionsSettings');
                        }
                        pages.commissions.dropdown = commissionsDropDown;
                    } else {
                        pages.commissions.component = ModuleDisabledPage;
                    }

                }
                if (checkPermissions(user.permissions, 'settingsPage')) {
                    pages.settings = {
                        label: 'global.settings',
                        path: '/settings',
                        component: Settings,
                        menu: 'main',
                        icon: <SettingsIcon/>,
                        order: Object.keys(pages).length + 1,
                        dropdown: ['generalSettings', 'accessControlSettings', 'metricsSettings', 'shiftsSettings']
                    };
                    if (checkPermissions(user.permissions, 'unit_settings', 'view')) {
                        pages.generalSettings = {
                            label: 'settings.general',
                            path: '/settings/general',
                            component: Settings,
                            order: Object.keys(pages).length + 1
                        };
                    }
                    if (checkPermissions(user.permissions, 'accessControl', 'view')) {
                        pages.accessControlSettings = {
                            label: 'settings.access_control',
                            path: '/settings/access_control',
                            component: Settings,
                            order: Object.keys(pages).length + 1
                        };
                    }
                    if (checkPermissions(user.permissions, 'metrics', 'view')) {
                        pages.metricsSettings = {
                            label: 'settings.metrics',
                            path: '/settings/metrics',
                            component: Settings,
                            order: Object.keys(pages).length + 1
                        };
                    }
                    if (checkPermissions(user.permissions, 'shifts', 'view')) {
                        pages.shiftsSettings = {
                            label: 'settings.shifts',
                            path: '/settings/shifts',
                            component: Settings,
                            order: Object.keys(pages).length + 1
                        };
                    }
                    if (checkPermissions(user.permissions, 'challenges', 'view') && challenges_controlling_center === attached_center_id) {
                        pages.rules = {
                            label: 'settings.automatic_feedback',
                            path: '/settings/automatic_feedback',
                            component: !challenges_enabled ? ModuleDisabledPage : Settings,
                            order: Object.keys(pages).length + 1,
                            isModuleDisabled: !challenges_enabled
                        };
                        pages.settings.dropdown.push('rules');
                    }
                    if (checkPermissions(user.permissions, 'security_settings', 'view') && origin_center_id === attached_center_id) {
                        pages.security = {
                            label: 'settings.security',
                            path: '/settings/security',
                            component: Settings,
                            order: Object.keys(pages).length + 1
                        };
                        pages.settings.dropdown.splice(1, 0, 'security');
                    }
                }
                if (checkPermissions(user.permissions, 'tvs', 'view')) {
                    pages.tvSetupPage = {
                        label: 'global.tvs',
                        path: '/tvs',
                        component: TvSetupPage,
                        menu: 'main',
                        icon: 'tv',
                        isAllowedInTv: true,
                        order: Object.keys(pages).length + 1
                    };
                }

                if (!center_is_parent || directChildren.length) {
                    pages.WorkSchedule = {
                        label: 'global.work_schedule',
                        path: '/weekly-plan/work-schedule',
                        menu: 'main',
                        icon: 'calendar',
                        component: WorkSchedule,
                        alert: alerts['weekly_schedule'],
                        order: Object.keys(pages).length + 1
                    };
                }

                if (!center_is_parent && goals_origin !== 3) {
                    pages.goals = {
                        label: 'global.goals_management',
                        path: '/performance/manage-goals',
                        component: GoalsWidget,
                        order: Object.keys(pages).length + 1
                    };
                }
            }
            else {
                if (store_enabled) {
                    pages.store = {
                        label: 'global.joystore',
                        path: '/store',
                        component: Store,
                        menu: 'main',
                        icon: 'cart',
                        order: Object.keys(pages).length + 1,
                        showOnMobile: true
                    };
                }

                pages.WorkSchedule = {
                    label: 'global.work_schedule',
                    path: '/work-schedule',
                    component: WorkSchedule,
                    menu: 'main',
                    icon: 'calendar',
                    order: Object.keys(pages).length + 1
                };
            }
        } else if (user && user.isPasswordExpired) {
            pages.changePassword = {
                label: 'global.choose_new_password',
                path: '/change-password',
                component: ChangePassword,
                order: Object.keys(pages).length + 1
            };
        }

        if (!attached_center_id && user && is_manager) {
            if (checkPermissions(user.permissions, 'inner_reports')) {
                pages.reports = {
                    label: 'global.reports',
                    path: '/reports',
                    component: Reports,
                    menu: 'main',
                    icon: 'earth',
                    order: Object.keys(pages).length + 1
                };
            }
            if (checkPermissions(user.permissions, 'inner_tools')) {
                pages.inner_tools = {
                    label: 'global.tools',
                    path: '/tools',
                    component: Tools,
                    menu: 'main',
                    icon: <BuildIcon/>,
                    order: Object.keys(pages).length + 1
                };
            }
        }

        if (user && !user.isPasswordExpired) {
            pages.home = {
                label: 'global.home',
                path: '/',
                menu: 'main',
                component: Home,
                icon: 'app',
                order: Object.keys(pages).length + 1,
                showOnMobile: true
            };
        }

        let isLoggingIn = Boolean(auth && ((auth.loggingIn && auth.notificationApproval !== 'request') || (auth.sessionData && !auth.userData && !auth.accountData)));
        let reqStatus = apiRequestStatusFeedback && {
            prefix: apiRequestStatusFeedback.prefix,
            feedback: apiRequestStatusFeedback.status,
            additionalData: apiRequestStatusFeedback.additionalData,
            customMsg: apiRequestStatusFeedback.customMsg,
            retryFunc: apiRequestStatusFeedback.retryFunc
        };
        user = user || null;

        const childCentersMenu = is_manager && (attached_center_id || user.permissions.accessCustomersCenters) && Object.keys(_.get(auth, "sessionData.additionalData.centersDetails", {})).length > 1 ? getChildCentersMenu({
            child_centers: auth.sessionData.additionalData.child_centers,
            centersDetails: auth.sessionData.additionalData.centersDetails,
            allowedCenterIds: auth.sessionData.additionalData.allowedCenterIds || []
        }) : [];

        return {
            pages: Object.entries(pages).reduce((result, [pageKey, page]) => {
                if (login_to_ui !== 'tv' || page.isAllowedInTv) {
                    result[pageKey] = page;
                }
                return result;
            }, {}),
            user,
            first_day_of_month,
            time_zone_offset,
            months,
            center_shifts,
            center_is_parent,
            isLoggingIn,
            reqStatus,
            childCentersMenu,
            attached_center_id,
            attached_center: auth.sessionData && (auth.sessionData.additionalData.centersDetails[attached_center_id] || {}),
            notificationApproval,
            lang: getLocale(),
            employeeCapabilities,
            notification_medium_priority,
            login_to_ui,
            scriptId,
            disabledFeatures,
            ...extraProps
        };
    }
);