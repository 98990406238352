import {
    RECEIVED_TVS_DATA,
    RECEIVED_TV_SAVED_DATA,
    RECEIVED_DELETED_DATA,
    RECEIVED_UPLOADED_BG_IMG, RECEIVED_DELETED_BG_IMG, RECEIVED_TVS_SETTINGS, START_WATCH_TV, STOP_WATCH_TV
} from "../actions/tvs";

const _ = require('lodash');

const initialState = {
    tvs: [],
    backgrounds: [],
    watchingTvId: undefined,
    trigger_defs: [],
    tvs_settings: {}
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function tvs(state = initializeState(), action = {}) {
    switch (action.type) {
        case RECEIVED_TVS_DATA:
            return {
                ...state,
                tvs: action.payload.tvsData.tvs,
                backgrounds: _.keyBy(action.payload.tvsData.backgrounds, 'file_id'),
                trigger_defs: action.payload.tvsData.trigger_defs.map(trigger => trigger.key)
            };
        case RECEIVED_TVS_SETTINGS:
            return {
                ...state,
                tvs_settings: action.payload.tvsSettings
            };
        case START_WATCH_TV:
            return {
                ...state,
                watchingTvId: action.tvId
            };
        case STOP_WATCH_TV:
            return {
                ...state,
                watchingTvId: undefined
            };
        case RECEIVED_UPLOADED_BG_IMG:
            return {
                ...state,
                backgrounds: {
                    ...state.backgrounds,
                    [action.payload.imgData.file_id]: action.payload.imgData
                }
            };
        case RECEIVED_DELETED_BG_IMG:
            delete state.backgrounds[action.payload.file_id];
            return {
                ...state,
                backgrounds: {
                    ...state.backgrounds
                }
            };
        case RECEIVED_TV_SAVED_DATA:
            let tvIndex = state.tvs.findIndex(tv => {
                return tv.id === action.payload.tvSavedData.tvScreen.id;
            });

            if (tvIndex === -1) {
                state.tvs.push(action.payload.tvSavedData.tvScreen);
            } else {
                state.tvs[tvIndex] = {...state.tvs[tvIndex], ...action.payload.tvSavedData.tvScreen};
            }
            return {
                ...state,
                tvs: [...state.tvs]
            };
        case RECEIVED_DELETED_DATA:
            let deletedIndex = state.tvs.findIndex(tv => {
                return action.payload.tvDeletedData.id === tv.id;
            });
            return {
                ...state,
                tvs: [...state.tvs.slice(0, deletedIndex), ...state.tvs.slice(deletedIndex + 1)]
            };
        default:
            return state;
    }
}