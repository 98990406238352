import React from 'react';

export default () => <svg className={'clapIcon'} version="1.1" viewBox="0 0 1024 1024"
                          xmlns="http://www.w3.org/2000/svg">
    <g id="hand2">
        <path
            d="m263.55 281.99c22.616 16.348 37.432 42.849 37.661 72.767l0.836 29.15 247.41-251.96c17.407-17.407 20.085-45.415 4.81-64.715-9.639-12.173-23.693-18.258-37.751-18.262-12.313 0-24.623 4.674-33.964 14.011l-219 219.01z"/>
        <path
            d="m60.41 687.67 16.004-18.603 43.599-317.01c1.554-41.757 31.498-76.36 71.044-85.08l-1.271-44.489c0-26.156-21.403-47.555-47.559-47.555s-47.555 21.399-47.555 47.555l-45.663 332c-4e-3 0.036-0.022 0.072-0.043 0.093l-44.087 51.256c-6.885 8.005-6.429 19.963 1.045 27.423l54.487 54.404z"/>
        <path
            d="m605.98 141.32c19.766 4.656 37.489 15.803 50.427 32.147 8.623 10.895 14.302 23.259 17.17 36.125l25.678-25.675c17.407-17.411 20.085-45.415 4.807-64.718-9.635-12.173-23.693-18.258-37.747-18.258-12.313 0-24.623 4.67-33.967 14.008z"/>
    </g>
    <g id="clap_sound">
        <path
            d="m909.46 159.94c4.301 0 8.644-1.285 12.425-3.963l53.791-38.063c9.711-6.867 12.012-20.311 5.141-30.022-6.867-9.711-20.311-12.015-30.022-5.141l-53.791 38.063c-9.711 6.867-12.012 20.311-5.141 30.022 4.197 5.934 10.845 9.104 17.598 9.104z"/>
        <path
            d="m833.17 105.54c2.495 0.941 5.058 1.386 7.575 1.386 8.716 0 16.919-5.327 20.164-13.968l23.191-61.681c4.186-11.132-1.447-23.553-12.582-27.739-11.136-4.189-23.553 1.45-27.739 12.582l-23.191 61.681c-4.186 11.132 1.45 23.553 12.582 27.739z"/>
        <path
            d="m1002.5 199.68h-0.445l-65.878 0.553c-11.893 0.241-21.338 9.355-21.098 21.245 0.237 11.742 9.833 21.281 21.528 21.281h0.445l65.878-1.124c11.893-0.244 21.338-9.808 21.098-21.701-0.237-11.742-9.833-20.254-21.528-20.254z"/>
    </g>
    <path id="hand1"
          d="m854.25 633.8c20.003-20.003 18.592-53.636-4.247-71.715-8.551-6.77-18.815-10.012-29.099-10.012-12.941 0-25.922 5.126-35.619 14.823l-107.6 107.6c-4.329 4.329-10.009 6.498-15.688 6.498s-11.355-2.168-15.688-6.498l-0.28-0.284c-9.377-9.373-9.377-24.58 0-33.953l173.57-173.57c17.411-17.407 20.089-45.415 4.81-64.718-9.635-12.173-23.693-18.258-37.747-18.258-12.313 0-24.623 4.67-33.967 14.011l-175.22 175.22c-4.516 4.516-10.432 6.774-16.352 6.774s-11.839-2.258-16.355-6.774c-9.165-9.169-9.165-24.027 0-33.192l222.86-222.86c17.407-17.407 20.089-45.415 4.81-64.718-9.635-12.173-23.693-18.258-37.747-18.258-12.317 0-24.627 4.67-33.967 14.011l-223.92 223.91c-4.573 4.577-10.576 6.867-16.574 6.867s-12.001-2.29-16.574-6.867c-9.043-9.043-9.043-23.704 0-32.743l174.17-174.17c17.407-17.407 20.089-45.415 4.81-64.718-9.635-12.173-23.693-18.258-37.747-18.258-12.317 0-24.623 4.67-33.967 14.011l-252.84 252.84c-4.491 4.491-10.034 6.508-15.469 6.508-11.139 0-21.855-8.472-22.225-21.489l-2.244-78.349c0-26.156-21.399-47.559-47.555-47.559s-47.559 21.403-47.559 47.559l-45.66 332c-7e-3 0.039-0.022 0.072-0.047 0.097l-44.084 51.249c-6.889 8.005-6.433 19.97 1.045 27.427l250.11 249.71c3.909 3.899 9.064 5.891 14.248 5.891 4.308 0 8.634-1.375 12.274-4.171l56.199-43.157c2.409-1.849 5.191-3.094 8.153-3.744 97.497-21.252 208.48-106.48 314.66-212.65l124.32-124.32z"/>
</svg>

