import {callApi} from "../utils/apiUtils";

export const RECEIVED_FEATURES_SCORES = "RECEIVED_FEATURES_SCORES";

export function getFeaturesScores(callback) {
    return callApi('getFeaturesScores', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: RECEIVED_FEATURES_SCORES, payload: responseData});
        if (callback) {
            callback(responseData);
        }
    });
}