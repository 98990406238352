import {
    RECEIVED_INACTIVE_USERS_DATA,
    RECEIVED_ORG_USERS_LIST,
    RECEIVED_USERS_LIST, RECEIVED_USERS_SHIFT_STATUS
} from "../actions/users";
import {RECEIVED_REMOVED_UNIT, RECEIVED_UPDATED_UNIT_DATA} from "../actions/accessControl";
import {RESET_LIVE_DATA} from "../actions/auth";
import {Loadable} from "../utils/apiUtils";
import {GET_PROFILE_DATA, RECEIVE_PROFILE_METRICS, RECEIVE_PROFILE_ORDERS} from "../actions/managerStore";

const _ = require('lodash');

const initialState = {
    inactive_users_data: {},
    users: {},
    org_users: {},
    users_shift_status: {},
    profile_data: {
        user_id: 0,
        metrics: new Loadable(),
        lead_types: new Loadable(),
        orders: new Loadable()
    }
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function users(state = initializeState(), action = {}) {
    let users = state.users;
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_USERS_LIST:
            return {
                ...state,
                users: action.users
            };
        case RECEIVED_INACTIVE_USERS_DATA:
            return {
                ...state,
                inactive_users_data: {
                    ...state.inactive_users_data,
                    ...action.usersData
                }
            };
        case RECEIVED_ORG_USERS_LIST:
            return {
                ...state,
                org_users: action.org_users
            };
        case RECEIVED_USERS_SHIFT_STATUS:
            return {
                ...state,
                users_shift_status: action.users_shift_status
            };
        case RECEIVED_UPDATED_UNIT_DATA:
            action.payload.unitsData.users.forEach(user => {
                users[user.id].assigned_center_id = action.payload.unitsData.id;
            });
            action.payload.unitsData.availableUsers.forEach(user => {
                users[user.id].assigned_center_id = 0;
            });
            return {
                ...state,
                users: {...users}
            };
        case RECEIVED_REMOVED_UNIT:
            action.payload.removedUnit.users.forEach(user => {
                users[user.id].assigned_center_id = 0;
            });
            return {
                ...state,
                users: users
            };
        case GET_PROFILE_DATA:
            return {
                ...state,
                profile_data: {
                    user_id: action.userId,
                    metrics: new Loadable(),
                    lead_types: new Loadable(),
                    orders: new Loadable()
                }
            };
        case RECEIVE_PROFILE_METRICS:
            return {
                ...state,
                profile_data: {
                    ...state.profile_data,
                    metrics: new Loadable(action.metrics, false, true),
                    lead_types: new Loadable(action.lead_types, false, true)
                }
            };
        case RECEIVE_PROFILE_ORDERS:
            return {
                ...state,
                profile_data: {
                    ...state.profile_data,
                    orders: new Loadable(_.keyBy(action.orders, 'id'), false, true)
                }
            };
        default:
            return state;
    }
}
