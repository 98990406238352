import React, {PureComponent} from 'react'
import {connect} from "react-redux";
import {getUserImg, getStaticFileURL} from "erj-common/generalUtils";
import PropTypes from "prop-types";
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import PointsIcon from '../../components/icons/PointsIcon';
import './employee-profile.css';
import {shortNum} from "../../utils/generalUtils";
import MetricValueDisplay from "../misc/MetricValueDisplay";
import {UNIT_TYPE} from "../../utils/metrics";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {getLastPayout, getMetricsForUser, GET_PROFILE_DATA, getUserLastOrders} from "../../actions/managerStore";
import CircularProgress from "@material-ui/core/CircularProgress";


import {t} from 'react-i18nify'
import ErjScroll from "../../components/controls/ErjScroll";
import {makeGetEmployeeProfile} from "../../selectors/employeeProfileSelector";
import ErjMenu from "../../components/controls/ErjMenu";
import {makeGetActivityStatsSummary} from "../../selectors/activitiesSelector";
import CurrentTime from "../misc/CurrentTime";

const MetricRow = ({metricData, vsMode}) => {
    let currentProgressSelf = Math.min(Math.round(((metricData.myLead / metricData.myGoal || 0)) * 100), 100);
    let currentProgressOther = Math.min(Math.round(((metricData.userLead / metricData.userGoal || 0)) * 100), 100);
    return (
        <div className={'metric-row'}>
            <div className={'metric-title'}>
                <span>{metricData.title}</span>
            </div>
            <div className={'metric-data-container'}>
                <div className={'metric-data'}>
                    <div className={'leads'}>
                        <MetricValueDisplay value={metricData.userLead} unitType={metricData.unitType}
                                            roundOpts={{decimals: metricData.display_decimal_digits}}/>
                        {' / '}
                        <MetricValueDisplay value={metricData.userGoal} unitType={metricData.unitType}
                                            roundOpts={{decimals: metricData.display_decimal_digits}}/>
                    </div>
                    <div className={'progressBar'}>
                        <div className={'currentProgress'} style={{width: currentProgressOther + '%'}}>&nbsp;</div>
                        <Tooltip className={'percentage-tooltip'} title={metricData.userPercentage}>
                            <div className={'leadsIndicator'} style={{left: currentProgressOther + '%'}}>
                            </div>
                        </Tooltip>
                    </div>
                </div>

                {vsMode && (
                    <div className={'metric-data' + (vsMode ? ' you' : '')}>
                        <div className={'leads'}>
                            <MetricValueDisplay value={metricData.myLead} unitType={metricData.unitType}
                                                roundOpts={{decimals: metricData.display_decimal_digits}}/>
                            {' / '}
                            <MetricValueDisplay value={metricData.myGoal} unitType={metricData.unitType}
                                                roundOpts={{decimals: metricData.display_decimal_digits}}/>
                        </div>
                        <div className={'progressBar-container'}>
                            <div className={'progressBar'}>
                                <div className={'currentProgress'}
                                     style={{width: currentProgressSelf + '%'}}>&nbsp;</div>
                                <Tooltip className={'percentage-tooltip'} title={metricData.myPercentage}>
                                    <div className={'leadsIndicator'} style={{left: currentProgressSelf + '%'}}>
                                    </div>
                                </Tooltip>
                            </div>
                            {vsMode && <div className={'you'}>{t('global.you')}</div>}
                        </div>

                    </div>
                )}
            </div>

        </div>
    );
};

class EmployeeProfile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'performance',
            timeFilter: 'today'
        }
    }

    componentWillMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedUser.id !== this.props.selectedUser.id) {
            this.loadData(nextProps);
        }
    }

    loadData(props) {
        const {dispatch, isMetricsLoaded, selectedUser, is_manager} = props;
        if (!isMetricsLoaded) {
            dispatch({type: GET_PROFILE_DATA, userId: selectedUser.id});
            dispatch(getMetricsForUser(selectedUser.center_id)); //Fetch metrics from internal cache
        }
        if (is_manager) {
            dispatch(getUserLastOrders(selectedUser.id));
        }
    }

    handleTabChange = (tabGroup, activeTab) => {
        this.setState({activeTab});
    };

    render() {
        const {selectedUser, centerSelectedUser, points, metricRows, vsModeShow, isMetricsLoaded, is_manager, activityStatsSummary, feedbackCount, orders} = this.props;

        const {timeFilter, activeTab} = this.state;

        const timeFrames = ['today', 'yesterday', 'this_week', 'this_month'].map(value => ({
            key: t('global.' + value),
            value
        }));

        const activityStatsCenterId = selectedUser.assigned_center_id || selectedUser.center_id;
        const selectedUserActivityStats = activityStatsSummary[activityStatsCenterId] && (activityStatsSummary[activityStatsCenterId].byUser[selectedUser.id] || activityStatsSummary[activityStatsCenterId].byUser[centerSelectedUser.id]);

        return (
            <div className={'employee-profile-popup'}>
                <IconButton className={"icon- closeButton"} onClick={this.props.closeModal}>close</IconButton>
                <div className={'top-area'}>
                    <div className={'user-row'}>
                        <img alt={selectedUser.first_name + ' ' + selectedUser.last_name} className={"user-image"}
                             src={getUserImg(selectedUser.user_img)}/>
                        <div className={'details'}>
                            <div className={'user-name'}>{selectedUser.first_name + ' ' + selectedUser.last_name}</div>
                            {isMetricsLoaded && (
                                <div className={'user-points'}>
                                    <Tooltip title={t('global.points')}>
                                        <PointsIcon/>
                                    </Tooltip>
                                    <MetricValueDisplay value={points}/>
                                </div>
                            )}
                        </div>
                    </div>
                    {Boolean(is_manager) && (
                        <Tabs value={activeTab} onChange={this.handleTabChange} className={'tabs'}
                              classes={{indicator: "indicator"}} variant="fullWidth">
                            <Tab disableRipple={true} value="performance"
                                 label={<span className="tabLabel">{t('global.performance')}</span>}/>
                            <Tab disableRipple={true} value="insights"
                                 label={<span className="tabLabel">{t('global.insights')}</span>}/>
                        </Tabs>
                    )}
                </div>
                <ErjScroll autoHeight autoHeightMax={window.innerHeight - 278}>
                    {activeTab === 'performance' ? (
                        !isMetricsLoaded ? (
                            <CircularProgress className="pageLoadProgressCircle" size={100}/>
                        ) : (
                            <div className={'user-metrics'}>
                                <ErjMenu defaultValue={timeFrames.filter(item => item.value === timeFilter)[0].key}
                                         isChangeValue={true} menuItems={timeFrames}
                                         cbFunc={value => this.setState({timeFilter: value})}/>
                                {metricRows[timeFilter].map((metricRow, key) => (
                                    <MetricRow key={key} vsMode={vsModeShow} metricData={metricRow}/>
                                ))}
                            </div>
                        )
                    ) : (
                        <div className={'insightsTab'}>
                            <div className={'sectionTitle'}>{t('summary.this_month_stats')}</div>
                            <div className={'summary'}>
                                <div className={'summaryItem'}>
                                    <div className={'number'}>
                                        <MetricValueDisplay
                                            value={selectedUserActivityStats ? (selectedUserActivityStats.checked_in / selectedUserActivityStats.total) : 0}
                                            unitType={UNIT_TYPE.PERCENTAGE} roundOpts={{decimals: 0}}/>
                                    </div>
                                    <div className={'label'}>{t('summary.participation')}</div>
                                </div>
                                <div className={'summaryItem'}>
                                    <div className={'number'}>
                                        <MetricValueDisplay value={feedbackCount}/>
                                    </div>
                                    <div className={'label'}>{t('global.feedback')}</div>
                                </div>
                                <div className={'summaryItem'}>
                                    <div className={'number'}>
                                        <MetricValueDisplay
                                            value={selectedUserActivityStats && selectedUserActivityStats.checked_in ? (selectedUserActivityStats.success / selectedUserActivityStats.checked_in) : 0}
                                            unitType={UNIT_TYPE.PERCENTAGE} roundOpts={{decimals: 0}}/>
                                    </div>
                                    <div className={'label'}>{t('summary.challenges_success_rate')}</div>
                                </div>
                            </div>
                            {orders.isLoaded() ? (
                                Object.keys(orders).length > 0 && (
                                    <>
                                        <div className={'sectionTitle'}>{t('summary.last_orders')}</div>
                                        <div className={'orders'}>
                                            {Object.values(orders).map(order => (
                                                <div className={'order'}>
                                                    <div className={'product'}
                                                         style={{backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5) ), url(${getStaticFileURL(order.product_img)})`}}>
                                                        {order.product_title}
                                                    </div>
                                                    <div className={'orderTime'}>
                                                        <CurrentTime format={'timeAgo'}
                                                                     compareTime={order.transaction_time}/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )
                            ) : (
                                <CircularProgress className="pageLoadProgressCircle" size={100}/>
                            )}
                        </div>
                    )}
                </ErjScroll>
            </div>
        );
    }
}

const makeMapStateToProps = () => {
    const getEmployeeProfile = makeGetEmployeeProfile();
    const getParticipationRateSummary = makeGetActivityStatsSummary();
    return (state, props) => {
        const {activityStatsSummary} = getParticipationRateSummary(state, props);
        return {
            ...getEmployeeProfile(state, props),
            activityStatsSummary
        }
    };
};

export default connect(makeMapStateToProps)(EmployeeProfile);

