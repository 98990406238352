import React, {Suspense} from "react";
import {Route, Redirect} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar/index";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LoadingOverlay from "../misc/LoadingOverlay";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Debugger from "../../utils/debugger";
import ErrorBoundary from "./ErrorBoundary";
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from "@material-ui/core/Icon";
import {withNewLine} from "../../utils/generalUtils";
import FloatingAddRecordWidget from "../users/FloatingAddRecordWidget";
import ViewPort from "./Viewport";
import PageAlert from "./PageAlert";
import Slide from "@material-ui/core/Slide/";

import {t, Translate} from 'react-i18nify'

function TransitionLeft(props) {
    return <Slide {...props} direction="left"/>;
}

const ErjRoute = ({component: Component, page, appProps, handleLogout, handleChangeCenter, closeSnackBar, handleMouseMove, ...rest}) => {
    // console.log(appProps, rest);
    let pageClass = 'pageContainer page' + ((rest.location.pathname === process.env.REACT_APP_ROUTE_PATH + '/' || rest.location.pathname === process.env.REACT_APP_ROUTE_PATH) ? '_home' : rest.location.pathname.replace(process.env.REACT_APP_ROUTE_PATH, '').replace(/\//g, '_').toLowerCase())
        + (page.isModuleDisabled ? ' moduleDisabledPage' : '');
    return (
        <ViewPort>
            {({mobile}) => (
                <div className={pageClass + (mobile ? ' mob' : '')} onMouseMove={handleMouseMove}>
                    <LoadingOverlay isLoggingIn={appProps.isLoggingIn} handleStateChange/>
                    <ErrorBoundary>
                        <Header user={appProps.user} pages={appProps.pages} mobile={mobile}
                                attached_center={appProps.attached_center}
                                childCentersMenu={appProps.childCentersMenu}
                                attached_center_id={appProps.attached_center_id}
                                first_day_of_month={appProps.first_day_of_month} months={appProps.months}
                                center_is_parent={appProps.center_is_parent}
                                handleLogout={handleLogout} handleChangeCenter={handleChangeCenter}
                                workDays={appProps.workDays} login_to_ui={appProps.login_to_ui}
                                thisMonthDaysWorked={appProps.thisMonthDaysWorked}
                                center_shifts={appProps.center_shifts}/>
                    </ErrorBoundary>
                    <div className={'main'} role="main">
                        <div className={"appContent container"}>
                            {Boolean(page.alert) && (
                                <PageAlert alerts={page.alert}/>
                            )}
                            <ErrorBoundary>
                                <Suspense fallback={(
                                    <div className={'pageLoadProgressContainer'}>
                                        <CircularProgress className="pageLoadProgressCircle" size={100}/>
                                    </div>
                                )}>
                                    <Route {...rest}
                                           render={props => <Component {...props} key={props.location.key}/>}/>
                                </Suspense>
                            </ErrorBoundary>
                            {   appProps.employeeCapabilities 
                            && ( appProps.employeeCapabilities.indexOf('floatingRecordsWidget') > -1)
                            && ( appProps.employeeCapabilities.indexOf('blockWidgetRecords')==-1) 
                            && (
                                <FloatingAddRecordWidget/>
                            )}
                        </div>
                        <Footer/>
                </div>
                <Snackbar className={"snackbar " + (appProps.reqStatus.feedback ? appProps.reqStatus.feedback : '')}
                          anchorOrigin={{
                              vertical: appProps.reqStatus.feedback === 'info' ? 'top' : 'bottom',
                              horizontal: appProps.reqStatus.feedback === 'info' ? 'center' : 'left',
                          }}
                          TransitionComponent={appProps.reqStatus.feedback === 'info' ? TransitionLeft : Slide}
                          open={appProps.reqStatus.feedback && true}
                          autoHideDuration={60000}
                          onClose={closeSnackBar}
                          message={appProps.reqStatus.feedback && (
                              appProps.reqStatus.customMsg ? appProps.reqStatus.customMsg : (
                                  <div className={'localText'}>
                                      <Translate
                                          value={"apiStatus." + (appProps.reqStatus.prefix ? `${appProps.reqStatus.prefix}_${appProps.reqStatus.feedback}` : appProps.reqStatus.feedback)} {...appProps.reqStatus.additionalData}/>
                                  </div>
                              )
                          )}
                          ContentProps={{classes: {message: 'snackbarMessage', action: 'snackbarAction'}}}
                          action={[
                              <IconButton
                                  key="close"
                                  aria-label="Close"
                                  color="inherit"
                                  className={"closeBtn"}
                                  onClick={closeSnackBar}>
                                  <CloseIcon/>
                              </IconButton>,
                              appProps.reqStatus.retryFunc && (
                                  <Button key="retry" aria-label="Retry" color="inherit" className={"retryBtn"}
                                          onClick={(e) => closeSnackBar(e, 'retry', appProps.reqStatus.retryFunc)}>
                                      <Translate value={'global.retry'}/>
                                  </Button>
                              )
                          ]}
                />
                {['blocked', 'unsupported'].indexOf(appProps.notificationApproval) > -1 && appProps.user && (!appProps.notification_medium_priority || appProps.notification_medium_priority[0] === 'push') && (
                    <Snackbar className={"snackbar notificationsError"}
                              anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                              }}
                              open={true}
                              message={(
                                  <div className={'localText'}>
                                      <Icon className={'icon- alert'}>alert</Icon>
                                      {withNewLine(t('global.notifications_' + appProps.notificationApproval + '_msg'))}
                                  </div>
                              )}
                    />
                )}
                {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || window.location.hash === '#debug') && (
                    <Debugger/>
                )}
            </div>)}
        </ViewPort>

    );
}
export default ErjRoute;
