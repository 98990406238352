import {callApi} from "../utils/apiUtils";
import {RECEIVED_DATA_FOR_COMMISSION_SIM} from "./commissions";

export const RECEIVED_INACTIVE_USERS_DATA = "RECEIVED_INACTIVE_USERS_DATA";
export const RECEIVED_USERS_LIST = "RECEIVED_USERS_LIST";
export const RECEIVED_USERS_SHIFT_STATUS = "RECEIVED_USERS_SHIFT_STATUS";
export const RECEIVED_ORG_USERS_LIST = "RECEIVED_ORG_USERS_LIST";

export function receivedUsers(users) {
    return {type: RECEIVED_USERS_LIST, users};
}

export function receivedOrgUsers(org_users) {
    return {type: RECEIVED_ORG_USERS_LIST, org_users};
}

export function receivedUsersShiftStatus(users_shift_status) {
    return {type: RECEIVED_USERS_SHIFT_STATUS, users_shift_status};
}

export function getMissingInactiveUsersData(user_ids, callback) {
    return callApi('getMissingInactiveUsersData', {user_ids}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: RECEIVED_INACTIVE_USERS_DATA, usersData: responseData.usersData});

            if (callback)
                callback();
        }
    });
}