import React, {Component} from "react";
import {callApi} from "./apiUtils";
import {
    RECEIVED_FEEDS_LIST, SEND_CHAT_MSG_SUCCESS, SEND_CHAT_MSG_IN_PROGRESS
} from "../actions/feeds";
import {
    RECEIVED_ACTIVITIES_LIST,
    CHECK_IN_ACTIVITY_SUCCESS
} from "../actions/activities";
import {connect} from "react-redux";
import moment from 'moment-timezone';

class Debugger extends Component {
    constructor(props) {
        super(props);
        console.log('in Debugger',props);
        this.stopNewData = false;
        this.stopApiCalls = false;
        window.erj = this;
        window.moment = moment;
    }

    callApi(...args) {
        return callApi(...args)((...dArgs) => {
            console.log('dispatch!', ...dArgs)
        });
    }

    createPosts(posts) {
        let feeds = {
            private: {...this.props.feeds.private},
            public: {...this.props.feeds.public},
            activity: {...this.props.feeds.activity}
        };

        for(let feed_type of Object.keys(posts))
            feeds[feed_type] = {...feeds[feed_type], ...posts[feed_type]};

        this.props.dispatch({type: RECEIVED_FEEDS_LIST, feeds});
    }
    
    sendChats(chats) {
        this.props.dispatch({type: SEND_CHAT_MSG_IN_PROGRESS});
        for(let post_id of Object.keys(chats))
            setTimeout(()=> this.props.dispatch({type: SEND_CHAT_MSG_SUCCESS, post: chats[post_id]}), chats[post_id].delay)
    }

    createActivities(script_activities) {
        let activities = {...this.props.activities.by_ids, ...script_activities};
        this.props.dispatch({type: RECEIVED_ACTIVITIES_LIST, activities});
    }

    checkIn(activityId) {
        let activities = {...this.props.activities.by_ids};
        activities[ activityId ].checked_in = 1;
        this.props.dispatch({type: RECEIVED_ACTIVITIES_LIST, activities});
    }

    async runScript(name) {
        const {script} = await import("../debug_scripts/"+name+".js");
        console.log(script);
        if(script.activities)
            this.createActivities(script.activities);
        if(script.posts)
            this.createPosts(script.posts);
        if(script.chats)
            this.sendChats(script.chats);
    }

    render() {
        return <div className={'debug'} style={{display: 'none'}}>&nbsp;</div>;
    }
}

const mapStateToProps = state => {
    return {...state};
};

export default connect(mapStateToProps)(Debugger);