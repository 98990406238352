import React, {Component} from "react";
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';

import "./controls.css";
import Button from "@material-ui/core/Button";

class PopOverLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleOpenPopoverDialog = (e) => {
        e.preventDefault();
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        let {body, classNameSpace, className, placement, disablePortal = true, ...otherProps} = this.props;
        if (!classNameSpace) {
            classNameSpace = 'general';
        }

        return (
            <span
                className={'popOverLinkContainer ' + (className ? className : '') + (this.state.open ? ' open' : '')} {...otherProps}>
                <Button className={'popover-target'} buttonRef={node => this.anchorEl = node}>
                    <a className={'popOverLink ' + classNameSpace + 'PopOverLink'} href=""
                       onClick={this.handleOpenPopoverDialog} ref="popOverLink">{this.props.children}</a>
                </Button>
                <Popper open={this.state.open} anchorEl={this.anchorEl}
                        placement={placement ? placement : "bottom-start"} transition
                        className={'popOver ' + classNameSpace + 'PopOver'} disablePortal={disablePortal}>
                    {({TransitionProps, placement}) => (
                        <Grow {...TransitionProps}>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <Paper className={'popBody ' + classNameSpace + 'PopBody' + ' ' + placement}>
                                    {body}
                                </Paper>
                            </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>
            </span>
        );
    }
}

PopOverLink.propTypes = {
    body: PropTypes.isRequired,
    classNameSpace: PropTypes.string,
    className: PropTypes.string,
    placement: PropTypes.string,
    disablePortal: PropTypes.bool,
    label: PropTypes.string,
    maxHeight: PropTypes.number
};

export default PopOverLink;