import {callApi} from "../utils/apiUtils";
import {CHECK_IN_ACTIVITY_SUCCESS} from "./activities";
import {disabledButtons} from "./controls";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_ERROR, API_REQUEST_SAVING} from "./global";
import {loginSuccess} from "./auth";

export const RECEIVED_FEEDS_LIST = "RECEIVED_FEEDS_LIST";
export const RECEIVED_FEEDBACK_STATS = "RECEIVED_FEEDBACK_STATS";
export const LIKE_CHAT_MSG_SUCCESS = "LIKE_CHAT_MSG_SUCCESS";
export const SEND_CHAT_MSG_SUCCESS = "SEND_CHAT_MSG_SUCCESS";
export const DELETE_CHAT_MSG = "DELETE_CHAT_MSG";

export const SEND_CHAT_MSG_IN_PROGRESS = "SEND_CHAT_MSG_IN_PROGRESS";

export const SAVE_ANNOUNCEMENT_SUCCESS = "CREATE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";

export const LIKE_PUBLIC_POST_SUCCESS = "LIKE_PUBLIC_POST_SUCCESS";
export const SEND_COMMENT_MSG_SUCCESS = "SEND_COMMENT_MSG_SUCCESS";
export const SEND_COMMENT_MSG_IN_PROGRESS = "SEND_COMMENT_MSG_IN_PROGRESS";

export const REPORT_POSTS_VIEWED_SUCCESS = "REPORT_POSTS_VIEWED_SUCCESS";

export const FORCE_UPDATE_FEED = "FORCE_UPDATE_FEED";

export function receivedFeeds(feeds) {
    return {type: RECEIVED_FEEDS_LIST, feeds};
}

export function receivedFeedbackStats(feedback_stats) {
    return {type: RECEIVED_FEEDBACK_STATS, feedback_stats};
}

export function sendChatMessageToActivityHub(centerId, activityGroupId, chosenChannel, chatMsg) {
    return callApi('sendChatMessageToActivityHub', {centerId, activityGroupId, chosenChannel, chatMsg}, dispatch => {
        dispatch({type: SEND_CHAT_MSG_IN_PROGRESS});
    }, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: SEND_CHAT_MSG_SUCCESS, post: responseData.post});
        dispatch(disabledButtons('activityHubChatTextArea', false));
    });
}

export function deleteChatMessage(centerId,activityGroupId,chatMsgData){
    console.log(activityGroupId);
    const {msgId,activityId,chatTextChosenChannel,origin_object_id} = chatMsgData
    return callApi('deleteChatMessage',{msgId,centerId,activityGroupId},dispatch =>{
        } ,(dispatch, responseData) => {
            dispatch({type: DELETE_CHAT_MSG, msgId,activityId,chatTextChosenChannel,origin_object_id});
    });
}

export function getRichUrlData(url, maxWidth, callback) {
    return callApi('getRichUrlData', {url, maxWidth}, dispatch => {
    }, (dispatch, responseData) => {
        callback(responseData);
    });
}

export function saveAnnouncement(announcementData, callback) {
    return callApi('saveAnnouncement', announcementData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: SAVE_ANNOUNCEMENT_SUCCESS, post: responseData.post});
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        callback(responseData.status === "SUCCESS");
    });
}

export function deleteAnnouncement(postId, callback) {
    return callApi('deleteAnnouncement', {postId}, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: DELETE_ANNOUNCEMENT_SUCCESS, postId});
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        callback(responseData.status === "SUCCESS");
    });
}

export function likeChatMessage(chatPost, emoji, user_type, user_id) {
    const {origin_object_id, receiver_type, receiver_id, id} = chatPost;
    return callApi('likePost', {activityGroupId: origin_object_id, postId: id, emoji}, dispatch => {
    }, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({
            type: LIKE_CHAT_MSG_SUCCESS,
            post: {origin_object_id, receiver_type, receiver_id, id},
            like: {like_code: emoji, user_type, user_id: responseData.user_id}
        });
    });
}
export function sendCommentForPublicPost(postId,commentMsg) {
    return callApi('sendCommentForPublicPost',{postId, commentMsg}, dispatch => {
        dispatch({type: SEND_COMMENT_MSG_IN_PROGRESS });
    }, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: SEND_COMMENT_MSG_SUCCESS, post: responseData.post });
        dispatch(disabledButtons('publicFeedCommentsTextArea',false));
    });
}
export function likePublicPost(postId, user_type, user_id) {
    return callApi('likePost',{postId}, dispatch => {
    }, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: LIKE_PUBLIC_POST_SUCCESS, postId, user_type, user_id });
    });
}

export function reportPostsViewed(centerIds, postIds, activityFeedChannel) {
    return callApi('reportPostsViewed', {centerIds, postIds}, dispatch => {
    }, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: REPORT_POSTS_VIEWED_SUCCESS, postIds, activityFeedChannel});
    });
}