import _ from "lodash";
import {
    RECEIVED_STORE_ORDERS,
    RECEIVED_STORE_PRODUCTS,
    RECEIVED_STORE_WISH_LIST,
    RECEIVED_STORE_SHOPPING_CART, RECEIVED_STORE_TEAM_CASH,
    SHOPPING_CART_ADD_ITEM,
    SHOPPING_CART_REMOVE_ITEM,
    SHOPPING_CART_UPDATE_QUANTITY,
    UPDATE_PRODUCT_WISH,
    ORDER_SUCCEEDED, RECEIVED_TEAM_TRANSACTIONS
} from "../actions/store";
import {RESET_LIVE_DATA} from "../actions/auth";

const initialState = {
    shoppingCart: {cart: [], totalCartAmount: 0},
    products: {},
    wishList: [],
    orders: [],
    teamCash: 0
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function store(state = initializeState(), action = {}) {
    let clonedCart;
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_STORE_ORDERS:
            return {
                ...state,
                orders: action.payload.orders
            };
        case RECEIVED_STORE_TEAM_CASH:
            return {
                ...state,
                teamCash: action.payload.teamCash
            };
        case RECEIVED_STORE_PRODUCTS:
            return {
                ...state,
                products: action.payload.products
            };
        case RECEIVED_STORE_WISH_LIST:
            return {
                ...state,
                wishList: action.payload.wishList
            };
        case RECEIVED_STORE_SHOPPING_CART:
            let totalAmount = 0;
            action.payload.shoppingCart.cart.map(item => {
                totalAmount += item.price * item.quantityByUser;
            });
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    cart: action.payload.shoppingCart.cart,
                    totalCartAmount: totalAmount
                }
            };
        case UPDATE_PRODUCT_WISH:
            let wishList = [...state.wishList];
            let wishIndex = wishList.indexOf(action.payload.id);
            if (wishIndex > -1 && !action.payload.isWish)
                wishList.splice(wishIndex, 1);
            else if (wishIndex === -1 && action.payload.isWish)
                wishList.push(action.payload.id);
            return {
                ...state,
                wishList
            };
        case SHOPPING_CART_ADD_ITEM:
            clonedCart = _.cloneDeep(state.shoppingCart.cart);
            let productExist = false;
            let cartResult, totalCartAmount = 0;

            clonedCart.map((item) => {
                if (item.id === action.payload.product.id) {
                    item.quantityByUser += action.payload.orderQuantity;
                    productExist = true;
                }
                totalCartAmount += item.price * item.quantityByUser;
            });

            if (productExist) {
                cartResult = {
                    cart: clonedCart,
                    totalCartAmount: totalCartAmount
                }
            } else {
                cartResult = {
                    cart: [...clonedCart, Object.assign({}, action.payload.product, {
                        quantityByUser: action.payload.orderQuantity
                    })],
                    totalCartAmount: totalCartAmount + (action.payload.product.price * action.payload.orderQuantity)
                }
            }

            return {
                ...state,
                shoppingCart: {...cartResult, orderSucceeded: false}
            };
        case SHOPPING_CART_REMOVE_ITEM:
            let reduceTotalAmount = 0, arrIndex = 0;
            state.shoppingCart.cart.map((item, key) => {
                if (item.id === action.payload.productId) {
                    arrIndex = key;
                    reduceTotalAmount = item.price * item.quantityByUser;
                }
            });
            return {
                ...state,
                shoppingCart: {
                    cart: [...state.shoppingCart.cart.slice(0, arrIndex), ...state.shoppingCart.cart.slice(arrIndex + 1)],
                    totalCartAmount: state.shoppingCart.totalCartAmount -= reduceTotalAmount
                }
            };
        case SHOPPING_CART_UPDATE_QUANTITY:
            clonedCart = _.cloneDeep(state.shoppingCart.cart);
            let deltaForTotalPrice = 0;

            clonedCart.map(item => {
                if (item.id === action.payload.productId) {
                    deltaForTotalPrice = (item.price * action.payload.orderQuantity) - (item.price * item.quantityByUser);
                    item.quantityByUser = action.payload.orderQuantity;
                }
            });
            return {
                ...state,
                shoppingCart: {
                    cart: clonedCart,
                    totalCartAmount: state.shoppingCart.totalCartAmount + deltaForTotalPrice
                }
            };
        case ORDER_SUCCEEDED:
            return {
                ...state,
                shoppingCart: {...state.shoppingCart, cart: [], orderSucceeded: action.payload.orderSucceeded}
            };
        default:
            return state;
    }
}
