import React from 'react';

export default () => <svg className="teamWorkIcon" enableBackground="new 0 0 512 512" version="1.1"
                          viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g id="heads" fill="#00f">
        <path d="m106 0c-41.353 0-75 34.647-75 76s33.647 75 75 75 75-33.647 75-75-33.647-76-75-76z"/>
        <path d="m406 0c-41.353 0-75 34.647-75 76s33.647 75 75 75 75-33.647 75-75-33.647-76-75-76z"/>
        <path d="m256 271c-41.353 0-75 33.647-75 75s33.647 75 75 75 75-33.647 75-75-33.647-75-75-75z"/>
    </g>
    <g id="shirts" fill="#f00">
        <path
            d="m179.25 151c-18.939 18.499-44.753 30-73.251 30s-54.313-11.501-73.251-30c-19.532 19.08-32.749 45.608-32.749 75 0 8.291 6.709 15 15 15h181c8.291 0 15-6.709 15-15 0-29.392-12.217-55.92-31.749-75z"/>
        <path
            d="m479.25 151c-18.938 18.499-44.752 30-73.251 30s-54.313-11.501-73.251-30c-19.532 19.08-31.749 45.608-31.749 75 0 8.291 6.709 15 15 15h181c8.291 0 15-6.709 15-15 0-29.392-13.217-55.92-32.749-75z"/>
        <path
            d="m329.25 421c-18.938 18.499-44.752 30-73.251 30s-54.313-11.501-73.251-30c-19.532 19.08-31.749 46.608-31.749 76 0 8.291 6.709 15 15 15h180c8.291 0 15-6.709 15-15 0-29.392-12.217-56.92-31.749-76z"/>
    </g>
    <g id="connections" fill="#0f0">
        <path
            d="m301 76c0-2.975 0.635-5.773 0.879-8.687-30.108-7.09-61.65-7.09-91.758 0 0.244 2.914 0.879 5.713 0.879 8.687 0 7.687-0.941 15.135-2.518 22.355 30.851-9.053 64.184-9.053 95.035 0-1.574-7.22-2.517-14.67-2.517-22.355z"/>
        <path
            d="m420.07 271c-4.472 48.759-30.248 92.342-69.544 119.89-0.959 2.01-2.097 3.907-3.179 5.847l2.869 2.803c5.565 5.435 10.302 11.437 14.782 17.58 48.653-32.919 80.403-86.321 85.056-146.12z"/>
        <path
            d="m161.47 390.89c-39.295-27.548-65.071-71.131-69.544-119.89h-29.984c4.653 59.799 36.403 113.2 85.056 146.12 4.481-6.143 9.218-12.145 14.782-17.58l2.869-2.803c-1.082-1.939-2.219-3.836-3.179-5.847z"/>
    </g>
</svg>