import React, {Component, Suspense} from "react";
import PersonalSummaryWidget from "../widgets/PersonalSummaryWidget";
import ManagerSummaryWidget from "../widgets/ManagerSummaryWidget";
import MetricsWidget from "../widgets/MetricsWidget";
import Button from '@material-ui/core/Button';
import {connect} from "react-redux";
import YogaIcon from '../../components/icons/YogaIcon';
import "./home.css";
import ErrorBoundary from "../misc/ErrorBoundary";
import {withNewLine} from "../../utils/generalUtils";
import ViewPort from "../misc/Viewport";
import CircularProgress from "@material-ui/core/CircularProgress";
import {t} from 'react-i18nify'

const PrivateFeed = React.lazy(() => import("../feed/PrivateFeed"));
const ManagerFeed = React.lazy(() => import("../feed/ManagerFeed"));
const EmployeeHighlights = React.lazy(() => import("../feed/EmployeeHighlights"));

export const YogaTime = ({isManager, history}) => {
    return (
        <div className={'empty-feed'}>
            <div className={'main-title'}>
                <span>{t('feeds.you_have_cleaned_the_table')}</span>
            </div>
            <div className={'sub-title'}>
                {withNewLine(t('feeds.there_is_nothing_urgent_left_for_you_to_do_see_you_later'))}
            </div>
            <div>
                <YogaIcon/>
            </div>
            {isManager &&
            <Button className={'create-challenge-btn'} onClick={() => {
                return history.push(process.env.REACT_APP_ROUTE_PATH + "/manage-challenges/new_challenge");
            }}>
                {t('feeds.create_a_new_challenge')}
            </Button>}
        </div>
    )
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return Boolean(this.props.attached_center_id) && (
            <ViewPort>
                {({mobile}) => (
                    <div className="homepage">
                        <div className={`homeTopRow ${!mobile ? 'contentBox' : ''}`}>
                            <ErrorBoundary>
                                {this.props.is_manager ? (
                                    <ManagerSummaryWidget history={this.props.history}/>
                                ) : (
                                    <PersonalSummaryWidget/>
                                )}
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <MetricsWidget history={this.props.history}/>
                            </ErrorBoundary>
                        </div>
                        {Boolean(this.props.challenges_enabled) && (
                            <div className={'homeMainArea'}>
                                {this.props.is_manager ? (
                                    <ErrorBoundary>
                                        <Suspense
                                            fallback={<CircularProgress className="pageLoadProgressCircle"
                                                                        size={100}/>}>
                                            <ManagerFeed history={this.props.history}/>
                                        </Suspense>
                                    </ErrorBoundary>
                                ) : (
                                    <React.Fragment>
                                        <ErrorBoundary>
                                            <Suspense fallback={<CircularProgress className="pageLoadProgressCircle"
                                                                                  size={100}/>}>
                                                <PrivateFeed history={this.props.history} mobile={mobile}/>
                                            </Suspense>
                                        </ErrorBoundary>
                                        {!mobile && (
                                            <Suspense fallback={<CircularProgress className="pageLoadProgressCircle"
                                                                                  size={100}/>}>
                                                <div className={'sideArea'}>
                                                    <ErrorBoundary>
                                                        <EmployeeHighlights/>
                                                    </ErrorBoundary>
                                                </div>
                                            </Suspense>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </ViewPort>
        );
    }
}

function mapStateToProps(state) {
    const {controls, auth} = state;
    return {
        controls: controls,
        is_manager: auth.is_manager,
        attached_center_id: auth.sessionData && auth.sessionData.attached_center_id,
        challenges_enabled: auth.sessionData && auth.sessionData.challenges_enabled,
        center_is_parent: auth.sessionData && auth.sessionData.additionalData && Boolean(auth.sessionData.additionalData.center_is_parent)
    };
}

export default connect(mapStateToProps)(Home);