import {
    REQUEST_APPROVE_NOTIFICATIONS,
    REQUEST_APPROVE_NOTIFICATIONS_BLOCKED,
    REQUEST_APPROVE_NOTIFICATIONS_UNSUPPORTED
} from "../actions/auth";
import {callApi} from "./apiUtils";
import {reportDebug, reportError} from "../actions/global";

import firebase from "firebase/app";
import "firebase/messaging";

let messaging = null;
let registration = null;
const vapidKey = 'BOQAACfRLc62PJa1IocZ6VzCPFNG5qYRCgMsovrCEkZVL83IhzdwCtjGz5IxzRWM1XuupBlAu7eHfvdNoqCrlPI';

const isNotificationsSupported = ('serviceWorker' in navigator && 'PushManager' in window);

export async function initNotifications(store) {
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && window.location.hostname !== 'localhost')
        return true;
    if (!isNotificationsSupported) {
        return;
    }
    const config = {
        apiKey: "AIzaSyBPkkZPcJD_in31voyfTvuR7yv7SlW7UNo",
        authDomain: "enerjoyco-ca2fd.firebaseapp.com",
        databaseURL: "https://enerjoyco-ca2fd.firebaseio.com",
        projectId: "enerjoyco-ca2fd",
        storageBucket: "enerjoyco-ca2fd.appspot.com",
        messagingSenderId: "612966335466",
        appId: "1:612966335466:web:96e41988ccfcb0fc"
    };
    firebase.initializeApp(config);
    try {
        messaging = firebase.messaging();
    } catch (err) {
        messaging = err;
        return;
    }

    registration = await navigator.serviceWorker.register(process.env.REACT_APP_ROUTE_PATH + '/sw.js');
    if (registration.waiting) {
        console.log('updating service worker...');
        registration.waiting.postMessage({action: 'skipWaiting'});
    }
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let oldRegistration of registrations) {
            if (oldRegistration.scope !== registration.scope) {
                console.log('unregistered SW', oldRegistration);
                oldRegistration.unregister();
            }
        }
    });

    messaging.onTokenRefresh(async function () {
        let currentToken = await registerToNotifications(store.dispatch);
        console.info('token refreshed!:',currentToken);
    });

    window.isFocused = true;
    window.onfocus = () => window.isFocused = true;
    window.onblur = () => window.isFocused = false;


    messaging.onMessage(function(payload) {
        console.log('Message received. ', payload);
        let notification = JSON.parse(payload.data.notification);
        const notificationOptions = {
            body: notification.body,
            icon: notification.icon,
            data: payload.data
        };

        registration.showNotification(notification.title, notificationOptions);
        reportDebug({type: 'newForgroundNotification', isFocused: window.isFocused, payload});
    });
}

export async function registerToNotifications(dispatch, lang, numRetries = 0) {
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && window.location.hostname !== 'localhost')
        return true;
    if (!isNotificationsSupported) {
        dispatch({type: REQUEST_APPROVE_NOTIFICATIONS_UNSUPPORTED});
        return;
    }
    try {
        console.log('in registerToNotifications', messaging);
        if (!messaging) {
            if (numRetries < 5) {
                await (() => new Promise((resolve, reject) => setTimeout(() => resolve(), 1000)))();
                return registerToNotifications(dispatch, lang, numRetries + 1);
            } else {
                dispatch({type: REQUEST_APPROVE_NOTIFICATIONS_BLOCKED});
                let error = new Error('notifications blocked -  messaging not ready');
                error.info = {};
                reportError({level: 'info', error});
            }
        }
        if (messaging instanceof Error) {
            throw messaging;
        }
        console.log('getting currentToken');
        const isNotificationApproved = Notification.permission === 'granted';
        if (isNotificationApproved) {
            console.log('notifications approved, requesting token...');
            let isGotToken = false;
            setTimeout(() => {
                if (!isGotToken) {
                    console.error('getToken timeout');
                    dispatch({type: REQUEST_APPROVE_NOTIFICATIONS_BLOCKED});
                }
            }, 30000)
            messaging.getToken({serviceWorkerRegistration: registration, vapidKey}).then(currentToken => {
                isGotToken = true;
                console.log({currentToken})
                dispatch(callApi("updateNotificationToken", {token: currentToken, lang}));
            });
            return 'requesting token...';
        } else {
            dispatch({type: REQUEST_APPROVE_NOTIFICATIONS});
            try {
                let currentToken = await messaging.getToken({serviceWorkerRegistration: registration, vapidKey});
                console.log({currentToken})
                dispatch(callApi("updateNotificationToken", {token: currentToken, lang}));
                return currentToken;
            } catch (err) {
                dispatch({type: REQUEST_APPROVE_NOTIFICATIONS_BLOCKED});
                console.error(err);
                let error = new Error('notifications blocked');
                error.info = err.code;
                reportError({level: 'info', error});
            }

            // Show permission request.
            // console.log('No Instance ID token available. Request permission to generate one.');
            // Show permission UI.
            // updateUIForPushPermissionRequired();
            // setTokenSentToServer(false);
        }
    } catch(err) {
        console.error('An error occurred while retrieving token. ', err);
        if( err.code==='messaging/token-update-failed' ) //try again
            return registerToNotifications(dispatch);
        reportError({level: 'info', error: err});
        dispatch({type: REQUEST_APPROVE_NOTIFICATIONS_BLOCKED});
        // showToken('Error retrieving Instance ID token. ', err);
        // setTokenSentToServer(false);
    }
    return false;
}

async function requestPermission(){
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && window.location.hostname !== 'localhost')
        return true;
    try{
        let permissionResult = await messaging.requestPermission();
        return true;
    } catch(err) {
        console.log('permission denied',err);
        return err.code;
    }
}