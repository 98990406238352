import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DatePicker, {CalendarContainer} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './controls.css';

import moment from 'moment';
import {t} from 'react-i18nify'

class ErjDateRangePicker extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            selection: this.props.value || []
        };
    }

    closeCalendar = () => {
        if (!this.state.selection[1]) {
            this.setState({
                selection: [],
            });
        }
    };

    handleSelect = (data) => {
        let selection = this.state.selection;
        if (selection[1]) {
            selection = [data.date];
        } else if (selection[0]) {
            if (data.date.diff(selection[0]) < 0) {
                selection[0] = data.date;
            } else {
                selection.push(data.date);
            }
        } else {
            selection = [data.date];
        }
        if (selection.length === 2) {
            this.setState({value: selection});
            this.props.onChange({name: this.props.name, value: selection});
        } else {
            this.setState({selection});
        }

    };

    selectedDateFormat = (selectedDateRange) => {
        return `${selectedDateRange[0].format('ll')} - ${selectedDateRange[1].format('ll')}`;
    };

    handleReset = () => {
        this.setState({value: this.props.value, selection: []});
        this.props.onChange({name: this.props.name, value: []});
        this.ref.setOpen(false);
    };

    menuItems = ['today', 'yesterday', 'this_week', 'this_month'];

    changeTimeFrame = timeFrame => {
        let selection = [];
        switch (timeFrame) {
            case 'today':
                selection[0] = moment().startOf('day');
                selection[1] = selection[0];
                break;
            case 'yesterday':
                selection[0] = moment().subtract(1, 'day').startOf('day');
                selection[1] = selection[0];
                break;
            case 'this_week':
                selection[0] = moment().day(0).startOf('day');
                selection[1] = moment().day(6).startOf('day');
                break;
            case 'this_month':
                selection[0] = this.props.monthStartDate;
                selection[1] = this.props.monthEndDate;
                break;
        }
        this.setState({selection, value: selection});
        this.ref.setOpen(false);
        this.props.onChange({name: this.props.name, value: selection});
    };

    getSelectedTimeFrame = () => {
        const {value} = this.state;
        if (value[0].isSame(moment().startOf('day')) && value[0].isSame(value[1])) {
            return 'today';
        }
        if (value[0].isSame(moment().subtract(1, 'day').startOf('day')) && value[0].isSame(value[1])) {
            return 'yesterday';
        }
        if (value[0].isSame(moment().day(0).startOf('day')) && value[1].isSame(moment().day(6).startOf('day'))) {
            return 'this_week';
        }
        if (value[0].isSame(this.props.monthStartDate) && value[1].isSame(this.props.monthEndDate)) {
            return 'this_month';
        }
        return 'custom';
    };

    render() {
        const {calendarClassName, className, maxDate, maxDuration, useWeekdaysShort, customInput, isShowMenu, isShowResetButton, popperPlacement, additionalProps} = this.props;
        const {selection, value} = this.state;

        let props = {
            shouldCloseOnSelect: Boolean(selection[0] && !selection[1]),
            calendarClassName: calendarClassName || "erj-calendar",
            className: className || "date-picker date-selector localText",
            selectsEnd: !selection[1],
            onClickOutside: () => {
                this.closeCalendar();
            },
            onSelect: date => {
                this.handleSelect({date: date});
            },
            maxDate: maxDate || (maxDuration && selection[0]) ? moment(selection[0]).add(maxDuration[0], maxDuration[1]).subtract(1, 'day') : moment(),
            startDate: selection[0],
            endDate: selection[1],
            useWeekdaysShort: useWeekdaysShort !== undefined ? useWeekdaysShort : true,
            customInput: customInput ? customInput(value) : (
                <button>
                    {isShowMenu && (
                        <span className={'selectedMenuItem'}>{t('global.' + this.getSelectedTimeFrame())}</span>
                    )}
                    <span className={'selectedDate localText'}>
                        {this.selectedDateFormat(value)}
                    </span>
                </button>
            ),
            ref: ref => this.ref = ref,
            calendarContainer: ({className, children, ...otherProps}) => {
                console.log('calendarContainerProps', otherProps);
                const selectedTimeFrame = this.getSelectedTimeFrame();
                return (
                    <CalendarContainer className={className + (isShowMenu ? ' hasMenu' : '')}>
                        {isShowMenu && (
                            <div className={'menu'}>
                                {this.menuItems.map(timeFrame => (
                                    <button
                                        className={'timeFrame' + (selectedTimeFrame === timeFrame ? ' selected' : '')}
                                        onClick={() => this.changeTimeFrame(timeFrame)}>{t('global.' + timeFrame)}</button>
                                ))}
                            </div>
                        )}
                        <div className={'erj-calendar-container'}>
                            {children}
                        </div>
                    </CalendarContainer>
                );
            },
            popperPlacement,
            ...additionalProps
        };
        if (selection.length === 1)
            props.minDate = selection[0];

        return (
            <DatePicker {...props}>
                <div className={'bottomButtons'}>
                    {isShowResetButton && value && (
                        <button className={'btn'} onClick={this.handleReset}>
                            {t('global.reset')}
                        </button>
                    )}
                </div>
            </DatePicker>
        );
    }
}

ErjDateRangePicker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
    onChange: PropTypes.func.isRequired,
    calendarClassName: PropTypes.string,
    className: PropTypes.string,
    maxDate: PropTypes.instanceOf(moment),
    maxDuration: PropTypes.shape([PropTypes.number.isRequired, PropTypes.string.isRequired]),
    useWeekdaysShort: PropTypes.bool,
    isShowResetButton: PropTypes.bool,
    isShowMenu: PropTypes.bool,
    customInput: PropTypes.func,
    monthStartDate: PropTypes.instanceOf(moment),
    monthEndDate: PropTypes.instanceOf(moment)
};

export default ErjDateRangePicker;