import {base64ToFile, callApi} from "../utils/apiUtils";
import {disabledButtons} from "./controls";
import {API_REQUEST_SAVING, API_REQ_SAVE_SUCCESS, API_REQUEST_ERROR} from './global';

export const RECEIVED_ACCESS_CONTROL_DATA = "RECEIVED_ACCESS_CONTROL_DATA";
export const RECEIVED_DELETED_PERMISSION = "RECEIVED_DELETED_PERMISSION";
export const RECEIVED_CREATE_OR_UPDATE_ROLE = "RECEIVED_CREATE_OR_UPDATE_ROLE";
export const RECEIVED_CREATE_OR_UPDATE_MANAGER = "RECEIVED_CREATE_OR_UPDATE_MANAGER";
export const RECEIVED_DELETED_MANAGER = "RECEIVED_DELETED_MANAGER";
export const RECEIVED_USERS_DATA = "RECEIVED_USERS_DATA";
export const RECEIVED_UPDATED_USER_CHALLENGES_ENABLED_STATUS = "RECEIVED_UPDATED_USER_CHALLENGES_ENABLED_STATUS";
export const RECEIVED_UPDATED_USER_DATA = "RECEIVED_UPDATED_USER_DATA";
export const RECEIVED_UNITS_DATA = "RECEIVED_UNITS_DATA";
export const RECEIVED_UPDATED_UNIT_DATA = "RECEIVED_UPDATED_UNIT_DATA";
export const RECEIVED_REMOVED_UNIT = "RECEIVED_REMOVED_UNIT";
export const RECEIVED_BLOCKED_LOGINS = "RECEIVED_BLOCKED_LOGINS";

function receivedAccessControlData(accessControlData) {
    return {type: RECEIVED_ACCESS_CONTROL_DATA, payload: {accessControlData}};
}

function receivedDeletedPermission(deletedPerId) {
    return {type: RECEIVED_DELETED_PERMISSION, payload: {id: deletedPerId.id}};
}

function receivedCreateOrUpdateRole(accessControlData) {
    return {type: RECEIVED_CREATE_OR_UPDATE_ROLE, payload: accessControlData};
}

function receivedCreateOrUpdateManager(managerData) {
    return {type: RECEIVED_CREATE_OR_UPDATE_MANAGER, payload: managerData};
}

function receivedDeletedManager(deletedManagerId) {
    return {type: RECEIVED_DELETED_MANAGER, payload: {id: deletedManagerId.id}};
}

function receivedUsersData(usersData) {
    return {type: RECEIVED_USERS_DATA, payload: {usersData}};
}

function receivedUpdatedUserChallengesEnabledStatus(challengesStatus) {
    return {type: RECEIVED_UPDATED_USER_CHALLENGES_ENABLED_STATUS, payload: {challengesStatus}};
}

function receivedUpdatedUserData(userData) {
    return {type: RECEIVED_UPDATED_USER_DATA, payload: {userData}};
}

function receivedUnitsData(unitsData) {
    return {type: RECEIVED_UNITS_DATA, payload: {unitsData}};
}

function receivedUpdatedUnitData(unitsData) {
    return {type: RECEIVED_UPDATED_UNIT_DATA, payload: {unitsData}};
}

function receivedRemovedUnit(removedUnit) {
    return {type: RECEIVED_REMOVED_UNIT, payload: {removedUnit}};
}

export function getAccessControlData() {
    return callApi('getAccessControlData', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedAccessControlData(responseData.data));
        }
    });
}

export function createOrUpdateRole(data, buttonName) {
    let btnName = buttonName;
    return callApi('createOrUpdateRole', data, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedCreateOrUpdateRole(responseData.data));
        }
        dispatch(disabledButtons(btnName, false));
    });
}

export function deletePermission(id, buttonName) {
    let btnName = buttonName;
    return callApi('deletePermission', id, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedDeletedPermission(responseData.data));
        }
        dispatch(disabledButtons(btnName, false));
    });
}

export function createOrUpdateManager(data, callback) {
    if (data.userDetails.imageDataUrl) {
        data.userDetails.imageDataUrl = base64ToFile(data.userDetails.imageDataUrl);
    }
    return callApi('createOrUpdateManager', data, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
            dispatch(receivedCreateOrUpdateManager(responseData.data));
        } else if (responseData.status === "ERR_USER_NAME_EXIST") {
            dispatch({type: API_REQUEST_ERROR, prefix: "usernameExist"});
        } else if (responseData.status === "ERR_REPEATED_PASSWORD") {
            dispatch({type: API_REQUEST_ERROR, prefix: "reusedPassword"});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        if (callback)
            callback(responseData);
    });
}

export function deleteManager(id, buttonName) {
    let btnName = buttonName;
    return callApi('deleteManager', id, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
            dispatch(receivedDeletedManager(responseData.data));
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        dispatch(disabledButtons(btnName, false));
    });
}

export function getUsersData() {
    return callApi('getUsersData', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedUsersData(responseData.users));
        }
    });
}

export function setUserChallengesEnabledStatus(challengesStatus, callback) {
    return callApi('setUserChallengesEnabledStatus', challengesStatus, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
            callback(responseData);
            dispatch(receivedUpdatedUserChallengesEnabledStatus(responseData.challengesStatus));
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
    });
}

export function resetUserTwoStepAuthCode(userId) {
    return callApi('resetUserTwoStepAuthCode', {userId}, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
    });
}

export function resetManagerTwoStepAuthCode(userId) {
    return callApi('resetManagerTwoStepAuthCode', {userId}, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
    });
}

export function createOrUpdateUser(userData, callback) {
    if (userData.imageDataUrl) {
        userData.imageDataUrl = base64ToFile(userData.imageDataUrl);
    }
    return callApi('createOrUpdateUser', userData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedUpdatedUserData(responseData.updatedUser));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else if (responseData.status === "ERR_USER_NAME_EXIST") {
            dispatch({type: API_REQUEST_ERROR, prefix: "usernameExist"});
        } else if (responseData.status === "ERR_IN_CRM_ID_EXIST") {
            dispatch({
                type: API_REQUEST_ERROR,
                prefix: "userCrmIdExist",
                additionalData: {full_name: responseData.data.full_name}
            });
        } else if (responseData.status === "ERR_IN_DIALER_ID_EXIST") {
            dispatch({
                type: API_REQUEST_ERROR,
                prefix: "userInDialerIdExist",
                additionalData: {full_name: responseData.data.full_name}
            });
        } else if (responseData.status === "ERR_REPEATED_PASSWORD") {
            dispatch({type: API_REQUEST_ERROR, prefix: "reusedPassword"});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        if (callback)
            callback(responseData);
    });
}

export function getUnitsData() {
    return callApi('getUnitsData', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedUnitsData(responseData.units));
        }
    });
}

export function createOrUpdateUnit(unitData, callback) {
    return callApi('createOrUpdateUnit', unitData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedUpdatedUnitData(responseData.updatedUnit));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        if (callback)
            callback(responseData);
    });
}

export function removeUnit(unitId) {
    return callApi('removeUnit', unitId, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedRemovedUnit(responseData.removedUnit));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
    });
}

export function getBlockedLogins(callback) {
    return callApi('getBlockedLogins', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: RECEIVED_BLOCKED_LOGINS, payload: {blocked_list: responseData.blocked_list}});
            if (callback) {
                callback();
            }
        }
    });
}

export function removeBlockedLogin(keyToRemove, callback) {
    return callApi('removeBlockedLogin', {keyToRemove}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            if (callback) {
                callback();
            }
        }
    });
}