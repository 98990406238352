import moment from 'moment';
import {COIN_TO_MONEY_RATIO} from "erj-common/generalUtils";

const _ = require('lodash');

export const ORIGIN_TYPE = {
    POINTS: 0,
    ACTION: 1,
    MONEY: 2,
    DE_TIME: 3,
    NUM_OF_DES: 4,
    RATIO: 5,
    AVERAGE: 6,
    WEIGHED: 7,
    TIME: 8,
    SCORE: 9
};

export const UNIT_TYPE = {
    NUMBER: 1,
    MONEY: 2,
    SECONDS: 3,
    PERCENTAGE: 4
};

export const MEASURE_METHOD = {
    ASCENDING: 1,
    DESCENDING: 2,
    ON_TARGET: 3
};

export const GOAL_TIMEFRAME = {
    MONTHLY: 1,
    DAILY: 2,
    HOURLY: 3
};

export const PERFORMANCE_DISPLAY_METHOD = {
    REGULAR: 1,
    PERCENT_OF_GOAL: 2
};

export const GOAL_RELATION_DISPLAY = {
    PERCENT_OF_GOAL: 1,
    PERCENT_OF_IDEAL_PACE: 2,
    DIFF_FROM_GOAL: 3
};

export const IsUserWorkedThen = (daysWorked, timeFilter, showBasedOnLeads) => {
    if (showBasedOnLeads === 2)
        return true;

    return daysWorked && Boolean(daysWorked[timeFilter]);
};

export const metricSort = (a, b) => {
    if (!b.id || !a.id)
        return b.id - a.id; //make Enerpoints last
    const byOrder = (!a.order || !b.order) ? b.order - a.order : a.order - b.order;
    return byOrder || (b.is_primary - a.is_primary) || ((b.origin_type === ORIGIN_TYPE.WEIGHED) - (a.origin_type === ORIGIN_TYPE.WEIGHED)) || (b.id - a.id);
};
const coinToMoneyRatio = COIN_TO_MONEY_RATIO;
export const calculateEnercoins = function (userRows, monthlyBudget, coinsPartitionMethod, multiplyPointsByMetricStats) { // coinsPartitionMethod 1: by rank, 2: by proportion
    let totalEnercoins = (monthlyBudget * coinToMoneyRatio);
    let validUsers = [];
    let totalValidUsersAmount;
    let scalar = 0.05;
    let totalWeight = 0;
    let sumPointsForProportion = 0;

    let userRowsCloned = _.cloneDeep(userRows);

    for (let userRow of userRowsCloned) {
        userRow.pointsForCalc = multiplyPointsByMetricStats ? userRow.points * multiplyPointsByMetricStats[userRow.user_id] : userRow.points;
        if (userRow.pointsForCalc !== 0) {
            validUsers.push(userRow);
            sumPointsForProportion += userRow.pointsForCalc;
        }
    }

    // by rank method
    if (coinsPartitionMethod === 1) {
        totalValidUsersAmount = validUsers.length;
        validUsers.sort((a, b) => {
            if (a.pointsForCalc < b.pointsForCalc)
                return 1;
            if (a.pointsForCalc > b.pointsForCalc)
                return -1;
            return 0;
        });

        // calculation total weight of all users, and calculating personal weight
        for (let i in validUsers) {
            let currentUserPosition = totalValidUsersAmount - (+i + 1);
            validUsers[i].weight = (currentUserPosition + 1) + (Math.pow(currentUserPosition, 1 + (scalar * currentUserPosition)));
            totalWeight += validUsers[i].weight;
        }

        // creation of 2d array for calculating avg coins for same point amount users
        let mapByPoints = {};
        for (let i in validUsers) {
            validUsers[i].payout = Math.floor(totalEnercoins * (validUsers[i].weight / totalWeight));

            let userObj = {key: validUsers[i].pointsForCalc, value: validUsers[i]};
            if (userObj.key in mapByPoints) {
                mapByPoints[userObj.key].push(userObj.value);
                mapByPoints[userObj.key].totalAmount += userObj.value.payout;
            } else {
                mapByPoints[userObj.key] = [userObj.value];
                mapByPoints[userObj.key].totalAmount = userObj.value.payout;
            }
        }

        //splitting the avg amount of coins to users with same amount of points
        for (let i in mapByPoints) {
            if (mapByPoints[i].length > 1) {
                let avg = Math.floor(mapByPoints[i].totalAmount / mapByPoints[i].length);
                for (let j in mapByPoints[i]) {
                    if (Number(j) === Number(j)) {
                        mapByPoints[i][j].payout = avg;
                        totalEnercoins -= mapByPoints[i][j].payout;
                    }
                }
            } else {
                totalEnercoins -= mapByPoints[i][0].payout;
            }
        }
    } else {
        for (let user of validUsers) {
            let percentage = user.pointsForCalc / sumPointsForProportion;
            user.payout = Math.floor(totalEnercoins * (percentage));
            user.proportionRatio = percentage;
        }
    }

    return {users: validUsers, change: totalEnercoins};
};