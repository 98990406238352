import React, {Component} from 'react';
import {IconButton} from "@material-ui/core";
import {ReactComponent as AnnouncementIcon} from "../../assets/icons/announcement.svg";
import {ReactComponent as CloseIcon} from "../../assets/icons/X.svg";
import {connect} from "react-redux";
import {t} from 'react-i18nify'
import Icon from "@material-ui/core/Icon";
import Announcement from "./Announcement";
import {getAnnouncements} from "../../selectors/feedSelector";
import {reportPostsViewed} from "../../actions/feeds";
import ErrorBoundary from "../misc/ErrorBoundary";
import ViewPort from "../misc/Viewport";

class MessagesBar extends Component {
    state = {
        activeTab: '',
        isAnnouncementFormOpen: false
    }

    componentWillUnmount() {
        window.document.querySelector('body').style.overflowY = 'scroll';
    }

    toggleAnnouncementForm = () => {
        this.setState({isAnnouncementFormOpen: !this.state.isAnnouncementFormOpen})
    }

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        window.document.querySelector('body').style.overflowY = !activeTab ? 'hidden' : 'scroll';
        this.setState({activeTab: activeTab === tab ? '' : tab});
        if (activeTab !== 'announcements' && tab === 'announcements') {
            const unreadAnnouncements = this.props.announcements.filter(announcement => !announcement.viewed);
            if (unreadAnnouncements.length) {
                this.props.dispatch(
                    reportPostsViewed(Array.from(new Set(unreadAnnouncements.map(post => post.center_id))), unreadAnnouncements.map(post => post.id))
                );
            }
        }
    }

    render() {
        const {activeTab, isAnnouncementFormOpen} = this.state;
        const {is_manager, announcements, managers, num_unread, allowedCenterIds} = this.props;
        let messages = [];
        return (
            <ViewPort>
                {({mobile}) => !mobile && (
                    <div className={'message-bar' + (activeTab ? ' active' : '')}>
                        <div className={'barIcons'}>
                            <IconButton
                                className={'barIcon announcementIcon' + (activeTab === 'announcements' ? ' active' : '')}
                                onClick={() => this.toggleTab('announcements')}>
                                {activeTab === 'announcements' ? <CloseIcon className={'closeIcon'}/> :
                                    <AnnouncementIcon/>}
                                {num_unread.announcements > 0 && (
                                    <div className={'numUnread'}>{num_unread.announcements}</div>
                                )}
                            </IconButton>
                            {/*<IconButton
                        className={'barIcon notificationIcon' + (activeTab === 'notifications' ? ' active' : '')}
                        onClick={() => this.setState({activeTab: activeTab === 'notifications' ? '' : 'notifications'})}>
                        {activeTab === 'notifications' ? <CloseIcon className={'closeIcon'}/> : <NotificationIcon/>}
                    </IconButton>*/}
                        </div>
                        <div className={'messages'}>
                            {activeTab === 'announcements' && [
                                Boolean(is_manager) && !isAnnouncementFormOpen && (
                                    <button onClick={this.toggleAnnouncementForm} className={'create-new-item'}
                                            key={'create-new-item'}>
                                        <Icon className={"icon- plus_in_circle"}>plus_in_circle</Icon>
                                        {t('feeds.add_new_announcement')}
                                    </button>
                                ),
                                isAnnouncementFormOpen && (
                                    <Announcement key={'newAnnouncementForm'} editMode={true}
                                                  closeForm={this.toggleAnnouncementForm} is_manager={is_manager}
                                                  allowedCenterIds={allowedCenterIds}/>
                                ),
                                announcements.length > 0 ? (
                                    announcements.map(announcement => (
                                        <ErrorBoundary>
                                            <Announcement key={announcement.id} announcement={announcement}
                                                          managers={managers}
                                                          is_manager={is_manager} allowedCenterIds={allowedCenterIds}/>
                                        </ErrorBoundary>
                                    ))
                                ) : (
                                    <div className={'noNewAnnouncements'}>
                                        <AnnouncementIcon/>
                                        <div
                                            className={'noNewAnnouncementsText'}>{t('feeds.no_announcements_text')}</div>
                                        {Boolean(is_manager) && (
                                            <div
                                                className={'announcementsExplanationText'}>{t('feeds.announcements_explanation_text')}</div>
                                        )}
                                    </div>
                                )
                            ]}
                        </div>
                    </div>
                )}
            </ViewPort>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...getAnnouncements(state, props)
    };
}

export default connect(mapStateToProps)(MessagesBar);