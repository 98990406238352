import {base64ToFile, callApi} from "../utils/apiUtils";
import {disabledButtons} from "./controls";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_SAVING} from "./global";
import {loginSuccess} from "./auth";
import {RECEIVED_TEAM_TRANSACTIONS} from "./store";

export const RECEIVED_MANAGER_SHOP_SUMMARY = "RECEIVED_MANAGER_SHOP_SUMMARY";
export const RECEIVED_MANAGER_SHOP_PRODUCTS = "RECEIVED_MANAGER_SHOP_PRODUCTS";
export const RECEIVED_PRODUCT_PUBLISH_STATUS = "RECEIVED_PRODUCT_PUBLISH_STATUS";
export const RECEIVED_UPDATED_PRODUCT_DETAILS = "RECEIVED_UPDATED_PRODUCT_DETAILS";
export const RECEIVED_DELETED_PRODUCT_DETAILS = "RECEIVED_DELETED_PRODUCT_DETAILS";
export const RECEIVED_MANAGER_STORE_ORDERS = "RECEIVED_MANAGER_STORE_ORDERS";
export const RECEIVED_PENDING_STORE_ORDERS = "RECEIVED_PENDING_STORE_ORDERS";
export const RECEIVED_BUDGET_DATA = 'RECEIVED_BUDGET_DATA';
export const RECEIVED_POINTS_DATA = 'RECEIVED_POINTS_DATA';
export const RECEIVED_LAST_PAYOUT = 'RECEIVED_LAST_PAYOUT';
export const RECEIVED_CHANGED_ORDER = 'RECEIVED_CHANGED_ORDER';
export const RECEIVED_AUTO_BUDGET_DATA = 'RECEIVED_AUTO_BUDGET_DATA';
export const RECEIVED_BUDGET_UPDATE_AMOUNT = 'RECEIVED_BUDGET_UPDATE_AMOUNT';
export const RECEIVED_GET_STORE_SETTINGS = 'RECEIVED_GET_STORE_SETTINGS';
export const RECEIVED_GET_EMPLOYEES_WALLET = 'RECEIVED_GET_EMPLOYEES_WALLET';
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const RECEIVE_PROFILE_METRICS = 'RECEIVE_PROFILE_METRICS';
export const RECEIVE_PROFILE_ORDERS = 'RECEIVE_PROFILE_ORDERS';

export const orderStatusMap = {1: 'booked', 2: 'processed', 3: 'delivered', 4: 'canceled'};

function receivedManagerShopSummary(summary) {
    return {type: RECEIVED_MANAGER_SHOP_SUMMARY, payload: {summary}};
}

function receivedManagerShopProducts(products) {
    return {type: RECEIVED_MANAGER_SHOP_PRODUCTS, payload: {products}};
}

function receivedProductPublishStatus(productId) {
    return {type: RECEIVED_PRODUCT_PUBLISH_STATUS, payload: {productId}};
}

function receivedUpdatedProductDetails(product) {
    return {type: RECEIVED_UPDATED_PRODUCT_DETAILS, payload: {product}};
}

function receivedDeletedProductDetails(productId) {
    return {type: RECEIVED_DELETED_PRODUCT_DETAILS, payload: {productId}};
}

function receivedManagerStoreOrders(orders) {
    return {type: RECEIVED_MANAGER_STORE_ORDERS, payload: {orders}};
}

export function receivedPendingStoreOrders(orders) {
    return {type: RECEIVED_PENDING_STORE_ORDERS, payload: {orders}};
}

function receivedBudgetData(budgets, autoBudget) {
    return {type: RECEIVED_BUDGET_DATA, payload: {budgets, autoBudget}};
}

function receivedTeamTransactions(transactions) {
    return {type: RECEIVED_TEAM_TRANSACTIONS, payload: {transactions}};
}

function receivedPointsData(pointsGranted) {
    return {type: RECEIVED_POINTS_DATA, payload: {pointsGranted}};
}

function receivedLastPayout(payout) {
    return {type: RECEIVED_LAST_PAYOUT, payload: {payout}};
}

function receiveChangedOrder(order) {
    return {type: RECEIVED_CHANGED_ORDER, payload: {order}};
}

function receivedAutoBudgetFwd(isAutoBudget) {
    return {type: RECEIVED_AUTO_BUDGET_DATA, payload: {isAutoBudget}};
}

function receivedBudgetUpdateAmount(budgetData) {
    return {type: RECEIVED_BUDGET_UPDATE_AMOUNT, payload: {budgetData}};
}

function receivedGetStoreSettings(storeSettingsData) {
    return {
        type: RECEIVED_GET_STORE_SETTINGS, payload: {storeSettingsData}
    };
}

function receivedGetEmployeesWallet(employeesWallet) {
    return {type: RECEIVED_GET_EMPLOYEES_WALLET, employeesWallet: employeesWallet};
}

export function getManagerStoreSummary(callback) {
    return callApi('getManagerStoreSummaryData', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.data) {
            if (responseData.data.summary)
                dispatch(receivedManagerShopSummary(responseData.data.summary));
        }

        if (callback) {
            callback();
        }
    });
}

export function getManagerStoreProducts() {
    return callApi('getManagerStoreProductsData', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.data) {
            if (responseData.data.products)
                dispatch(receivedManagerShopProducts(responseData.data.products));
        }
    });
}

export function setProductPublishStatus(product) {
    let btnName = product.btnNamespace;
    return callApi('setProductPublishStatus', product, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.data) {
            if (responseData.data.productId)
                dispatch(disabledButtons(responseData.data.productId, btnName));
            dispatch(receivedProductPublishStatus(responseData.data.productId));
        }
    });
}

export function updateProductDetails(product) {
    return callApi('updateProductDetails', product, dispatch => {
        if (product.imgDataUrl && typeof (product.imgDataUrl) === 'string') {
            product.imgDataUrl = base64ToFile(product.imgDataUrl);
        }
        dispatch({type: API_REQUEST_SAVING});
    }, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.product) {
            dispatch(receivedUpdatedProductDetails(responseData.product));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        }
    });
}

export function deleteProductFromStore(productData) {
    let btnName = productData.btnNamespace;
    return callApi('deleteProductFromStore', productData, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.data) {
            if (responseData.data.productId)
                dispatch(disabledButtons(responseData.data.productId, btnName));
            dispatch(receivedDeletedProductDetails(responseData.data.productId));
        }
    });
}

export function getManagerStoreOrders(filterObj, callback) {
    return callApi('getManagerStoreOrders', filterObj, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.data) {
            if (responseData.data.orders)
                dispatch(receivedManagerStoreOrders(responseData.data.orders));
        }
        if (callback) {
            callback();
        }
    });
}

export function setManagerStoreOrders(ordersData) {
    return callApi('setManagerStoreOrders', ordersData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.data) {
            dispatch(receiveChangedOrder(responseData.data));
            dispatch({type: API_REQ_SAVE_SUCCESS});
            dispatch(getManagerStoreOrders());
            dispatch(getManagerStoreSummary());
        }
    });
}

export function getLastPayout(callback) {
    return callApi('getLastPayout', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.payout) {
            dispatch(receivedLastPayout(responseData.payout));
        }
        callback();
    });
}

export function getBudgetData(filter, callback) {
    return callApi('getBudgetData', filter, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.data) {
            if (responseData.data.budgetData)
                dispatch(receivedBudgetData(responseData.data.budgetData, responseData.data.autoBudget));
        }
        if (callback)
            callback();
    });
}

export function getPointsData(filter, callback) {
    return callApi('getPointsData', filter, null, (dispatch, responseData) => {
        console.log(responseData);
        let posts = [];
        if (responseData.data) {
            if (responseData.data.pointsGranted)
                dispatch(receivedPointsData(responseData.data.pointsGranted));
            if (responseData.data.posts) {
                posts = responseData.data.posts
            }
        }
        if (callback)
            callback(posts);
    });
}

export function setAutoBudgetFwd(data) {
    let btnName = data.btnNamespace;
    return callApi('toggleAutoBudgetFwd', data.value, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(disabledButtons('', btnName));
            dispatch(receivedAutoBudgetFwd(responseData.data));
        }
    });
}

export function setBudgetAmount(data, callback) {
    return callApi('updateBudgetAmount', data, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedBudgetUpdateAmount(responseData.data));
            if (callback) {
                callback();
            }
        }
    });
}

export function payoutBudget(data, callback) {
    return callApi('payoutBudget', data, (dispatch) => dispatch({
        type: API_REQUEST_SAVING,
        prefix: 'please_wait'
    }), (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS, prefix: 'action'});
            if (callback) {
                callback();
            }
        }
    });
}

export function getStoreSettings(callback) {
    return callApi('getStoreSettings', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedGetStoreSettings(responseData.data));
            if (callback) {
                callback(responseData.data);
            }
        }
    });
}

export function setStoreSettings(storeSettings) {
    return callApi('setStoreSettings', storeSettings, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            let {data, ...sessionData} = responseData;
            if (sessionData.attached_center_id) {
                dispatch(loginSuccess(sessionData));
            }
            dispatch(receivedGetStoreSettings(data));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        }
    });
}

export function getEmployeesWallet() {
    return callApi('getEmployeesWallet', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedGetEmployeesWallet(responseData.employeesWallet));
        }
    });
}

export function moveInactiveCoinsToTeamWallet(user_id, callback) {
    return callApi('moveInactiveCoinsToTeamWallet', {user_id}, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(getManagerStoreSummary());
            dispatch(getEmployeesWallet());
            dispatch({type: API_REQ_SAVE_SUCCESS});
            if (callback) {
                callback();
            }
        }
    });
}

export function getMetricsForUser(center_id, callback) {
    return callApi('getMetricsForUser', {center_id}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch({
                type: RECEIVE_PROFILE_METRICS,
                lead_types: responseData.data.leadTypes,
                metrics: responseData.data.metrics
            });
            if (callback) {
                callback(responseData.data.leadTypes, responseData.data.metrics);
            }
        }
    });
}

export function getUserLastOrders(userId, callback) {
    return callApi('getUserLastOrders', {userId}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch({type: RECEIVE_PROFILE_ORDERS, orders: responseData.orders});
            if (callback) {
                callback();
            }
        }
    });
}

export function getTeamTransactions(callback) {
    return callApi('getTeamTransactions', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedTeamTransactions(responseData.transactions));

            if (callback)
                callback();
        }
    });
}

export function makeTeamTransaction(data, callback) {
    return callApi('makeTeamTransaction', data, null, (dispatch, responseData) => {
        dispatch(
            getTeamTransactions(() => callback(responseData.transaction_id))
        );
    });
}