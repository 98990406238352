import React from 'react';

export default () => <svg className="dailyGoalIcon" version="1.1" viewBox="0 0 382 382"
                          xmlns="http://www.w3.org/2000/svg">
    <path id="circle"
          d="m85.623 218.28h-27.968c9.9864 48.513 45.731 87.423 92.417 102.21v-20.356c0-2.7015 0.29963-5.2934 0.79179-7.8577-32.062-12.714-56.456-40.191-65.241-73.999zm64.448-128.32v-28.459c-46.686 14.789-82.429 53.697-92.416 102.21h27.94c8.7037-33.562 32.77-60.902 64.476-73.753zm146.33 73.753h27.94c-9.9864-48.515-45.703-87.423-92.416-102.21v28.431c31.705 12.879 55.772 40.219 64.476 73.781zm-65.268 128.57c0.49096 2.5643 0.79179 5.1575 0.79179 7.8577v20.356c46.713-14.789 82.431-53.671 92.417-102.21h-27.968c-8.759 33.808-33.153 61.284-65.241 73.999z"
          fill="#f22c80" fillRule="nonzero"/>
    <circle id="center" cx="191.5" cy="190.5" r="28.5" fill="#f22c80"/>
    <g id="markers" fill="#24d173">
        <path id="Path"
              d="m136.43 191c0-7.5304-6.1117-13.643-13.643-13.643h-109.14c-7.5317 0-13.643 6.1129-13.643 13.643 0 7.5304 6.1117 13.643 13.643 13.643h109.14c7.5292 0 13.642-6.1129 13.642-13.643z"/>
        <path
            d="m368.36 177.36h-109.14c-7.5304 0-13.643 6.1117-13.643 13.643 0 7.5304 6.1117 13.643 13.643 13.643h109.14c7.5304 0 13.643-6.1117 13.643-13.643 0-7.5304-6.1117-13.643-13.643-13.643z"/>
        <path
            d="m191 0c-7.5304 0-13.643 6.1117-13.643 13.643v109.14c0 7.5304 6.1117 13.643 13.643 13.643 7.5304 0 13.643-6.1117 13.643-13.643v-109.14c0-7.5316-6.1129-13.643-13.643-13.643z"/>
        <path
            d="m191 245.57c-7.5304 0-13.643 6.1117-13.643 13.643v109.14c0 7.5304 6.1117 13.643 13.643 13.643 7.5304 0 13.643-6.1117 13.643-13.643v-109.14c0-7.5304-6.1129-13.643-13.643-13.643z"/>
    </g>
</svg>

