import React, {PureComponent} from "react";
import Button from '@material-ui/core/Button';
import {withNewLine} from '../../utils/generalUtils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import "./controls.css";

import {t} from 'react-i18nify'

export default class ErjConfirm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            title: props.title ? props.title : t("erj_confirm.warning"),
            body: props.body ? props.body : t("erj_confirm.changes_will_be_permanent"),
            confirmBtnText: props.confirmBtnText ? props.confirmBtnText : t("global.confirm"),
            cancelBtnText: props.cancelBtnText ? props.cancelBtnText : t("global.cancel"),
        };

        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            body: nextProps.body ? nextProps.body : t("erj_confirm.changes_will_be_permanent")
        });
    }

    handleCancel() {
        this.setState({open: false});
        this.props.close();
    }

    handleOk(props) {
        this.setState({open: false});
        this.props.confirmCB(props);
    }

    render() {
        const dialogProps = this.props.dialogProps || {};
        const confirmBtnProps = this.props.confirmBtnProps || {};
        const cancelBtnProps = this.props.cancelBtnProps || {};
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={"ErjConfirm localText aligned"}
                disableBackdropClick={Boolean(this.props.disableBackdropClick)}
                {...dialogProps}>
                <DialogTitle className={"ErjConfirm-title"}>
                    {this.state.title}</DialogTitle>
                <DialogContent className={"ErjConfirm-body"}>
                    <DialogContentText id="alert-dialog-description">
                        {withNewLine(this.state.body)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={"ErjConfirm-buttons"}>
                    {this.props.close && (
                        <Button onClick={this.handleCancel}
                                className={'cancelBtn'} {...cancelBtnProps}>
                            {this.state.cancelBtnText}
                        </Button>
                    )}
                    <Button onClick={this.handleOk} className={'confirmBtn'}
                            autoFocus {...confirmBtnProps}>
                        {this.state.confirmBtnText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ErjConfirm.propTypes = {
    open: PropTypes.bool.isRequired,
    disableBackdropClick: PropTypes.bool,
    title: PropTypes.string,
    body: PropTypes.string,
    confirmBtnText: PropTypes.string,
    cancelBtnText: PropTypes.string,
    confirmBtnProps: PropTypes.object,
    cancelBtnProps: PropTypes.object,
    close: PropTypes.func,
    confirmCB: PropTypes.func.isRequired,
    dialogProps: PropTypes.object
};