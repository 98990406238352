import React, {Component} from 'react'
import PropTypes from "prop-types";
import {showModal} from "../../actions/controls";
import {changeCenter} from "../../actions/auth";
import {connect} from "react-redux";

class EmployeeProfileLink extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    showModal = (e) => {
        e.preventDefault();
        const {userId, centerId} = this.props;
        if (this.props.switchToCenter) {
            this.props.dispatch(changeCenter(userId));
        } else {
            this.props.dispatch(showModal("EmployeeProfile", {userId, centerId}));
        }
    };

    render() {
        const {titleName, className} = this.props;
        return (
            <a href={''} className={'employee-profile-link' + (className ? ' ' + className : '')}
               style={{cursor: 'pointer'}} onClick={this.showModal}>
                {titleName || this.props.children}
            </a>
        );
    }
}

EmployeeProfileLink.propTypes = {
    userId: PropTypes.number.isRequired,
    centerId: PropTypes.number,
    titleName: PropTypes.string,
    isCenter: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
    const {sessionData} = state.auth;
    return {
        switchToCenter: ownProps.isCenter && sessionData.is_manager && sessionData.additionalData.allowedCenterIds.indexOf('' + ownProps.userId) > -1,
    };
}

export default connect(mapStateToProps)(EmployeeProfileLink);