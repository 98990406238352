import React from "react";
import Responsive from 'react-responsive';
import {connect} from "react-redux";

class ViewPort extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {auth, children} = this.props;
        const isAllowMobile = (!auth || !auth.is_manager);
        return (
            <Responsive {...this.props}
                        maxWidth={1199}>{matches => children({mobile: matches && isAllowMobile})}</Responsive>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {auth} = state;
    return {auth};
};

export default connect(mapStateToProps)(ViewPort);