import {
    RECEIVED_MANAGER_SHOP_SUMMARY,
    RECEIVED_MANAGER_SHOP_PRODUCTS,
    RECEIVED_PRODUCT_PUBLISH_STATUS,
    RECEIVED_UPDATED_PRODUCT_DETAILS,
    RECEIVED_DELETED_PRODUCT_DETAILS,
    RECEIVED_MANAGER_STORE_ORDERS,
    RECEIVED_BUDGET_DATA,
    RECEIVED_CHANGED_ORDER,
    RECEIVED_AUTO_BUDGET_DATA,
    RECEIVED_BUDGET_UPDATE_AMOUNT,
    RECEIVED_GET_STORE_SETTINGS, RECEIVED_LAST_PAYOUT, RECEIVED_GET_EMPLOYEES_WALLET, RECEIVED_POINTS_DATA,
    RECEIVED_PENDING_STORE_ORDERS
} from "../actions/managerStore";

import {RESET_LIVE_DATA} from "../actions/auth";
import {RECEIVED_TEAM_TRANSACTIONS} from "../actions/store";

const _ = require('lodash');

const initialState = {
    summary: {},
    products: [],
    orders: [],
    pending_orders: {},
    budgets: [],
    employeesWallet: [],
    last_payout: [],
    settings: {conditions: [], isLimitation: null},
    pointsGranted: [],
    team_transactions: []
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function managerStore(state = initializeState(), action = {}) {
    let clonedProducts;
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_MANAGER_SHOP_SUMMARY:
            return {
                ...state,
                summary: {
                    employeesCash: Number(action.payload.summary.employeesCash),
                    moneySpent: Number(action.payload.summary.moneySpent),
                    awaitingOrders: Number(action.payload.summary.awaitingOrders),
                    teamCash: action.payload.summary.teamCash,
                    lockedTeamCoins: action.payload.summary.lockedTeamCoins
                }
            };
        case RECEIVED_MANAGER_SHOP_PRODUCTS:
            return {
                ...state,
                products: action.payload.products
            };
        case RECEIVED_PENDING_STORE_ORDERS:
            return {
                ...state,
                pending_orders: Object.values(action.payload.orders).reduce((orders_stats, order) => {
                    if (order.order_status === 1) {
                        orders_stats.new++;
                    }
                    if (order.order_status === 2) {
                        orders_stats.awaitingDelivery++;
                    }
                    return orders_stats;
                }, {new: 0, awaitingDelivery: 0})
            };
        case RECEIVED_PRODUCT_PUBLISH_STATUS:
            clonedProducts = _.cloneDeep(state.products);
            clonedProducts.map(item => {
                if (item.id === action.payload.productId) {
                    item.active = item.active ? 0 : 1;
                    item.disable = false;
                }
            });
            return {
                ...state,
                products: clonedProducts
            };
        case RECEIVED_UPDATED_PRODUCT_DETAILS:
            let productIndex = state.products.findIndex((product) => {
                    return product.id === action.payload.product.id;
                }
            );
            if (productIndex === -1) {
                state.products.unshift(action.payload.product);

            } else {
                state.products[productIndex] = action.payload.product;
            }
            clonedProducts = [...state.products.splice(0)];
            return {
                ...state,
                products: clonedProducts
            };
        case RECEIVED_DELETED_PRODUCT_DETAILS:
            return {
                ...state,
                products: state.products.filter((product) => {
                    return product.id !== action.payload.productId
                })
            };
        case RECEIVED_MANAGER_STORE_ORDERS:
            return {
                ...state,
                orders: action.payload.orders.filter((order) => {
                    return order;
                })
            };
        case RECEIVED_BUDGET_DATA:
            return {
                ...state,
                budgets: action.payload.budgets,
                autoBudget: action.payload.autoBudget,
            };
        case RECEIVED_TEAM_TRANSACTIONS:
            return {
                ...state,
                team_transactions: action.payload.transactions
            };
        case RECEIVED_POINTS_DATA:
            return {
                ...state,
                pointsGranted: action.payload.pointsGranted
            };
        case RECEIVED_LAST_PAYOUT:
            return {
                ...state,
                last_payout: action.payload.payout,
            };
        case RECEIVED_CHANGED_ORDER:
            return {
                ...state,
                orders: state.orders.map((order) => {
                    if (order.id === Number(action.payload.order.orderId))
                        order['status'] = Number(action.payload.order.orderStatus);
                    return order;
                })
            };
        case RECEIVED_AUTO_BUDGET_DATA:
            return {
                ...state,
                autoBudget: action.payload.isAutoBudget
            };
        case RECEIVED_BUDGET_UPDATE_AMOUNT:
            return {
                ...state,
                budgets: {
                    ...state.budgets,
                    [action.payload.budgetData.month]: {
                        ...state.budgets[action.payload.budgetData.month],
                        budget: action.payload.budgetData.budget
                    }
                }
            };
        case RECEIVED_GET_STORE_SETTINGS:
            return {
                ...state,
                settings: {
                    isLimitation: action.payload.storeSettingsData.isLimitation,
                    auto_payout_day: action.payload.storeSettingsData.auto_payout_day,
                    conditions: action.payload.storeSettingsData.conditions,
                    coinsPartitionMethod: action.payload.storeSettingsData.coinsPartitionMethod,
                    multiplyPointsByMetric: action.payload.storeSettingsData.multiplyPointsByMetric,
                    leftover_budget_behavior: action.payload.storeSettingsData.leftover_budget_behavior,
                    employee_display_currency: action.payload.storeSettingsData.employee_display_currency,
                    controlling_center: action.payload.storeSettingsData.controlling_center
                }
            };
        case RECEIVED_GET_EMPLOYEES_WALLET:
            return {
                ...state,
                employeesWallet: action.employeesWallet
            };
        default:
            return state;
    }
}
