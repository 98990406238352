import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Icon from '@material-ui/core/Icon';
import ErjScroll from "./ErjScroll";
import Button from "@material-ui/core/Button";

const styles = {
    root: {
        display: 'flex'
    },
    popperClose: {
        pointerEvents: 'none',
    }
};

class ErjMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedValue: props.defaultValue
        };
    }

    handleClick = () => {
        this.setState({open: !this.state.open});
    };

    handleChangeSelection = (selectedItem) => {
        if (typeof selectedItem.value === 'string' || (typeof(selectedItem.value) !== 'undefined' && this.props.isChangeValue)) {
            this.setState({selectedValue: selectedItem.key});
            this.props.cbFunc(selectedItem.value, selectedItem);
        }
        if (!(selectedItem instanceof Event && selectedItem.target.className === 'thumb-vertical')) {
            this.setState({open: false});
        }
    };

    render() {
        const {classes, label, emptyMenuItem} = this.props;
        const {open, selectedValue} = this.state;
        const selectedMenuItem = this.props.menuItems.filter(menuItem => menuItem.key === selectedValue)[0];
        return (
            <div className={classes.root}>
                <span className={'erjMenuWrapper'}>
                    <Button className="erjMenu-button" onClick={this.handleClick}
                            buttonRef={node => this.anchorEl = node}>
                        {label ? (
                            <>
                                {label}
                                {(!emptyMenuItem || [emptyMenuItem.key, label].indexOf(selectedValue) === -1) &&
                                    <span className={'selectedValue'}>({selectedValue})</span>}
                            </>
                        ) : (
                            (selectedMenuItem && selectedMenuItem.title) || selectedValue
                        )}
                        <Icon className="icon- dropdownIcon dropdownIcon-erjMenu-menu">dropdown_arrow</Icon>
                    </Button>
                    <div className="erjMenu-container">
                        <Popper open={this.state.open} disablePortal={true} anchorEl={this.anchorEl}
                                placement="bottom-start" transition
                                className={classNames({[classes.popperClose]: !open}) + ' menu erjMenuPopper'}>
                            {({TransitionProps, placement}) => (
                                <Grow {...TransitionProps}>
                                    <Paper className="erjMenu-menu">
                                        <ClickAwayListener onClickAway={this.handleChangeSelection}>
                                            <MenuList role="menu">
                                                <ErjScroll autoHeight autoHeightMax={this.props.maxHeight}
                                                           isDisabled={!this.props.maxHeight}>
                                                    {(emptyMenuItem ? [emptyMenuItem] : []).concat(this.props.menuItems).map((menuItem, index) => (
                                                        <MenuItem
                                                            className={menuItem.key === selectedValue ? 'selected' : ''}
                                                            key={index}
                                                            onClick={() => this.handleChangeSelection(menuItem)}>{menuItem.title || menuItem.key}</MenuItem>
                                                    ))}
                                                </ErjScroll>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </span>
            </div>
        );
    }
}

ErjMenu.propTypes = {
    defaultValue: PropTypes.string.isRequired,
    menuItems: PropTypes.array.isRequired,
    isChangeValue: PropTypes.bool,
    classes: PropTypes.object,
    cbFunc: PropTypes.func,
    label: PropTypes.string,
    maxHeight: PropTypes.number
};

export default withStyles(styles)(ErjMenu);