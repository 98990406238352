import {callApi} from "../utils/apiUtils";
import {disabledButtons} from "./controls";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_ERROR, API_REQUEST_SAVING} from "./global";
import {loginSuccess} from "./auth";

export const RECEIVED_GENERAL_SETTINGS_DATA = "RECEIVED_GENERAL_SETTINGS_DATA";
export const RECEIVED_CHALLENGES_SETTINGS_DATA = "RECEIVED_CHALLENGES_SETTINGS_DATA";
export const RECEIVED_UPDATED_GENERAL_SETTINGS_DATA = "RECEIVED_UPDATED_GENERAL_SETTINGS_DATA";
export const RECEIVED_SECURITY_SETTINGS_DATA = "RECEIVED_SECURITY_SETTINGS_DATA";

function receivedGeneralSettingsData(generalSettingsData) {
    return {type: RECEIVED_GENERAL_SETTINGS_DATA, payload: {generalSettingsData}};
}

function receivedUpdatedGeneralSettingsData(generalSettingsData) {
    return {type: RECEIVED_UPDATED_GENERAL_SETTINGS_DATA, payload: {generalSettingsData}};
}

export function getChallengesSettingsData(callback) {
    return callApi('getChallengesSettingsData', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({
                type: RECEIVED_CHALLENGES_SETTINGS_DATA,
                payload: {challengesSettingsData: responseData.challengesSettingsData}
            });
            callback();
        }
    });
}

export function setChallengesSettingsData(requestData, callback) {
    return callApi('setChallengesSettingsData', requestData, dispatch => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            let {data, ...sessionData} = responseData;
            dispatch(loginSuccess(sessionData));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        callback();
    });
}

export function getGeneralSettingsData(callback) {
    return callApi('getGeneralSettingsData', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedGeneralSettingsData(responseData.data));
            callback();
        }
    });
}

export function setGeneralSettingsData(requestData, callback) {
    return callApi('setGeneralSettingsData', requestData, dispatch => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            let {data, ...sessionData} = responseData;
            dispatch(loginSuccess(sessionData));
            dispatch(receivedUpdatedGeneralSettingsData(data));
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        callback();
    });
}

export function getSecuritySettingsData(callback) {
    return callApi('getSecuritySettingsData', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({
                type: RECEIVED_SECURITY_SETTINGS_DATA,
                payload: {securitySettingsData: responseData.securitySettingsData}
            });
            callback();
        }
    });
}

export function setSecuritySettingsData(requestData, callback) {
    return callApi('setSecuritySettingsData', requestData, dispatch => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            let {securitySettingsData, ...sessionData} = responseData;
            dispatch(loginSuccess(sessionData));
            dispatch({type: RECEIVED_SECURITY_SETTINGS_DATA, payload: {securitySettingsData}});
            dispatch({type: API_REQ_SAVE_SUCCESS});
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
        callback();
    });
}
