import React from 'react';

export default () => <svg className="personalChallengeIcon" version="1.1" viewBox="0 0 323 452"
                          xmlns="http://www.w3.org/2000/svg">
    <path id="ribbon"
          d="m217.83 327.39c-4.627 1.497-9.471 2.255-14.395 2.255-16.531 0-29.223-8.099-39.437-14.618-0.568-0.362-1.441-0.921-2.421-1.534-0.983 0.615-1.86 1.174-2.427 1.536-10.192 6.511-22.877 14.613-39.431 14.616-4.917 0-9.755-0.757-14.417-2.263-8.545-2.777-15.094-7.468-20.217-12.932v125.55c0 4.436 2.625 8.451 6.688 10.23 1.441 0.63 2.964 0.938 4.477 0.938 2.755 0 5.473-1.02 7.577-2.962l57.751-53.314 57.753 53.314c3.26 3.009 7.992 3.803 12.054 2.024 4.063-1.779 6.688-5.795 6.688-10.23v-125.56c-5.128 5.469-11.684 10.164-20.243 12.945z"
          fill="#3dde90"/>
    <path id="stamp"
          d="m301.1 122.2c-3.574-2.927-9.563-7.82-10.432-9.982-0.742-2.387 1.318-10.326 2.549-15.069 3.014-11.612 6.755-26.062-1.566-37.504-8.387-11.524-23.375-12.413-35.408-13.126-4.811-0.283-12.862-0.762-14.879-2.131-1.9-1.52-4.772-8.91-6.496-13.318-4.408-11.314-9.898-25.396-23.599-29.848-2.498-0.809-5.129-1.221-7.829-1.221-10.313 0-19.282 5.727-27.961 11.266-4.22 2.696-11.281 7.207-13.898 7.207s-9.676-4.511-13.895-7.205c-8.678-5.541-17.649-11.268-27.962-11.268-2.701 0-5.331 0.412-7.831 1.221-13.699 4.451-19.188 18.533-23.597 29.848-1.724 4.408-4.596 11.799-6.467 13.3-2.046 1.388-10.097 1.866-14.907 2.149-12.032 0.71-27.021 1.6-35.406 13.122-8.323 11.442-4.581 25.894-1.567 37.508 1.23 4.743 3.29 12.683 2.561 15.028-0.881 2.201-6.87 7.097-10.446 10.022-9.47 7.74-21.253 17.371-21.253 31.965 0 14.595 11.783 24.225 21.255 31.966 3.575 2.925 9.564 7.819 10.433 9.978 0.742 2.387-1.318 10.33-2.552 15.073-3.012 11.612-6.753 26.062 1.569 37.507 8.394 11.524 23.377 12.41 35.41 13.123 4.808 0.283 12.858 0.76 14.872 2.127 1.892 1.52 4.772 8.91 6.497 13.32 4.409 11.314 9.899 25.396 23.601 29.85 2.498 0.807 5.127 1.219 7.827 1.219h4e-3c10.311-2e-3 19.28-5.726 27.95-11.264 4.227-2.696 11.287-7.2 13.905-7.2 2.616 0 9.678 4.504 13.898 7.2 8.679 5.538 17.648 11.264 27.959 11.264 2.702 0 5.333-0.412 7.833-1.221 13.699-4.451 19.189-18.531 23.597-29.846 1.725-4.41 4.599-11.801 6.467-13.299 2.045-1.389 10.097-1.867 14.907-2.15 12.032-0.713 27.014-1.599 35.409-13.121 8.321-11.444 4.58-25.895 1.566-37.506-1.23-4.746-3.291-12.688-2.561-15.033 0.881-2.201 6.869-7.096 10.443-10.021 9.473-7.741 21.255-17.373 21.255-31.966 0-14.595-11.782-24.224-21.255-31.964zm-139.52 129.49c-53.862 0-97.522-43.668-97.522-97.527 0-53.857 43.66-97.522 97.522-97.522 53.864 0 97.524 43.665 97.524 97.522 0 53.859-43.66 97.527-97.524 97.527z"
          fill="#f22e81"/>
</svg>


