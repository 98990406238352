import {
    API_REQUEST_START,
    API_REQUEST_END,
    API_REQUEST_SAVING,
    API_REQ_SAVE_SUCCESS,
    API_REQUEST_ERROR,
    API_CLEAR_REQUEST_FEEDBACK,
    UPDATE_CURRENT_TIME,
    START_TEMP_DELAY_ACTIONS,
    STOP_TEMP_DELAY_ACTIONS, API_REQUEST_DELETING, API_REQUEST_UPLOADING, API_REQ_SUCCESS_INFO
} from "../actions/global";
import moment from 'moment-timezone';

const initialState = {
    currentTime: moment(),
    currentDate: moment().format('YYYY-MM-DD'),
    currentDateTimeMinute: moment().format('YYYY-MM-DD HH:mm:00'),
    apiRequestInProgress: false,
    apiRequestStatusFeedback: {
        status: null
    },
    tempDelayActions: false,
    scriptId: null,
    disabledFeatures: {
        pages: []
    }
};

export default function global(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_CURRENT_TIME :
            let currentTime = moment().add(10, 'seconds'); // add 10 seconds so that comparision with new arriving dates won't cause a negative value (10 seconds = the of calling UPDATE_CURRENT_TIME)
            return {
                ...state,
                currentTime,
                currentDate: currentTime.format('YYYY-MM-DD')
            };
        case API_REQUEST_START:
            return Object.assign(
                {},
                state,
                {apiRequestInProgress: true}
            );
        case API_REQUEST_END:
            return Object.assign(
                {},
                state,
                {apiRequestInProgress: false}
            );
        case API_REQUEST_SAVING:
            return Object.assign(
                {},
                state,
                {
                    apiRequestStatusFeedback: {
                        status: 'saving',
                        prefix: action.prefix
                    }
                }
            );
        case API_REQUEST_UPLOADING:
            return Object.assign(
                {},
                state,
                {
                    apiRequestStatusFeedback: {
                        status: 'uploading',
                        prefix: action.prefix
                    }
                }
            );
        case API_REQUEST_DELETING:
            return Object.assign(
                {},
                state,
                {
                    apiRequestStatusFeedback: {
                        status: 'deleting',
                        prefix: action.prefix
                    }
                }
            );
        case API_REQ_SAVE_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    apiRequestStatusFeedback: {
                        status: 'success',
                        prefix: action.prefix,
                        additionalData: action.additionalData,
                        customMsg: action.customMsg
                    }
                }
            );
        case API_REQ_SUCCESS_INFO:
            return Object.assign(
                {},
                state,
                {
                    apiRequestStatusFeedback: {
                        status: 'info',
                        prefix: action.prefix,
                        additionalData: action.additionalData,
                        customMsg: action.customMsg
                    }
                }
            );
        case API_REQUEST_ERROR:
            return Object.assign(
                {},
                state,
                {
                    apiRequestStatusFeedback: {
                        status: 'error',
                        prefix: action.prefix,
                        retryFunc: action.retryFunc,
                        additionalData: action.additionalData
                    }
                }
            );
        case API_CLEAR_REQUEST_FEEDBACK:
            return action.reason === 'clickaway' && state.apiRequestStatusFeedback.status === 'info' ? state : Object.assign(
                {},
                state,
                {
                    apiRequestStatusFeedback: {
                        status: null
                    }
                }
            );
        case START_TEMP_DELAY_ACTIONS:
            return Object.assign(
                {},
                state,
                {tempDelayActions: true}
            );
        case STOP_TEMP_DELAY_ACTIONS:
            return Object.assign(
                {},
                state,
                {tempDelayActions: false}
            );
        default:
            return state;
    }
}
