import React from 'react';
import LayersIcon from "@material-ui/icons/Layers";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import {Tooltip} from "@material-ui/core";
import {t, Translate} from 'react-i18nify'
import Icon from "@material-ui/core/Icon";

export const LeadTypesContext = React.createContext({});
export const UnitsContext = React.createContext({});
export const SavedComponentsContext = React.createContext({});
export const ChangesContext = React.createContext([]);
export const subComponentsTypes = {
    number: {
        type: "saved",
        CIcon: (label) => (
            <span className={'numIcon'}>#</span>
        )
    },
    tier_table: {
        type: "saved",
        CIcon: () => (
            <LayersIcon className={'tierIcon'}/>
        )
    },
    process: {
        type: "saved",
        CIcon: () => (
            <AccountTreeIcon className={'flowIcon'}/>
        )
    },
    goal_percent: {
        type: "data",
        CIcon: () => (
            <span className={'goalPercentIcon'}>%</span>
        )
    },
    monthly_performance: {
        type: "data",
        CIcon: () => (
            <EqualizerIcon className={'monthPerformIcon'}/>
        )
    },
    team_average_monthly_performance: {
        type: "data",
        CIcon: () => (
            <TrendingUpIcon className={'avgTeamPerformIcon'}/>
        )
    },
    employee_data: {
        type: "data",
        CIcon: () => (
            <PersonIcon className={'employeeDataIcon'}/>
        )
    },
    units: {
        type: "data",
        CIcon: () => (
            <GroupIcon className={'employeeDataIcon'}/>
        )
    }
};
export const ComponentLabel = ({type, label, CIcon, onEditComponent}) => {
    const subComponentType = subComponentsTypes[type];
    const LabelElement = subComponentType.type === 'saved' && onEditComponent ? `button` : `div`;
    const labelElementProps = {className: 'componentLabel'};
    if (onEditComponent) {
        labelElementProps.onClick = onEditComponent;
    }
    return (
        <Tooltip
            title={t('commissions.' + (subComponentType.type === 'saved' ? 'component_types' : 'data_types') + '.' + type)}>
            <LabelElement {...labelElementProps}>
                <Icon className={'componentIcon'}>
                    {CIcon ? <CIcon/> : <subComponentType.CIcon/>}
                </Icon>
                <span className={'label'}>{' ' + label}</span>
            </LabelElement>
        </Tooltip>
    );
}

export const stringComparisonFunctionTypes = {
    "condition": {
        "args": [
            {
                "components": "*",
                "data": {
                    "isAllowStringValue": true
                },
                "number": true
            },
            {
                "logic_test": ["=", "!="],
            },
            {
                "string": true
            }
        ],
        "sep": ""
    }
}

export const unitComparisonFunctionTypes = {
    "condition": {
        "args": [
            {
                "components": "*",
                "data": {
                    "isAllowStringValue": true
                },
                "number": true
            },
            {
                "logic_test": ["=", "!="],
            },
            {
                "data": ["units"]
            }
        ],
        "sep": ""
    }
}

export const functionTypes = {
    "logical": {
        "min_args": 1,
        "arg_types": {
            "functions": ["logical"],
            "condition": true
        }
    },
    "condition": {
        "args": [
            {
                "components": "*",
                "data": {
                    "isAllowStringValue": true
                },
                "number": true
            },
            {
                "logic_test": "*",
            },
            {
                "components": "*",
                "data": "*",
                "number": true
            }
        ],
        "sep": ""
    },
    "math": {
        "min_args": 2,
        "arg_types": {
            "functions": ["math"],
            "components": "*",
            "data": "*",
            "number": true
        }
    }
};

export const stringDataTypes = [
    "role",
    "group",
    "sub_group"
];

export const unitDataTypes = [
    "assigned_center_ids"
];

export const functionDefinitions = {
    "$and": {
        "type": "logical",
        "sep": <Translate value={'commissions.functions.$and'}/>
    },
    "$or": {
        "type": "logical",
        "sep": <Translate value={'commissions.functions.$or'}/>
    },
    "$cond": {
        "type": "condition"
    },
    "$subtract": {
        "type": "math",
        "num_args": 2,
        "sep": "-"
    },
    "$divide": {
        "type": "math",
        "num_args": 2,
        "sep": "/"
    },
    "$add": {
        "type": "math",
        "sep": '+'
    },
    "$multiply": {
        "type": "math",
        "sep": '×'
    },
    "$min": {
        "type": "math"
    },
    "$max": {
        "type": "math"
    },
    "$round": {
        "type": "math",
        "args": [
            {
                "components": "*",
                "data": "*",
                "number": true
            },
            {
                "number": true,
                "buttonLabel": <Translate value={'commissions.specify_decimals'}/>
            }
        ]
    }
};