import {
    RECEIVED_METRIC_LAST_TRANSACTION,
    RECEIVED_METRICS_LIST,
    RECEIVED_LEAD_TYPES_LIST,
    RECEIVED_ORG_LEAD_TYPES_LIST,
    RECEIVED_METRICS_SETTINGS,
    RECEIVED_METRICS_NEW_ORDER
} from "../actions/metrics";
import {RESET_LIVE_DATA} from "../actions/auth";

const _ = require('lodash');

const initialState = {
    metrics: {},
    lead_types: {},
    org_lead_types: {},
    last_transactions: {},
    metricsSettings: null,
    sameMetricCreateIdError: false,
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function metrics(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_METRICS_LIST:
            let {last_transactions} = state;
            for (let metric_id of Object.keys(action.metrics)) {
                if (action.metrics[metric_id].last_lead) {
                    if (!last_transactions[metric_id])
                        last_transactions[metric_id] = [];
                    if (!last_transactions[metric_id].length || last_transactions[metric_id][0].id !== action.metrics[metric_id].last_lead.id)
                        last_transactions[metric_id] = [action.metrics[metric_id].last_lead, ...last_transactions[metric_id]];
                }
            }
            return {
                ...state,
                last_transactions,
                metrics: action.metrics
            };
        case RECEIVED_LEAD_TYPES_LIST:
            return {
                ...state,
                lead_types: action.lead_types
            };
        case RECEIVED_ORG_LEAD_TYPES_LIST:
            return {
                ...state,
                org_lead_types: action.org_lead_types
            };
        case RECEIVED_METRIC_LAST_TRANSACTION: {
            let {last_transactions} = state;
            if (action.last_transaction.length)
                last_transactions[action.metric_id] = [...last_transactions[action.metric_id], action.last_transaction[0]];
            else
                last_transactions[action.metric_id][last_transactions[action.metric_id].length - 1].isLast = true;
            return {
                ...state,
                last_transactions
            };
        }
        case RECEIVED_METRICS_SETTINGS:
            return {
                ...state,
                metricsSettings: action.metrics
            };
        case RECEIVED_METRICS_NEW_ORDER:
            let byId = _.keyBy(action.metricNewOrder, 'id');
            return {
                ...state,
                metricsSettings: state.metricsSettings.map(metric => {
                    let findUpdated = byId[metric.id];
                    if (findUpdated) {
                        metric.order = findUpdated.order;
                    }
                    return metric;
                })
            };
        default:
            return state;
    }
}
