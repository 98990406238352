import {
    CHANGE_SELECTED_TAB,
    TOGGLE_DISABLED_BUTTONS,
    SHOW_MODAL,
    HIDE_MODAL,
    DISPATCH_UI_ACTION
} from "../actions/controls";
import {RESET_LIVE_DATA} from "../actions/auth";

const _ = require('lodash');
const initialState = {
    uiAction: null,
    tabs: {
        storeProductsDateSwitch: 'all',
        shopCartDateSwitch: 'shopping_cart',
        userDisplaySwitch: 'Users',
        leaderBoardDateSwitch: 'today',
        personalMetricsWidgetDateSwitch: 'today',
        uploadProductsDateSwitch: 'all',
        privateFeedTab: 'inbox',
    },
    dropDowns: {
        managerStoreItemFilter: '',
        managerStoreStatusFilter: ''
    },
    disabledButtons: {},
    modal: {modalShow: false}
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function controls(state = initializeState(), action) {
    let modal = {};
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case CHANGE_SELECTED_TAB:
            let tabs = {
                ...state.tabs,
                [action.namespace]: action.tab
            };
            return {
                ...state,
                tabs
            };
        case TOGGLE_DISABLED_BUTTONS:
            let clonedBtns = _.clone(state.disabledButtons);
            let disabledButtons;
            if (clonedBtns[action.disabledButtons]) {
                delete clonedBtns[action.disabledButtons];
                disabledButtons = clonedBtns;
            }
            else {
                disabledButtons = {
                    ...state.disabledButtons,
                    [action.disabledButtons]: action.isDisable
                };

            }
            return {
                ...state,
                disabledButtons
            };
        case SHOW_MODAL:
            modal = {
                ...state.modal,
                modalType: action.modalType,
                modalData: action.modalData,
                modalShow: action.modalShow,
            };
            return {
                ...state,
                modal
            };
        case HIDE_MODAL:
            modal = {
                ...state.modal,
                modalType: action.modalType,
                modalData: action.modalData,
                modalShow: action.modalShow,
            };
            return {
                ...state,
                modal
            };
        case DISPATCH_UI_ACTION:
            let uiAction = {
                key: action.key,
                params: action.params,
                time: Date.now(),
            };
            return {
                ...state,
                uiAction
            };
        default:
            return state;
    }
}