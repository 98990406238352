import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "../app/app.css";
import {hideModal} from "../../actions/controls";
import EmployeeProfile from "../users/EmployeeProfile"

class ErjModal extends PureComponent {
    closeModal = () => {
        this.props.dispatch(hideModal());
    };

    setModalBody = (modalType, modalData) => {
        switch (modalType) {
            case "EmployeeProfile":
                return <EmployeeProfile closeModal={this.closeModal} data={modalData}/>;
            default:
                return false;
        }
    };

    render() {
        const {modal} = this.props;
        return (modal && this.setModalBody(modal.modalType, modal.modalData)
        )
    }
}

function mapStateToProps(state) {
    const {controls} = state;
    let modal = null;

    if (controls.modal.modalShow) {
        modal = controls.modal;
    }

    return {modal: modal};
}

export default connect(mapStateToProps)(ErjModal);
