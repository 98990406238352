import {evaluate} from 'mathjs';
import {MAX_CHECK_IN_TIME_PERCENT} from "erj-common/generalUtils";
import moment from 'moment';
import {PERFORMANCE_DISPLAY_METHOD} from "./metrics";

const _ = require('lodash');

const ACTIVITY_DEFS_WIN_REQ_BY_ORDER = [8];

export const getPrizeForDeclaredVal = (base_prize, win_req_val, declare_val, num_leads, cost = 0) => {
    if (base_prize < 0)
        return base_prize;
    const declarePrizeExpression = '({grandPrize}*{isDailyGoalReached}) + (0.2*{grandPrize}*{numAboveGoal}) + (0.1*{grandPrize}*{numAboveDeclare}) - (0.05*{grandPrize}*{numBelowDeclare}) - {cost}';
    let calcLeadsUnit = win_req_val / 10;
    if (num_leads > win_req_val * 2)
        num_leads = win_req_val * 2;
    let isDailyGoalReached = num_leads >= win_req_val ? 1 : 0;
    let numAboveGoal = Math.max((Math.min(num_leads, declare_val) - win_req_val) / calcLeadsUnit, 0);
    let numAboveDeclare = declare_val >= win_req_val ? ((num_leads - declare_val) / calcLeadsUnit) : 0; // num above declare is only relevant from at least the daily goal
    let numBelowDeclare = 0;
    if (numAboveDeclare < 0) {
        numBelowDeclare = numAboveDeclare * (-1);
        numAboveDeclare = 0;
    }

    return Math.ceil(Math.max(0, evaluate(declarePrizeExpression.replace(/{grandPrize}/g, base_prize)
        .replace(/{isDailyGoalReached}/g, isDailyGoalReached)
        .replace(/{numAboveGoal}/g, numAboveGoal)
        .replace(/{numAboveDeclare}/g, numAboveDeclare)
        .replace(/{numBelowDeclare}/g, numBelowDeclare)
        .replace(/{cost}/g, cost)
    )));
};

export const getCurrentExpectedPrize = (activity, num_leads) => {
    if (activity.declare_val) {
        return getPrizeForDeclaredVal((activity.data && activity.data.basePrize) || activity.offered_prize, activity.win_req_val, activity.declare_val, num_leads);
    }
    else {
        if (!activity.participant_group_id && num_leads > (activity.win_req_val * 2)) {
            num_leads = activity.win_req_val * 2;
        }
        return Math.round(num_leads > activity.win_req_val ? activity.offered_prize + ((num_leads - activity.win_req_val) / (activity.win_req_val / activity.offered_prize)) : 0);
    }
};

export const getWinCriteriaType = activity => ACTIVITY_DEFS_WIN_REQ_BY_ORDER.indexOf(activity.activity_def_id) > -1 ? 'winOrder' : 'moreIsBetter';

export const getActivityLeaders = (groupActivities, result) => {
    result = result || groupActivities[0].result;
    let particpants = groupActivities.map(val => ({
        user_id: val.user_id,
        team_id: val.participant_team_id,
        win_req_val: val.win_req_val,
        reach_win_req_time: val.reach_win_req_time
    }));
    let teams = _.keyBy(particpants, val => val.team_id);
    let results = [];
    if (!particpants[0].team_id) {
        if (!result)
            result = {num_leads: 0};
        results = [{user_id: particpants[0].user_id, leads: result.num_leads, win_req_val: particpants[0].win_req_val}];
    }
    else {
        if (!result)
            result = _.mapValues(teams, () => 0);
        results = Object.keys(result)
            .map(team_id => {
                return teams[team_id] &&
                    {
                        team_id: parseInt(team_id),
                        user_id: teams[team_id].user_id,
                        leads: result[team_id],
                        win_req_val: teams[team_id].win_req_val,
                        reach_win_req_time: result.reachWinReqTimes && result.reachWinReqTimes[team_id] ? new Date(result.reachWinReqTimes[team_id]) : new Date()
                    };
            })
            .filter(val => Boolean(val));
        results.sort((a, b) => {
            if (getWinCriteriaType(groupActivities[0]) === 'winOrder')
                return a.reach_win_req_time.getTime() - b.reach_win_req_time.getTime();
            else
                return (b.leads / b.win_req_val) - (a.leads / a.win_req_val)
        });
    }
    return results;
};

export const getActivityLeaderBoards = (groupActivities, result, performance_display_method) => {
    result = result || groupActivities[0].result;
    let win_req_vals, win_req_val;
    if (!groupActivities[0].participant_group_id) {
        win_req_vals = groupActivities[0].data.win_req_vals.sort((a, b) => a.val - b.val);
        win_req_val = win_req_vals.slice(-1)[0].val;
    }
    let particpants = groupActivities.map(val => ({
        user_id: val.user_id,
        team_id: val.participant_team_id,
        win_req_val: !groupActivities[0].participant_group_id ? win_req_val : val.win_req_val,
        reach_win_req_time: val.reach_win_req_time,
        personal_win_req: val.personal_win_req,
        leads: (val.result && val.result.num_leads) || 0
    }));
    let teams = _.keyBy(particpants, 'team_id');
    let users = _.keyBy(particpants, 'user_id');
    if (!result)
        result = _.mapValues(teams, () => 0);
    let results = {
        "leaders": [],
        "team": [],
        "user": []
    };
    results.team = Object.keys(result)
        .map(team_id => {
            return teams[team_id] &&
                {
                    team_id: parseInt(team_id),
                    user_id: teams[team_id].user_id,
                    leads: result[team_id],
                    win_req_val: teams[team_id].win_req_val,
                    reach_win_req_time: teams[team_id].reach_win_req_time ? new Date(teams[team_id].reach_win_req_time) : new Date()
                };
        })
        .filter(val => Boolean(val));
    results.user = result.users ? Object.keys(result.users)
        .map(user_id => {
            return {
                user_id,
                leads: result.users[user_id].leads,
                personal_win_req: users[user_id].personal_win_req
            };
        }) : particpants;
    results.user.sort((a, b) => performance_display_method === PERFORMANCE_DISPLAY_METHOD.PERCENT_OF_GOAL && a.personal_win_req ? (b.leads / b.personal_win_req) - (a.leads / a.personal_win_req) : b.leads - a.leads);
    results.team.sort((a, b) => {
        if (getWinCriteriaType(groupActivities[0]) === 'winOrder')
            return (a.reach_win_req_time.getTime() - b.reach_win_req_time.getTime()) || ((b.leads / b.win_req_val) - (a.leads / a.win_req_val));
        else
            return (b.leads / b.win_req_val) - (a.leads / a.win_req_val);
    });
    results.leaders = [...results.team];

    return results;
};

export const getSecondaryDefOutput = (activity) => {
    if (!activity || !activity.data)
        return false;

    let secondaryDefOutputs = {
        'improve_shift_avg': {
            'prevVal': activity.data.prev_shift_avg,
            'prevVal_title': 'prev_daily_avg',
            'newVal': activity.data.curr_shift_avg,
            'newVal_title': 'new_daily_avg'
        },
        'improve_monthly_leads': {
            'prevVal': activity.data.prev_month_leads,
            'prevVal_title': 'last_month',
            'newVal': activity.data.curr_month_leads,
            'newVal_title': 'this_month'
        },
        'achieve_monthly_goal': {
            'newVal': activity.data.monthly_goal,
            'newVal_title': 'monthly_goal'
        },
        'achieve_daily_goal': {
            'newVal': activity.data.daily_goal,
            'newVal_title': 'daily_goal'
        },
        'most_time_1st_place': {
            'newVal': activity.data.times_in_pos,
            'newVal_title': 'days_in_1st_place'
        }
    };

    let secondary_def_id = activity.secondary_def_id && activity.secondary_def_id.substr(0, activity.secondary_def_id.lastIndexOf('_'));

    return secondary_def_id && {
        id: secondary_def_id,
        ...secondaryDefOutputs[secondary_def_id]
    };
};

export const getChats = (chats, chatTextChosenChannel) => {
    return chats ? (
        Object.keys(chats).filter(receiver_type => Object.keys(chatTextChosenChannel)[0] === receiver_type)
            .reduce((prev, receiver_type) => prev.concat(_.toArray(chats[receiver_type][chatTextChosenChannel[receiver_type]])), [])
            .sort((a, b) => a.id - b.id)
    ) : [];
};

export const isAllowCheckin = (activity) => !activity.length || moment().diff(activity.start_time, 'hours', true) < activity.length * MAX_CHECK_IN_TIME_PERCENT;