import React, {Component} from 'react';
import {connect} from "react-redux";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import MetricValueDisplay from "../misc/MetricValueDisplay";
import ClapIcon from "../../components/icons/ClapIcon";
import ErjScroll from "../../components/controls/ErjScroll";
import {getUserImg} from "erj-common/generalUtils";

import {t} from 'react-i18nify'
import {UNIT_TYPE} from "../../utils/metrics";
const _ = require('lodash');

class FeedbackSummaryWidget extends Component {
    constructor(props) {
        super(props);
        this.initState(props);
    }

    initState(props) {
        this.state = {
            isModalOpen: false
        };
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    render() {
        const {center_is_parent, feedbackCount, feedbackStats, managers, centersDetails, user_id} = this.props;

        const {opportunities, given} = feedbackCount;

        const countByProperty = (property) => Object.values(feedbackStats).reduce((stats, feedback) => {
            if (!feedback[property]) {
                return stats;
            }
            if (!stats[feedback[property]]) {
                stats[feedback[property]] = {opportunities: 0, given: 0};
            }
            stats[feedback[property]].opportunities++;
            if (feedback.postId) {
                stats[feedback[property]].given++;
            }
            return stats;
        }, {})

        const feedbackByManager = Object.entries(countByProperty('origin_sender_id')).map(([manager_id, {given}]) => ({
            manager_id,
            given
        })).sort((a, b) => b.given - a.given);

        const feedbackByCenter = Object.entries(countByProperty('center_id')).map(([center_id, {
            opportunities,
            given
        }]) => ({
            center_id,
            opportunities,
            given,
            value: given / opportunities
        })).sort((a, b) => b.value - a.value);

        const feedbackByEmployees = countByProperty('user_id');

        const employees = this.props.employees.map(employee => {
            employee = {...employee};
            const stats = feedbackByEmployees[employee.id];
            employee.opportunities = (stats && stats.opportunities) || 0;
            employee.given = (stats && stats.given) || 0;
            employee.value = employee.opportunities ? (employee.given / employee.opportunities) : 0;
            return employee;
        }).sort((a, b) => b.value - a.value);

        const isBtnDisabled = !opportunities;

        return (
            <div>
                <Modal
                    aria-labelledby="show_summary_table"
                    aria-describedby="show_summary_table"
                    className={'summaryModal'}
                    open={this.state.isModalOpen}
                    onClose={this.closeModal}
                >
                    <div className={'modalContent'}>
                        <div className={'summary-widget-modal'}>
                            <div className={'post'}>
                                <div className={'postHeader'}>
                                    <div className={'postHeaderIcon'}>
                                        <ClapIcon/>
                                    </div>
                                    <div className={'postHeaderTitle'}>
                                        <div
                                            className={'post-title'}>{t(center_is_parent ? 'summary.feedback_summary' : 'summary.feedback_you_sent_this_month')}</div>
                                    </div>
                                </div>
                                <div
                                    className={'postBody feedbackSummaryModalBody'}>
                                    <div className={'summaryTable'}>
                                        {center_is_parent ? (
                                            <div className={'summaryHeader'}>
                                                <div className={'title'}>{t('settings.managers')}</div>
                                                <div/>
                                                <div className={'title'}>{t('summary.sent_to_units')}</div>
                                                <div/>
                                            </div>
                                        ) : (
                                            <div className={'summaryHeader'}>
                                                <div className={'title'}>{t('settings.managers')}</div>
                                                <div/>
                                                <div className={'title'}>{t('manager_store.employees')}</div>
                                                <div/>
                                            </div>
                                        )}
                                        <ErjScroll ref={ref => this.tableScroll = ref}>
                                            <div className={'summaryBody'}>
                                                <div className={'summaryCol'}>
                                                    {feedbackByManager.map(({
                                                                                manager_id,
                                                                                given
                                                                            }) => managers[manager_id] && (
                                                        <div className={'userRow'} key={'managerRow_' + manager_id}>
                                                            <div
                                                                className={'dotted'}>
                                                                <div className={'details'}>
                                                                    <img className={'userImg'}
                                                                         src={getUserImg(managers[manager_id].image_uri)}/>
                                                                    <div>
                                                                        <div
                                                                            className={'name'}>{managers[manager_id].first_name + ' ' + managers[manager_id].last_name}</div>
                                                                        <div className={'value'}>
                                                                            {given + ' ' + t('global.feedback')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {center_is_parent ? (
                                                    <div className={'summaryCol'}>
                                                        {feedbackByCenter.map(({
                                                                                   center_id,
                                                                                   opportunities,
                                                                                   given,
                                                                                   value
                                                                               }) => centersDetails[center_id] && (
                                                            <div className={'userRow'} key={'centerRow_' + center_id}>
                                                                <div className={'dotted'}>
                                                                    <Tooltip classes={{popper: 'localPopperText'}}
                                                                             title={t('summary.feedback_percent_breakdown', {
                                                                                 opportunities,
                                                                                 given
                                                                             })}>
                                                                        <div className={'details'}>
                                                                            <img className={'userImg'}
                                                                                 src={getUserImg(centersDetails[center_id].image_uri, 'xxs', true)}/>
                                                                            <div>
                                                                                <div
                                                                                    className={'name'}>{centersDetails[center_id].title}</div>
                                                                                <div className={'value'}>
                                                                                    <MetricValueDisplay value={value}
                                                                                                        unitType={UNIT_TYPE.PERCENTAGE}
                                                                                                        roundOpts={{decimals: 0}}/>
                                                                                    {' ' + t('global.feedback')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className={'summaryCol'}>
                                                        {employees.map(({
                                                                            id,
                                                                            first_name,
                                                                            last_name,
                                                                            user_img,
                                                                            opportunities,
                                                                            given,
                                                                            value
                                                                        }) => (
                                                            <div className={'userRow'} key={'userRow_' + id}>
                                                                <div className={'dotted'}>
                                                                    <Tooltip classes={{popper: 'localPopperText'}}
                                                                             title={t('summary.feedback_percent_breakdown', {
                                                                                 opportunities,
                                                                                 given
                                                                             })}>
                                                                        <div className={'details'}>
                                                                            <img className={'userImg'}
                                                                                 src={getUserImg(user_img, 'xxs')}/>
                                                                            <div>
                                                                                <div
                                                                                    className={'name'}>{first_name + ' ' + last_name}</div>
                                                                                <div className={'value'}>
                                                                                    <MetricValueDisplay value={value}
                                                                                                        unitType={UNIT_TYPE.PERCENTAGE}
                                                                                                        roundOpts={{decimals: 0}}/>
                                                                                    {' ' + t('global.feedback')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </ErjScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Tooltip PopperProps={{className: 'localText'}}
                         title={t('summary.feedback_percent_breakdown', {opportunities, given})}>
                    <button className={'summaryBtn feedback' + (isBtnDisabled ? ' disabled' : '')}
                            onClick={() => !isBtnDisabled && this.setState({isModalOpen: true})}>
                        <ClapIcon/>
                        <div className={'benefitContent'}>
                            <div className={'benefitValue'}>
                                <MetricValueDisplay value={opportunities ? given / opportunities : 0}
                                                    unitType={UNIT_TYPE.PERCENTAGE} roundOpts={{decimals: 0}}/>
                            </div>
                            <div className={'benefitLabel'}>{t('global.feedback')}</div>
                        </div>
                    </button>
                </Tooltip>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {centersDetails, managers} = state.auth.sessionData.additionalData;
    return {centersDetails, managers};
};

export default connect(mapStateToProps)(FeedbackSummaryWidget);