import React, {Component} from 'react';
import {connect} from "react-redux";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import MetricValueDisplay from "../misc/MetricValueDisplay";
import ErjScroll from "../../components/controls/ErjScroll";
import {getUserImg} from "erj-common/generalUtils";
import Icon from "@material-ui/core/Icon";
import {UNIT_TYPE} from "../../utils/metrics";
import {makeGetActivityStatsSummary} from "../../selectors/activitiesSelector";

import {t} from 'react-i18nify'
const _ = require('lodash');

const CheckInIcon = () => (
    <div className={'checkInIcon'}>
        <Icon className="icon- checkMarkIcon">check_mark</Icon>
    </div>
);

class CheckinsSummaryWidget extends Component {
    constructor(props) {
        super(props);

        this.state = this.initState(props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.attached_center_id !== nextProps.attached_center_id || (!this.props.participationRateByCenter.length && nextProps.participationRateByCenter.length)) {
            this.setState(this.initState(nextProps));
        }
    }

    initState(props) {
        return {
            isModalOpen: false,
            selectedCenterId: props.participationRateByCenter[0] && props.participationRateByCenter[0].center_id
        };
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    onClickCenterRow = (e, selectedCenterId) => {
        e.preventDefault();
        this.tableScroll.scrollToTop();
        this.setState({selectedCenterId});
    };

    render() {
        const {center_is_parent, activityStatsSummary, participationRateByCenter, totalParticipationRate, centersDetails, users} = this.props;
        const {selectedCenterId} = this.state;

        const participationRateByEmployee = Object.values((activityStatsSummary[selectedCenterId] && activityStatsSummary[selectedCenterId].byUser) || {})
            .map(({user_id, total, checked_in}) => ({
                user_id,
                rate: checked_in / total
            })).sort((a, b) => b.rate - a.rate);

        return (
            <div>
                <Modal
                    aria-labelledby="show_summary_table"
                    aria-describedby="show_summary_table"
                    className={'summaryModal'}
                    open={this.state.isModalOpen}
                    onClose={this.closeModal}
                >
                    <div className={'modalContent'}>
                        <div className={'summary-widget-modal'}>
                            <div className={'post'}>
                                <div className={'postHeader'}>
                                    <div className={'postHeaderIcon'}>
                                        <CheckInIcon/>
                                    </div>
                                    <div className={'postHeaderTitle'}>
                                        <div className={'post-title'}>{t('summary.participation')}</div>
                                    </div>
                                </div>
                                <div
                                    className={'postBody checkInsSummaryModalBody' + (!center_is_parent ? ' singleColumn' : '')}>
                                    <div className={'summaryTable'}>
                                        {center_is_parent ? (
                                            <div className={'summaryHeader'}>
                                                <div className={'title'}>{t('settings.units')}</div>
                                                <div/>
                                                <div className={'title'}>{t('manager_store.employees')}</div>
                                                <div/>
                                            </div>
                                        ) : (
                                            <div className={'summaryHeader'}>
                                                <div className={'title'}>{t('manager_store.employees')}</div>
                                                <div/>
                                            </div>
                                        )}
                                        <ErjScroll ref={ref => this.tableScroll = ref}>
                                            <div className={'summaryBody'}>
                                                {center_is_parent && (
                                                    <div className={'summaryCol'}>
                                                        {participationRateByCenter.map(({center_id, rate}) => centersDetails[center_id] && (
                                                            <a href=''
                                                               onClick={(e) => this.onClickCenterRow(e, center_id)}
                                                               className={center_id === selectedCenterId ? 'userRow selected' : 'userRow'}
                                                               key={'centerRow_' + center_id}>
                                                                <div
                                                                    className={center_id === selectedCenterId ? 'dotted selected' : 'dotted'}>
                                                                    <div className={'details'}>
                                                                        <img className={'userImg'}
                                                                             src={getUserImg(centersDetails[center_id].image_uri, 'xxs', true)}/>
                                                                        <div>
                                                                            <div
                                                                                className={'name'}>{centersDetails[center_id].title}</div>
                                                                            <div className={'value'}>
                                                                                <MetricValueDisplay value={rate}
                                                                                                    unitType={UNIT_TYPE.PERCENTAGE}
                                                                                                    roundOpts={{decimals: 0}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        ))}
                                                    </div>
                                                )}
                                                <div className={'summaryCol'}>
                                                    {participationRateByEmployee.map(({user_id, rate}) => users[user_id] && (
                                                        <div className={'userRow'} key={'userRow' + user_id}>
                                                            <div className={'dotted'}>
                                                                <div className={'details'}>
                                                                    <img className={'userImg'}
                                                                         src={getUserImg(users[user_id].user_img)}/>
                                                                    <div>
                                                                        <div
                                                                            className={'name'}>{users[user_id].first_name + ' ' + users[user_id].last_name}</div>
                                                                        <div className={'value'}>
                                                                            <MetricValueDisplay value={rate}
                                                                                                unitType={UNIT_TYPE.PERCENTAGE}
                                                                                                roundOpts={{decimals: 0}}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </ErjScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Tooltip title={t('summary.participation_rate_this_month')}>
                    <button className={'summaryBtn participation_rate'}
                            onClick={() => this.setState({isModalOpen: true})}>
                        <CheckInIcon/>
                        <div className={'benefitContent'}>
                            <div className={'benefitValue'}>
                                <MetricValueDisplay value={totalParticipationRate} unitType={UNIT_TYPE.PERCENTAGE}
                                                    roundOpts={{decimals: 0}}/>
                            </div>
                            <div className={'benefitLabel'}>{t('summary.participation')}</div>
                        </div>
                    </button>
                </Tooltip>
            </div>
        );
    }
}

const makeMapStateToProps = () => {
    const getParticipationRateSummary = makeGetActivityStatsSummary();
    return (state, props) => {
        const {users} = state.users;
        const {centersDetails} = state.auth.sessionData.additionalData;
        const {attached_center_id} = state.auth.sessionData;

        return {
            ...getParticipationRateSummary(state, props),
            centersDetails,
            users,
            attached_center_id
        };
    };
};

export default connect(makeMapStateToProps)(CheckinsSummaryWidget);