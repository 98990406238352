import {disabledButtons, DISPATCH_UI_ACTION} from "./controls";
import {base64ToFile, callApi} from "../utils/apiUtils";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_DELETING, API_REQUEST_SAVING, API_REQUEST_UPLOADING} from "./global";

export const RECEIVED_TVS_DATA = "RECEIVED_TVS_DATA";
export const RECEIVED_TVS_SETTINGS = "RECEIVED_TVS_SETTINGS";
export const RECEIVED_UPLOADED_BG_IMG = "RECEIVED_UPLOADED_BG_IMG";
export const RECEIVED_DELETED_BG_IMG = "RECEIVED_DELETED_BG_IMG";
export const RECEIVED_TV_SAVED_DATA = "RECEIVED_TV_SAVED_DATA";
export const RECEIVED_DELETED_DATA = "RECEIVED_DELETED_DATA";
export const START_WATCH_TV = "START_WATCH_TV";
export const STOP_WATCH_TV = "STOP_WATCH_TV";

function receivedTvsData(tvsData) {
    return {type: RECEIVED_TVS_DATA, payload: {tvsData}};
}

export function receivedTvsSettings(tvsSettings) {
    return {type: RECEIVED_TVS_SETTINGS, payload: {tvsSettings}};
}

function receivedUploadedBgImg(imgData) {
    return {type: RECEIVED_UPLOADED_BG_IMG, payload: {imgData}};
}

function receivedDeletedBgImg(file_id) {
    return {type: RECEIVED_DELETED_BG_IMG, payload: {file_id}};
}

function receivedTvSavedData(tvSavedData) {
    return {type: RECEIVED_TV_SAVED_DATA, payload: {tvSavedData}};
}

function receivedDeletedData(tvDeletedData) {
    return {type: RECEIVED_DELETED_DATA, payload: {tvDeletedData}};
}

export function getTvsData(callback) {
    return callApi('getTvsData', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedTvsData(responseData.data));
        }
        callback();
    });
}

export function uploadBackgroundImage(data, callback) {
    return callApi('uploadBackgroundImage', data, dispatch => {
        if (data.imgDataUrl && typeof (data.imgDataUrl) === 'string') {
            data.imgDataUrl = base64ToFile(data.imgDataUrl);
        }
        dispatch({type: API_REQUEST_UPLOADING});
    }, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.data) {
            dispatch(receivedUploadedBgImg(responseData.data));
            dispatch({type: API_REQ_SAVE_SUCCESS});
            callback(responseData.data);
        }
    });
}

export function startWatchTv(tvId, centerIds, callback) {
    return callApi('updateWatchedTv', {action: 'start', tvId, centerIds}, null, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: START_WATCH_TV, tvId});
        callback();
    });
}

export function stopWatchTv(tvId) {
    return callApi('updateWatchedTv', {action: 'stop', tvId}, null, (dispatch, responseData) => {
        console.log(responseData);
        dispatch({type: STOP_WATCH_TV, tvId});
    });
}

export function deleteBackgroundImage(file_id) {
    return callApi('deleteBackgroundImage', {file_id}, dispatch => {
        dispatch({type: API_REQUEST_DELETING});
    }, (dispatch, responseData) => {
        console.log(responseData);
        dispatch(receivedDeletedBgImg(file_id));
        dispatch({type: API_REQ_SAVE_SUCCESS});
    });
}

export function saveTVScreen(tvData, buttonName) {
    let btnName = buttonName;
    return callApi('saveTVScreen', tvData, null, (dispatch, responseData) => {
        dispatch({type: API_REQUEST_SAVING});
        if (responseData.status === "SUCCESS") {
            dispatch(receivedTvSavedData(responseData.data));
            dispatch(disabledButtons(btnName, false));
            dispatch({type: API_REQ_SAVE_SUCCESS});
            dispatch({type: DISPATCH_UI_ACTION, key: 'changeTab'});
        }
    });
}

export function deleteTVScreen(tvData, buttonName) {
    let btnName = buttonName;
    return callApi('deleteTVScreen', tvData, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedDeletedData(responseData.data));
            dispatch(disabledButtons(btnName, false));
            dispatch({type: DISPATCH_UI_ACTION, key: 'changeTab'})
        }
    });
}