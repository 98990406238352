import React, {Component, Fragment} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import "./summary-widgets.css";
import "../commissions/commission-wizard.css";
import MetricValueDisplay from "../misc/MetricValueDisplay";
import {
    makeGetCommissionSummary
} from "../../selectors/commissionsSelector";
import {connect} from "react-redux";

import {t} from 'react-i18nify'
import {UNIT_TYPE} from "../../utils/metrics";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {getPreviousCommissions} from "../../actions/commissions";
import ViewPort from "../misc/Viewport";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import ErjScroll from "../../components/controls/ErjScroll";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import {ArgValueContent} from "../../components/commission_wizard/Designer";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import {LeadTypesContext, SavedComponentsContext} from "../../components/commission_wizard/commission-context";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const CommissionDetailsModal = ({
                                    displayColumns,
                                    resultsCache,
                                    data,
                                    current_user_id,
                                    savedComponents,
                                    plan,
                                    commission,
                                    predictedCommission,
                                    lead_types,
                                    onClose
                                }) => {
    return (
        <ViewPort>
            {({mobile}) => {
                return (
                    <div className={'post post_commissions'}>
                        {!mobile && (
                            <div className={'postHeader'}>
                                <div className={'postHeaderIcon'}>
                                    <AccountBalanceIcon className={'commissionsIcon'}/>
                                </div>
                                <div className={'postHeaderTitle'}>
                                    <div className={'post-header'}>
                                        <div className={'post-title'}>{t('commissions.calculation_details')}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {mobile && (
                            <NavigationBar onClose={() => onClose()}
                                           title={t('commissions.calculation_details')}/>
                        )}
                        <div className={'postBody commissionModal commissionDetailsModal'}>
                            <div className={'summaryTable'}>
                                <ErjScroll>
                                    <div className={'summaryBody'}>
                                        <LeadTypesContext.Provider value={lead_types}>
                                            <SavedComponentsContext.Provider value={savedComponents}>
                                                <div className={'summaryCol verticalHeader'}>
                                                    {displayColumns.map((column, index) => (
                                                        <div className={'summaryRow'}>
                                                            <ArgValueContent name={column.slice(-1)[0]}
                                                                             type={column.slice(0, -1)}
                                                                             onEditComponent={() => {
                                                                             }}/>
                                                        </div>
                                                    ))}
                                                    <div
                                                        className={'summaryRow'}>{t('commissions.final_commission')}</div>
                                                    <div
                                                        className={'summaryRow'}>{t('commissions.month_end_prediction')}</div>
                                                </div>
                                                <div className={'summaryCol'}>
                                                    {displayColumns.map(column => (
                                                        <div className={'summaryRow'}>
                                                            {column[0] === 'data' ? (
                                                                <MetricValueDisplay
                                                                    value={_.get(data, [column[1], column[2], current_user_id], 0)}
                                                                    roundOpts={{decimals: 2}}
                                                                />
                                                            ) : (
                                                                savedComponents[column[1]].type === 'number' ? (
                                                                    plan.components[column[1]] ? plan.components[column[1]].value : ''
                                                                ) : (
                                                                    resultsCache[savedComponents[column[1]].key] ? (
                                                                        <MetricValueDisplay
                                                                            value={resultsCache[savedComponents[column[1]].key]}
                                                                            roundOpts={{decimals: 2}}
                                                                        />
                                                                    ) : (
                                                                        resultsCache['#unlinked#' + savedComponents[column[1]].key] ? (
                                                                            <span className={'unlinked'}>
                                                                        <MetricValueDisplay
                                                                            value={resultsCache['#unlinked#' + savedComponents[column[1]].key]}
                                                                            roundOpts={{decimals: 2}}
                                                                        />
                                                                        <Tooltip
                                                                            title={t('commissions.unlinked_component_note')}>
                                                                            <LinkOffIcon/>
                                                                        </Tooltip>
                                                                    </span>
                                                                        ) : '0'
                                                                    )
                                                                )
                                                            )}
                                                        </div>
                                                    ))}
                                                    <div className={'summaryRow'}>
                                                        <strong>
                                                            <MetricValueDisplay value={commission}
                                                                                unitType={UNIT_TYPE.MONEY}
                                                                                roundOpts={{decimals: 2}}/>
                                                        </strong>
                                                    </div>
                                                    <div className={'summaryRow'} style={{opacity: 0.6}}>
                                                        <strong>
                                                            <MetricValueDisplay value={predictedCommission}
                                                                                unitType={UNIT_TYPE.MONEY}
                                                                                roundOpts={{decimals: 2}}/>
                                                        </strong>
                                                    </div>
                                                </div>
                                            </SavedComponentsContext.Provider>
                                        </LeadTypesContext.Provider>
                                    </div>
                                </ErjScroll>
                            </div>
                        </div>
                    </div>);
            }}
        </ViewPort>
    );
}

const PreviousCommissionsModal = ({modal_data, onClose}) => {
    return (
        <ViewPort>
            {({mobile}) => {
                return (
                    <div className={'post post_commissions'}>
                        {!mobile && (
                            <div className={'postHeader'}>
                                <div className={'postHeaderIcon'}>
                                    <AccountBalanceIcon className={'commissionsIcon'}/>
                                </div>
                                <div className={'postHeaderTitle'}>
                                    <div className={'post-header'}>
                                        <div className={'post-title'}>{t('commissions.commissions_summary')}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {mobile && (
                            <NavigationBar onClose={() => onClose()}
                                           title={t('commissions.commissions_summary')}/>
                        )}
                        <div className={'postBody commissionsModal'}>
                            <div className={'summaryTable'}>
                                <div className={'summaryHeader'}>
                                    <div className={'title'}>
                                        <span className={'primary'}>{t('commissions.previous_months')}</span>
                                    </div>
                                </div>
                                <ErjScroll>
                                    <div className={'summaryBody'}>
                                        <div className={'summaryCol'}>
                                            {modal_data.length ? (
                                                modal_data.map(({month, commission, is_final}) => (
                                                    <div className={'userRow'} key={'month_' + month}>
                                                        <div className={'details'}>
                                                            <div className={'value'}>
                                                                {month}
                                                                {!is_final && ' - ' + t('commissions.not_final')}
                                                            </div>
                                                            <div className={'name'}>
                                                                <MetricValueDisplay value={commission}
                                                                                    unitType={UNIT_TYPE.MONEY}
                                                                                    roundOpts={{decimals: 2}}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={'userRow'} key={'month_'}>
                                                    <div className={'details'}>
                                                        <div
                                                            className={'name'}>{t('commissions.no_previous_commissions')}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ErjScroll>
                            </div>
                        </div>
                    </div>);
            }}
        </ViewPort>
    );
}

class PersonalCommissionSummaryWidget extends Component {
    state = {
        isLoadingData: false,
        isModalOpen: false,
        modalType: ''
    };

    loadPreviousCommissions = () => {
        this.setState({isLoadingData: true, isModalOpen: true, modalType: 'previousCommissions'});
        this.props.dispatch(getPreviousCommissions(() => {
            this.setState({isLoadingData: false})
        }))
    }

    showCommissionDetails = () => {
        this.setState({isModalOpen: true, modalType: 'commissionsDetails'});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    render() {
        const {commission, predictedCommission, mobile, modal_data, is_manager} = this.props;
        return (
            <>
                <div className={'moduleRow commissionsModule'}>
                    <div className={'moduleTitle localText aligned'}>
                        <AccountBalanceIcon/>
                        {' ' + t('commissions.accrued_commission')}
                    </div>
                    <div className={'moduleContent'}>
                        <div className={'moduleTextItem localText aligned'}>
                            {t('commissions.current_commission') + ' (' + t('commissions.not_final') + '):'}
                            <span className={'value'}>
                                {' '}
                                <strong>
                                    <MetricValueDisplay value={commission || 0} roundOpts={{decimals: 2}}
                                                        unitType={UNIT_TYPE.MONEY}/>
                                </strong>
                            </span>
                        </div>
                        <div className={'moduleTextItem localText aligned'}>
                            {t('commissions.month_end_prediction') + ':'}
                            <span className={'value'}>
                                {' '}
                                <MetricValueDisplay value={predictedCommission || 0} roundOpts={{decimals: 2}}
                                                    unitType={UNIT_TYPE.MONEY}/>
                            </span>
                        </div>
                        <div className={'advancedLink-container localText'}>
                            <button className={'advancedLink previousMonths'} onClick={this.loadPreviousCommissions}>
                                <span>{t('commissions.previous_months')}</span>
                            </button>
                            {this.props.displayColumns && this.props.displayColumns.length > 0 && (
                                <button className={'advancedLink'} onClick={this.showCommissionDetails}>
                                    <ZoomInIcon/>
                                    <span>{t('commissions.calculation_details')}</span>
                                </button>
                            )}
                        </div>
                    </div>
                    {this.props.recommendations.length > 0 && (
                        <>
                            <hr/>
                            <div className={'moduleTitle recommendationsTitle localText aligned'}>
                                {' ' + t('commissions.recommendations') + ':'}
                            </div>
                            <div className={'moduleContent'}>
                                <div className={'moduleTextItem localText aligned'}>
                                    <div className={'recommendationsHeader'}>
                                        <div className={'label'}>{t('commissions.targets_to_achieve')}</div>
                                        <div className={'label'}>{t('commissions.expected_commission')}</div>
                                    </div>
                                    {this.props.recommendations.map(({changes, newResult}) => (
                                        <div className={'recommendation'}>
                                            <div className={'changes'}>
                                                {Object.entries(changes).map(([lead_type_id, {
                                                    monthly_performance,
                                                    originalMetricValue
                                                }], index) => (
                                                    <>
                                                        {index > 0 && <div className={'changeSeparator'}/>}
                                                        <div className={'change'}>
                                                    <span
                                                        className={'metricName'}>{this.props.lead_types[lead_type_id].title}</span>
                                                            <ArrowRightAltIcon className={'targetIcon'}/>
                                                            <span className={'targetAmount'}>
                                                        <MetricValueDisplay
                                                            value={monthly_performance}
                                                            unitType={this.props.lead_types[lead_type_id].unit_type}
                                                            roundOpts={{decimals: this.props.lead_types[lead_type_id].display_decimal_digits}}
                                                            shortNumOpts={{decimals: 1, maxLength: 6}}
                                                        />
                                                    </span>
                                                            <div className={'progress'}
                                                                 style={{width: ((originalMetricValue / monthly_performance) * 100) + '%'}}/>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                            <DoubleArrowIcon className={'outcomeIcon'}/>
                                            <div className={'outcomeCommission'}>
                                                <MetricValueDisplay value={newResult} unitType={UNIT_TYPE.MONEY}
                                                                    roundOpts={{decimals: 0}}
                                                                    shortNumOpts={{shortThreshold: 100000}}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <Modal
                    aria-labelledby="show_summary_table"
                    aria-describedby="show_summary_table"
                    className={'summaryModal' + (mobile ? ' mob' : '')}
                    open={this.state.isModalOpen}
                    onClose={this.closeModal}
                >
                    <div className={'modalContent' + (mobile ? ' mobileModal' : '')}>
                        {this.state.isLoadingData ? (
                            <CircularProgress className="pageLoadProgressCircle" size={100}/>
                        ) : (
                            <div className={'summary-widget-modal'}>
                                {this.state.modalType === 'previousCommissions' ? (
                                    <PreviousCommissionsModal onClose={this.closeModal} modal_data={modal_data}/>
                                ) : (
                                    <CommissionDetailsModal
                                        displayColumns={this.props.displayColumns}
                                        resultsCache={this.props.resultsCache}
                                        data={this.props.data}
                                        current_user_id={this.props.current_user_id}
                                        savedComponents={this.props.savedComponents}
                                        plan={this.props.plan}
                                        commission={commission}
                                        predictedCommission={predictedCommission}
                                        lead_types={this.props.lead_types}
                                        onClose={this.closeModal}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </Modal>
            </>
        )
    }
}

const makeMapStateToProps = () => {
    const getCommissionSummary = makeGetCommissionSummary();
    return (state, props) => {
        const {auth, metrics} = state;

        return {
            is_manager: auth.sessionData.is_manager,
            ...getCommissionSummary(state, props)
        };
    }
};

export default connect(makeMapStateToProps)(PersonalCommissionSummaryWidget);