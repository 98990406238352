import {callApi} from "../utils/apiUtils";
import {disabledButtons} from "./controls";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_ERROR, API_REQUEST_SAVING} from "./global";
import {loginSuccess} from "./auth";

export const RECEIVED_TRIGGERS_DATA = "RECEIVED_TRIGGERS_DATA";

function receivedTriggersData(triggers) {
    return {type: RECEIVED_TRIGGERS_DATA, payload: {triggers}};
}

export function getCustomTriggers(callback) {
    return callApi('getCustomTriggers', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedTriggersData(responseData.trigger_defs));
            callback();
        }
    });
}

export function setCustomTriggerParams(data, callback) {
    return callApi('setCustomTriggerParams', data, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
            if (callback) {
                callback();
            }
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
    });
}
