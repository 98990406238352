import React, {Component} from 'react';

const _ = require('lodash');

class ErjForm extends Component {
    constructor(props) {
        super(props);
        console.log(props.children);
        this.rootElmProps = {...props};
        this.rootElmProps.className = 'ErjForm ' + props.className || '';
        this.cleanState = {
            formData: {},
            fieldValidations: {},
            defaultValues: {}
        };
        this.state = _.cloneDeep(this.cleanState);
        this.state = this.initState(props);
    }

    initState(props) {
        let state = {
            formData: {},
            fieldValidations: {},
            defaultValues: {}
        };
        // console.log('top children',props.children);
        this.children = React.Children.map(typeof(props.children) === 'function' ? props.children(state.formData) : props.children, this.collectInputs(state, state.formData, 'init').bind(this));
        state.defaultValues = _.cloneDeep(state.formData);

        return state;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.refresh && (!this.props.refresh || nextProps.refresh > this.props.refresh))
            this.setState(this.initState(nextProps));
        // if( !nextProps["data-preserve-state"] )
        //     this.setState( this.initState(nextProps) );
    }

    onChange(eventObj, props) {
        let is_array = Array.isArray(props["bound-state-obj"]);
        if (is_array) {
            let valIndex = props["bound-state-obj"].indexOf(props.value);
            if (valIndex > -1)
                props["bound-state-obj"].splice(valIndex, 1);
            else
                props["bound-state-obj"].push(props.value);
        }
        else if (props.type && props.type === 'checkbox') {
            props["bound-state-obj"][props["bound-state-obj-field"]] = eventObj.target.checked ? 1 : 0
        }
        else if (props.type && props.type === 'date-picker') {
            props["bound-state-obj"][props["bound-state-obj-field"]] = eventObj;
        }
        else
            props["bound-state-obj"][props["bound-state-obj-field"]] = (eventObj.target && typeof(eventObj.target.value) !== 'undefined') ? eventObj.target.value : eventObj.value;
        this.setState(this.state);
        this.validate(props, is_array);
    }

    onToggle(event, props) {
        if (props.type !== 'checkbox')
            event.preventDefault();
        this.onChange(event, props);
    }

    onSubmit(event) {
        event.preventDefault();
        console.log('form submitted!', this.state.formData);
        let failedValidations = Object.keys(this.state.fieldValidations).filter(field => {
            let is_array = field.indexOf('[]') > -1;
            return !this.validate(this.state.fieldValidations[field].props, is_array);
        });
        console.log('failedValidations', failedValidations);
        if (!failedValidations.length)
            this.props.onSubmit(event, this.state.formData, this.state.defaultValues);
    }

    onReset(event) {
        event.preventDefault();
        this.setState({formData: _.cloneDeep(this.state.defaultValues)});
        console.log('form reset!');
        this.props.onReset(event);
    }

    onInvalid(eventObj, props) {
        eventObj.preventDefault();
        console.log('onInvalid', eventObj, props);
        this.onSubmit(eventObj);
    }

    static isFieldValid(method, props) {
        if (method === 'required') {
            if (props.type === 'email') {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(props.value).toLowerCase());
            }
            return Array.isArray(props.value) ? props.value.length > 0 : typeof(props.value) === 'string' && props.value.trim() !== '';
        }
    }

    validate(props, is_array) {
        let isValid = true;
        let field_name = is_array ? props.name.match(/^(.*)\.([^\.]+)$/)[1] : props.name;
        if (props["data-error-field-id"])
            field_name = props["data-error-field-id"];
        if (props.required) {
            let value = is_array ? props["bound-state-obj"] : props["bound-state-obj"][props["bound-state-obj-field"]];
            isValid = ErjForm.isFieldValid('required', {value, type: props.type});
            if (isValid)
                this.state.fieldValidations = {...this.state.fieldValidations, [field_name]: {props, failed: false}};
            else
                this.state.fieldValidations = {
                    ...this.state.fieldValidations,
                    [field_name]: {props, failed: 'required'}
                };
            this.setState(this.state);
        }
        if (props["data-custom-validate"]) {
            let methods = Object.keys(props["data-custom-validate"]);
            for (let method of methods) {
                isValid = props["data-custom-validate"][method](props["bound-state-obj"][props["bound-state-obj-field"]], props["bound-state-obj"]);
                if (isValid)
                    this.state.fieldValidations = {
                        ...this.state.fieldValidations,
                        [field_name]: {props, failed: false}
                    };
                else {
                    this.state.fieldValidations = {
                        ...this.state.fieldValidations,
                        [field_name]: {props, failed: method}
                    };
                    break;
                }
            }

            this.setState(this.state);
        }

        return isValid;
    }

    mountDomOnEvent(props, event_name) {
        let dom_event_name = event_name === 'toggle' ? (props.type !== 'checkbox' ? 'click' : 'change') : event_name;
        let domEvent = 'on' + dom_event_name.charAt(0).toUpperCase() + dom_event_name.slice(1);
        let original_func = null;
        let func = (e) => {
            if (this['on' + event_name.charAt(0).toUpperCase() + event_name.slice(1)])
                this['on' + event_name.charAt(0).toUpperCase() + event_name.slice(1)](e, props);
            original_func && original_func(e, props, this.state.formData);
        };
        if (props[domEvent] && props[domEvent].toString() !== func.toString())
            original_func = props[domEvent];
        props[domEvent] = func;
    }

    collectInputs(rootStateObj, parentStateObj, stage) {
        return child => {
            if (!child || typeof(child) !== 'object')
                return child;
            let currStateObj = parentStateObj;
            let props = {...child.props};
            if (child.props.name || child.props["data-bind-on-change"]) {
                // console.log('in collectInputs',parentStateObj,stage,props,child.props);
                let field_name = child.props.name ? child.props.name : child.props["data-bind-on-change"];
                let path = field_name.match(/([a-zA-Z_0-9]+(\[(?:[0-9]+)?\])?)+/g);
                for (let k = 0; k < path.length; k++) {
                    let key = path[k];
                    if (k === path.length - 1) {
                        if (stage === 'init' || typeof(this.props.children) === 'function') {
                            props["bound-state-obj"] = currStateObj;
                            if (Array.isArray(currStateObj)) {
                                if (stage === 'init') {
                                    rootStateObj.fieldValidations[child.props.name.match(/^(.*)\.([^\.]+)$/)[1]] = {
                                        props,
                                        failed: false
                                    };
                                    props.value = Number(key) == key && key !== '' ? Number(key) : key;
                                    if (child.props["aria-checked"])
                                        currStateObj.push(props.value);
                                    if (child.props.type === 'checkbox' && child.props["checked"])
                                        currStateObj.push(props.value);
                                }

                                this.mountDomOnEvent(props, child.props.type === 'button' ? 'toggle' : 'change');
                            }
                            else {
                                if (stage === 'init') {
                                    rootStateObj.fieldValidations[child.props.name] = {props, failed: false};
                                    if (child.props.type && child.props.type === 'checkbox')
                                        currStateObj[key] = child.props.checked ? 1 : 0;
                                    else if (typeof(child.props.value) !== 'undefined')
                                        currStateObj[key] = child.props.value;
                                }

                                props["bound-state-obj-field"] = key;
                                this.mountDomOnEvent(props, 'change');
                                this.mountDomOnEvent(props, 'invalid');
                                if (child.props.type === 'button')
                                    this.mountDomOnEvent(props, 'click');
                            }

                            if (child.props["data-attach-props-to-events"]) {
                                for (let event of child.props["data-attach-props-to-events"])
                                    this.mountDomOnEvent(props, event);
                            }
                        }

                        if (child.props.name) {
                            let classNames = props.className ? props.className.split(' ') : [];
                            let validation_field = props.name;
                            if (Array.isArray(currStateObj)) {
                                let value = Number(key) == key && key !== '' ? Number(key) : key;
                                let checkedAttr = props.type === 'checkbox' ? 'checked' : "aria-checked";
                                if (currStateObj.indexOf(value) > -1) {
                                    props[checkedAttr] = true;
                                    classNames.push('selected');
                                }
                                else if (props[checkedAttr]) {
                                    props[checkedAttr] = false;
                                    // console.log(JSON.stringify(classNames),props.className,child.props.className);
                                    if (classNames.indexOf('selected') > -1) {
                                        classNames.splice(classNames.indexOf('selected'), 1);
                                    }
                                    // console.log(JSON.stringify(classNames));
                                }
                                validation_field = props.name.match(/^(.*)\.([^\.]+)$/)[1];
                            }
                            else {
                                if (child.props.type && child.props.type === 'checkbox')
                                    props.checked = (currStateObj[key] === 1);
                                else
                                    props.value = currStateObj[key];
                            }

                            if (props["data-bind-prop-to-value"])
                                props[props["data-bind-prop-to-value"]] = props.value;

                            if (this.state.fieldValidations[validation_field] && this.state.fieldValidations[validation_field].failed)
                                classNames.push('is-invalid');
                            else if (classNames.indexOf('is-invalid') > -1)
                                classNames.splice(classNames.indexOf('is-invalid'), 1);

                            if (classNames.length > 0 || props.className)
                                props.className = classNames.join(' ');
                        }
                    }
                    else if (key.indexOf('[') > -1) {
                        let field = key.substr(0, key.indexOf('['));
                        if (!currStateObj[field])
                            currStateObj[field] = [];
                        currStateObj = currStateObj[field];

                        let arrIndex = key.match(/[a-zA-Z_0-9]+\[([0-9]+)\]/);
                        if (arrIndex) {
                            arrIndex = parseInt(arrIndex[1]);
                            currStateObj[arrIndex] = {};
                            currStateObj = currStateObj[arrIndex];
                        }
                    }
                    else // object
                    {
                        if (!currStateObj[key])
                            currStateObj[key] = {};
                        currStateObj = currStateObj[key];
                    }
                }


                // currStateObj = currStateObj[child.props.name]; // do on arrayed names
            }
            else if (child.props["data-error-msg-field"]) {
                let error_reason = this.state.fieldValidations[child.props["data-error-msg-field"]] && this.state.fieldValidations[child.props["data-error-msg-field"]].failed;
                if (error_reason) {
                    props.hidden = false;
                    props.children = props["data-" + error_reason + "-msg"];
                }
                else {
                    props.hidden = true;
                    props.children = '';
                }
            }
            else if (child.props["data-error-marked-field"]) {
                let classNames = props.className ? props.className.split(' ') : [];
                let error_reason = this.state.fieldValidations[child.props["data-error-marked-field"]] && this.state.fieldValidations[child.props["data-error-marked-field"]].failed;
                if (error_reason)
                    classNames.push('is-invalid');
                else if (classNames.indexOf('is-invalid') > -1)
                    classNames.splice(classNames.indexOf('is-invalid'), 1);
                if (classNames.length > 0 || props.className)
                    props.className = classNames.join(' ');
            }

            if (child.props.children && typeof(child.props.children) === 'object') {
                let children = Array.isArray(child.props.children) ? child.props.children : [child.props.children];
                // console.log(props,children);
                props.children = React.Children.map(children, this.collectInputs(rootStateObj, currStateObj, stage).bind(this));
                if (props.children.length === 1)
                    props.children = props.children[0];
            }
            return React.cloneElement(child, props);
        };
    }

    render() {
        console.log('render');
        return (
            <form {...this.rootElmProps} onSubmit={this.onSubmit.bind(this)} onReset={this.onReset.bind(this)}
                  ref="formElm">
                {React.Children.map(typeof(this.props.children) === 'function' ? this.props.children(this.state.formData) : this.children, this.collectInputs(this.state, this.state.formData, 'render').bind(this))}
            </form>
        );
    }
}

export default ErjForm;