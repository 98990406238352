import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';

export default class ErjScroll extends Component {

    componentDidMount() {
        if (this.props.scrollOnOpen) {
            const selectedOption = document.querySelector('.erjScroll .scrollOnOpen');
            if (selectedOption) {
                this.refs.scrollbars.scrollTop(Math.max(0, selectedOption.offsetTop - selectedOption.offsetHeight));
            }
        }
    }

    scrollToBottom = () => {
        this.refs.scrollbars.scrollToBottom();
    };
    scrollToTop = () => {
        this.refs.scrollbars.view.scroll ? this.refs.scrollbars.view.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        }) : this.refs.scrollbars.scrollToTop();
    };

    render() {
        const {isDisabled, scrollOnOpen, ...props} = this.props;
        return !isDisabled ? (
            <Scrollbars {...props} ref={"scrollbars"} className={"erjScroll"} hideTracksWhenNotNeeded={true}
                        renderTrackVertical={({style, ...props}) => <div {...props} style={{...style}}
                                                                         className="track-vertical"/>}
                        renderThumbVertical={({style, ...props}) => <div {...props} style={{...style}}
                                                                         className="thumb-vertical"/>}
                        renderTrackHorizontal={({style, ...props}) => <div {...props} style={{...style}}
                                                                           className="track-horizontal"/>}
                        renderThumbHorizontal={({style, ...props}) => <div {...props} style={{...style}}
                                                                           className="thumb-horizontal"/>}
                        renderView={props => <div {...props} className="view"/>}>
                {props.children}
            </Scrollbars>
        ) : props.children;
    }
}