import React from 'react';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {makeStyles, Menu} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        backgroundColor: ({isSubMenuOpen}) => isSubMenuOpen ? theme.palette.action.hover : "transparent",
        minWidth: "12rem"
    },
    contentContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    name: {
        paddingRight: 6
    },
    expandIcon: {
        fontSize: 12
    }
}));

const NestedMenuItem = React.forwardRef(({
                                             id: parentId,
                                             title: parentTitle,
                                             lvl,
                                             childrenItems: parentChildrenItems = [],
                                             onClick,
                                             path,
                                             menuClassName
                                         }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isSubMenuOpen = Boolean(Boolean(anchorEl));
    const classes = useStyles({isSubMenuOpen});
    const hasChildrenItems = (parentChildrenItems === null || parentChildrenItems === void 0 ? void 0 : parentChildrenItems.length) || false;
    const isLeafNode = !hasChildrenItems;
    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        event.stopPropagation();
        if (isLeafNode) {
            onClick(parentId, path);
        }
    };
    return (
        <MenuItem
            ref={ref}
            disableRipple
            className={classes.menuItem}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleClose}
        >
            <div className={classes.contentContainer}>
                <span className={classes.name}>
                    {lvl > 0 && _.fill(Array(lvl), true).map((item, index) => (
                        <span key={index}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    ))}
                    {parentTitle}
                </span>
                {hasChildrenItems && (
                    <ArrowForwardIosIcon className={classes.expandIcon}/>
                )}
            </div>
            {hasChildrenItems && (
                <>
                    <Menu
                        // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
                        style={{pointerEvents: "none"}}
                        anchorEl={anchorEl}
                        open={isSubMenuOpen}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        PaperProps={{
                            style: {pointerEvents: "auto"},
                            elevation: 4
                        }}
                        MenuListProps={{
                            className: 'erjButtonMenu ' + menuClassName
                        }}
                    >
                        <div>
                            {parentChildrenItems.map((item) => {
                                const {id, title, children} = item;
                                return (
                                    <NestedMenuItem
                                        key={id}
                                        id={id}
                                        title={title}
                                        childrenItems={children}
                                        menuClassName={menuClassName}
                                        onClick={onClick}
                                        path={path ? path.concat(parentId) : [parentId]}
                                    />
                                );
                            })}
                        </div>
                    </Menu>
                </>
            )}
        </MenuItem>
    );
});

const ErjButtonMenu = React.forwardRef(({
                                            title,
                                            options = [],
                                            isSkipMenuForSingleOption,
                                            className,
                                            menuClassName,
                                            disabled,
                                            onClick,
                                            containerEl,
                                            defaultAnchorPosition = 'bottom',
                                            ...btnProps
                                        }, ref) => {

    const isMenuEnabled = options.length > 0 && !(options.length === 1 && isSkipMenuForSingleOption);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        if (!isMenuEnabled) {
            onClick(options[0] && options[0].id);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemClick = (id, path) => {
        setAnchorEl(null);
        onClick(id, path);
    };


    return (
        <>
            <Button
                color="primary"
                size="small"
                aria-controls={open ? 'button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                className={className}
                disabled={disabled}
                onClick={handleClick}
                ref={ref}
                {...btnProps}
            >
                <span className={'title'}>
                    {title}
                </span>
                {isMenuEnabled && (
                    <ArrowDropDownIcon/>
                )}
            </Button>
            {isMenuEnabled && (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: defaultAnchorPosition,
                        horizontal: "left"
                    }}
                    transformOrigin={{
                        vertical: defaultAnchorPosition === "bottom" ? "top" : "bottom",
                        horizontal: "left"
                    }}
                    MenuListProps={{
                        className: 'erjButtonMenu ' + menuClassName
                    }}
                    PaperProps={{
                        elevation: 4
                    }}
                >
                    {options.map((item) => {
                        const {id, title, lvl, path, children} = item;
                        return (
                            <NestedMenuItem
                                key={id}
                                id={id}
                                title={title}
                                lvl={lvl}
                                path={path}
                                childrenItems={children}
                                onClick={handleItemClick}
                                menuClassName={menuClassName}
                            />
                        );
                    })}
                </Menu>
            )}
        </>
    )
});

ErjButtonMenu.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any.isRequired,
            title: PropTypes.any.isRequired,
            disabled: PropTypes.bool,
            children: PropTypes.array
        })
    ).isRequired,
    className: PropTypes.string,
    disabled: PropTypes.any,
    onClick: PropTypes.func.isRequired
};

export default ErjButtonMenu;