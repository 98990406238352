import React, {Component, Fragment} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import "./summary-widgets.css";
import MetricValueDisplay from "../misc/MetricValueDisplay";
import {
    getCurrentCommissionPlans,
    makeGetCommissionResults,
    makeGetCommissionSummary
} from "../../selectors/commissionsSelector";
import {connect} from "react-redux";

import {t} from 'react-i18nify'
import {UNIT_TYPE} from "../../utils/metrics";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {getPreviousCommissions} from "../../actions/commissions";
import ViewPort from "../misc/Viewport";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import ErjScroll from "../../components/controls/ErjScroll";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";

const PreviousCommissionsModal = ({modal_data}) => {
    return (
        <ViewPort>
            {({mobile}) => {
                return (
                    <div className={'post post_commissions'}>
                        {!mobile && (
                            <div className={'postHeader'}>
                                <div className={'postHeaderIcon'}>
                                    <AccountBalanceIcon className={'commissionsIcon'}/>
                                </div>
                                <div className={'postHeaderTitle'}>
                                    <div className={'post-header'}>
                                        <div className={'post-title'}>{t('commissions.commissions_summary')}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {mobile && (
                            <NavigationBar onClose={() => this.props.onClose()} title={t('feeds.commissions_summary')}/>
                        )}
                        <div className={'postBody commissionsModal'}>
                            <div className={'summaryTable'}>
                                <div className={'summaryHeader'}>
                                    <div className={'title'}>
                                        <span className={'primary'}>{t('commissions.previous_months')}</span>
                                    </div>
                                </div>
                                <ErjScroll>
                                    <div className={'summaryBody'}>
                                        <div className={'summaryCol'}>
                                            {modal_data.length ? (
                                                modal_data.map(({month, commission, is_final}) => (
                                                    <div className={'userRow'} key={'month_' + month}>
                                                        <div className={'details'}>
                                                            <div className={'value'}>
                                                                {month}
                                                                {!is_final && ' - ' + t('commissions.not_final')}
                                                            </div>
                                                            <div className={'name'}>
                                                                <MetricValueDisplay value={commission}
                                                                                    unitType={UNIT_TYPE.MONEY}
                                                                                    roundOpts={{decimals: 2}}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={'userRow'} key={'month_'}>
                                                    <div className={'details'}>
                                                        <div
                                                            className={'name'}>{t('commissions.no_previous_commissions')}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ErjScroll>
                            </div>
                        </div>
                    </div>);
            }}
        </ViewPort>
    );
}

class CommissionSummaryWidget extends Component {
    state = {
        isLoadingData: false,
        isModalOpen: false
    };

    loadPreviousCommissions = () => {
        this.setState({isLoadingData: true, isModalOpen: true});
        this.props.dispatch(getPreviousCommissions(() => {
            this.setState({isLoadingData: false})
        }))
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    render() {
        const {commission, predictedCommission, mobile, modal_data, is_manager} = this.props;
        return (
            <div>
                <Tooltip title={t('commissions.accrued_commission_tooltip')} disableHoverListener={is_manager}>
                    <button className={'summaryBtn commission'}
                            onClick={is_manager ? () => this.props.history.push(process.env.REACT_APP_ROUTE_PATH + "/commissions/management") : this.loadPreviousCommissions}>
                        <AccountBalanceIcon/>
                        <div className={'benefitContent'}>
                            <div className={'benefitValue'}>
                                <MetricValueDisplay value={commission || 0} shortNumOpts={{shortThreshold: 10000}}
                                                    roundOpts={{decimals: 2}} unitType={UNIT_TYPE.MONEY}/>
                            </div>
                            <div
                                className={'benefitLabel'}>{t('commissions.accrued_commission') + ' (' + t('commissions.not_final') + ')'}</div>
                        </div>
                        <div className={'benefitPrediction localText'}>
                            {t('commissions.month_end_prediction') + ': '}
                            <strong><MetricValueDisplay value={predictedCommission || 0}
                                                        shortNumOpts={{shortThreshold: 10000}} roundOpts={{decimals: 2}}
                                                        unitType={UNIT_TYPE.MONEY}/></strong>
                        </div>
                    </button>
                </Tooltip>
                {!is_manager && (
                    <Modal
                        aria-labelledby="show_summary_table"
                        aria-describedby="show_summary_table"
                        className={'summaryModal' + (mobile ? ' mob' : '')}
                        open={this.state.isModalOpen}
                        onClose={this.closeModal}
                    >
                        <div className={'modalContent' + (mobile ? ' mobileModal' : '')}>
                            {this.state.isLoadingData ? (
                                <CircularProgress className="pageLoadProgressCircle" size={100}/>
                            ) : (
                                <div className={'summary-widget-modal'}>
                                    <PreviousCommissionsModal onClose={this.closeModal} modal_data={modal_data}/>
                                </div>
                            )}
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

const makeMapStateToProps = () => {
    const getCommissionSummary = makeGetCommissionSummary();
    const getCommissionResults = makeGetCommissionResults();
    return (state, props) => {
        const {auth, global} = state;

        let derivedState = {
            is_manager: auth.sessionData.is_manager,
        };

        if (derivedState.is_manager) {
            const {
                totalCommissions,
                totalPredictedCommissions
            } = getCommissionResults(state, {plans: getCurrentCommissionPlans(state, props), isCurrentMonth: true});
            derivedState.commission = totalCommissions;
            derivedState.predictedCommission = totalPredictedCommissions;
        } else {
            derivedState = {
                ...derivedState,
                ...getCommissionSummary(state, props)
            }
        }

        return derivedState;
    }
};

export default connect(makeMapStateToProps)(CommissionSummaryWidget);