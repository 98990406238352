import React, {Component, Fragment} from 'react';
import ReactTimeout from 'react-timeout';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import VolumeOnIcon from '@material-ui/icons/VolumeUp';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CurrentTime from './CurrentTime';
import BellIcon from '../../components/icons/BellIcon';
import CrownWithStarsIcon from '../../components/icons/CrownWithStarsIcon';
import EarlyWinnersIcon from '../../components/icons/EarlyWinnersIcon';
import TeamWorkIcon from '../../components/icons/TeamWorkIcon';
import PersonalChallengeIcon from '../../components/icons/PersonalChallengeIcon';
import WheelOfFortuneIcon from '../../components/icons/WheelOfFortuneIcon';
import DailyGoalIcon from '../../components/icons/DailyGoalIcon';
import ContestIcon from '../../components/icons/ContestIcon';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import {getNotifications} from "../../selectors/feedSelector";
import {notificationClickedByUser, setReadNotifications} from "../../actions/notifications";
import ErjScroll from "../../components/controls/ErjScroll";
import {DISPATCH_UI_ACTION} from "../../actions/controls";
import notifSound from "../../assets/notification.mp3";
import challengeStartNotifSound from "../../assets/challenge_start_notification.mp3";
import favIconAlert from "../../assets/favicon_alert.png";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {RECEIVED_USER_PREFERENCES, updateUserPreferences} from "../../actions/auth";
import {API_REQ_SUCCESS_INFO} from "../../actions/global";

import {t} from 'react-i18nify'

const _ = require('lodash');

const Notification = ({userId, notification, clickHandler}) => (
    <MenuItem
        className={`notificationRow ${notification.users_views && notification.users_views[userId] ? ' clicked' : ''}`}
        onClick={e => clickHandler(e, notification.primary_post_id || notification.post_path[0], notification.post, {
            isClicked: notification.users_views ? notification.users_views[userId] : 0,
            id: notification.id,
            userId
        })}>
        <div className={'notificationRowIcon'}>
            {(() => {
                switch (notification.post.key) {
                    case 'activity':
                    case 'activity_ended':
                    case 'activity_canceled':
                    case 'auto_checkin_reminder':
                    case 'activity_will_end_soon':
                    case 'reached_win_req_val':
                        let {activity} = notification;
                        return (
                            <div className={'activity-symbol'}>
                                {activity.activity_def_id === 2 && <WheelOfFortuneIcon/>}
                                {activity.activity_def_id === 8 && <EarlyWinnersIcon/>}
                                {activity.activity_def_id === 9 && <CrownWithStarsIcon/>}
                                {activity.activity_def_id === 13 && <ContestIcon/>}
                                {activity.activity_def_id === 14 && <TeamWorkIcon/>}
                                {activity.activity_def_id === 15 && (!activity.data || !activity.data.is_allow_declare_val) &&
                                <PersonalChallengeIcon/>}
                                {activity.activity_def_id === 15 && activity.data && activity.data.is_allow_declare_val &&
                                <DailyGoalIcon/>}
                            </div>
                        );
                    case 'shifts_schedule_updated':
                        return (
                            <div className={'activity-symbol'}>
                                <BellIcon/>
                            </div>
                        );
                    case 'commission_limit_alert':
                        return (
                            <div className={'activity-symbol'}>
                                <AccountBalanceIcon/>
                            </div>
                        );
                    default:
                        return <img src={notification.content.icon} alt={'event-icon'}/>
                }
            })()}
        </div>
        <div className={'notificationContentArea'}>
            <div className={'notificationTitle localText'}>{notification.content.title}</div>
            <div className={'notificationBody localText'}>{notification.content.body}</div>
            <div className={'notificationTimeAgo'}><CurrentTime format={'timeAgo'}
                                                                compareTime={notification.last_update}/></div>
        </div>
    </MenuItem>
);

class NotificationIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            lastMenuEventFired: new Date().getTime(),
            ...this.initState(props)
        };
        if (!window.originalTitle) {
            window.originalTitle = document.title;
            window.originalIcon = document.querySelector("link[rel*='icon']").href;
        }
    }

    initState(props) {
        const {notifications, user_id} = props;
        let unreadNotifications = notifications.filter(notification => !notification.users_views || notification.users_views[user_id] === undefined);
        let readNotifications = notifications.filter(notification => notification.users_views && notification.users_views[user_id] !== undefined);
        let unreadFeedbackNotifications = unreadNotifications.filter(notification => ['Booster_Feedback', 'personal_Feedback', 'end_Activity_Feedback'].indexOf(notification.post.key) > -1);
        if (unreadFeedbackNotifications.length) {
            this.props.dispatch(setReadNotifications(unreadFeedbackNotifications.map(notification => notification.id)));
            this.props.dispatch({
                type: API_REQ_SUCCESS_INFO,
                customMsg: (
                    <div className={'feedbackSuccessMsg'}>
                        <div className={'notificationRowIcon'}>
                            <img src={unreadFeedbackNotifications[0].content.icon} alt={'event-icon'}/>
                        </div>
                        <div className={'content localText'}>
                            <div className={'title'}>{unreadFeedbackNotifications[0].content.title}</div>
                            <div className={'body'}>
                                {unreadFeedbackNotifications[0].content.body}
                            </div>
                        </div>
                    </div>
                )
            });
        }
        return {notifications, unreadNotifications, readNotifications, numUnread: unreadNotifications.length};
    }

    componentWillReceiveProps(nextProps) {
        const newState = this.initState(nextProps);
        if (newState.numUnread > this.state.numUnread) {
            // this.refs.notifSound.volume = 0.01;
            if (!this.props.preferences || !this.props.preferences.muteNotifs) {
                try {
                    this.refs.notifSound.src = newState.unreadNotifications[0].post.key === 'activity' ? challengeStartNotifSound : notifSound;
                    const playAction = this.refs.notifSound.play();
                    playAction && playAction.catch && playAction.catch((err) => {
                        console.info('could not play notif sound', err);
                    });
                } catch (err) {
                    console.info('could not play notif sound', err);
                }
            }
            this.props.clearTimeout(window.animateBrowserTabTimeout);
            window.animateBrowserTabTimeout = this.props.setTimeout(() => {
                this.animateBrowserTab();
            }, 1000);
        } else if (!this.state.numUnread && document.title !== window.originalTitle) {
            let link = document.querySelector("link[rel*='icon']");
            link.href = window.originalIcon;
            document.title = window.originalTitle
        }
        this.setState(newState);
    }

    animateBrowserTab = () => {
        let link = document.querySelector("link[rel*='icon']");
        link.href = favIconAlert;
        let numUnreadPrefix = this.state.numUnread ? `(${this.state.numUnread}) ` : ``;
        document.title = numUnreadPrefix + t('global.new_notification') + ' - ' + window.originalTitle;
        this.props.setTimeout(() => {
            link.href = window.originalIcon;
            document.title = numUnreadPrefix + window.originalTitle;
            if (this.state.numUnread > 0)
                window.animateBrowserTabTimeout = this.props.setTimeout(() => {
                    this.animateBrowserTab();
                }, 1000);
        }, 1000);
    };

    handleMenuClick = event => {
        // this.refs.notificationsMenuContainer.childNodes[0].style.left = ReactDOM.findDOMNode(this.refs.notificationsMenuButton).offsetLeft+'px';
        if (new Date().getTime() > this.state.lastMenuEventFired + 500) //wait 500ms after last menu event, this is to allow closing if clicking again on menu button
        {
            if (this.state.numUnread > 0)
                this.props.dispatch(setReadNotifications(this.state.unreadNotifications.map(notif => notif.id)));
            this.setState({menuOpen: true, lastMenuEventFired: new Date().getTime(), numUnread: 0});
        }
    };

    handleMenuClose = (event) => {
        if (event && event.target.tagName !== 'HTML' && this.state.menuOpen)
            this.setState({menuOpen: false, lastMenuEventFired: new Date().getTime()});
    };

    handleNotificationClick = (event, postId, originalPost, notificationParams) => {
        switch (originalPost.key) {
            case 'shifts_schedule_updated':
                this.props.history.push(process.env.REACT_APP_ROUTE_PATH + '/work-schedule');
                this.props.dispatch({
                    type: DISPATCH_UI_ACTION,
                    key: 'openWorkSchedule',
                    params: {data: originalPost.data}
                });
                break;
            case 'on_new_order':
                this.props.history.push(process.env.REACT_APP_ROUTE_PATH + '/manage-store/orders');
                break;
            default:
                this.props.history.push(process.env.REACT_APP_ROUTE_PATH + '/');
                this.props.dispatch({
                    type: DISPATCH_UI_ACTION,
                    key: 'goToPost',
                    params: {switchTo: originalPost.is_archive ? 'archive' : 'inbox', postId, originalPost}
                });
        }
        if (!notificationParams.isClicked) {
            this.props.dispatch(notificationClickedByUser(notificationParams));
        }
        this.setState({menuOpen: false});
    };

    onUpdateMuteNotifications = (isMute) => {
        const preferences = this.props.preferences ? {...this.props.preferences} : {};
        preferences.muteNotifs = isMute;
        this.props.dispatch(updateUserPreferences(preferences));
        this.props.dispatch({type: RECEIVED_USER_PREFERENCES, preferences});
    };

    render() {
        const {menuOpen, unreadNotifications, readNotifications, numUnread} = this.state;
        const {user_id, preferences} = this.props;
        const muteNotifs = preferences && preferences.muteNotifs;
        return (
            <span className="notificationsMenuButtonArea" ref="notificationsMenuButton">
                <Button className={"menuItem"} onClick={this.handleMenuClick}
                        buttonRef={node => this.anchorEl = node}
                        classes={{label: "menuItemLabel"}}>
                    <Icon color="primary" className={'notification-navButton icon- menuIcon'}>
                        {numUnread > 0 && (
                            <div className={'numUnread'}>{numUnread}</div>
                        )}
                        notification
                        <audio ref={'notifSound'}/>
                    </Icon>
                    <span className={"menuItemLabel"}>{t('global.notifications')}</span>
                </Button>
                <div ref="notificationsMenuContainer" className="notificationsMenuContainer">
                    <Popper className={'menu'} open={menuOpen} anchorEl={this.anchorEl} placement="top-end" transition
                            disablePortal={true}
                            modifiers={{preventOverflow: {enabled: false}, hide: {enabled: false}}}>
                        {({TransitionProps, placement}) => (
                            <Grow {...TransitionProps}>
                                <Paper className="notificationsMenu">
                                    <ClickAwayListener onClickAway={this.handleMenuClose}>
                                        <div>
                                            <div className={'notificationsList'}>
                                                <ErjScroll>
                                                    {unreadNotifications.length > 0 && (
                                                        <Fragment>
                                                            <div
                                                                className={'notificationsSectionTitle'}>{t('global.new')}</div>
                                                            <MenuList role="menu">
                                                                {unreadNotifications.map(notification => notification.content &&
                                                                    <Notification userId={user_id}
                                                                                  notification={notification}
                                                                                  key={notification.id}
                                                                                  clickHandler={this.handleNotificationClick}/>)}
                                                            </MenuList>d
                                                        </Fragment>
                                                    )}
                                                    {readNotifications.length > 0 && (
                                                        <Fragment>
                                                            <div
                                                                className={'notificationsSectionTitle'}>{t('global.earlier')}</div>
                                                            <MenuList role="menu">
                                                                {readNotifications.map(notification => (
                                                                    <Notification userId={user_id}
                                                                                  key={notification.id}
                                                                                  notification={notification}
                                                                                  clickHandler={this.handleNotificationClick}/>
                                                                ))}
                                                            </MenuList>
                                                        </Fragment>
                                                    )}
                                                </ErjScroll>
                                            </div>
                                            <div className={'notificationsSettingsRow'}>
                                                <Tooltip title={t('global.toggle_mute_notifications')}>
                                                    <IconButton className={'muteNotifSoundBtn'}
                                                                onClick={() => this.onUpdateMuteNotifications(!muteNotifs)}>
                                                        <VolumeOnIcon
                                                            className={muteNotifs ? 'off' : 'on'}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </span>
        )
    }
}


const mapStateToProps = (state, props) => {
    const {auth} = state;

    let notifications = getNotifications(state, props);
    return {
        notifications,
        user_id: auth.sessionData && auth.sessionData.user_id,
        preferences: auth.accountData && auth.accountData.preferences
    };
};

export default withRouter(connect(mapStateToProps)(ReactTimeout(NotificationIcon)));