import React from 'react';
import ReactDOM from 'react-dom';
import ErjConfirm from "../../components/controls/ErjConfirm";

import {t} from 'react-i18nify'

export const getUserConfirmation = (message, callback) => showUserConfirmation({
    title: t(`erj_confirm.you_are_going_to_leave_this_page`),
    body: t(`erj_confirm.${message}`),
    confirmBtnText: t('erj_confirm.leave'),
    cancelBtnText: t('erj_confirm.stay'),
}, callback);

export const showUserConfirmation = ({title, body, confirmBtnText, cancelBtnText}, callback) => {
    const modal = document.createElement('div');
    document.body.appendChild(modal);

    const withCleanup = (answer) => {
        document.body.removeChild(modal);
        callback(answer)
    };

    ReactDOM.render(
        <ErjConfirm title={title}
                    body={body}
                    confirmBtnText={confirmBtnText} cancelBtnText={cancelBtnText}
                    confirmCB={() => withCleanup(true)}
                    open={true}
                    close={() => withCleanup(false)}
                    dialogProps={{className: "ErjConfirm localText aligned prompt"}}
                    confirmBtnProps={{variant: "outlined"}} cancelBtnProps={{variant: "contained"}}
        />,
        modal
    );
};