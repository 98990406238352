import React, {Component, Fragment} from 'react';
import {getUserImg} from "erj-common/generalUtils";
import {numberWithCommas, shortNum} from "../../utils/generalUtils";
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import "./summary-widgets.css";
import ErjScroll from "../../components/controls/ErjScroll";
import ViewPort from "../misc/Viewport";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import CoinsIcon from "../../components/icons/CoinsIcon";
import MetricValueDisplay from "../misc/MetricValueDisplay";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import {getLastPayout} from "../../actions/managerStore";
import {makeGetCoinsSummary} from "../../selectors/storeSelector";
import {connect} from "react-redux";
import {ExportToExcel} from "../../utils/exportUtils";

import {t} from 'react-i18nify'
import {UNIT_TYPE} from "../../utils/metrics";

export class TeamReceivedCoinsPost extends Component {
    render() {

        const {
            post,
            top_wallets,
            top_grants,
            total_wallets,
            total_grants,
            users,
            is_manager,
            isInSummary,
            user_id,
            lead_types,
            center_is_parent,
            centersDetails,
            centers_coins,
            centers_grants,
            employee_display_currency
        } = this.props;

        const excelColumns = [
            {
                title: t('team_coins.employee'),
                width: {wpx: 150},
                style: {
                    fill: {patternType: "solid", fgColor: {rgb: "FF6724C6"}},
                    font: {color: {rgb: "FFFFFFFF"}, bold: true}
                }
            },
            {
                title: t(employee_display_currency === 1 ? 'global.coins' : 'settings.unit_formats.money'),
                width: {wpx: 150},
                style: {
                    fill: {patternType: "solid", fgColor: {rgb: "FF6724C6"}},
                    font: {color: {rgb: "FFFFFFFF"}, bold: true}
                }
            }
        ];

        return (
            <ViewPort>
                {({mobile}) => {
                    return (<div className={'post post_' + post.key}>
                        {!mobile &&
                        <div className={'postHeader'}>
                            <div className={'postHeaderIcon'}>
                                <CoinsIcon/>
                            </div>
                            <div className={'postHeaderTitle'}>
                                <div className={'post-header'}>
                                    <div
                                        className={'post-title'}>{t(employee_display_currency === 1 ? 'feeds.coins_summary' : 'feeds.budget_payout')}</div>
                                </div>
                            </div>
                            {!center_is_parent && (
                                <div className={'exportToExcelLink'}>
                                    <ExportToExcel
                                        fileName={t(employee_display_currency === 1 ? 'feeds.coins_summary' : 'feeds.budget_payout')}
                                        sheets={[
                                            {
                                                sheetName: t('feeds.top_savers'),
                                                columns: excelColumns,
                                                rows: top_wallets.filter(wallet => users[wallet.user_id])
                                                    .map(wallet => [
                                                        {value: users[wallet.user_id].first_name + ' ' + users[wallet.user_id].last_name},
                                                        {
                                                            value: (employee_display_currency === 1 ? wallet.enercoins : wallet.enercoins / 7) || 0,
                                                            style: {numFmt: "#,##0"}
                                                        }
                                                    ]).concat([[
                                                        {
                                                            value: t(employee_display_currency === 1 ? 'feeds.total_coins' : 'feeds.total_money'),
                                                            style: {font: {bold: true}}
                                                        },
                                                        {
                                                            value: employee_display_currency === 1 ? total_wallets : total_wallets / 7,
                                                            style: {numFmt: "#,##0", font: {bold: true}}
                                                        }
                                                    ]])
                                            },
                                            {
                                                sheetName: t('feeds.top_added'),
                                                columns: excelColumns,
                                                rows: top_grants.filter(grant => users[grant.user_id])
                                                    .map(grant => [
                                                        {value: users[grant.user_id].first_name + ' ' + users[grant.user_id].last_name},
                                                        {
                                                            value: (employee_display_currency === 1 ? grant.enercoins : grant.enercoins / 7) || 0,
                                                            style: {numFmt: "#,##0"}
                                                        }
                                                    ]).concat([[
                                                        {
                                                            value: t(employee_display_currency === 1 ? 'feeds.total_coins' : 'feeds.total_money'),
                                                            style: {font: {bold: true}}
                                                        },
                                                        {
                                                            value: employee_display_currency === 1 ? total_grants : total_grants / 7,
                                                            style: {numFmt: "#,##0", font: {bold: true}}
                                                        }
                                                    ]])
                                            }
                                        ]}
                                        buttonText={(
                                            <button className={'export-to-excel localText'}>
                                                {t('global.export_to_excel')}
                                            </button>
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                        }
                        {mobile && (
                            <NavigationBar onClose={() => this.props.onClose()}
                                           title={t(employee_display_currency === 1 ? 'feeds.coins_summary' : 'feeds.budget_payout')}/>
                        )}
                        <div className={'postBody coinsModal'}>
                            <div className={'summaryTable'}>
                                <div className={'summaryHeader'}>
                                    <div className={'title'}>
                                        <span className={'primary'}>{t('feeds.top_savers')}</span>
                                        <span className={'secondary'}>
                                            {employee_display_currency === 1 ? (
                                                t('feeds.total_coins_val', {coins: numberWithCommas(total_wallets)})
                                            ) : (
                                                <>
                                                    {t('feeds.total') + ': '}<MetricValueDisplay
                                                    value={total_wallets / 7} unitType={UNIT_TYPE.MONEY}
                                                    roundOpts={{decimals: 2}}/>
                                                </>
                                            )}
                                        </span>
                                    </div>
                                    <div className={'title'}>
                                        <span className={'primary'}>{t('feeds.top_added')}</span>
                                        <span className={'secondary'}>
                                            {employee_display_currency === 1 ? (
                                                t('feeds.total_coins_val', {coins: numberWithCommas(total_grants)})
                                            ) : (
                                                <>
                                                    {t('feeds.total') + ': '}<MetricValueDisplay
                                                    value={total_grants / 7} unitType={UNIT_TYPE.MONEY}
                                                    roundOpts={{decimals: 2}}/>
                                                </>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <ErjScroll isDisabled={!isInSummary}>
                                    <div className={'summaryBody'}>
                                        <div className={'summaryCol'}>
                                            {center_is_parent ? (
                                                centers_coins.map(wallet => centersDetails[wallet.center_id] && (
                                                    <div className={'userRow'} key={'wallet_' + wallet.center_id}>
                                                        <img className={'userImg'}
                                                             src={getUserImg(centersDetails[wallet.center_id].image_uri, 'xxs', true)}
                                                             alt={centersDetails[wallet.center_id].title}/>
                                                        <div className={'details'}>
                                                            <div
                                                                className={'name'}>{centersDetails[wallet.center_id].title}</div>
                                                            <div className={'value'}>
                                                                {employee_display_currency === 1 ? (
                                                                    t('feeds.coins_coins', {coins: wallet.enercoins || 0})
                                                                ) : (
                                                                    <MetricValueDisplay value={wallet.enercoins / 7}
                                                                                        unitType={UNIT_TYPE.MONEY}
                                                                                        roundOpts={{decimals: 2}}/>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                top_wallets.map(wallet => users[wallet.user_id] && (
                                                    <div className={'userRow'} key={'wallet_' + wallet.user_id}>
                                                        <img className={'userImg'}
                                                             src={getUserImg(users[wallet.user_id].user_img)}
                                                             alt={users[wallet.user_id].first_name}/>
                                                        <div className={'details'}>
                                                            <div
                                                                className={'name'}>{users[wallet.user_id].first_name}</div>
                                                            <div className={'value'}>
                                                                {employee_display_currency === 1 ? (
                                                                    t('feeds.coins_coins', {coins: wallet.enercoins || 0})
                                                                ) : (
                                                                    <MetricValueDisplay
                                                                        value={(wallet.enercoins / 7) || 0}
                                                                        unitType={UNIT_TYPE.MONEY}
                                                                        roundOpts={{decimals: 2}}/>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                        <div className={'summaryCol'}>
                                            {center_is_parent ? (
                                                centers_grants.map(grant => centersDetails[grant.center_id] && (
                                                    <div className={'userRow'} key={'grant_' + grant.center_id}>
                                                        <img className={'userImg'}
                                                             src={getUserImg(centersDetails[grant.center_id].image_uri, 'xxs', true)}
                                                             alt={centersDetails[grant.center_id].title}/>
                                                        <div className={'details'}>
                                                            <div
                                                                className={'name'}>{centersDetails[grant.center_id].title}</div>
                                                            <div className={'value'}>
                                                                {employee_display_currency === 1 ? (
                                                                    t('feeds.coins_coins', {coins: grant.enercoins || 0})
                                                                ) : (
                                                                    <MetricValueDisplay
                                                                        value={(grant.enercoins / 7) || 0}
                                                                        unitType={UNIT_TYPE.MONEY}
                                                                        roundOpts={{decimals: 2}}/>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                top_grants.map(grant => users[grant.user_id] && (
                                                    <div className={'userRow'} key={'grant_' + grant.user_id}>
                                                        <img className={'userImg'}
                                                             src={getUserImg(users[grant.user_id].user_img)}
                                                             alt={users[grant.user_id].first_name}/>
                                                        <div className={'details'}>
                                                            <div
                                                                className={'name'}>{users[grant.user_id].first_name}</div>
                                                            <div className={'value'}>
                                                                {employee_display_currency === 1 ? (
                                                                    t('feeds.coins_coins', {coins: grant.enercoins || 0})
                                                                ) : (
                                                                    <MetricValueDisplay
                                                                        value={(grant.enercoins / 7) || 0}
                                                                        unitType={UNIT_TYPE.MONEY}
                                                                        roundOpts={{decimals: 2}}/>
                                                                )}
                                                            </div>
                                                            {grant.grant_status === 3 && grant.data && grant.data.unmet_conditions && (is_manager || grant.user_id === user_id) && (
                                                                <Tooltip
                                                                    className={'no_received_coins_reason localText aligned'}
                                                                    classes={{tooltip: 'no_received_coins_reasonTooltip'}}
                                                                    placement={"top"}
                                                                    title={(
                                                                        <Fragment>
                                                                            <div
                                                                                className={'no_received_coins_reason_title'}>{t('feeds.reasons_for_denied_payout') + ':'}</div>
                                                                            {grant.data.unmet_conditions.map(({
                                                                                                                  conditionKey,
                                                                                                                  metric_id,
                                                                                                                  metricTitle,
                                                                                                                  condition,
                                                                                                                  actual
                                                                                                              }) => {
                                                                                if (!metricTitle) {
                                                                                    metricTitle = lead_types[metric_id].title;
                                                                                }
                                                                                if (['min_improve_ratio', 'min_goal_ratio'].indexOf(conditionKey) > -1) {
                                                                                    if (conditionKey === 'min_improve_ratio') {
                                                                                        condition -= 1;
                                                                                        actual -= 1;
                                                                                    }
                                                                                    condition = shortNum({num: condition * 100}) + '%';
                                                                                    actual = shortNum({
                                                                                        num: actual * 100,
                                                                                        decimals: 1
                                                                                    }) + '%';
                                                                                } else {
                                                                                    condition = shortNum({
                                                                                        shortThreshold: 1000000,
                                                                                        num: condition
                                                                                    });
                                                                                    actual = shortNum({
                                                                                        shortThreshold: 1000000,
                                                                                        num: actual
                                                                                    });
                                                                                }
                                                                                return (
                                                                                    <div
                                                                                        className={'conditionRow'}>{t('feeds.condition_unmet_reasons.' + conditionKey, {
                                                                                        metricTitle,
                                                                                        condition,
                                                                                        actual
                                                                                    })}</div>
                                                                                );
                                                                            })}
                                                                        </Fragment>
                                                                    )}>
                                                                    <Icon className={'icon- alert'}>alert</Icon>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </ErjScroll>
                            </div>
                        </div>
                    </div>);
                }}
            </ViewPort>
        );
    }
}

class CoinsSummaryWidget extends Component {
    state = {
        isLoadingData: false,
        isModalOpen: false
    };

    loadCoinsPostData = () => {
        if (this.props.disabledFeatures.pointsSummaryWindow) {
            return;
        }
        this.setState({isModalOpen: true, isLoadingData: true});
        this.props.dispatch(getLastPayout(() => this.setState({isLoadingData: false})));
    };

    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    render() {
        const {mobile, coins_post_data, userId, enercoins, isManager, employee_display_currency} = this.props;
        return (
            <>
                <Modal
                    aria-labelledby="show_summary_table"
                    aria-describedby="show_summary_table"
                    className={'summaryModal' + (mobile ? ' mob' : '')}
                    open={this.state.isModalOpen}
                    onClose={this.closeModal}
                >
                    <div className={'modalContent' + (mobile ? ' mobileModal' : '')}>
                        {this.state.isLoadingData ? (
                            <CircularProgress className="pageLoadProgressCircle" size={100}/>
                        ) : (
                            <div className={'summary-widget-modal'}>
                                <TeamReceivedCoinsPost onClose={this.closeModal} {...coins_post_data} user_id={userId}
                                                       employee_display_currency={employee_display_currency}/>
                            </div>
                        )}
                    </div>
                </Modal>
                <Tooltip
                    title={employee_display_currency === 1 ? t(isManager ? 'summary.total_employees_coins' : 'summary.your_coins') : ''}>
                    <button className={'summaryBtn coins'} onClick={this.loadCoinsPostData}>
                        <CoinsIcon/>
                        <div className={'benefitContent'}>
                            <div className={'benefitValue'}>
                                {employee_display_currency === 1 ? (
                                    <MetricValueDisplay value={enercoins || 0} shortNumOpts={{shortThreshold: 10000}}/>
                                ) : (
                                    <MetricValueDisplay value={enercoins / 7 || 0} shortNumOpts={{shortThreshold: 1000}}
                                                        unitType={UNIT_TYPE.MONEY} roundOpts={{decimals: 0}}/>
                                )}

                            </div>
                            <div className={'benefitLabel'}>
                                {t(employee_display_currency === 1 ? 'global.coins' : (
                                    isManager ? 'manager_store.employees_coins' : 'store.my_balance'
                                ))}
                            </div>
                        </div>
                    </button>
                </Tooltip>
            </>
        )
    }
}

const makeMapStateToProps = () => {
    const getCoinsSummary = makeGetCoinsSummary();
    return (state, props) => {
        const {auth, global} = state;

        return {
            ...getCoinsSummary(state, props),
            isManager: auth.sessionData.is_manager,
            userId: !auth.sessionData.is_manager && auth.userData.in_crm_id,
            employee_display_currency: auth.sessionData.employee_display_currency,
            disabledFeatures: global.disabledFeatures
        };
    }
};

export default connect(makeMapStateToProps)(CoinsSummaryWidget);