import React, {Component} from 'react';
import PropTypes from "prop-types";
import {t} from 'react-i18nify'
import Select from "react-select";
import 'react-select/dist/react-select.css';

const _ = require('lodash');


export default class ErjSelect extends Component {
    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
    }

    componentWillMount() {
        let {keyId, defaultOption, value, userId, name, namespace} = this.props;
        this.setState({
            value: typeof(defaultOption) !== 'undefined' ? defaultOption : value,
            id: keyId,
            userId: userId,
            name: name,
            namespace: namespace
        });
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            ...nextProps,
            value: typeof(nextProps.defaultOption) !== 'undefined' ? nextProps.defaultOption : nextProps.value
        });
    }

    change(event) {
        if (this.props.isMulti) {
            const values = event.map(({value}) => value);
            event = {
                target: {
                    name: this.props.name,
                    value: values
                }
            };
        }

        if (this.props.handleChange) {
            this.props.handleChange({
                order: this.state.id,
                value: event.target.value,
                user: this.state.userId,
                name: this.state.name,
                namespace: this.state.namespace
            });
        } else {
            this.props.onChange(event);
        }
        this.setState({value: event.target.value});
    }

    render() {
        const {options, permission, isDisable, className, name, isMulti} = this.props;
        let selectedOptionClass = options.filter(option => option.id === this.state.value + '')[0];
        return isMulti ? (
            <Select name={name}
                    className={"custom-multi-select" + (className ? ' ' + className : '') + (selectedOptionClass ? ' ' + selectedOptionClass.className : '')}
                    value={this.state.value}
                    disabled={(permission !== undefined && !permission) || isDisable}
                    onChange={(e) => this.change(e)}
                    options={options.map((option) => ({
                        value: option.id,
                        label: option.title.indexOf('.') > -1 && option.title.indexOf(' ') === -1 ? t(option.title) : option.title
                    }))}
                    multi={true}
                    searchable={false}
                    clearable={false}
                    placeholder={t('global.select')}
                    removeSelected={false}
                    backspaceRemoves={false}
                    inputRenderer={() => <span/>}
                    valueComponent={({children, ...props}) => {
                        if (props.values.length === 1) {
                            return (
                                <span className={'selectedValue'}>{props.value.label}</span>
                            );
                        } else if (props.values.indexOf(props.value) === 0) {
                            return (
                                <span
                                    className={'selectedValue'}>{t('global.num_selected', {num: props.values.length})}</span>
                            );
                        } else {
                            return <span/>;
                        }
                    }}
            />
        ) : (
            <select name={name}
                    className={"custom-select" + (className ? ' ' + className : '') + (selectedOptionClass ? ' ' + selectedOptionClass.className : '')}
                    value={this.state.value}
                    disabled={(permission !== undefined && !permission) || isDisable}
                    onChange={(e) => this.change(e)}>
                {options.map((option) => {
                    let optionProps = option.className ? {className: option.className} : {};
                    return (
                        <option key={option.id} value={option.id} disabled={option.disabled} {...optionProps}>
                            {option.lvl > 0 && _.fill(Array(option.lvl), true).map((item, index) => '|-- ')}
                            {option.title.indexOf('.') > -1 && option.title.indexOf(' ') === -1 ? t(option.title) : option.title}
                        </option>
                    );
                })}
            </select>
        );
    }
}

ErjSelect.propTypes = {
    defaultOption: PropTypes.any,
    value: PropTypes.any,
    keyId: PropTypes.number,
    userId: PropTypes.number,
    name: PropTypes.string,
    namespace: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    permission: PropTypes.bool,
    isMulti: PropTypes.bool,
    isDisable: PropTypes.bool
};