import {
    createSelectorWithDependencies as createSelector
} from 'reselect-tools'
import {generateNotificationContent, MAX_CHECK_IN_TIME_PERCENT} from "erj-common/generalUtils";
import moment from "moment";
import {getActivityLeaderBoards, getActivityLeaders, isAllowCheckin} from "../utils/activities";
import {getChildCentersMenu} from "../utils/generalUtils";
import {getLocale} from "react-i18nify";

const _ = require('lodash');

const getCurrentDate = (state, props) => state.global.currentDate;
const getActivityHubFeeds = (state, props) => state.feeds.activity;
const getPublicFromFeeds = (state, props) => state.feeds.public;
const getPrivateFromFeeds = (state, props) => state.feeds.private;
const getActivities = (state, props) => state.activities;
const getNotificationsFromState = (state, props) => state.notifications.notifications;
const getUsers = (state, props) => state.users.users;
const getOrgUsers = (state, props) => state.users.org_users;
const getLeadTypes = (state, props) => state.metrics.lead_types;
const getOrgLeadTypes = (state, props) => state.metrics.org_lead_types;
const getAuth = (state, props) => state.auth;
const getSessionAdditionalData = (state, props) => state.auth.sessionData.additionalData;
const getManagers = (state, props) => state.auth.sessionData.additionalData.managers;
const getAllowedCenters = (state, props) => state.auth.sessionData.additionalData.allowedCenterIds;
const getIsManager = (state, props) => state.auth.is_manager;
const getAttachedCenterId = (state, props) => state.auth.sessionData.attached_center_id;
const getPerformanceDisplayMethod = (state, props) => state.auth.sessionData.performance_display_method;

const getPostLastUpdate = (post, activities) => {
    return (post && post.data && post.data.activity_id && activities.by_ids[post.data.activity_id]) ? (activities.by_ids[post.data.activity_id].win_req_val === 0 ? activities.by_ids[post.data.activity_id].create_time : activities.by_ids[post.data.activity_id].last_update) : post.last_update
};

export const getAnnouncements = createSelector(
    [getPublicFromFeeds, getIsManager, getCurrentDate, getManagers, getAllowedCenters],
    (publicFeed, is_manager, currentDate, managers, allowedCenterIds) => {
        const announcements = Object.values(publicFeed || {})
            .filter(({key, active_from, active_until, is_deleted}) => !is_deleted && key === 'announcement' && (is_manager || moment(currentDate).isBetween(active_from, moment(active_until).add(1, 'day'), undefined, '[]')))
            .sort((a, b) => {
                const aTime = a.active_from < a.created_on ? a.created_on : a.active_from;
                const bTime = b.active_from < b.created_on ? b.created_on : b.active_from;
                return new Date(bTime) - new Date(aTime);
            });
        return {
            is_manager,
            managers,
            allowedCenterIds,
            announcements,
            num_unread: {
                announcements: announcements.reduce((count, announcement) => !announcement.viewed ? count + 1 : count, 0)
            }
        }
    }
)

export const getPrivateFeed = createSelector(
    [getPrivateFromFeeds, getActivities, getIsManager, getPerformanceDisplayMethod, getAttachedCenterId, getUsers, getOrgUsers, getSessionAdditionalData],
    (privateFeed, activities, is_manager, performance_display_method, attached_center_id, users, org_users, sessionAdditionalData) => {
        console.log('in getPrivateFeed');
        const ignored_keys = ['team_received_coins', 'daily_points_update', 'login_reminder'];
        let all_participants = [];
        let postNewestOnly = {
            daily_points_update: false,
            user_received_coins_reminder: false,
            change_points_pos: false
        };
        let activity_mapper = {by_id: {}, by_group: {}};
        let schedule_groups = {};

        const usersByCrmIds = _.keyBy(users, 'in_crm_id');

        let posts = privateFeed ? Object.keys(privateFeed).sort((a, b) => {
            return new Date(getPostLastUpdate(privateFeed[b], activities)).getTime() - new Date(getPostLastUpdate(privateFeed[a], activities)).getTime()
        }).map(postId => {
            let post = {...privateFeed[postId]};
            if (post.origin_event_type === 1) { //activity
                for (let [key, object_id] of Object.entries(post.data || {})) {
                    if (key === 'activity_group_id' || key === 'activity_id') {
                        const mapKey = key === 'activity_group_id' ? 'by_group' : 'by_id';
                        if (!activity_mapper[mapKey][object_id]) {
                            activity_mapper[mapKey][object_id] = {
                                primary_post: null,
                                secondary_post: []
                            }
                        }
                        if (post.key === 'activity') {
                            activity_mapper[mapKey][object_id].primary_post = post;
                        } else { // any kind on reminder or feedback
                            activity_mapper[mapKey][object_id].secondary_post.push(post.id);
                        }
                    }
                }
            }

            if (Object.keys(postNewestOnly).includes(post.key)) {
                if (postNewestOnly[post.key]) {
                    return false;
                } else {
                    postNewestOnly[post.key] = true;
                }
            }

            post.activity = post.data && activities.by_ids[post.data.activity_id];

            if ((post.key === 'activity' && !post.activity) || (post.key === 'activity' && is_manager && !post.activity.win_req_val && !post.activity.secondary_def_id) || ignored_keys.indexOf(post.key) > -1)
                return false;
            if (post.viewed && moment.utc(post.viewed).isBefore(getPostLastUpdate(post, activities)))
                post.viewed = null;

            if (post.activity && ((post.activity.activity_def_id === 2 && (!post.activity.data || !post.activity.data.chosenOption)) || post.activity.activity_def_id === 16)) {
                return false;
            }

            let time_viewed = moment.utc(post.viewed);
            // show post in feed for at least 1 hour from the time is was viewed
            post.is_archive = post.viewed && time_viewed.isBefore(moment().subtract(1, 'hour')) && (post.activity
                ? (
                    (post.activity.length && post.activity.status === 4 && (moment(post.activity.end_time).isBefore(moment().subtract(1, 'days')) || moment(post.activity.end_time).date() !== moment().date()))
                    ||
                    (!post.activity.length && !is_manager && moment(post.activity.end_time).isBefore(moment().subtract(20, 'seconds')))
                ) : time_viewed.isBefore(moment().subtract(1, 'days')));

            if (is_manager && _.get(post, 'activity.data.schedule_id') && post.activity.title && post.activity.activity_def_id === 15 && activities.by_groups[post.activity.data.schedule_id].length > 1) {
                if (schedule_groups[post.activity.data.schedule_id]) {
                    return false;
                }

                post.activityGroup = activities.by_groups[post.activity.data.schedule_id];
                schedule_groups[post.activity.data.schedule_id] = post.activityGroup;
            } else {
                post.activityGroup = post.activity && activities.by_groups[post.activity.participant_group_id];
            }

            if (post.activity) {
                let isCheckedIn = post.activityGroup ? post.activityGroup.reduce((prev, activity) => activity.checked_in ? true : prev, false) : post.activity.checked_in === 1;
                if (!is_manager) {
                    if (!isCheckedIn && !isAllowCheckin(post.activity)) {
                        post.is_archive = true;
                    }
                    if (!post.activity.length && moment().month() !== moment(post.activity.start_time).month()) { //don't allow to claim points from previous month
                        return false;
                    }
                }
                else {
                    if (!post.activity.length && moment().diff(post.activity.create_time, 'hours', true) > 24) {
                        return false;
                    }
                    else if (!isCheckedIn && post.activity.length && window.location.hash !== '#showNotCheckedIn') {
                        // if (post.activity.activity_def_id === 15 && post.activity.status < 4) {
                        //     post.is_archive = moment().add(10, 's').isSameOrAfter(moment(post.data.sent_reminder, 'YYYY-MM-DD HH:mm:ss'));
                        // }
                        if (moment().diff(post.activity.start_time, 'hours', true) > post.activity.length * MAX_CHECK_IN_TIME_PERCENT) {
                            post.is_archive = true;
                        }
                    }
                }

                if (post.data.Booster_Feedback) {
                    post.is_archive = moment().add(10, 's').isSameOrAfter(moment(post.data.Booster_Feedback, 'YYYY-MM-DD HH:mm:ss'));
                }

                if (post.data.personal_Feedback) {
                    post.is_archive = moment().add(10, 's').isSameOrAfter(moment(post.data.personal_Feedback, 'YYYY-MM-DD HH:mm:ss'));
                }

                post.participants = post.activityGroup ? post.activityGroup.map(participant => participant.user_id) : (post.activity ? [post.activity.user_id] : []);
                post.leaderboards = post.activityGroup ? getActivityLeaderBoards(post.activityGroup, null, performance_display_method) : {leaders: getActivityLeaders([post.activity])};

                const postUsers = post.center_id !== attached_center_id ? org_users[post.center_id] : users;
                const hasRelevantParticipants = post.participants && post.participants.filter(participant_id => postUsers && postUsers[participant_id] && usersByCrmIds[postUsers[participant_id].in_crm_id]).length;
                if (!hasRelevantParticipants)
                    return false;

                all_participants = [...all_participants, ...post.participants];
            }
            return post;
        }).filter(post => post) : [];
        all_participants = Array.from(new Set(all_participants));

        const childCentersMenu = is_manager && Object.keys(_.get(sessionAdditionalData, "centersDetails", {})).length > 1 ? getChildCentersMenu({
            child_centers: sessionAdditionalData.child_centers,
            centersDetails: sessionAdditionalData.centersDetails,
            allowedCenterIds: sessionAdditionalData.allowedCenterIds || [],
            root_center_id: String(attached_center_id)
        }).filter(menuItem => menuItem.lvl < 2) : [];

        return {posts, all_participants, activity_mapper, childCentersMenu};
    }
);

export const getNotifications = createSelector(
    [getNotificationsFromState, getPrivateFeed, getPublicFromFeeds, getActivityHubFeeds, getActivities, getUsers, getLeadTypes, getOrgUsers, getOrgLeadTypes, getAuth],
    (notifications, privateFeedData, publicFeed, activityHubsFeed, activities, users, lead_types, org_users, org_lead_types, auth) => {
        let privateFeed = _.keyBy(privateFeedData.posts, 'id');
        let userDetails = {
            is_manager: auth.is_manager,
            center_id: auth.sessionData.attached_center_id,
            managers: auth.sessionData.additionalData.managers,
            lang: getLocale()
        };
        let foundActivityHubChatGroups = [];
        return users ? (
            Object.keys(notifications).sort((a, b) => b - a).map(notifId => {
                let notification = notifications[notifId];
                if (notification.medium === 1) { //e-mail
                    return false;
                }
                let post = _.get(privateFeed, notification.post_path) || (activityHubsFeed && _.get(activityHubsFeed, notification.post_path));
                if (!post) {
                    return false;
                }

                let activity = post.key === 'activityHubChatMsg' && activities.by_groups[post.origin_object_id] ? activities.by_groups[post.origin_object_id][0] : post.data && post.data.activity_id && activities.by_ids[post.data.activity_id];

                if (post.key !== 'activity' && post.origin_event_type === 1) {
                    let post_mapper = post.key === 'activityHubChatMsg' ? privateFeedData.activity_mapper.by_group[post.origin_object_id] : privateFeedData.activity_mapper.by_id[post.origin_object_id];
                    if (!post_mapper || !post_mapper.primary_post) {
                        return false;
                    }
                    notification.primary_post_id = post_mapper.primary_post.id;
                    post.is_archive = post_mapper.primary_post.is_archive;
                }

                if (activity) {
                    activity.leadType = post.center_id !== userDetails.center_id && org_lead_types[post.center_id] ? org_lead_types[post.center_id][activity.win_req_type] : lead_types[activity.win_req_type];
                    activity.user = post.center_id !== userDetails.center_id && org_users[post.center_id] ? org_users[post.center_id][activity.user_id] : users[activity.user_id];
                }

                let sender;
                if (post.origin_sender_type === 2) {
                    sender = userDetails.managers[post.origin_sender_id];
                }
                if (post.key === 'activityHubChatMsg') {
                    if (foundActivityHubChatGroups.indexOf(post.origin_object_id) > -1) {
                        return false;
                    }
                    foundActivityHubChatGroups.push(post.origin_object_id);
                    sender = post.origin_sender_type === 2 ? userDetails.managers[post.origin_sender_id] : (users[post.origin_sender_id] || (org_users[post.center_id] && org_users[post.center_id][post.origin_sender_id]));
                }

                let content = generateNotificationContent({post, activity, userDetails, sender});
                if (!content) {
                    return false;
                }

                return {...notification, post, activity, content};
            }).filter(notification => Boolean(notification)).slice(0, 18)
        ) : [];
    }
);