import React, {Component} from "react";
import {connect} from "react-redux";
import {login} from "../../utils/apiUtils";
import ErjForm from "../../components/controls/ErjForm";
import ArrowUpToLeftIcon from "../../components/icons/ArrowUpToLeftIcon";

import "./login.css";
import {withNewLine} from "../../utils/generalUtils";
import {UPDATE_CURRENT_TIME} from "../../actions/global";
import cookies from "../../utils/cookies";
import {SUPPORTED_LOCALES} from "../../utils/generalUtils";
import {NOHEBREW_LIMITED} from "../../utils/generalUtils";

import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import ViewPort from "../misc/Viewport";

import {I18n, t, Translate, getLocale, setLocale} from 'react-i18nify';
import ErjSelect from "../../components/controls/ErjSelect";
import {Modal, Paper} from "@material-ui/core";

class Login extends Component {
    constructor(props) {
        console.log('in login constructor');
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLoginStep2 = this.handleLoginStep2.bind(this);
    }

    state = {
        username: '',
        password: '',
        login_to_ui: window.location.hash === '#tv' ? 'tv' : 'app',
        auth_code: '',
        isShowRecaptchaModal: false
    };

    componentWillReceiveProps(nextProps) {
        console.log('nextProps', nextProps);
        if (nextProps.user) {
            // logged in, let's show redirect if any, or show home
            try {
                const {from} = this.props.location.state || {
                    from: {pathname: process.env.REACT_APP_ROUTE_PATH + "/"}
                };
                from.search = '';
                console.log('from', from);
                nextProps.history.replace(from);
            } catch (err) {
                nextProps.history.replace(process.env.REACT_APP_ROUTE_PATH + "/");
            }
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleLangChange(event) {
        let {value} = event.target;
        if (this.props.location.state) {
            cookies.removeItem('lang', this.props.location.state.from.pathname, undefined, true);
        }
        // cookies.setItem('lang', value, Infinity, this.props.location.state.from.pathname, undefined, true);
        cookies.setItem('lang', value, Infinity, '/', undefined, true);
        setLocale(value);
        moment.locale(value);
        this.props.dispatch({type: UPDATE_CURRENT_TIME});
    }

    handleLogin(event, formData) {
        const {username, password, login_to_ui} = formData;
        if (window.grecaptcha && window.grecaptcha.enterprise && window.grecaptcha.enterprise.execute) {
            window.grecaptcha.enterprise.ready(() => {
                window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(token => {
                    console.log(token);
                    this.props.dispatch(login({
                        username,
                        password,
                        login_to_ui,
                        lang: getLocale(),
                        recaptcha_token: token,
                        recaptcha_callback: () => {
                            this.setState({isShowRecaptchaModal: true}, () => {
                                setTimeout(() => {
                                    window.grecaptcha.enterprise.render('recaptcha_checkbox_container', {
                                        'sitekey': process.env.REACT_APP_RECAPTCHA_CHECKBOX_KEY,
                                        'callback': (token) => {
                                            this.props.dispatch(login({
                                                username,
                                                password,
                                                login_to_ui,
                                                lang: getLocale(),
                                                recaptcha_token: token,
                                                is_checkbox_token: true
                                            }));
                                            this.setState({isShowRecaptchaModal: false});
                                        }
                                    });
                                }, 100);
                            })
                        }
                    }));
                });
            });
        } else {
            this.props.dispatch(login({username, password, login_to_ui, lang: getLocale()}));
        }
    }

    handleLoginStep2(event, formData) {
        const {auth_code} = formData;
        const {username, password, login_to_ui} = this.state;
        this.props.dispatch(login({username, password, login_to_ui, auth_code, lang: getLocale()}));
    }

    render() {
        /* global navigator */
        const platform = JSON.stringify(process.env.NODE_ENV) ;
        const {loginError, notificationApproval, login2StepAuth} = this.props;
        const browserLang = navigator.language.split('-')[0];
        for (let i=0; i<NOHEBREW_LIMITED.length; ++i) {
            if( window.location.host.split(".")[0] == NOHEBREW_LIMITED[i] ) 
            {
                delete SUPPORTED_LOCALES["he"];
            }
        }
        return (
            <I18n render={() => (
                notificationApproval === 'request' ? (
                    <div className={'requestApproveNotificationMsg brws_lang_' + browserLang}>
                        <ViewPort>
                            {({mobile}) => [
                                !mobile && <ArrowUpToLeftIcon/>
                                ,
                                !mobile ? (
                                    <div className={'msg'}>
                                        <div className={'welcome'}>{t('global.welcome_to_enerjoy')}</div>
                                        {t('global.please_click_allow')}
                                    </div>
                                ) : (
                                    <span>&nbsp;</span>
                                )
                            ]}
                        </ViewPort>
                    </div>
                ) : (
                    <ViewPort>
                        {({mobile}) => (
                            <div className={'loginPage' + (mobile ? '' : ' contentBox')}>
                                <select className="custom-select langChooser" onChange={(e) => this.handleLangChange(e)}
                                        value={getLocale()}>
                                    {Object.entries(SUPPORTED_LOCALES).map(([key, title]) => (
                                        <option value={key} key={key}>{title}</option>
                                    ))}
                                </select>
                                <span title={platform} className="platform">...</span>
                                <div className={'formBg'}>
                                    {login2StepAuth ? (
                                        <ErjForm key={'loginStep2Form'} className={'loginForm loginStep2Form'}
                                                 onSubmit={this.handleLoginStep2} refresh={loginError}>
                                            {login2StepAuth.image_url &&
                                            <img className={'qrCode'} src={login2StepAuth.image_url}/>}
                                            <h1><Translate value={'global.enter_2_step_auth_code'}/></h1>
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="auth_code"
                                                           onChange={(e) => this.handleChange(e)}
                                                           value={this.state.auth_code}
                                                           required={true} autoFocus={true}/>
                                                    <div className="invalid-feedback" data-error-msg-field="auth_code"
                                                         data-required-msg={t('global.please_enter_auth_code')}>&nbsp;</div>
                                                </div>
                                            </div>
                                            <div className={'buttons'}>
                                                <button className={'btn btn-green'} name="login"
                                                        type="submit"><Translate value={'global.login'}/></button>
                                            </div>
                                        </ErjForm>
                                    ) : (
                                        <ErjForm key={'loginForm'} className={'loginForm'} onSubmit={this.handleLogin}
                                                 refresh={loginError}>
                                            {formData => (
                                                <>
                                                    <h1><Translate value={'global.login_to_get_started'}/></h1>
                                                    <div className="form-row">
                                                        <div className="col-md-12">
                                                            <Translate value={'global.username'}/>
                                                            <input type="text" className="form-control" name="username"
                                                                   onChange={(e) => this.handleChange(e)}
                                                                   value={this.state.username}
                                                                   required={true}/>
                                                            <div className="invalid-feedback"
                                                                 data-error-msg-field="username"
                                                                 data-required-msg={t('global.please_enter_username')}>&nbsp;</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row passwordRow">
                                                        <div className="col-md-12">
                                                            <Translate value={'global.password'}/>
                                                            <input type="password" className="form-control"
                                                                   name="password"
                                                                   onChange={(e) => this.handleChange(e)}
                                                                   value={this.state.password}
                                                                   required={true}/>
                                                            <div className="invalid-feedback"
                                                                 data-error-msg-field="password"
                                                                 data-required-msg={t('global.please_enter_password')}>&nbsp;</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-12">
                                                            <Translate value={'global.login_to'}/>
                                                            <ErjSelect name={'login_to_ui'} className={'form-control'}
                                                                       options={[
                                                                           {id: 'app', title: t('global.app')},
                                                                           {id: 'tv', title: t('global.tv')}
                                                                       ]} value={this.state.login_to_ui}
                                                                       onChange={(e) => this.handleChange(e)}/>
                                                            <div className="invalid-feedback"
                                                                 data-error-msg-field="password"
                                                                 data-required-msg={t('global.please_enter_password')}>&nbsp;</div>
                                                        </div>
                                                    </div>
                                                    <div className={'buttons'}>
                                                        <button className={'btn btn-green'} name="login"
                                                                type="submit"><Translate value={'global.login'}/>
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </ErjForm>
                                    )}
                                    {Boolean(window.grecaptcha) && (
                                        <div className={'recaptchaNotice'}>
                                            This site is protected by reCAPTCHA and the Google <a
                                            href="https://policies.google.com/privacy">Privacy Policy</a> and <a
                                            href="https://policies.google.com/terms">Terms of Service</a> apply
                                        </div>
                                    )}
                                    <Modal open={this.state.isShowRecaptchaModal}>
                                        <div id={'recaptcha_checkbox_container'}/>
                                    </Modal>
                                </div>
                                <div className={'background'}>
                                    <div className={'welcomeMsg localText'}>{t('global.welcome_aboard')}!</div>
                                </div>
                            </div>
                        )}

                    </ViewPort>
                )
            )}/>
        );
    }
}

function mapStateToProps(state) {
    const {auth, controls, global, i18n} = state;
    // console.log('in mapStateToProps',controls,auth);
    return {
        controls,
        loginError: auth.loginError,
        login2StepAuth: auth.login2StepAuth,
        user: auth.accountData,
        notificationApproval: auth.notificationApproval,
        isLoading: global && global.apiRequestInProgress
    };
}

export default connect(mapStateToProps)(Login);
