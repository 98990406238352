import {callApi} from "../utils/apiUtils";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_SAVING, API_REQUEST_ERROR} from "./global";

export const SET_SELECTED_WEEK = "SET_SELECTED_WEEK";
export const RECEIVED_WORK_SCHEDULE = "RECEIVED_WORK_SCHEDULE";
export const RECEIVED_PUBLISHED_WORK_SCHEDULE = "RECEIVED_PUBLISHED_WORK_SCHEDULE";
export const RECEIVED_UPDATED_WORK_SCHEDULE = "RECEIVED_UPDATED_WORK_SCHEDULE";
export const RECEIVED_DELETED_SHIFT = "RECEIVED_DELETED_SHIFT";
export const ADD_USER_SHIFT_ROW = "ADD_USER_SHIFT_ROW";

export const DEFAULT_SCHEDULE_DATE = "0000-01-02";

function receivedWorkSchedule(workSchedule) {
    return {type: RECEIVED_WORK_SCHEDULE, workSchedule};
}

function receivedPublishedWorkSchedule(shiftIdToUpdate) {
    return {type: RECEIVED_PUBLISHED_WORK_SCHEDULE, shiftIdToUpdate};
}

function receivedDeletedShift(deletedShiftsData) {
    return {type: RECEIVED_DELETED_SHIFT, deletedShiftsData};
}

export function getWorkSchedule(workScheduleData, callback) {
    return callApi('getWorkSchedule', workScheduleData, (dispatch, reqData) => {
        dispatch({type: SET_SELECTED_WEEK, selectedWeek: workScheduleData.selectedWeek});
    }, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedWorkSchedule(responseData.data));
            if (callback) {
                callback(responseData.data);
            }
        }
    });
}

export function publishWorkSchedule(workScheduleData, callback) {
    return callApi('publishWorkSchedule', workScheduleData, null, (dispatch, responseData) => {
        dispatch({type: API_REQUEST_SAVING});
        if (responseData.status === "SUCCESS") {
            // moved success logic to callee
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }

        if (callback) {
            callback(responseData);
        }
    });
}

export function updateShifts(shiftData, callback) {
    return callApi('updateShifts', shiftData, (dispatch, requestData) => {
        dispatch({type: API_REQUEST_SAVING});
    }, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            // moved success logic to callee
        } else if (responseData.status === "ERROR_SHIFT_COLLISION") {
            dispatch({
                type: API_REQUEST_ERROR,
                prefix: "shiftCollision",
                additionalData: {centerTitle: responseData.data[0].centerTitle}
            });
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }

        if (callback) {
            callback(responseData);
        }
    });
}

export function addUserShiftRow(userShiftRowData, callback) {
    return callApi('addUserShiftRow', userShiftRowData, (dispatch, requestData) => {
        dispatch({type: ADD_USER_SHIFT_ROW, userShiftRowData});
    }, (dispatch, responseData) => {
        console.log(responseData);
        callback();
    });
}

export function copyShiftsToNextWeek(copyWeekShiftsData, callback) {
    return callApi('copyShiftsToNextWeek', copyWeekShiftsData, null, (dispatch, responseData) => {
        console.log(responseData);
        callback();
    });
}