import {
    RECEIVED_FEEDS_LIST,
    SEND_CHAT_MSG_SUCCESS,
    DELETE_CHAT_MSG,
    SEND_CHAT_MSG_IN_PROGRESS,
    LIKE_CHAT_MSG_SUCCESS,
    SEND_COMMENT_MSG_SUCCESS,
    SEND_COMMENT_MSG_IN_PROGRESS,
    LIKE_PUBLIC_POST_SUCCESS,
    REPORT_POSTS_VIEWED_SUCCESS,
    FORCE_UPDATE_FEED,
    RECEIVED_FEEDBACK_STATS, SAVE_ANNOUNCEMENT_SUCCESS, DELETE_ANNOUNCEMENT_SUCCESS
} from "../actions/feeds";
import {RESET_LIVE_DATA} from "../actions/auth";
import {CHECK_IN_REMINDER_SENT} from "../actions/activities";

const _ = require('lodash');

const initialState = {
    private : {},
    public : {},
    activity : {},
    feedback_stats: {},
    isChatMsgSending : false,
    isCommentMsgSending : false,
    lastChatUpdate : new Date()
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function feeds(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_FEEDS_LIST:
            return {
                ...state,
                private: action.feeds.private,
                public: action.feeds.public,
                activity: action.feeds.activity,
                tv_alerts: action.feeds.tv_alerts,
                lastChatUpdate: new Date()
            };
        case RECEIVED_FEEDBACK_STATS:
            return {
                ...state,
                feedback_stats: action.feedback_stats
            };
        case FORCE_UPDATE_FEED:
            let returnedState = {...state};
            for (let feed_type of action.feed_types) {
                returnedState[feed_type] = {...state[feed_type]};
            }
            return returnedState;
        case REPORT_POSTS_VIEWED_SUCCESS:
            if (action.activityFeedChannel) {
                let chats = {...state.activity[action.activityFeedChannel[0]][action.activityFeedChannel[1]][action.activityFeedChannel[2]]};
                for (let postId of action.postIds) {
                    if (chats[postId])
                        chats[postId] = {...chats[postId], viewed: new Date()};
                }
                return {
                    ...state,
                    activity: {
                        ...state.activity,
                        [action.activityFeedChannel[0]]: {
                            ...state.activity[action.activityFeedChannel[0]],
                            [action.activityFeedChannel[1]]: {
                                ...state.activity[action.activityFeedChannel[0]][action.activityFeedChannel[1]],
                                [action.activityFeedChannel[2]]: chats
                            }
                        }
                    }
                };
            }
            else {
                let privateFeed = {...state.private};
                let publicFeed = {...state.public};
                let updateObj = {viewed: new Date()};
                for (let postId of action.postIds) {
                    if (privateFeed[postId])
                        privateFeed[postId] = {...privateFeed[postId], ...updateObj};
                    if (publicFeed[postId])
                        publicFeed[postId] = {...publicFeed[postId], ...updateObj};
                }

                return {
                    ...state,
                    private: privateFeed,
                    public: publicFeed
                };
            }
            break;
        case SAVE_ANNOUNCEMENT_SUCCESS:
            return {
                ...state,
                public: {
                    ...state.public,
                    [action.post.id]: {
                        ...(state.public[action.post.id] || {}),
                        ...action.post
                    }
                }
            };
            break;
        case DELETE_ANNOUNCEMENT_SUCCESS:
            const {[action.postId]: deletedPost, ...publicPosts} = state.public;
            return {
                ...state,
                public: {
                    ...publicPosts
                }
            };
            break;
        case SEND_CHAT_MSG_IN_PROGRESS:
            return {
                ...state,
                isChatMsgSending: true
            };
        case SEND_CHAT_MSG_SUCCESS: {
            const {origin_object_id, receiver_type, receiver_id, id} = action.post;
            return {
                ...state,
                activity: {
                    ...state.activity,
                    [origin_object_id]: {
                        ...(_.get(state.activity, [origin_object_id])),
                        [receiver_type]: {
                            ...(_.get(state.activity, [origin_object_id, receiver_type])),
                            [receiver_id]: {
                                ...(_.get(state.activity, [origin_object_id, receiver_type, receiver_id])),
                                [id]: action.post
                            }
                        }
                    }
                },
                isChatMsgSending: false,
                lastChatUpdate: new Date()
            };
        }

        case DELETE_CHAT_MSG: {
            const {msgId, activityId, chatTextChosenChannel, origin_object_id} = action;
            const test = state.activity;
            const myChat = test[origin_object_id][chatTextChosenChannel][activityId]
            delete myChat[msgId]
            const checkstates = (_.get(state.activity, [origin_object_id, chatTextChosenChannel]))

            return {
                ...state,
                activity: {
                    ...state.activity,
                    [origin_object_id]: {
                        ...(_.get(state.activity, [origin_object_id])),
                        [chatTextChosenChannel]: {
                            ...(_.get(state.activity, [origin_object_id, chatTextChosenChannel])),
                            [activityId]: {
                                ...myChat
                            }
                        }
                    }
                }
            }
  
        }

        case LIKE_CHAT_MSG_SUCCESS: {
            const {origin_object_id, receiver_type, receiver_id, id} = action.post;
            const like = {...action.like};
            return {
                ...state,
                activity: {
                    ...state.activity,
                    [origin_object_id]: {
                        ...(_.get(state.activity, [origin_object_id])),
                        [receiver_type]: {
                            ...(_.get(state.activity, [origin_object_id, receiver_type])),
                            [receiver_id]: {
                                ...(_.get(state.activity, [origin_object_id, receiver_type, receiver_id])),
                                [id]: {
                                    ...(_.get(state.activity, [origin_object_id, receiver_type, receiver_id, id])),
                                    "likes": [
                                        ...(_.get(state.activity, [origin_object_id, receiver_type, receiver_id, id, 'likes'], [])),
                                        like
                                    ]
                                }
                            }
                        }
                    }
                }
            };
        }
        case CHECK_IN_REMINDER_SENT:
            return {
                ...state,
                private: {
                    ...state.private,
                    [action.postId]: {
                        ...state.private[action.postId],
                        data: {
                            ...state.private[action.postId].data,
                            sent_reminder: new Date()
                        }
                    }
                }
            };
        case SEND_COMMENT_MSG_IN_PROGRESS:
            return {
                ...state,
                isCommentMsgSending : true
            };
        case SEND_COMMENT_MSG_SUCCESS: {
            const {root_parent_post_id, origin_sender_id, origin_sender_type, data, created_on} = action.post;
            return {
                ...state,
                public: {
                    ...state.public,
                    [root_parent_post_id]: {
                        ...(_.get(state.public, [root_parent_post_id])),
                        comments: [
                            ...(_.get(state.public, [root_parent_post_id, "comments"], [])),
                            {
                                user_id: origin_sender_id,
                                user_type: origin_sender_type,
                                data,
                                created_on
                            }
                        ]
                    }
                },
                isCommentMsgSending: false
            };
        }
        case LIKE_PUBLIC_POST_SUCCESS: {
            const {postId, user_type, user_id} = action;
            // console.log(state,state.public,_.get(state.public, [postId]),_.get(state.public, [postId, "likes"]));
            return {
                ...state,
                public: {
                    ...state.public,
                    [postId]: {
                        ...(_.get(state.public, [postId])),
                        likes: [
                            ...(_.get(state.public, [postId, "likes"],[])),
                            {
                                user_id: user_id,
                                user_type: user_type,
                                like_code: 1
                            }
                        ]
                    }
                }
            };
        }
        default:
            return state;
    }
}
