import React, {Component} from 'react';
import {connect} from "react-redux";
import {getUserImg, MAX_CHECK_IN_TIME_PERCENT} from "erj-common/generalUtils";
import CurrentTime from "../misc/CurrentTime";
import {DISPATCH_UI_ACTION} from "../../actions/controls";

import PeopleIcon from '@material-ui/icons/People';
import "../feed/feed.css";
import MetricValueDisplay from "../misc/MetricValueDisplay";
import Tooltip from "@material-ui/core/Tooltip";
import PointsSummaryWidget from "./PointsSummaryWidget";
import CoinsSummaryWidget from "./CoinsSummaryWidget";
import FeedbackSummaryWidget from "./FeedbackSummaryWidget";
import CheckinsSummaryWidget from "./CheckinsSummaryWidget";
import {t} from 'react-i18nify'
import Icon from "@material-ui/core/Icon";
import {Link} from "react-router-dom";
import PopOverLink from "../../components/controls/PopOverLink";
import moment from "moment";
import CommissionSummaryWidget from "./CommissionSummaryWidget";

const _ = require('lodash');

class ManagerSummaryWidget extends Component {

    goToMotivationProfiles = () => {
        this.props.setTimeout(() => this.props.dispatch({
            type: DISPATCH_UI_ACTION,
            key: 'changeFeedFilter',
            params: {switchTo: 'insights'}
        }), 500);
    };

    render() {
        const {
            userData,
            center_is_parent,
            show_inactive_users,
            users,
            org_users,
            user_id,
            feedbackStats,
            pending_orders,
            commissions_enabled,
            challenges_enabled,
            store_enabled
        } = this.props;
        const feedbackCount = Object.values(feedbackStats).reduce((stats, feedback) => {
            stats.opportunities++;
            if (feedback.postId) {
                stats.given++;
            }
            return stats;
        }, {opportunities: 0, given: 0});
        const employees = Object.values(users || {})
            .filter(user => user.isActive || show_inactive_users)
            .map(({id, first_name, last_name, user_img}) => ({
                id,
                first_name,
                last_name,
                user_img,
                isNotVisitedUI: this.props.users_shift_status[id] && !this.props.users_shift_status[id].isVisitedUI && this.props.users_shift_status[id].status,
                isVisitedUI: this.props.users_shift_status[id] && this.props.users_shift_status[id].isVisitedUI && this.props.users_shift_status[id].status
            }));

        const notVisitedUiEmployees = employees.filter(employee => employee.isNotVisitedUI);
        const visitedUiEmployees = employees.filter(employee => employee.isVisitedUI);

        const usersByCrmIds = _.keyBy(users, 'in_crm_id');

        const notCheckedInEmployees = Object.values(
            _.keyBy(
                Object.values(this.props.activities).filter(activity => {
                    return activity.length && activity.status !== 4 && moment().diff(activity.start_time, 'hours', true) < activity.length * MAX_CHECK_IN_TIME_PERCENT && !activity.checked_in
                })
                , "user_id")
        ).map(({
                   user_id,
                   center_id
               }) => (users[user_id] || (org_users[center_id] && org_users[center_id][user_id]))).filter(user => user && usersByCrmIds[user.in_crm_id]);

        const numModules = (commissions_enabled ? 1 : 0) + (store_enabled ? 1 : 0) + (challenges_enabled ? 1 : 0);

        return Object.keys(userData).length !== 0 && (
            <div className={'user-summary-widget manager-summary'}>
                <div className="greeting localText"><CurrentTime format={'dayPartGreeting'}
                                                                 renderIn={greeting => t('global.' + greeting, {
                                                                     first_name: userData.first_name,
                                                                     last_name: userData.last_name
                                                                 })}/></div>
                {numModules < 2 && (
                    <img className="personalImg" alt={userData.first_name + '_img'}
                         src={getUserImg(userData.user_img, "xs")}/>
                )}
                <div className={'moduleRow'}>
                    {store_enabled && (
                        <div className={'moduleContent incentivesModule'}>
                            <PointsSummaryWidget/>
                            <CoinsSummaryWidget/>
                        </div>
                    )}
                    {challenges_enabled && (
                        <div className={'moduleContent incentivesModule'}>
                            <FeedbackSummaryWidget center_is_parent={center_is_parent} feedbackCount={feedbackCount}
                                                   user_id={user_id} feedbackStats={feedbackStats}
                                                   employees={employees}/>
                            <CheckinsSummaryWidget center_is_parent={center_is_parent}/>
                        </div>
                    )}
                    {commissions_enabled && (
                        <div className={'moduleContent incentivesModule'}>
                            <CommissionSummaryWidget history={this.props.history}/>
                        </div>
                    )}
                </div>
                {store_enabled && Boolean(pending_orders.new || pending_orders.awaitingDelivery) && (
                    <div className={'moduleRow'}>
                        <div className={'moduleTitle'}>
                            <Icon className={'titleIcon joyStoreIcon icon- '}>cart</Icon>
                            {' ' + t('global.joystore_orders')}
                        </div>
                        <div className={'moduleContent'}>
                            {pending_orders.new > 0 && (
                                <div className={'orderStatsItem ordersNew localText'}>
                                    {t('manager_store.x_awaiting_approval') + ': '}
                                    <span className={'value'}>{pending_orders.new}</span>
                                </div>
                            )}
                            {pending_orders.awaitingDelivery > 0 && (
                                <div className={'orderStatsItem ordersAwaitingDelivery localText'}>
                                    {t('manager_store.x_awaiting_delivery') + ': '}
                                    <span className={'value'}>{pending_orders.awaitingDelivery}</span>
                                </div>
                            )}
                            <div className={'advancedLink-container'}>
                                <Link to={process.env.REACT_APP_ROUTE_PATH + '/manage-store/orders'}
                                      className={'advancedLink'}>{t('manager_store.view_all')}</Link>
                                <Icon className={'icon- bold_arrow_right'}>bold_arrow_right</Icon>
                            </div>
                        </div>
                    </div>
                )}
                {(notVisitedUiEmployees.length > 0 || visitedUiEmployees.length > 0 || notCheckedInEmployees.length > 0) && (
                    <div className={'moduleRow'}>
                        <div className={'moduleTitle'}>
                            <PeopleIcon className={'titleIcon'}/>{' ' + t('global.employees_usage')}
                        </div>
                        <div className={'moduleContent'}>
                            {visitedUiEmployees.length > 0 && (
                                <PopOverLink body={(
                                    <div className={'warningEmployeesRows'}>
                                        {visitedUiEmployees.map(({id, first_name, last_name, user_img}) => (
                                            <div className={'employeeRow'} key={id}>
                                                <img alt={first_name + ' ' + last_name} src={getUserImg(user_img)}/>
                                                {first_name + ' ' + last_name}
                                            </div>
                                        ))}
                                    </div>
                                )} className={'warningLink info'}>
                                    {visitedUiEmployees.length + ' ' + t('global.employees_logged_in_today')}
                                </PopOverLink>
                            )}
                            {notVisitedUiEmployees.length > 0 && (
                                <PopOverLink body={(
                                    <div className={'warningEmployeesRows'}>
                                        {notVisitedUiEmployees.map(({id, first_name, last_name, user_img}) => (
                                            <div className={'employeeRow'} key={id}>
                                                <img alt={first_name + ' ' + last_name} src={getUserImg(user_img)}/>
                                                {first_name + ' ' + last_name}
                                            </div>
                                        ))}
                                    </div>
                                )} className={'warningLink'}>
                                    {notVisitedUiEmployees.length + ' ' + t('global.employees_not_logged_in_today')}
                                </PopOverLink>
                            )}
                            {challenges_enabled && notCheckedInEmployees.length > 0 && (
                                <PopOverLink body={(
                                    <div className={'warningEmployeesRows'}>
                                        {notCheckedInEmployees.map(({id, first_name, last_name, user_img}) => (
                                            <div className={'employeeRow'} key={id}>
                                                <img alt={first_name + ' ' + last_name} src={getUserImg(user_img)}/>
                                                {first_name + ' ' + last_name}
                                            </div>
                                        ))}
                                    </div>
                                )} className={'warningLink'}>
                                    {notCheckedInEmployees.length + ' ' + t('global.employees_not_checked_in')}
                                </PopOverLink>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {auth} = state;
    return {
        userData: auth.accountData,
        users: state.users.users,
        org_users: state.users.org_users,
        feedbackStats: state.feeds.feedback_stats,
        center_is_parent: auth.sessionData && auth.sessionData.additionalData && Boolean(auth.sessionData.additionalData.center_is_parent),
        show_inactive_users: auth.sessionData.show_inactive_users,
        user_id: auth.sessionData.user_id,
        pending_orders: state.managerStore.pending_orders,
        users_shift_status: state.users.users_shift_status,
        activities: state.activities.by_ids,
        commissions_enabled: auth.sessionData.commissions_enabled === 1 && auth.sessionData.commissions_controlling_center === auth.sessionData.attached_center_id,
        challenges_enabled: auth.sessionData.challenges_enabled === 1,
        store_enabled: auth.sessionData.store_enabled === 1
    };
};

export default connect(mapStateToProps)(ManagerSummaryWidget);