import {callApi} from "../utils/apiUtils";
import {disabledButtons} from "./controls";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_ERROR, API_REQUEST_SAVING} from "./global";

export const RECEIVED_METRICS_LIST = "RECEIVED_METRICS_LIST";
export const RECEIVED_LEAD_TYPES_LIST = "RECEIVED_LEAD_TYPES_LIST";
export const RECEIVED_ORG_LEAD_TYPES_LIST = "RECEIVED_ORG_LEAD_TYPES_LIST";
export const RECEIVED_METRIC_LAST_TRANSACTION = "RECEIVED_METRIC_LAST_TRANSACTION";
export const RECEIVED_METRICS_SETTINGS = "RECEIVED_METRICS_SETTINGS";
export const RECEIVED_METRICS_NEW_ORDER = "RECEIVED_METRICS_NEW_ORDER";

export function receivedMetrics(metrics) {
    return {type: RECEIVED_METRICS_LIST, metrics: metrics};
}

export function receivedLeadTypes(lead_types) {
    return {type: RECEIVED_LEAD_TYPES_LIST, lead_types};
}

export function receivedOrgLeadTypes(org_lead_types) {
    return {type: RECEIVED_ORG_LEAD_TYPES_LIST, org_lead_types};
}

export function receivedLastTransaction(metric_id, last_transaction) {
    return {type: RECEIVED_METRIC_LAST_TRANSACTION, metric_id, last_transaction};
}

export function receivedMetricsSettings(metrics) {
    return {type: RECEIVED_METRICS_SETTINGS, metrics: metrics};
}

export function receivedMetricsNewOrder(metricNewOrder) {
    return {type: RECEIVED_METRICS_NEW_ORDER, metricNewOrder};
}

export function getMetricsSettings() {
    return callApi('getMetricsSettings', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedMetricsSettings(responseData.metrics));
        }
    });
}

export function setPrimaryMetric(metric_id) {
    return callApi('setPrimaryMetric', {metric_id}, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
            dispatch(getMetricsSettings());
        }
    });
}

export function setIsShowInHome(metric_id, is_show_in_home) {
    return callApi('setIsShowInHome', {
        metric_id,
        is_show_in_home
    }, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
            dispatch(getMetricsSettings());
        }
    });
}

export function setMetricSettings(actionData, callback) {
    return callApi('setMetricSettings', actionData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
            dispatch(getMetricsSettings());
            callback(responseData.status);
        }
        if (responseData.status === "ERR_IN_CRM_ID_EXIST") {
            callback(responseData.status);
        }
    });
}

export function setBlendedMetric(actionData) {
    return callApi('setMetricSettings', actionData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(getMetricsSettings());
            dispatch({type: API_REQ_SAVE_SUCCESS});
        }
    });
}

export function getPrevTransaction(actionData) {
    return callApi('getPrevTransaction', actionData, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedLastTransaction(actionData.metric_id, responseData.last_transaction));
        }
    });
}

export function setMetricOrder(metricNewOrder) {
    return callApi('setMetricOrder', metricNewOrder, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS, prefix: 'metricOrderChanged'});
            dispatch(receivedMetricsNewOrder(metricNewOrder));
        } else {
            dispatch({type: API_REQUEST_ERROR});
        }
    });
}