import {callApi} from "../utils/apiUtils";
import StackTracey from 'stacktracey'

export const API_REQUEST_START = 'API_REQUEST_START';
export const API_REQUEST_END = 'API_REQUEST_END';
export const API_REQUEST_SAVING = 'API_REQUEST_SAVING';
export const API_REQUEST_UPLOADING = 'API_REQUEST_UPLOADING';
export const API_REQUEST_DELETING = 'API_REQUEST_DELETING';
export const API_REQ_SAVE_SUCCESS = 'API_REQ_SAVE_SUCCESS';
export const API_REQ_SUCCESS_INFO = 'API_REQ_SUCCESS_INFO';
export const API_REQUEST_ERROR = 'API_REQUEST_ERROR';
export const GENERAL_ERROR = 'GENERAL_ERROR';
export const NO_PERMISSION = 'NO_PERMISSION';
export const API_CLEAR_REQUEST_FEEDBACK = 'API_CLEAR_REQUEST_FEEDBACK';
export const UPDATE_CURRENT_TIME = 'UPDATE_CURRENT_TIME';
export const START_TEMP_DELAY_ACTIONS = 'START_TEMP_DELAY_ACTIONS';
export const STOP_TEMP_DELAY_ACTIONS = 'STOP_TEMP_DELAY_ACTIONS';

export const clearRequestFeedback = function (reason) {
    return {type: API_CLEAR_REQUEST_FEEDBACK, reason};
};

export const updateCurrentTime = function () {
    return {type: UPDATE_CURRENT_TIME};
};

export const updateLastActivity = function (currentPage) {
    return callApi('updateLastActivity', {currentPage});
};

export const subscribeToUpdates = function (keys) {
    return callApi('subscribeToUpdates', {keys});
};

export const unsubscribeFromUpdates = function (keys) {
    return callApi('unsubscribeFromUpdates', {keys});
};

const appStartTime = new Date();

Error.stackTraceLimit = 20;

export function reportError(err_data) {
    let isOldVersionError = (err_data.error.message.indexOf('Loading') > -1 && err_data.error.message.indexOf('chunk') > -1 && err_data.error.message.indexOf('failed') > -1);
    console.error(err_data);
    const stack = new StackTracey(err_data.error);
    stack.cleanAsync().then(async err => {
        // do what you want with mappedStack here
        let errorMsg = err_data.error.message + ' at ' + err.asTable();
        let appException = {
            errorMsg,
            info: err_data.info,
            level: err_data.level || 'error',
            userAgent: navigator.userAgent
        };
        if ('serviceWorker' in navigator) {
            appException.service_workers = (await navigator.serviceWorker.getRegistrations()).map(r => r.active && r.active.scriptURL);
        }

        if (isOldVersionError) {
            appException.forceReload = true;
            appException.appStartTime = appStartTime;
            appException.level = 'info';
        }

        callApi(appException.level === 'info' ? 'appDebug' : 'appException', appException, () => {
        }, () => {
            if (appException.forceReload) {
                window.location.reload(true);
            }
        })((...dArgs) => {
            console.log('dummy dispatch', ...dArgs);
        });
    });

    return !isOldVersionError;
}

export function reportDebug(data) {
    console.info(reportDebug, data);
    callApi('appDebug', {data}, () => {
    }, () => {
    })((...dArgs) => {
        console.log('dummy dispatch', ...dArgs);
    });
}

