import React from 'react';

export default () => <svg version="1.1" className={"bellIcon"} viewBox="0 0 29.692 26.978"
                          xmlns="http://www.w3.org/2000/svg">
    <g id="stripes" transform="translate(-29.1 -32.456)" fill="#3fa9f5">
        <path fill="#3fa9f5"
              d="m40.17 38.41-1.26 3.08h-8.34a1.44 1.44 0 0 1-1.44-1.41 1.48 1.48 0 0 1 1.25-1.59 2 2 0 0 1 0.48-0.07z"
              style={{isolation: 'isolate'}}/>
        <path fill="#3fa9f5"
              d="m38.66 43.49v0.19c0 2.72-0.08 2.83-2.71 2.83h-5.1c-1.12 0-1.75-0.56-1.75-1.51s0.66-1.5 1.76-1.5z"
              style={{isolation: 'isolate'}}/>
    </g>
    <path id="clapper" d="m20.9 24.414 4.91-0.72a2.51 2.51 0 0 1-3.28 3.12 2.48 2.48 0 0 1-1.63-2.4z" fill="#c61e56"
          style={{isolation: 'isolate'}}/>
    <path id="bellBody"
          d="m19.95 22.934a36 36 0 0 1-10.49-1.34 8.21 8.21 0 0 1-1.7-0.79c-0.54-0.33-0.55-0.77 0-1.2 2.63-2.19 3.46-5.18 3.66-8.41a9.48 9.48 0 0 1 3.71-7.46 4.12 4.12 0 0 1 0.77-0.48 1.18 1.18 0 0 0 0.86-1.18 2.32 2.32 0 0 1 2.45-2.07 2.44 2.44 0 0 1 2.3 2.27 1.11 1.11 0 0 0 0.4 0.76 8.86 8.86 0 0 1 4.83 7.88 21.49 21.49 0 0 0 1.85 7.45 7.34 7.34 0 0 0 0.71 1.18c0.53 0.72 0.52 1.2-0.31 1.58a13.35 13.35 0 0 1-3.25 1.08c-2.27 0.34-4.56 0.58-5.79 0.73z"
          fill="#fa487d" style={{isolation: 'isolate'}}/>
    <path id="shadow"
          d="m22.55 9.4244a22.73 22.73 0 0 0 2 7.91 6.81 6.81 0 0 0 0.8 1.25 2.28 2.28 0 0 1 0.33 0.6 8.53 8.53 0 0 0 1.75 2.21l0.36 0.24q0.63-0.24 1.23-0.51c0.83-0.38 0.84-0.86 0.31-1.58a7.34 7.34 0 0 1-0.75-1.18 21.49 21.49 0 0 1-1.85-7.45 8.86 8.86 0 0 0-4.83-7.88 1.11 1.11 0 0 1-0.4-0.76 2.44 2.44 0 0 0-2.3-2.27 2.47 2.47 0 0 0-2 0.85 0.63 0.63 0 0 0 0.2 0.2c3.4 1.78 4.92 4.7 5.15 8.37z"
          fill="#c92d61" style={{isolation: 'isolate'}}/>
</svg>
