import React from 'react';

export default () => <svg className={"wheelOfFortuneIcon"} version="1.1" viewBox="0 0 490 512"
                          xmlns="http://www.w3.org/2000/svg">
    <path id="bg"
          d="m412.45 268.61-0.704-0.224c-65.034-32.178-104.47-100.18-100.1-172.61 0-0.512 0.256-1.056 0.352-1.568 0.813-4.693 1.23-9.445 1.248-14.208 0-44.183-35.817-80-80-80s-80 35.817-80 80c-3e-3 4.74 0.393 9.471 1.184 14.144 0.091 0.628 0.23 1.249 0.416 1.856 4.268 72.587-35.478 140.64-100.8 172.58-41.636 14.783-63.405 60.52-48.622 102.16s60.52 63.405 102.16 48.622c9.214-3.272 17.751-8.205 25.186-14.554 60.744-40.86 140.18-40.86 200.93 0 33.579 28.716 84.078 24.774 112.79-8.805s24.774-84.078-8.805-112.79c-7.447-6.369-16.002-11.315-25.237-14.593z"
          fill="#2dceda"/>
    <g id="outline">
        <path
            d="m233.25 320c-35.346 0-64-28.654-64-64s28.654-64 64-64 64 28.654 64 64-28.654 64-64 64zm0-96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"/>
        <path
            d="m385.66 424c-19.057-6e-3 -37.485-6.815-51.968-19.2-60.728-40.912-140.2-40.912-200.93 0-33.568 28.728-84.069 24.805-112.8-8.763s-24.805-84.068 8.763-112.8c7.468-6.391 16.049-11.351 25.314-14.632 65.315-31.964 105.06-100.02 100.8-172.61-0.184-0.597-0.323-1.206-0.416-1.824-0.793-4.684-1.189-9.426-1.184-14.176 0-44.183 35.817-80 80-80s80 35.817 80 80c3e-3 4.74-0.393 9.471-1.184 14.144-0.091 0.628-0.23 1.249-0.416 1.856-4.23 72.346 35.209 140.21 100.16 172.35l0.672 0.224c41.638 14.779 63.412 60.513 48.633 102.15-11.335 31.936-41.561 53.265-75.449 53.241zm-152.42-81.984c43.627-1.51 86.444 12.05 121.25 38.4 20.2 17.167 50.492 14.709 67.66-5.491s14.709-50.492-5.491-67.66c-4.26-3.62-9.12-6.469-14.36-8.417l-0.608-0.192c-80.744-37.29-129.29-121.35-121.25-209.92 0.065-0.433 0.162-0.861 0.288-1.28 0.346-2.47 0.517-4.962 0.512-7.456 0-26.51-21.49-48-48-48s-48 21.49-48 48c-5e-3 2.505 0.166 5.007 0.512 7.488 0.126 0.408 0.223 0.826 0.288 1.248 8.088 88.607-40.469 172.71-121.25 210.02-24.993 8.838-38.09 36.263-29.252 61.256s36.263 38.09 61.256 29.252c5.548-1.962 10.688-4.927 15.164-8.748 34.808-26.374 77.632-39.967 121.28-38.496z"/>
        <path
            d="m296.29 107.68c-8.837 0-16-7.163-16-16v-0.16c0.088-8.836 7.323-15.928 16.159-15.839 8.836 0.088 15.928 7.323 15.839 16.159-0.087 8.774-7.224 15.84-15.998 15.84z"/>
        <path
            d="m440.93 391.68c-8.837-8e-3 -15.994-7.177-15.986-16.014 2e-3 -2.816 0.748-5.582 2.162-8.018 19.715-33.908 30.114-72.425 30.144-111.65 0.015-12.946-1.174-25.866-3.552-38.592-1.819-8.647 3.716-17.132 12.363-18.951s17.132 3.716 18.951 12.363c0.04 0.19 0.076 0.381 0.109 0.572 2.777 14.706 4.159 29.642 4.128 44.608-0.036 44.878-11.937 88.948-34.496 127.74-2.867 4.916-8.131 7.938-13.823 7.936z"/>
        <path
            d="m456.29 184c-6.537-2e-3 -12.416-3.98-14.848-10.048-4.061-10.209-8.852-20.112-14.336-29.632-4.096-7.83-1.069-17.498 6.761-21.594 7.367-3.854 16.458-1.423 20.919 5.594 6.25 10.853 11.725 22.135 16.384 33.76 3.28 8.205-0.713 17.516-8.918 20.796-3e-3 1e-3 -7e-3 3e-3 -0.01 4e-3 -1.892 0.759-3.914 1.139-5.952 1.12z"/>
        <path
            d="m79.009 88.96c-8.837 0.018-16.015-7.13-16.033-15.967-0.01-4.689 2.038-9.146 5.601-12.193 45.839-39.332 104.27-60.907 164.67-60.8 8.837 0 16 7.163 16 16s-7.163 16-16 16c-52.746-0.094-103.78 18.743-143.81 53.088-2.902 2.498-6.603 3.871-10.432 3.872z"/>
        <path
            d="m25.537 152.32c-8.837-0.015-15.988-7.191-15.973-16.027 5e-3 -2.824 0.757-5.597 2.181-8.037 6.271-10.846 13.387-21.182 21.28-30.912 5.611-6.839 15.705-7.835 22.544-2.224s7.835 15.705 2.224 22.544c-6.829 8.396-12.981 17.32-18.4 26.688-2.866 4.938-8.146 7.975-13.856 7.968z"/>
        <path
            d="m233.57 512c-12.433-0.014-24.849-0.923-37.152-2.72-8.748-1.255-14.823-9.364-13.568-18.112s9.364-14.823 18.112-13.568c10.69 1.583 21.481 2.386 32.288 2.4 8.836-0.088 16.071 7.003 16.159 15.839 1e-3 0.054 1e-3 0.107 1e-3 0.161 1e-3 8.775-7.066 15.913-15.84 16z"/>
        <path
            d="m151.97 497.92c-1.843 4e-3 -3.672-0.31-5.408-0.928-56.756-20.443-104.39-60.402-134.4-112.74-4.418-7.67-1.782-17.47 5.888-21.888s17.47-1.782 21.888 5.888c26.257 45.758 67.945 80.674 117.6 98.496 8.324 2.966 12.668 12.118 9.702 20.442-2.276 6.387-8.33 10.646-15.11 10.63z"/>
        <path
            d="m406.88 301.09c-9.087-0.547-16.136-8.145-16-17.248v-0.32c1.519-9.096 2.311-18.298 2.368-27.52-0.052-7.489-0.543-14.969-1.472-22.4-0.997-8.78 5.312-16.706 14.092-17.703 8.716-0.99 16.603 5.222 17.684 13.927 1.084 8.684 1.65 17.425 1.696 26.176 0.063 10.155-0.773 20.296-2.496 30.304-0.035 8.218-6.725 14.852-14.943 14.817-0.31-1e-3 -0.619-0.012-0.929-0.033z"/>
        <path
            d="m394.21 201.6c-6.448-3e-3 -12.263-3.876-14.752-9.824-2.231-5.278-4.796-10.408-7.68-15.36-4.48-7.617-1.937-17.424 5.68-21.904s17.424-1.937 21.904 5.68c3.606 6.189 6.813 12.602 9.6 19.2 3.424 8.146-0.405 17.526-8.551 20.949-8e-3 4e-3 -0.017 7e-3 -0.025 0.01-1.953 0.831-4.054 1.256-6.176 1.249z"/>
        <path
            d="m124.77 133.44c-8.837 0-16-7.163-16-16 0-5.036 2.371-9.778 6.4-12.8 14.523-11.249 30.579-20.365 47.68-27.072 8.258-3.869 18.089-0.312 21.958 7.946 0.885 1.888 1.404 3.926 1.53 6.006v11.424l-10.4 3.904c-14.846 5.669-28.768 13.509-41.312 23.264-2.823 2.177-6.291 3.348-9.856 3.328z"/>
        <path
            d="m80.929 184.32c-8.836 0.044-16.035-7.084-16.079-15.921-0.014-2.736 0.675-5.429 1.999-7.823 3.491-6.305 7.404-12.367 11.712-18.144 5.305-7.067 15.334-8.495 22.401-3.19 7.063 5.302 8.494 15.323 3.199 22.39-3.445 4.611-6.567 9.454-9.344 14.496-2.814 5.035-8.12 8.164-13.888 8.192z"/>
        <path
            d="m233.57 448c-6.968 0.017-13.933-0.335-20.864-1.056-8.744-1.273-14.801-9.394-13.528-18.138 1.194-8.201 8.453-14.133 16.728-13.67 5.763 0.587 11.552 0.875 17.344 0.864 8.836-0.088 16.071 7.003 16.159 15.839 1e-3 0.054 1e-3 0.107 1e-3 0.161 1e-3 8.775-7.066 15.913-15.84 16z"/>
        <path
            d="m167.97 435.2c-1.968-6e-3 -3.918-0.364-5.76-1.056-18.132-6.93-34.998-16.801-49.92-29.216-6.922-5.492-8.082-15.556-2.589-22.479s15.556-8.082 22.479-2.589c0.201 0.159 0.398 0.323 0.59 0.492 12.243 10.17 26.083 18.247 40.96 23.904 8.253 3.157 12.385 12.407 9.228 20.66-2.375 6.209-8.341 10.303-14.988 10.284z"/>
    </g>
</svg>


