import React, {Component, Suspense} from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";

/////////////////////////////////////////////////////////////////////////
// BrowserRouter would be preferred over HashRouter, but BrowserRouter
// would require configuring the server. So we will use HashRouter here.
// Please change to BrowserRouter if you have your own backend server.
///////////////////////////////////////////////////////////////////////////

import {BrowserRouter as Router, Switch} from "react-router-dom";

import {connect} from "react-redux";

import moment from 'moment-timezone';

import {logout} from "../../utils/apiUtils";
import {changeCenter} from "../../actions/auth";
import {clearRequestFeedback, updateCurrentTime, updateLastActivity} from "../../actions/global";
import "./app.css";
import Login from "../login/Login";
import ErjRoute from "../misc/ErjRoute";
import {getUserConfirmation} from "./getUserConfirmation";
import {getGlobalAppData} from "../../selectors/appSelector";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from "react-helmet";
import getFonts from "../../assets/fonts/fonts";
import ErjModal from "../misc/ErjModal";
import MessagesBar from "../feed/MessagesBar";
import {getLangDirection} from "../../utils/generalUtils";

const NotFound = React.lazy(() => import("../misc/NotFound"));

// console.log(process.env);

class App extends Component {

    constructor(props) {
        super(props);
        this.closeSnackBar = this.closeSnackBar.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

        this.state = {
            windowWidth: window.innerWidth
        };

        this.currentMousePos = [0, 0];
        this.lastMousePos = [...this.currentMousePos];
        this.lastRecordedPage = window.location.pathname;
        this.lastActivityUpdated = 0;

        window.addEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
        if (this.props.user && !this.props.scriptId) {
            this.initAppTimeZone(props.time_zone_offset);
        }
        setInterval(() => this.props.dispatch(updateCurrentTime()), 10000);
        setInterval(() => {
            if (this.lastRecordedPage !== window.location.pathname || (this.lastActivityUpdated < (Date.now() - 10000) && (this.lastMousePos[0] !== this.currentMousePos[0] || this.lastMousePos[1] !== this.currentMousePos[1]))) {
                this.lastRecordedPage = window.location.pathname;
                this.lastActivityUpdated = Date.now();
                this.lastMousePos = [...this.currentMousePos];
                const currentPage = window.location.pathname.replace('/app', '');
                if (currentPage !== '/login') {
                    this.props.dispatch(updateLastActivity(currentPage));
                }
            }
        }, 1000);
    }

    componentDidMount() {
        document.body.classList.add('lang_' + this.props.lang);
        document.body.classList.add('langDir_' + getLangDirection(this.props.lang));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.lang !== nextProps.lang) {
            document.body.classList.remove('lang_' + this.props.lang);
            document.body.classList.add('lang_' + nextProps.lang);
            document.body.classList.remove('langDir_' + getLangDirection(this.props.lang));
            document.body.classList.add('langDir_' + getLangDirection(nextProps.lang));
        }
        if (((!this.props.user && nextProps.user) || (this.props.time_zone_offset !== nextProps.time_zone_offset)) && !nextProps.scriptId) {
            this.initAppTimeZone(nextProps.time_zone_offset);
        }
    }

    initAppTimeZone(time_zone_offset) {
        const hours_offset = Math.round(time_zone_offset / 60 / 60) * (-1);
        moment.tz.setDefault('Etc/GMT' + (hours_offset >= 0 ? '+' : '') + hours_offset);
        this.props.dispatch(updateCurrentTime())
    };

    handleMouseMove = event => {
        this.currentMousePos = [event.clientX, event.clientY];
    };

    handleLogout() {
        const {user} = this.props;
        logout(user);
    }

    handleChangeCenter(center_id) {
        this.props.dispatch(changeCenter(center_id));
    }

    closeSnackBar(event, reason, callback) {
        this.props.dispatch(clearRequestFeedback(reason));
        if (callback) {
            console.log('callback', callback);
            setTimeout(() => callback(), 1000);
        }
    }

    render() {
        const {
            user, pages, isLoggingIn, first_day_of_month, months, center_shifts, center_is_parent, reqStatus, childCentersMenu, attached_center_id
            , lang, notificationApproval, workDays, thisMonthDaysWorked, employeeCapabilities, notification_medium_priority, attached_center, login_to_ui
        } = this.props;

        let routes = [
            <ErjRoute path={process.env.REACT_APP_ROUTE_PATH + "/login"} key="login" page={pages.login}
                      component={Login}
                      handleLogout={this.handleLogout}
                      handleChangeCenter={(center_id) => this.handleChangeCenter(center_id)}
                      closeSnackBar={this.closeSnackBar}
                      appProps={{
                          user,
                          pages,
                          isLoggingIn,
                          first_day_of_month,
                          months,
                          center_shifts,
                          reqStatus,
                          lang,
                          notificationApproval,
                          workDays,
                          thisMonthDaysWorked,
                          employeeCapabilities,
                          notification_medium_priority,
                          attached_center,
                          login_to_ui
                      }}/>
        ];
        if (user) {
            Object.keys(pages).map(page => {
                if (page === 'login' || pages[page].dropdown || this.props.disabledFeatures.pages.indexOf(page) > -1)
                    return;
                let addProps = !['settings', 'ManagerStore', 'manageChallenges', 'commissionsPlan'].includes(page) ? {exact: true} : [];
                routes.push(<ErjRoute {...addProps} path={process.env.REACT_APP_ROUTE_PATH + pages[page].path}
                                      permissions={user.permissions} key={page} page={pages[page]}
                                      component={pages[page].component} handleLogout={this.handleLogout}
                                      handleChangeCenter={(center_id) => this.handleChangeCenter(center_id)}
                                      closeSnackBar={this.closeSnackBar} handleMouseMove={this.handleMouseMove}
                                      appProps={{
                                          user,
                                          pages,
                                          isLoggingIn,
                                          first_day_of_month,
                                          months,
                                          center_shifts,
                                          reqStatus,
                                          childCentersMenu,
                                          attached_center_id,
                                          lang,
                                          notificationApproval,
                                          workDays,
                                          thisMonthDaysWorked,
                                          employeeCapabilities,
                                          notification_medium_priority,
                                          attached_center,
                                          login_to_ui
                                      }}/>);
            });
        }

        return [
            <Helmet key={'helmet'} style={[{
                "cssText": getFonts()
            }]}>
                <meta name="viewport"
                      content={`width=${this.state.windowWidth < 1200 ? '360' : 'device-width'}, user-scalable=no`}/>
            </Helmet>
            ,
            user && <MessagesBar key={'messageBar'}/>,
            <Router getUserConfirmation={getUserConfirmation} key={'router'}>
                <Switch>
                    {routes}
                    <Suspense fallback={<CircularProgress className="pageLoadProgressCircle" size={100}/>}>
                        <NotFound center_is_parent={center_is_parent} user={user}
                                  component={NotFound} login_to_ui={login_to_ui}
                                  lang={lang}/>
                    </Suspense>
                </Switch>
            </Router>,
            <ErjModal key={'erjModal'}/>
        ];
    }
}

App.propTypes = {
    user: PropTypes.object,
    dispatch: PropTypes.func.isRequired
};

App.contextTypes = {
    store: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
    return getGlobalAppData(state, props);
};

export default connect(mapStateToProps)(App);
