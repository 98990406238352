import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {UNIT_TYPE} from "../../utils/metrics";
import {numberWithCommas, metricValueRepresentation, shortNum, round} from "../../utils/generalUtils";

class MetricValueDisplay extends Component {
    render() {
        return MetricValueDisplay.getRepresentation(this.props);
    }

    static getRepresentation({value, center_currency_symbol, unitType, shortNumOpts, roundOpts, makeGraphFriendly}) {
        if (value === '' || isNaN(Number(value))) {
            return value === undefined ? '' : value;
        }
        if (unitType === UNIT_TYPE.PERCENTAGE)
            value *= 100;
        if (roundOpts)
            value = round(value, roundOpts.decimals);
        if (unitType === UNIT_TYPE.SECONDS) {
            let hours = Math.floor(value / 3600);
            let minutes = Math.floor((value % 3600) / 60);
            let seconds = Math.floor((value % 3600) % 60);
            value = (hours > 0 ? hours + ':' : '') + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
            if (shortNumOpts && shortNumOpts.maxLength && value.length > shortNumOpts.maxLength + 3) {
                value = shortNum({
                    showToolTip: !makeGraphFriendly,
                    maxLength: shortNumOpts.maxLength,
                    unitType,
                    num: value,
                    makeGraphFriendly
                });
            }
            return value;
        }
        else {
            return metricValueRepresentation(shortNumOpts ? shortNum({
                showToolTip: !makeGraphFriendly,
                center_currency_symbol,
                unitType,
                num: value, ...shortNumOpts
            }) : numberWithCommas(value), unitType, center_currency_symbol, makeGraphFriendly);
        }
    }
}

MetricValueDisplay.propTypes = {
    value: PropTypes.number.isRequired,
    unitType: PropTypes.number,
    roundOpts: PropTypes.shape({
        decimals: PropTypes.number,
    }),
    shortNumOpts: PropTypes.shape({
        showToolTip: PropTypes.bool,
        decimals: PropTypes.number,
        shortThreshold: PropTypes.number,
        suffix: PropTypes.string,
        mill_shortThreshold: PropTypes.number,
        mill_suffix: PropTypes.string,
        key: PropTypes.string,
        maxLength: PropTypes.number
    })
};

function mapStateToProps(state) {
    return {center_currency_symbol: state.auth.sessionData.center_currency_symbol};
}

export {MetricValueDisplay};
export default connect(mapStateToProps)(MetricValueDisplay);