import {disabledButtons} from "./controls";
import {callApi} from "../utils/apiUtils";
import {loginSuccess} from "./auth";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_SAVING} from "./global";

export const RECEIVED_SHIFTS_DATA = "RECEIVED_SHIFTS_DATA";
export const RECEIVED_UPDATED_SHIFTS_DATA = "RECEIVED_UPDATED_SHIFTS_DATA";
export const RECEIVED_DELETED_SHIFT_DATA = "RECEIVED_DELETED_SHIFT_DATA";

function receivedShiftsData(shiftsData) {
    return {type: RECEIVED_SHIFTS_DATA, payload: {shiftsData}};
}

function receivedUpdatedShiftsData(shiftsData) {
    return {type: RECEIVED_UPDATED_SHIFTS_DATA, payload: {shiftsData: shiftsData}};
}

function receivedDeletedShiftData(shiftsData) {
    return {type: RECEIVED_DELETED_SHIFT_DATA, payload: {shiftsData: shiftsData}};
}

export function getShiftsData() {
    return callApi('getShiftsData', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch(receivedShiftsData(responseData.data));
        }
    });
}

export function setShiftsData(shiftData, buttonName) {
    let btnName = buttonName;
    return callApi('createOrUpdateShifts', shiftData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            let {data, ...sessionData} = responseData;
            dispatch(loginSuccess(sessionData));
            dispatch(receivedUpdatedShiftsData(data));
            dispatch(disabledButtons(btnName, false));
            dispatch({type: API_REQ_SAVE_SUCCESS})
        }
    });
}

export function deleteShift(shiftData) {
    return callApi('deleteShiftsData', shiftData, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            let {data, ...sessionData} = responseData;
            dispatch(loginSuccess(sessionData));
            dispatch(receivedDeletedShiftData(data));
        }
    });
}