import React, {Component} from "react";
import {connect} from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {setReadAlerts} from "../../actions/alerts";
import moment from 'moment';

import {t} from 'react-i18nify'
const _ = require('lodash');

class PageAlert extends Component {
    dismissAlert = () => {
        this.props.dispatch(setReadAlerts(this.props.alerts.map(alert => alert.id), this.props.user_id));
    };

    render() {
        const {alerts, months} = this.props;
        const alert = alerts[0];
        const {params} = alert;
        return (
            <div className={'pageAlertContainer'}>
                <div className={'pageAlert'}>
                    <div className={'alertContent'}>
                        {(() => {
                            if (alert.section === 'store' && params.subSection === 'budgets') {
                                const monthsMap = months && _.keyBy(months, month => moment(month.start).format('YYYY-MM-01'));
                                const customMonth = monthsMap && monthsMap[params.month];
                                return t('alerts.missing_budget', {month: customMonth ? customMonth.name : moment(params.month).format('MMMM')})
                            }
                            if (alert.section === 'weekly_schedule') {
                                return t('alerts.missing_weekly_schedule', {date_range: moment(params.week).format('L') + '-' + moment(params.week).add(6, 'days').format('L')});
                            }
                            return false;
                        })()}
                    </div>
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={"closeBtn"}
                        onClick={this.dismissAlert}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {auth} = state;
    return {
        user_id: auth.sessionData.user_id,
        months: auth.sessionData.additionalData.months
    };
};

export default connect(mapStateToProps)(PageAlert);