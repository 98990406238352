import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import {UNIT_TYPE} from "./metrics";
import {t} from 'react-i18nify'

const _ = require('lodash');

import {fraction, floor, multiply, divide} from 'mathjs';

export const SHIFT_COLORS = {
    VIOLET_RED: '250,72,125',
    SPRING_GREEN: '42,213,130',
    ORANGE: '247, 146, 35',
    DARK_PURPLE: '102, 45, 145',
    BRONZE: '166, 124, 82',
    PINK: '255, 0, 255',
    GRAY: '128, 128, 128',
};

const htmlEntityMatch = /(&[^;]+;)/g;

export function unicodeHtmlEntity(t) {
    if (!t) {
        return '';
    }
    const matches = t.match(htmlEntityMatch);
    if (!matches) {
        return t;
    }

    return matches.reduce((result, match) => {
        let hex = match.charAt(2) === 'x';
        let base = hex ? 16 : 10;
        let pos = hex ? 3 : 2;
        let numericValue = parseInt(match.slice(pos, -1), base);
        return result + String.fromCharCode(numericValue);
    }, '');
}

/**
 *
 * @param showToolTip
 * @param num
 * @param decimals
 * @param shortThreshold
 * @param suffix
 * @param mill_shortThreshold
 * @param mill_suffix
 * @param key
 * @param maxLength
 * @param center_currency_symbol
 * @param unitType
 * @returns {*|number}
 */
export function shortNum({showToolTip, num, decimals = 2, shortThreshold = 1000, suffix = 'K', mill_shortThreshold = 1000000, mill_suffix = 'M', key = 'shortNum', maxLength, center_currency_symbol, unitType, makeGraphFriendly}) {
    let number = num;
    let shortened = false;
    let shortSuffix = suffix;
    let tooltipTitle;

    const getTotalExpectedLength = () => Math.floor(number).toString().length + 1 + decimals + (shortSuffix ? 1 : 0) + (unitType !== UNIT_TYPE.NUMBER ? 1 : 0);
    let total_expected_length = getTotalExpectedLength();
    const roundAccordingToMaxLength = () => {
        return round(number, Math.max(0, Math.min(decimals, maxLength - Math.floor(number).toString().length - 1)));
    }

    if (unitType !== UNIT_TYPE.SECONDS) {
        number = Number(num);
        if (maxLength) {
            if (total_expected_length > maxLength) {
                number = roundAccordingToMaxLength();
                total_expected_length = getTotalExpectedLength();
                if (total_expected_length > maxLength) {
                    if (Math.abs(number) >= 1000000 && (total_expected_length - maxLength) > 2) {
                        number = number / 1000000;
                        shortSuffix = mill_suffix;
                        shortened = true;
                    } else if (Math.abs(number) >= 1000) {
                        number = number / 1000;
                        shortened = true;
                    }
                }
            }
        } else if (Math.abs(number) >= mill_shortThreshold) {
            number = number / 1000000;
            shortSuffix = mill_suffix;
            shortened = true;
        } else if (Math.abs(number) >= shortThreshold) {
            number = number / 1000;
            shortened = true;
        }

        if (shortened) {
            number = maxLength ? roundAccordingToMaxLength() : round(number, decimals);
        }
        showToolTip = showToolTip && number !== Number(num);
        number = numberWithCommas(number);

        tooltipTitle = unitType ? metricValueRepresentation(numberWithCommas(num), unitType, center_currency_symbol) : numberWithCommas(num);
        const numberWithSuffix = shortened ? number + '' + shortSuffix : number;

        number = showToolTip ? (
            <Tooltip key={key} className={'number-tooltip'}
                     title={tooltipTitle}><span>{numberWithSuffix}</span></Tooltip>
        ) : numberWithSuffix;
    } else {
        number = showToolTip ? (
            <Tooltip key={key} className={'number-tooltip inlineLocalText'}
                     title={number}><span>{[number.substring(0, number.indexOf(':')),
                <span className={'hrs'} key={key + '_hrs'}>{t('time.hrs')}</span>]}</span></Tooltip>
        ) : (
            makeGraphFriendly ? number.substring(0, number.indexOf(':')) + t('time.hrs') : [number.substring(0, number.indexOf(':')),
                <span className={'hrs'}>{t('time.hrs')}</span>]
        );
    }
    return number;
}

export function round(num, decimals) {
    if ((!decimals && decimals !== 0) || typeof(decimals) === 'object')
        decimals = 2;
    let multiplier = Math.pow(10, decimals);
    return divide(floor(multiply(fraction(num || 0), multiplier)), multiplier);
}


export function withNewLine(text) {
    return text.split('\n').map((item, index) => {
        return (<span key={index}>{item}<br/></span>)
    });
}

export function getSplitTimeDiff(start_time, end_time) {
    let timeDff = [];
    end_time = end_time && end_time !== '0000-00-00 00:00:00' ? moment(end_time) : null;
    if (end_time && end_time.isAfter(start_time)) {
        timeDff[0] = _.padStart(end_time.diff(start_time, 'days'), 2, 0);
        timeDff[1] = _.padStart(end_time.subtract(timeDff[0], 'days').diff(start_time, 'hours'), 2, '0');
        timeDff[2] = _.padStart(Math.floor(end_time.subtract(timeDff[1], 'hours').diff(start_time, 'minutes', true)), 2, '0');
    }
    else
        timeDff = ['00', '00', '00'];
    return timeDff;
}

export function convertFloatHourToTime(floatHour) {
    let hourPart = Math.floor(floatHour);
    let minutePart = Math.round((floatHour - hourPart) * 60);
    return hourPart + ':' + (minutePart < 10 ? '0' + minutePart : minutePart);
}

/**
 * get the time portions from the number of total seconds
 *
 * @param value
 * @returns {{hours: number, seconds: number, minutes: number, value: number}}
 */
export function convertSecondsToTime(value) {
    let hours = Math.floor(value / 60 / 60);
    let minutes = Math.floor((value - (hours * 3600)) / 60);
    let seconds = value - (hours * 3600) - (minutes * 60);
    return {value, hours, minutes, seconds};
}

export function numberWithCommas(x) {
    const num = Number(x).toString().split('.');
    num[0] = num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num.join('.');
}

export function metricValueRepresentation(value, representation, currency, makeGraphFriendly) {
    let symbol;
    switch (representation) {
        case UNIT_TYPE.MONEY:
            symbol = unicodeHtmlEntity(currency);
            break;
        case UNIT_TYPE.PERCENTAGE:
            symbol = '%';
            break;
    }

    const result = symbol ? (representation === 2 ? [symbol, value] : [value, symbol]) : value;
    return Array.isArray(result) && makeGraphFriendly ? result.join('') : result;
}

const buildChildCenterMenu = (childCentersData, center_id, lvl) => {
    if (childCentersData.allowedCenterIds.indexOf(center_id + '') === -1) {
        return [];
    }
    let menu = [{id: center_id, title: childCentersData.centersDetails[center_id].title, lvl}];
    if (childCentersData.child_centers && childCentersData.child_centers[center_id]) {
        menu = menu.concat(
            childCentersData.child_centers[center_id]
                .sort((a, b) => childCentersData.centersDetails[a].title.localeCompare(childCentersData.centersDetails[b].title))
                .reduce((prev, child_center_id) => prev.concat(buildChildCenterMenu(childCentersData, child_center_id, lvl + 1)), [])
        );
    }
    return menu;
};

const getChildCenterMenuRoots = (childCentersData) => {
    const children = childCentersData.child_centers ? Object.values(childCentersData.child_centers).reduce((prev, list) => prev.concat(list), []) : [];
    return Object.keys(childCentersData.centersDetails).filter(center_id => children.indexOf(Number(center_id)) === -1);
};

export function getChildCentersMenu({child_centers, centersDetails, allowedCenterIds = [], root_center_id}) {
    return root_center_id ? (
        buildChildCenterMenu({
            child_centers,
            centersDetails,
            allowedCenterIds
        }, root_center_id, 0)
    ) : (
        getChildCenterMenuRoots({child_centers, centersDetails, allowedCenterIds})
            .sort((a, b) => (centersDetails[a].title || '').localeCompare(centersDetails[b].title))
            .reduce((menu, root_center_id) => menu.concat(buildChildCenterMenu({
                child_centers,
                centersDetails,
                allowedCenterIds
            }, root_center_id, 0)), [])
    );
}

const SUPPORTED_LOCALES = {
    "en": "English",
    "he": "עברית",
    "sk": "Slovenský",
    "es": "Español",
    "pt": "Portugues"
};

//
// Domain prefix to blocked the "he" hebrew option
// kicdev 9.2.2024
//////////////////////////////////////////////////
var NOHEBREW_LIMITED = ["44" , "belgrad", "leader" ] ;

const RTL_LANGS = ["he","ar"] ;

export const getLangDirection = (lang) => {
    if(RTL_LANGS.indexOf(lang)>-1) 
    {
        return "rtl";
    }
    else
    {
        return "ltr";
    }
};

export {SUPPORTED_LOCALES, NOHEBREW_LIMITED};