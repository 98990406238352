import {callApi} from "../utils/apiUtils";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_SAVING} from "./global";
import {loginSuccess} from "./auth";

export const RESET_EDIT_COMMISSION_PLAN = 'RESET_EDIT_COMMISSION_PLAN';
export const EDIT_COMMISSION_PLAN = 'EDIT_COMMISSION_PLAN';
export const RECEIVED_COMMISSION_SETTINGS = 'RECEIVED_COMMISSION_SETTINGS';
export const RECEIVED_COMMISSION_PLAN = 'RECEIVED_COMMISSION_PLAN';
export const RECEIVED_COMMISSION_PLANS = 'RECEIVED_COMMISSION_PLANS';
export const RECEIVED_MONTH_COMMISSION_DATA = 'RECEIVED_MONTH_COMMISSION_DATA';
export const RECEIVED_DATA_FOR_COMMISSION_SIM = 'RECEIVED_DATA_FOR_COMMISSION_SIM';
export const RECEIVED_PREVIOUS_COMMISSIONS = 'RECEIVED_PREVIOUS_COMMISSIONS';


export function receivedCommissionPlans(commission_plans) {
    return {type: RECEIVED_COMMISSION_PLANS, commission_plans};
}

export function getDataForCommissionCalc(data, callback) {
    return callApi('getDataForCommissionCalc', data, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: RECEIVED_DATA_FOR_COMMISSION_SIM, data_for_commission_sim: responseData.data});

            if (callback)
                callback();
        }
    });
}

export function getPreviousCommissions(callback) {
    return callApi('getPreviousCommissions', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: RECEIVED_PREVIOUS_COMMISSIONS, commissions: responseData.commissions});

            if (callback)
                callback();
        }
    });
}

export function getMonthCommissionData(month, callback) {
    return callApi('getMonthCommissionData', {month}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({
                type: RECEIVED_MONTH_COMMISSION_DATA,
                plans: responseData.data.plans,
                commissions: responseData.data.commissions,
                month
            });

            if (callback)
                callback();
        }
    });
}

export function getCommissionSettings(callback) {
    return callApi('getCommissionSettings', {}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: RECEIVED_COMMISSION_SETTINGS, commission_settings: responseData.data.commission_settings});

            if (callback)
                callback();
        }
    });
}

export function setCommissionSettings(commissionSettings, callback) {
    return callApi('setCommissionSettings', commissionSettings, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            let {data, ...sessionData} = responseData;
            if (sessionData.attached_center_id) {
                dispatch(loginSuccess(sessionData));
            }
            dispatch({type: API_REQ_SAVE_SUCCESS});
            if (callback) {
                callback();
            }
        }
    });
}

export function getCommissionPlan(editParams, callback) {
    return callApi('getCommissionPlan', {editParams}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: RECEIVED_COMMISSION_PLAN, commission_plan: responseData.data.commission_plan});

            if (callback)
                callback();
        }
    });
}

export function setCommissionPlan(data, callback) {
    return callApi('setCommissionPlan', data, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            if (callback) {
                callback(responseData.data.id);
            }
        }
    });
}

export function setCommissionPlanDisplayColumns(data, callback) {
    return callApi('setCommissionPlanDisplayColumns', data, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            if (callback) {
                callback();
            }
        }
    });
}

export function setCommissionPlanComponentNumber(data, callback) {
    return callApi('setCommissionPlanComponentNumber', data, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            if (callback) {
                callback();
            }
        }
    });
}

export function setCommissionPlanNotes(data, callback) {
    return callApi('setCommissionPlanNotes', data, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            if (callback) {
                callback(responseData.data.id);
            }
        }
    });
}

export function refillCommissions(month, callback) {
    return callApi('refillCommissions', {month}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(getMonthCommissionData(month, callback));
        }
    });
}

export function removeCommissionPlan(data, callback) {
    return callApi('removeCommissionPlan', data, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            if (callback) {
                callback(responseData.data.id);
            }
        }
    });
}