import icomoon from './icomoon/icomoon.ttf';
import OpenSansRegular from './Open_Sans/OpenSans-Regular.ttf';
import OpenSansBold from './Open_Sans/OpenSans-Bold.ttf';
import OpenSansBoldItalic from './Open_Sans/OpenSans-BoldItalic.ttf';
import OpenSansItalic from './Open_Sans/OpenSans-Italic.ttf';
import OpenSansLight from './Open_Sans/OpenSans-Light.ttf';
import OpenSansSemibold from './Open_Sans/OpenSans-Semibold.ttf';
import OpenSansHebrewRegular from './Open_Sans_Hebrew/OpenSansHebrew-Regular.ttf';
import OpenSansHebrewBold from './Open_Sans_Hebrew/OpenSansHebrew-Bold.ttf';
import OpenSansHebrewBoldItalic from './Open_Sans_Hebrew/OpenSansHebrew-BoldItalic.ttf';
import OpenSansHebrewItalic from './Open_Sans_Hebrew/OpenSansHebrew-Italic.ttf';
import OpenSansHebrewLight from './Open_Sans_Hebrew/OpenSansHebrew-Light.ttf';

const getLocalUrl = (fontUrl) => (process.env.PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL) + fontUrl.slice(1);

/**
 * generates @font-face definitions with fallback to public url in case of CORS blockage.
 *
 * @returns {string}
 */
export default function () {
    return `
        @font-face {
            font-family: 'icomoon';
            src: url('${icomoon}') format('truetype'),
                url('${getLocalUrl(icomoon)}') format('truetype');
            font-weight: normal;
            font-style: normal;
            font-display: block;
        }
        
        @font-face {
            font-family: 'OpenSans';
            src: url('${OpenSansRegular}') format('truetype'),
                url('${getLocalUrl(OpenSansRegular)}') format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'OpenSans';
            src: url('${OpenSansBold}') format('truetype'),
                url('${getLocalUrl(OpenSansBold)}') format('truetype');
            font-weight: bold;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'OpenSans';
            src: url('${OpenSansBoldItalic}') format('truetype'),
                url('${getLocalUrl(OpenSansBoldItalic)}') format('truetype');
            font-weight: bold;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'OpenSans';
            src: url('${OpenSansItalic}') format('truetype'),
                url('${getLocalUrl(OpenSansItalic)}') format('truetype');
            font-weight: normal;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'OpenSans-Light';
            src: url('${OpenSansLight}') format('truetype'),
                url('${getLocalUrl(OpenSansLight)}') format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'OpenSans-Semibold';
            src: url('${OpenSansSemibold}') format('truetype'),
                url('${getLocalUrl(OpenSansSemibold)}') format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'OpenSansHebrew';
            src: url('${OpenSansHebrewRegular}') format('truetype'),
                url('${getLocalUrl(OpenSansHebrewRegular)}') format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'OpenSansHebrew';
            src: url('${OpenSansHebrewBold}') format('truetype'),
                url('${getLocalUrl(OpenSansHebrewBold)}') format('truetype');
            font-weight: bold;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'OpenSansHebrew';
            src: url('${OpenSansHebrewBoldItalic}') format('truetype'),
                url('${getLocalUrl(OpenSansHebrewBoldItalic)}') format('truetype');
            font-weight: bold;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'OpenSansHebrew';
            src: url('${OpenSansHebrewItalic}') format('truetype'),
                url('${getLocalUrl(OpenSansHebrewItalic)}') format('truetype');
            font-weight: normal;
            font-style: italic;
        }
        
        @font-face {
            font-family: 'OpenSansHebrew-Light';
            src: url('${OpenSansHebrewLight}') format('truetype'),
                url('${getLocalUrl(OpenSansHebrewLight)}') format('truetype');
            font-weight: normal;
            font-style: normal;
        }
    `;
}