import {callApi} from "../utils/apiUtils";
import {API_REQ_SAVE_SUCCESS, API_REQUEST_SAVING} from "./global";

export const RECEIVED_USER_GOALS_DATA = "RECEIVED_USER_GOALS_DATA";
export const RECEIVED_TEAM_PERFORMANCE_CHART_DATA = "RECEIVED_TEAM_PERFORMANCE_CHART_DATA";
export const RECEIVED_TIMELINE_CHARTS_DATA = "RECEIVED_TIMELINE_CHARTS_DATA";
export const SET_TIMELINE_CHARTS_SHOW_AS_GOAL_PERCENT = "SET_TIMELINE_CHARTS_SHOW_AS_GOAL_PERCENT";
export const RECEIVED_RECORDS = "RECEIVED_RECORDS";
export const ADD_RECORD_SUCCESS = "ADD_RECORD_SUCCESS";
export const RECEIVED_TEAM_METRIC_GRAPH_DATA = "RECEIVED_TEAM_METRIC_GRAPH_DATA";
export const UPDATE_RECORD_SUCCESS = "UPDATE_RECORD_SUCCESS";
export const DELETE_RECORD_SUCCESS = "DELETE_RECORD_SUCCESS";
export const RECEIVED_LEAD_TYPE_FIELDS = "RECEIVED_LEAD_TYPE_FIELDS";
export const CLEAR_TEAM_METRIC_GRAPH_DATA = "CLEAR_TEAM_METRIC_GRAPH_DATA";

function receivedGoalsData(userGoals) {
    return {type: RECEIVED_USER_GOALS_DATA, payload: userGoals};
}

function receivedTeamPerformanceChartData(metric_id, chartData) {
    return {type: RECEIVED_TEAM_PERFORMANCE_CHART_DATA, metric_id, chartData};
}

function receivedTeamMetricGraphData(metricGraphData) {
    return {type: RECEIVED_TEAM_METRIC_GRAPH_DATA, metricGraphData};
}

export function clearTeamMetricGraphData() {
    return {type: CLEAR_TEAM_METRIC_GRAPH_DATA};
}

function receivedTimelineChartsData(chartsData) {
    return {type: RECEIVED_TIMELINE_CHARTS_DATA, chartsData};
}

function receivedRecords(records) {
    return {type: RECEIVED_RECORDS, records};
}

function receivedLeadTypeFields(leadTypeFields, records) {
    return {type: RECEIVED_LEAD_TYPE_FIELDS, leadTypeFields, records};
}

export function getGoalsData(filterData, cbFunc) {
    return callApi('getGoalsData', filterData, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            if (cbFunc) {
                cbFunc();
            }
            dispatch(receivedGoalsData(responseData.data));
        }
    });
}

export function saveUpdatedGoals(updatedData, callback) {
    return callApi('saveUpdatedGoals', updatedData, (dispatch, requestData) => {
        dispatch({type: API_REQUEST_SAVING});
    }, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            dispatch({type: API_REQ_SAVE_SUCCESS});
        }
        callback();
    });
}

export function addRecord(recordData, suppressSuccessMsg, callback) {
    return callApi('addRecord', recordData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            callback(responseData);
            if (!suppressSuccessMsg) {
                dispatch({type: API_REQ_SAVE_SUCCESS});
            }
        }
    });
}

export function updateRecord(recordData) {
    return callApi('updateRecord', recordData, (dispatch, requestData) => dispatch({type: API_REQUEST_SAVING}), (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: UPDATE_RECORD_SUCCESS, data: {...responseData.data}});
            dispatch({type: API_REQ_SAVE_SUCCESS});
        }
    });
}

export function deleteRecord(deletedRecord) {
    return callApi('deleteRecord', deletedRecord, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch({type: DELETE_RECORD_SUCCESS, data: {...responseData.data}});
            dispatch({type: API_REQ_SAVE_SUCCESS});
        }
    });

}

export function getFilteredRecords(data, callback) {
    return callApi('getFilteredRecords', data, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedRecords(responseData.records));
            callback(responseData);
        }
    });
}

export function getTeamPerformanceChartData(actionData) {
    return callApi('getTeamPerformanceChartData', actionData, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedTeamPerformanceChartData(actionData.metric_id, responseData.chartData));
        }
    });
}

export function getTeamMetricGraphData(actionData, callback) {
    return callApi('getTeamMetricGraphData', actionData, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedTeamMetricGraphData(responseData.metricGraphData));
            callback(responseData.metricGraphData);
        }
    });
}

export function getTimelinePerformanceChartData(actionData, callback) {
    return callApi('getTimelinePerformanceChartData', actionData, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedTimelineChartsData(responseData.chartsData));
            callback();
        }
    });
}

export function getLeadTypesFields(filters, callback) {
    return callApi('getLeadTypesFields', {filters}, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(receivedLeadTypeFields(responseData.leadTypeFields, responseData.records));
            callback(responseData);
        }
    });
}

export function getUsersHourlyGoals(actionData, callback) {
    return callApi('getUsersHourlyGoals', actionData, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            callback(responseData);
        }
    });
}
