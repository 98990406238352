import {
    RECEIVED_USER_GOALS_DATA,
    RECEIVED_TEAM_PERFORMANCE_CHART_DATA,
    RECEIVED_TIMELINE_CHARTS_DATA,
    RECEIVED_RECORDS,
    ADD_RECORD_SUCCESS,
    RECEIVED_TEAM_METRIC_GRAPH_DATA,
    CLEAR_TEAM_METRIC_GRAPH_DATA,
    RECEIVED_LEAD_TYPE_FIELDS,
    UPDATE_RECORD_SUCCESS, DELETE_RECORD_SUCCESS, SET_TIMELINE_CHARTS_SHOW_AS_GOAL_PERCENT
} from "../actions/performance";
import {RESET_LIVE_DATA} from "../actions/auth";

import moment from "moment";

const initialState = {
    lead_types: {},
    userDailyGoals: [],
    userGoals: [],
    users: [],
    records: [],
    selectedMonth: '',
    goalsChanges: [],
    teamPerformanceChartData: [],
    timelineChartsData: [],
    timelineChartsSelectedUsers: [-1],
    timelineChartsShowAsGoalPrcnt: false,
    teamMetricGraphData: [],
    leadTypeFields: [],
    refresh: new Date().getTime()
};

const checkToAddRecord = (record, filters) => {
    for (let keyVal of Object.entries(filters)) {
        if (keyVal[1].constructor === Array) {
            if (!moment(record.date).isBetween(keyVal[1][0], moment(keyVal[1][1]).add(1, 'days'), 'days', '[]')) {
                return false;
            }
        }
        else if (typeof(keyVal[1]) === 'object') {
            for (let [field_id, value] of Object.entries(keyVal[1])) {
                if (!record[keyVal[0]][field_id] || String(record[keyVal[0]][field_id]) !== String(value)) {
                    return false;
                }
            }
        }
        else if (String(keyVal[1]) !== String(record[keyVal[0]])) {
            return false;
        }
    }

    return true;
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function performance(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_USER_GOALS_DATA:
            return {
                ...state,
                lead_types: action.payload.lead_types,
                userGoals: action.payload.userGoals,
                userDailyGoals: action.payload.userDailyGoals,
                users: action.payload.users,
                selectedMonth: action.payload.selectedMonth,
                goalsChanges: action.payload.goalsChanges,
                refresh: new Date().getTime()
            };
        case RECEIVED_RECORDS:
            return {
                ...state,
                records: action.records
            };
        case ADD_RECORD_SUCCESS:
            return {
                ...state,
                records: (() => {
                    let nextRecords = state.records;
                    if (checkToAddRecord(action.data, action.data.filters)) {
                        delete action.data.filters;
                        action.data.created_on = action.data.date;
                        nextRecords.unshift(action.data);
                    }
                    return nextRecords;
                })()
            };
        case UPDATE_RECORD_SUCCESS:
            return {
                ...state,
                records: state.records.map(record => {
                    if (record.id === action.data.id) {
                        record = {...record, ...action.data};
                        if (action.data.date) {
                            record['created_on'] = (action.data.date === moment().format('YYYY-MM-DD') ? moment() : moment(action.data.date).hour(23).minute(59).second(59).millisecond(0)).format('YYYY-MM-DD HH:mm:ss');
                        }
                    }
                    return record;
                })
            };
        case DELETE_RECORD_SUCCESS:
            return {
                ...state,
                records: state.records.filter(record => {
                    return record.id !== action.data.id;
                })
            };
        case RECEIVED_TEAM_PERFORMANCE_CHART_DATA:
            return {
                ...state,
                teamPerformanceChartData: {metric_id: action.metric_id, chartData: action.chartData}
            };
        case RECEIVED_TEAM_METRIC_GRAPH_DATA:
            return {
                ...state,
                teamMetricGraphData: [...action.metricGraphData]
            };
        case CLEAR_TEAM_METRIC_GRAPH_DATA:
            return {
                ...state,
                teamMetricGraphData: []
            };
        case RECEIVED_LEAD_TYPE_FIELDS:
            return {
                ...state,
                records: action.records,
                leadTypeFields: action.leadTypeFields
            };
        case RECEIVED_TIMELINE_CHARTS_DATA:
            return {
                ...state,
                timelineChartsData: action.chartsData
            };
        case SET_TIMELINE_CHARTS_SHOW_AS_GOAL_PERCENT:
            return {
                ...state,
                timelineChartsShowAsGoalPrcnt: action.checked
            };
        default:
            return state;
    }
}