import {
    createSelectorWithDependencies as createSelector
} from 'reselect-tools';

const getActivitiesStats = (state, props) => state.activities.activities_stats;

export const makeGetActivityStatsSummary = () => {
    let getActivityStatsSummary = createSelector(
        [getActivitiesStats],
        (activitiesStats) => {
            let totalActivities = 0;
            let checkedInActivities = 0;
            let successfulActivities = 0;
            const activityStatsSummary = Object.values(activitiesStats).reduce((summary, {center_id, user_id, checked_in, points}) => {
                if (!summary[center_id]) {
                    summary[center_id] = {
                        center_id,
                        byUser: {},
                        total: 0,
                        checked_in: 0,
                        success: 0
                    };
                }
                if (!summary[center_id].byUser[user_id]) {
                    summary[center_id].byUser[user_id] = {
                        user_id,
                        total: 0,
                        checked_in: 0,
                        success: 0
                    }
                }

                totalActivities++;
                summary[center_id].total++;
                summary[center_id].byUser[user_id].total++;

                if (checked_in) {
                    checkedInActivities++;
                    summary[center_id].checked_in++;
                    summary[center_id].byUser[user_id].checked_in++;
                }

                if (points) {
                    successfulActivities++;
                    summary[center_id].success++;
                    summary[center_id].byUser[user_id].success++;
                }

                return summary;
            }, {});

            const participationRateByCenter = Object.values(activityStatsSummary).map(({center_id, total, checked_in}) => ({
                center_id,
                rate: checked_in / total
            })).sort((a, b) => b.rate - a.rate);

            return {
                participationRateByCenter,
                activityStatsSummary,
                totalParticipationRate: totalActivities ? checkedInActivities / totalActivities : 0,
                totalSuccessRate: checkedInActivities ? successfulActivities / checkedInActivities : 0
            };
        }
    );
    getActivityStatsSummary.selectorName = 'getActivityStatsSummary';
    return getActivityStatsSummary;
};