import React from "react";
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";
import './NavigationBar.css';

export default class NavigationBar extends React.PureComponent {
    render() {
        const {onClose, title, className} = this.props;
        return (
            <div className={'navigationBar' + (className ? ' ' + className : '')}>
                <Button onClick={() => onClose()}><div className="backArrow"/></Button>
                <div className={'navigationTitle'}>{title}</div>
            </div>
        )
    }
}

NavigationBar.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string
};
