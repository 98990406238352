import React, {Component} from 'react';
import PropTypes from "prop-types";
import {t} from 'react-i18nify';

const XLSX = require('xlsx-js-style');

export class ExportToExcel extends Component {

    exportToExcel = () => {
        const wb = XLSX.utils.book_new();

        for (let {columns, rows, sheetName, mergedCells} of this.props.sheets) {
            const colWidths = Array(columns.length).fill({wch: 18});
            const headerRow = columns.map((column, index) => {
                if (typeof (column) === 'object') {

                    if (column.width) {
                        colWidths[index] = column.width;
                    }

                    return {
                        v: column.title,
                        t: 's',
                        s: column.style
                    };
                } else {
                    return {
                        v: column, t: 's', s: {font: {bold: true}}
                    };
                }
            });
            const sheetRows = [headerRow].concat(
                rows.map((row, r) => {
                    return row.map(({value, style}) => ({
                        v: value,
                        t: (value instanceof Date) ? 'd' : (typeof (value) === 'number' ? 'n' : 's'),
                        s: style
                    }));
                })
            );
            const ws = XLSX.utils.aoa_to_sheet(sheetRows);

            ws["!cols"] = colWidths;
            if (mergedCells) {
                ws["!merges"] = mergedCells;
            }

            XLSX.utils.book_append_sheet(wb, ws, sheetName || "Enerjoy");
        }

        XLSX.writeFile(wb, (this.props.fileName || "Enerjoy") + ".xlsx");
    }

    render() {
        return (
            <button className={'export-to-excel localText'} onClick={this.exportToExcel}>
                {this.props.buttonText || t('global.export_to_excel')}
            </button>
        );
    }
}

ExportToExcel.propTypes = {
    sheets: PropTypes.object.isRequired,
    fileName: PropTypes.string,
    buttonText: PropTypes.string
};
