import React, {Component} from "react";
import NumberFormat from 'react-number-format';
import "./controls.css";
import PropTypes from "prop-types";
import ErjCheckBox from "./ErjCheckBox";

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

export class Counter extends Component {

    render() {
        return (
            <div className={'plus-input-minus'}>
                <div className={"input-group"}>
                    <div className={"input-group-btn"}>
                        <div className={"button"} onClick={() => {
                            this.props.onCounterChange(-1)
                        }}><span className={'fa fa-minus'}> </span></div>
                    </div>
                    <input type={"text"} className={"form-control"} onChange={(e) => {
                        this.setState({value: e.target.value})
                    }} value={this.props.value}/>
                    <div className={"input-group-btn"}>
                        <div className={"button"} onClick={() => {
                            this.props.onCounterChange(+1)
                        }}><span className={'fa fa-plus'}> </span></div>
                    </div>
                </div>
            </div>
        )
    }

    componentWillMount() {
        this.setState({value: this.props.value});
    }
}

export class ErjSpinner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name,
            min: props.min,
            max: props.max,
            interval: props.interval,
            value: props.value,
            error: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            name: nextProps.name,
            min: nextProps.min,
            max: nextProps.max,
            interval: nextProps.interval,
            value: nextProps.value,
            error: false
        });
    }

    handleChange(value) {
        let newValue;
        if (Number(value) > this.state.max) {
            newValue = this.state.min;
        } else if (Number(value) < this.state.min) {
            newValue = Math.floor(this.state.max / this.state.interval) * this.state.interval;
        } else {
            newValue = Number(value);
        }
        this.props.onChange({name: this.state.name, value: newValue});
        this.setState({value: newValue});
    }

    render() {
        return (
            <div className={'erj-spinner'}>
                <div className={"arrow-up"}
                     onClick={(e) => this.handleChange(this.state.value + this.state.interval)}/>
                <input type={"text"} onChange={(e) => this.handleChange(e.target.value)} value={this.state.value}
                       min={this.state.min} max={this.state.max}/>
                <div className={"arrow-down"}
                     onClick={(e) => this.handleChange(this.state.value - this.state.interval)}/>
            </div>
        )
    }

    componentWillMount() {
        this.setState({value: this.props.value});
    }
}

export class ErjNumberInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    }

    handleChange = (value) => {
        let newValue = value === '' ? value : Number(value);
        if (newValue === '' || !Number.isNaN(newValue)) {
            this.props.onChange({name: this.props.name, value: newValue, id: this.props.keyId});
            this.setState({value});
        }
    };

    handleClickArrow = (isArrowUp) => {
        const newValue = isArrowUp ? this.state.value + this.props.interval : this.state.value - this.props.interval;
        if (this.validateInput(newValue)) {
            this.handleChange(newValue);
        }
    };

    validateInput = (value) => {
        if (value === '-') {
            return true;
        }
        const number = Number(value);
        return (this.props.min === undefined || number >= this.props.min) && (this.props.max === undefined || number <= this.props.max);
    };

    render() {
        let inputProps = {
            thousandSeparator: true,
            className: this.props.className,
            name: this.props.name,
            value: this.state.value,
            placeholder: this.props.placeholder,
            key: this.props.keyId,
            disabled: this.props.disabled,
            onValueChange: ({value}) => this.handleChange(value),
            isAllowed: ({value}) => {
                return this.validateInput(value);
            },
            required: this.props.required,
            autoComplete: this.props.autoComplete,
            step: this.props.step
        };
        if (this.props.currency) {
            inputProps.prefix = entities.decode(this.props.currency);
        }
        if (this.props.suffix) {
            inputProps.suffix = this.props.suffix;
        }
        return (
            <div className={'erj-number-input'}>
                <NumberFormat {...inputProps} />
                {!this.props.hideArrows && (
                    <div className={'arrows'}>
                        <div className={"arrow-up"}
                             onClick={(e) => this.handleClickArrow(true)}/>
                        <div className={"arrow-down"}
                             onClick={(e) => this.handleClickArrow(false)}/>
                    </div>
                )}
            </div>
        )
    }

    componentWillMount() {
        this.setState({value: this.props.value});
    }
}

ErjNumberInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    interval: PropTypes.number,
    currency: PropTypes.string,
    keyId: PropTypes.string,
    onChange: PropTypes.bool,
    disabled: PropTypes.func,
    appendProps: PropTypes.object,
    hideArrows: PropTypes.bool
};