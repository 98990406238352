import {
    RECEIVE_DISMISSED_ALERTS,
    RECEIVE_READ_ALERTS,
    RECEIVED_ALERTS_LIST
} from "../actions/alerts";
import {RESET_LIVE_DATA} from "../actions/auth";

const initialState = {
    alerts: {},
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function feeds(state = initializeState(), action = {}) {
    switch (action.type) {
        case RESET_LIVE_DATA:
            return {
                ...initialState
            };
        case RECEIVED_ALERTS_LIST:
            return {
                ...state,
                alerts: action.alerts
            };
        case RECEIVE_READ_ALERTS:
            for (let alertId of action.alerts_ids) {
                if (state.alerts[alertId]) {
                    if (!state.alerts[alertId].users_views)
                        state.alerts[alertId].users_views = [];
                    state.alerts[alertId].users_views.push(action.user_id);
                    state.alerts = {...state.alerts, [alertId]: state.alerts[alertId]};
                }
            }
            return {
                ...state,
                alerts: state.alerts
            };
        case RECEIVE_DISMISSED_ALERTS:
            let alerts = {...state.alerts};
            for (let alertId of action.alerts_ids) {
                delete alerts[alertId];
            }
            return {
                ...state,
                alerts
            };
        default:
            return state;
    }
}
