import React, {Component} from "react";
import {calculateEnercoins, UNIT_TYPE} from "../../utils/metrics";
import ViewPort from "../misc/Viewport";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import ErjScroll from "../../components/controls/ErjScroll";
import {getUserImg} from "erj-common/generalUtils";
import CoinsIcon from "../../components/icons/CoinsIcon";
import Icon from "@material-ui/core/Icon";
import {getSecondaryDefOutput} from "../../utils/activities";
import moment from "moment";
import PointsIcon from "../../components/icons/PointsIcon";
import "./summary-widgets.css";
import {makeGetPointsSummary} from "../../selectors/storeSelector";
import {connect} from "react-redux";
import MetricValueDisplay from "../misc/MetricValueDisplay";
import {getBudgetData, getMetricsForUser, getPointsData} from "../../actions/managerStore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import {t} from 'react-i18nify'

const _ = require('lodash');

export class ActivitiesCol extends React.PureComponent {
    render() {
        const {userActivities, posts, managers, leadTypes, teams} = this.props;
        return (
            <div className={'summaryCol'}>
                {userActivities && userActivities.map((activityRow, index) => {
                    let currentTeam = activityRow.participant_team_id && teams[activityRow.participant_team_id];
                    let numInTeam = currentTeam ? currentTeam.length : 1;
                    let head2HeadSuffix = activityRow.activity_def_id === 13 && numInTeam === 1 ? '_1x1' : '';
                    let metricTitle = leadTypes[activityRow.win_req_type] && leadTypes[activityRow.win_req_type].title;
                    let secondary_def = getSecondaryDefOutput(activityRow);
                    let isAllowDeclareVal = activityRow.data && activityRow.data.is_allow_declare_val;
                    let challenge_name = activityRow.title || t('challenges.activity_def_title_' + activityRow.activity_def_id + head2HeadSuffix + (secondary_def ? '_' + secondary_def.id : '') + (isAllowDeclareVal ? '_declare' : ''), {
                        metricTitle,
                        numInTeam
                    });
                    let managerName = "";

                    if (activityRow.post_id) {
                        const post = posts.find(item => item.post_id === activityRow.post_id);
                        if (post) {
                            const manager = managers[post.post_origin_sender_id];
                            if (manager) {
                                managerName = manager.first_name + " " + manager.last_name
                            }
                        }
                    }

                    return (
                        <div className={'userRow pointGrant'}
                             key={'userRow_' + activityRow.id}>
                            <div className={'dotted'}>
                                <div className={'details'}>
                                    <div
                                        className={'name'}>{moment(activityRow.grant_time).format('L')}</div>
                                </div>
                                <div className={'details'}>
                                    <div
                                        className={'name localText'}>{activityRow.post_id ? `${t('feeds.received_from')} ${managerName}` : challenge_name}</div>
                                </div>
                                <div className={'value coins'}>
                                    <PointsIcon/>
                                    <div className={'value'}>{activityRow.points}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export class CentersPointsCol extends React.PureComponent {
    render() {
        const {centers_points, centersDetails, onUserPress, selectedRowId} = this.props;
        return (
            <div className={'summaryCol'}>
                {centers_points.map((centerRow, index) => {
                    if (!centersDetails[centerRow.center_id]) {
                        return false;
                    }
                    return (
                        <a href=''
                           onClick={(e) => onUserPress(e, centerRow.center_id, false)}
                           className={centerRow.center_id === selectedRowId ? 'userRow selected' : 'userRow'}
                           key={'centerRow_' + centerRow.center_id}>
                            <div className={'pos'}>{index + 1}</div>
                            <div
                                className={centerRow.center_id === selectedRowId ? 'dotted selected' : 'dotted'}>
                                <div className={'details'}>
                                    <img className={'userImg'}
                                         src={getUserImg(centersDetails[centerRow.center_id].image_uri, 'xxs', true)}/>
                                    <div>
                                        <div className={'name'}>{centersDetails[centerRow.center_id].title}</div>
                                        <div
                                            className={'value'}>{t('feeds.points', {points: centerRow.points || 0})}</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                })}
            </div>
        );
    }
}

export class UsersPointsCol extends React.PureComponent {
    render() {
        const {
            users_points,
            users,
            isManager,
            predicted_user_coins,
            mobile,
            onUserPress,
            selectedRowId,
            selectedCenterId,
            isActivitiesTableOpen,
            activitiesCol,
            employee_display_currency
        } = this.props;
        let index = 0;
        const RowTag = selectedCenterId ? 'div' : 'a';
        return (
            <div className={'summaryCol'}>
                {users_points.map(userRow => {
                    if (!users[userRow.user_id] || (selectedCenterId && users[userRow.user_id].assigned_center_id !== selectedCenterId)) {
                        return false;
                    }
                    index++;
                    return [
                        <RowTag href=''
                                onClick={(e) => !selectedCenterId && onUserPress(e, userRow.user_in_crm_id, mobile)}
                                className={userRow.user_in_crm_id === selectedRowId ? (isManager ? 'userRow selected' : 'userRow selected' + (mobile ? '' : ' disabled')) : (isManager ? 'userRow' : 'userRow disabled')}
                                key={'userRow_' + userRow.user_id}>
                            <div className={'pos'}>{index}</div>
                            <div
                                className={userRow.user_in_crm_id === selectedRowId ? 'dotted selected' : 'dotted'}>
                                <div className={'details'}>
                                    <img className={'userImg'}
                                         src={getUserImg(users[userRow.user_id].user_img)}/>
                                    <div>
                                        <div
                                            className={'name'}>{`${users[userRow.user_id].first_name} ${users[userRow.user_id].last_name}`}</div>
                                        <div
                                            className={'value'}>{t('feeds.points', {points: userRow.points || 0})}</div>
                                    </div>
                                </div>
                                {!selectedCenterId && (
                                    <div className={'value coins'}>
                                        <CoinsIcon/>
                                        <div className={'multi-rows'}>
                                            <span>
                                                {employee_display_currency === 1 ? (
                                                    (predicted_user_coins[userRow.user_id] && predicted_user_coins[userRow.user_id].payout) || 0
                                                ) : (
                                                    <MetricValueDisplay
                                                        value={(predicted_user_coins[userRow.user_id] && predicted_user_coins[userRow.user_id].payout / 7) || 0}
                                                        unitType={UNIT_TYPE.MONEY} roundOpts={{decimals: 2}}/>
                                                )}
                                            </span>
                                            {Boolean(predicted_user_coins[userRow.user_id] && predicted_user_coins[userRow.user_id].proportionRatio && predicted_user_coins[userRow.user_id].payout) &&
                                            <span
                                                className={'percentages'}>{`(${Math.round(predicted_user_coins[userRow.user_id].proportionRatio * 100)}%)`}</span>}
                                        </div>
                                    </div>
                                )}
                                {mobile && userRow.user_in_crm_id === selectedRowId && (
                                    <Icon
                                        className="icon- dropdownIcon dropdownIcon-erjMenu-menu arrow-down">dropdown_arrow</Icon>
                                )}
                            </div>
                        </RowTag>,
                        mobile && userRow.user_in_crm_id === selectedRowId && isActivitiesTableOpen && (
                            <div className={'userActivities'}>
                                <div className={'summaryHeader'}>
                                    <div
                                        className={'title'}>{t('performance.date')}</div>
                                    <div
                                        className={'title'}>{t('feeds.source')}</div>
                                    <div
                                        className={'title'}>{t('global.points')}</div>
                                </div>
                                {activitiesCol}
                            </div>
                        )
                    ];
                })}
            </div>
        );
    }
}

export class TeamPointsUpdatePost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activitiesTableOpen: false,
            userId: props.isManager ? (
                props.center_is_parent ? (
                    props.centers_points && Object.keys(props.centers_points).length > 0 ? props.centers_points[Object.keys(props.centers_points)[0]].center_id : null
                ) : (
                    props.users_points && Object.keys(props.users_points).length > 0 ? props.users_points[Object.keys(props.users_points)[0]].user_in_crm_id : null
                )
            ) : props.userId
        };
    }

    onUserPress = (e, userId, isMobile) => {
        e.preventDefault();

        if (!isMobile && this.props.isManager) {
            this.tableScroll.scrollToTop();
        }

        const activitiesTableOpen = isMobile ? !this.state.activitiesTableOpen : false;
        this.setState({userId, activitiesTableOpen});
    };

    render() {
        const {
            post,
            users,
            users_points,
            centers_points,
            centersDetails,
            coins_partition_method,
            multiply_points_by_metric,
            storeMetrics
            ,
            pointsGranted,
            leadTypes,
            activities,
            posts,
            managers,
            isManager,
            center_is_parent,
            employee_display_currency
        } = this.props;
        const {userId} = this.state;

        let multiplyPointsByMetricStats;
        if (multiply_points_by_metric) {
            multiplyPointsByMetricStats = _.mapValues(_.keyBy(storeMetrics[multiply_points_by_metric].leaderBoards.this_month, 'user_id'), 'leads');
        }
        let predicted_user_coins = _.keyBy(calculateEnercoins(users_points, post.data.budget, coins_partition_method, multiplyPointsByMetricStats).users, 'user_id') || {};
        let userActivities = (pointsGranted && pointsGranted[userId]) || [];

        let teams = {};

        if (activities) {
            let activityGroup = activities.by_groups;
            if (activityGroup) {
                Object.keys(activityGroup).forEach(groupId => {
                    let currentGroupActivity = activities.by_groups[groupId];
                    for (let pActivity of currentGroupActivity) {
                        if (!teams[pActivity.participant_team_id])
                            teams[pActivity.participant_team_id] = [];
                        teams[pActivity.participant_team_id].push({
                            user_id: pActivity.user_id,
                            checked_in: pActivity.checked_in,
                            win_req_val: pActivity.win_req_val,
                            activity: pActivity
                        });
                    }
                });
            }
        }

        const activitiesCol = <ActivitiesCol leadTypes={leadTypes} teams={teams}
                                             managers={managers} posts={posts}
                                             userActivities={userActivities}/>;

        return (
            <ViewPort>
                {({mobile}) => {
                    return (
                        <div className={'post post_' + post.key}>
                            {!mobile && (
                                <div className={'postHeader'}>
                                    <div className={'postHeaderIcon'}>
                                        <PointsIcon/>
                                    </div>
                                    <div className={'postHeaderTitle'}>
                                        <div className={'post-title'}>{t('feeds.points_summary')}</div>
                                    </div>
                                </div>
                            )}
                            {mobile && (
                                <NavigationBar onClose={() => this.props.onClose()}
                                               title={t('feeds.points_summary')}/>
                            )}
                            <div className={'postBody pointsModal'}>
                                <div className={'summaryTable'}>
                                    {center_is_parent ? (
                                        <div className={'summaryHeader'}>
                                            <div className={'title'}>{t('settings.units')}</div>
                                            <div/>
                                            <div className={'title'}>{t('manager_store.employees')}</div>
                                            <div/>
                                            <div/>
                                        </div>
                                    ) : (
                                        <div className={'summaryHeader'}>
                                            <div className={'title'}>{t('feeds.ranking')}</div>
                                            <div className={'title'}>{t('feeds.predicted_coins')}</div>
                                            {!mobile && <div className={'title'}>{t('performance.date')}</div>}
                                            {!mobile && <div className={'title'}>{t('feeds.name')}</div>}
                                            {!mobile && <div className={'title'}>{t('global.points')}</div>}
                                        </div>
                                    )}
                                    <ErjScroll ref={ref => this.tableScroll = ref}>
                                        <div className={'summaryBody'}>
                                            {center_is_parent ? (
                                                <CentersPointsCol centers_points={centers_points}
                                                                  centersDetails={centersDetails}
                                                                  selectedRowId={this.state.userId}
                                                                  onUserPress={this.onUserPress}/>
                                            ) : (
                                                <UsersPointsCol activitiesCol={activitiesCol}
                                                                users_points={users_points} users={users}
                                                                isManager={isManager}
                                                                predicted_user_coins={predicted_user_coins}
                                                                mobile={mobile} onUserPress={this.onUserPress}
                                                                selectedRowId={this.state.userId}
                                                                isActivitiesTableOpen={this.state.activitiesTableOpen}
                                                                employee_display_currency={employee_display_currency}/>
                                            )}
                                            {center_is_parent ? (
                                                <UsersPointsCol users_points={users_points} users={users}
                                                                isManager={isManager}
                                                                selectedCenterId={this.state.userId}/>
                                            ) : (
                                                !mobile && activitiesCol
                                            )}
                                        </div>
                                    </ErjScroll>
                                </div>
                            </div>
                        </div>
                    );
                }}

            </ViewPort>
        );
    }
}

class PointsSummaryWidget extends Component {
    state = {
        isLoadingData: false,
        isModalOpen: false
    };

    loadPointsPost = async () => {
        this.setState({isModalOpen: true, isLoadingData: true});
        const [result, posts, storeMetrics] = await Promise.all([
            new Promise(resolve => this.props.dispatch(getBudgetData(false, () => resolve(true)))),
            new Promise(resolve => this.props.dispatch(getPointsData(false, (posts) => resolve(posts)))),
            new Promise(resolve => this.props.dispatch(getMetricsForUser(this.props.store_controlling_center, (storeLeadTypes, storeMetrics) => resolve(storeMetrics))))
        ]);
        this.setState({isLoadingData: false, posts, storeMetrics});
    };

    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    render() {
        const {
            points,
            mobile,
            points_post_data,
            coins_partition_method,
            multiply_points_by_metric,
            pointsGranted,
            activities,
            leadTypes,
            managers,
            isManager,
            userId,
            employee_display_currency
        } = this.props;
        const {posts, storeMetrics} = this.state;
        const isPointsModalDisabled = this.props.disabledFeatures.pointsSummaryWindow || !this.props.store_controlling_center;
        return (
            <>
                <Modal
                    aria-labelledby="show_summary_table"
                    aria-describedby="show_summary_table"
                    className={'summaryModal' + (mobile ? ' mob' : '')}
                    open={this.state.isModalOpen}
                    onClose={this.closeModal}
                >
                    <div className={'modalContent' + (mobile ? ' mobileModal' : '')}>
                        {this.state.isLoadingData ? (
                            <CircularProgress className="pageLoadProgressCircle" size={100}/>
                        ) : (<div className={'summary-widget-modal'}>
                                <TeamPointsUpdatePost {...points_post_data}
                                                      onClose={this.closeModal}
                                                      coins_partition_method={coins_partition_method}
                                                      multiply_points_by_metric={multiply_points_by_metric}
                                                      pointsGranted={pointsGranted}
                                                      activities={activities}
                                                      leadTypes={leadTypes} posts={posts}
                                                      storeMetrics={storeMetrics}
                                                      managers={managers}
                                                      isManager={isManager}
                                                      userId={userId}
                                                      employee_display_currency={employee_display_currency}/>
                            </div>
                        )}
                    </div>
                </Modal>
                <Tooltip
                    title={t(isManager ? 'summary.total_points_this_month' : 'summary.your_points_this_month')}>
                    <button className={'summaryBtn points'} onClick={this.loadPointsPost}
                            disabled={isPointsModalDisabled}>
                        <PointsIcon/>
                        <div className={'benefitContent'}>
                            <div className={'benefitValue'}>
                                <MetricValueDisplay value={points}
                                                    shortNumOpts={{shortThreshold: 10000}}/>
                            </div>
                            <div className={'benefitLabel'}>{t('global.points')}</div>
                        </div>
                    </button>
                </Tooltip>
            </>
        );
    }
}

const makeMapStateToProps = () => {
    const getPointsSummary = makeGetPointsSummary();
    return (state, props) => {
        const {auth, global} = state;

        return {
            ...getPointsSummary(state, props),
            userId: !auth.sessionData.is_manager && auth.userData.in_crm_id,
            leadTypes: state.metrics.lead_types,
            pointsGranted: state.managerStore.pointsGranted,
            activities: state.activities,
            managers: auth.sessionData.additionalData.managers,
            isManager: auth.sessionData.is_manager,
            coins_partition_method: auth.sessionData.coins_partition_method,
            multiply_points_by_metric: auth.sessionData.multiply_points_by_metric,
            store_controlling_center: auth.sessionData.store_controlling_center,
            disabledFeatures: global.disabledFeatures,
            employee_display_currency: state.auth.sessionData.employee_display_currency
        };
    }
};

export default connect(makeMapStateToProps)(PointsSummaryWidget);