import {
    createSelectorWithDependencies as createSelector
} from 'reselect-tools';


import moment from 'moment';

const _ = require('lodash');

const getIsManager = (state, props) => state.auth.sessionData.is_manager;
const getCenterIsParent = (state, props) => state.auth.sessionData.additionalData.center_is_parent;
const getStoreUsers = (state, props) => (state.auth.sessionData.store_controlling_center !== state.auth.sessionData.attached_center_id && !getCenterIsParent(state)) ? (
    state.users.org_users[state.auth.sessionData.store_controlling_center]
) : state.users.users;
const getUserInCrmId = (state, props) => state.auth.userData && state.auth.userData.in_crm_id;
const getUserEnercoins = (state, props) => state.auth.userData && state.auth.userData.enercoins;
const getMetrics = (state, props) => state.metrics.metrics;
const getLeadTypes = (state, props) => state.metrics.lead_types;
const getBudgets = (state, props) => state.managerStore.budgets;
const getLastPayout = (state, props) => state.managerStore.last_payout;
const getCentersDetails = (state, props) => state.auth.sessionData.additionalData.centersDetails;

const convertUserRowsToCenterRows = (valueField, rows, users) => _.toArray(rows.reduce((result, {user_id, ...row}) => {
    if (users[user_id] && users[user_id].assigned_center_id) {
        if (!result[users[user_id].assigned_center_id]) {
            result[users[user_id].assigned_center_id] = {
                center_id: users[user_id].assigned_center_id,
                [valueField]: 0
            };
        }
        result[users[user_id].assigned_center_id][valueField] += row[valueField];
    }
    return result;
}, {})).sort((a, b) => b[valueField] - a[valueField] || a.center_id - b.center_id);

export const makeGetPointsSummary = () => {
    let getPointsSummary = createSelector(
        [getStoreUsers, getMetrics, getBudgets, getIsManager, getUserInCrmId, getCenterIsParent, getCentersDetails],
        (users, metrics, budgets, is_manager, user_in_crm_id, center_is_parent, centersDetails) => {
            const currDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
            users = {...users};
            for (let u of Object.keys(users)) {
                if (!users[u].isActive)
                    delete users[u];
            }
            let user_points = 0;
            let pointsLeaderboard = metrics["0"] && metrics["0"].leaderBoards.this_month ? metrics["0"].leaderBoards.this_month.filter(row => {
                if (users[row.user_id]) {
                    if (!is_manager && users[row.user_id].in_crm_id === user_in_crm_id) {
                        user_points = row.points;
                    }
                    return true;
                }
                return false;
            }).map(row => {
                row = {...row, user_in_crm_id: users[row.user_id].in_crm_id};
                return row;
            }) : [];
            const total_user_points = _.sumBy(pointsLeaderboard, 'points');

            let points_post_data = {
                users_points: pointsLeaderboard.sort((a, b) => b.points - a.points || a.user_id - b.user_id),
                post: {
                    key: 'periodic_team_points_update',
                    last_update: null,
                    data: {
                        budget: Object.values(budgets).reduce((prev, budget) => currDate.isBetween(budget.startDate, budget.endDate, null, '[]') ? budget.budget : prev, 0),
                        total_user_points
                    }
                },
                users
            };

            if (is_manager && center_is_parent) {
                points_post_data.center_is_parent = center_is_parent;
                points_post_data.centersDetails = centersDetails;
                points_post_data.centers_points = convertUserRowsToCenterRows('points', points_post_data.users_points, users);
            }

            return {
                points: is_manager ? total_user_points : user_points,
                points_post_data
            };
        }
    );

    getPointsSummary.selectorName = 'getPointsSummary';
    return getPointsSummary;
};

export const makeGetCoinsSummary = () => {
    let getCoinsSummary = createSelector(
        [getStoreUsers, getLeadTypes, getLastPayout, getIsManager, getCenterIsParent, getCentersDetails, getUserEnercoins],
        (users, lead_types, last_payout, is_manager, center_is_parent, centersDetails, userEnercoins) => {
            users = {...users};
            for (let u of Object.keys(users)) {
                if (!users[u].isActive)
                    delete users[u];
            }
            const total_wallets = _.sumBy(Object.values(users), val => val.enercoins || 0);

            let coins_post_data = {
                post: {
                    key: 'team_received_coins',
                    last_update: null,
                },
                top_wallets: Object.values(users).map(user => ({
                    user_id: user.id,
                    enercoins: user.enercoins
                })).sort((a, b) => (b.enercoins || 0) - (a.enercoins || 0) || a.user_id - b.user_id),
                top_grants: last_payout.sort((a, b) => b.enercoins - a.enercoins || a.user_id - b.user_id),
                total_wallets,
                total_grants: _.sumBy(last_payout, 'enercoins'),
                users,
                lead_types,
                isInSummary: true,
                is_manager
            };

            if (is_manager && center_is_parent) {
                coins_post_data.center_is_parent = center_is_parent;
                coins_post_data.centersDetails = centersDetails;
                coins_post_data.centers_coins = convertUserRowsToCenterRows('enercoins', coins_post_data.top_wallets, users);
                coins_post_data.centers_grants = convertUserRowsToCenterRows('enercoins', coins_post_data.top_grants, users);
            }

            return {
                enercoins: is_manager ? total_wallets : userEnercoins,
                coins_post_data
            };
        }
    );

    getCoinsSummary.selectorName = 'getCoinsSummary';
    return getCoinsSummary;
};