import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ErjMenu from '../controls/ErjMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import './erj-table.css';
import ErjDateRangePicker from "../controls/ErjDateRangePicker";
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';

import {t, Translate, Localize} from 'react-i18nify';

const DragHandle = SortableHandle(() => <div className={'arrows'}>
    <div className={"arrow-up"}/>
    <div className={"arrow-down"}/>
</div>);

const SortableItem = SortableElement(({data, ...other}) => {
    return {...data, ...other, className: "lala"};
});

export const ActionIcon = ({id, icon, label, onClick, isDisabled}) => {
    return isDisabled ? (
        <IconButton className="icon-" disabled={isDisabled} onClick={onClick}>{icon}</IconButton>
    ) : (
        <Tooltip className={"tooltip_" + id} title={label}>
            <IconButton className={"icon- iconButton iconButton_" + id} disabled={isDisabled}
                        onClick={onClick}>{icon}</IconButton>
        </Tooltip>
    );
};

const TableBodySortable = SortableContainer(({children}) => {
    return (
        <TableBody>
            {children}
        </TableBody>
    );
});

class ErjTable extends Component {
    constructor(props) {
        super(props);
        const defaultSort = props.defaultSorted && props.defaultSorted[0] ? props.defaultSorted[0] : {
            id: null,
            desc: null
        };
        let sortColumn = defaultSort.id;
        let sortOrder = defaultSort.desc ? 'desc' : 'asc';
        this.state = {
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            data: this.sort(sortColumn, null, sortOrder, props.data.map((row, index) => {
                row.indexId = index;
                return row;
            })),
            pagination: {
                show: false,
                page: 0,
                rowsPerPage: 20,
                rowsPerPageOptions: [],
                rowsPlaceHolder: false
            },
            openSubRows: {}
        };
        if (props.pagination)
            this.state.pagination = {...this.state.pagination, ...this.props.pagination};
    }

    componentWillReceiveProps(nextProps) {
        let nextData = this.sort(this.state.sortColumn, this.state.colIndex, this.state.sortOrder, nextProps.data.map((row, index) => {
            row.indexId = index;
            return row;
        }), nextProps.columns);
        this.setState({
            data: nextData
        });
    }

    sort = (sortColumn, nextColIndex, nextSortOrder, data, nextColumns) => {
        let columns = nextColumns || this.props.columns;
        if (!columns.length || !sortColumn)
            return data;
        let sortOrder = nextSortOrder || ((sortColumn === sortColumn && this.state.sortOrder === 'asc') ? 'desc' : 'asc');
        let nextData = data || this.state.data;
        let colIndex = Number.isInteger(nextColIndex) ? nextColIndex :
            columns.findIndex((column) => {
                return column.accessor === sortColumn;
            });

        let customSortMethod = columns[colIndex] && columns[colIndex].sortMethod;
        return nextData.sort(customSortMethod ? (a, b) => customSortMethod(a, b, sortOrder, sortColumn) : (a, b) => {
            let pairToCompare = (sortOrder === 'asc') ? [a, b] : [b, a];
            if (!pairToCompare[0][sortColumn] || !pairToCompare[1][sortColumn]) {
                return Number(Boolean(pairToCompare[0][sortColumn])) - Number(Boolean(pairToCompare[1][sortColumn]));
            }
            switch (columns[colIndex].sortType) {
                case 'moment':
                    return pairToCompare[0][sortColumn].toDate().getTime() - pairToCompare[1][sortColumn].toDate().getTime() || pairToCompare[0].indexId - pairToCompare[1].indexId;
                case 'date':
                    return new Date(pairToCompare[0][sortColumn]).getTime() - new Date(pairToCompare[1][sortColumn]).getTime() || pairToCompare[0].indexId - pairToCompare[1].indexId;
                case 'text':
                    return pairToCompare[0][sortColumn].localeCompare(pairToCompare[1][sortColumn]) || pairToCompare[0].indexId - pairToCompare[1].indexId;
                case 'length':
                    return pairToCompare[0][sortColumn].length - pairToCompare[1][sortColumn].length || pairToCompare[0].indexId - pairToCompare[1].indexId;
                default:
                    return pairToCompare[0][sortColumn] - pairToCompare[1][sortColumn] || pairToCompare[0].indexId - pairToCompare[1].indexId;
            }
        });
    };

    handleRequestSort = (sortColumn, colIndex) => event => {
        let sortOrder = ((this.state.sortColumn === sortColumn && this.state.sortOrder === 'asc') ? 'desc' : 'asc');
        let nextData = this.sort(sortColumn, colIndex, sortOrder);
        this.setState({data: nextData, sortColumn, sortOrder, colIndex});
    };

    handleChangePage = (event, page) => {
        this.setState({pagination: {...this.state.pagination, page}, openSubRows: {}});
    };

    handleChangeRowsPerPage = event => {
        this.setState({pagination: {...this.state.pagination, rowsPerPage: event.target.value}});
    };

    toggleSubRow = rowIndex => {
        let openSubRows = {};
        if (!this.props.singleSubRow) {
            openSubRows = this.state.openSubRows;
        }
        openSubRows[rowIndex] = !this.state.openSubRows[rowIndex];
        this.setState({openSubRows});
    };

    onSortEnd = ({oldIndex, newIndex}) => {
        let nextOrder = arrayMove(this.state.data, oldIndex, newIndex);
        this.setState({
            data: nextOrder
        });
        this.props.onSortEndCB && this.props.onSortEndCB(nextOrder);
    };

    render() {
        const emptyTable = !this.state.data.length;
        const pagination = this.state.pagination;
        let tableRows = [];
        (pagination.show ? this.state.data.slice(pagination.page * pagination.rowsPerPage, pagination.page * pagination.rowsPerPage + pagination.rowsPerPage) : this.state.data).forEach((row, rowIndex) => {
            let rowProps = {};
            let cells = this.props.columns.map((column, colIndex) => {
                return (<TableCell className={'tableCell ' + (column.className || '')} key={column.accessor}
                                   padding={/*column.disablePadding ? 'none' : */'default'}>
                    {column.Cell ? column.Cell({
                        value: row[column.accessor],
                        original: row,
                        index: rowIndex,
                        colIndex,
                        table: this.props,
                        toggleSubRow: event => this.toggleSubRow(rowIndex),
                        rowProps
                    }) : row[column.accessor]}
                </TableCell>)
            });
            if (this.props.onSortEndCB) {
                cells.unshift(<TableCell className="tableCell order" key={'order'}><DragHandle/></TableCell>)
            }
            tableRows.push(
                this.props.toggleByRow && this.props.toggleByRow(row) ? (
                    <TableRow
                        className={`clickable ${this.state.openSubRows['' + rowIndex] ? 'sub-row-open' : 'sub-row-close'}`}
                        onClick={() => this.toggleSubRow(rowIndex)} hover key={'row_' + rowIndex} {...rowProps}>
                        {cells}
                    </TableRow>
                ) : (
                    <TableRow hover key={'row_' + rowIndex} {...rowProps}>
                        {cells}
                    </TableRow>
                )
            );
            if (this.props.subRow && this.state.openSubRows['' + rowIndex]) {
                tableRows.push(
                    <TableRow key={'subRow_' + rowIndex} className="subRow">
                        <TableCell
                            colSpan={this.props.columns.length}>{this.props.subRow(row, () => this.toggleSubRow(rowIndex))}</TableCell>
                    </TableRow>
                );
            }
        });

        let delta = tableRows.length % this.state.pagination.rowsPerPage;
        if (this.state.pagination.rowsPlaceHolder && delta) {
            let rowsToAdd = this.state.pagination.rowsPerPage - delta;
            for (let i = 1; i <= rowsToAdd; i++) {
                tableRows.push(
                    <TableRow
                        hover
                        key={'row_' + tableRows.length + i}
                    >
                        {this.props.columns.map(column => {
                            return (<TableCell className="tableCell" key={column.accessor}
                                               padding={/*column.disablePadding ? 'none' : */'default'}>
                                <div className={'table-cell grey'}/>
                            </TableCell>)
                        })}
                    </TableRow>
                );
            }
        }

        let tableHeader = this.props.columns.map((column, colIndex) => {
            return (
                <TableCell className="tableCell localText"
                           key={colIndex}
                           sortDirection={this.state.sortColumn === column.accessor ? this.state.sortOrder : false}
                >
                    {(() => {
                        if (column.HeaderCell) {
                            return (
                                <React.Fragment>{column.HeaderCell({
                                    value: column.accessor,
                                    title: column.Header,
                                    index: colIndex,
                                    table: this.props,
                                    sort: (label) => (
                                        <Tooltip
                                            title={t('global.sort')}
                                            enterDelay={300}
                                        >
                                            <TableSortLabel
                                                active={this.state.sortColumn === column.accessor}
                                                direction={this.state.sortOrder}
                                                onClick={this.handleRequestSort(column.accessor, colIndex)}
                                            >
                                                {label}
                                            </TableSortLabel>
                                        </Tooltip>
                                    )
                                })}
                                </React.Fragment>
                            );
                        }
                        else if (column.filter && column.filter.list) {
                            return (
                                <div className={'filter-button'}>
                                    <ErjMenu
                                        isChangeValue={true}
                                        emptyMenuItem={{value: '', key: t('global.all')}}
                                        menuItems={column.filter.list.map(item => ({
                                            value: item.id,
                                            key: item.title
                                        }))}
                                        defaultValue={column.filter.value || column.Header}
                                        label={column.Header}
                                        cbFunc={value => column.filter.onChange({
                                            name: column.accessor,
                                            value
                                        })}
                                        maxHeight={288}
                                    />
                                </div>
                            );
                        }
                        else if (column.filter && column.filter.dateRange) {
                            return (
                                <div className={'filter-button'}>
                                    <ErjDateRangePicker
                                        name={column.accessor}
                                        onChange={column.filter.onChange}
                                        value={column.filter.value}
                                        customInput={selectedValue => (
                                            <button>
                                                <span className={'inlineLocalText'}>
                                                    {column.Header}
                                                    {selectedValue && (
                                                        <span className={'selectedValue'}>
                                                            ({selectedValue[0].format('L') === selectedValue[1].format('L') ? selectedValue[0].format('L') : selectedValue[0].format('L') + '-' + selectedValue[1].format('L')})
                                                        </span>
                                                    )}
                                                </span>
                                                <Icon
                                                    className="icon- dropdownIcon dropdownIcon-erjMenu-menu">dropdown_arrow</Icon>
                                            </button>
                                        )}
                                        isShowResetButton={true}
                                    />
                                </div>
                            );
                        }
                        else if (column.sortType || column.sortMethod) {
                            return (
                                <Tooltip
                                    title={t('global.sort')}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        className="sortLabel"
                                        active={this.state.sortColumn === column.accessor}
                                        direction={this.state.sortOrder}
                                        onClick={this.handleRequestSort(column.accessor, colIndex)}
                                    >
                                        {column.Header}
                                    </TableSortLabel>
                                </Tooltip>
                            );
                        }
                        else {
                            return <span>{column.Header}</span>;
                        }
                    })()}
                </TableCell>
            );
        }, this);

        if (this.props.onSortEndCB) {
            tableHeader.unshift(<TableCell className="tableCell"
                                           key={tableHeader.length}
                                           sortDirection={false}><span>{t('global.order')}</span></TableCell>);
        }

        return (
            <Table className={`ErjTable ${this.props.className || ''}`}>
                {this.props.isLoading ? (
                    <TableHead>
                        <TableRow className={"row-loading"}>
                            <TableCell className={"cell-loading"} colSpan={this.props.columns.length}>
                                <CircularProgress className="pageLoadProgressCircle" size={100}/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                ) : (
                    <>
                        <TableHead>
                            <TableRow className="headTableRow" key={'headTableRow'}>
                                {tableHeader}
                            </TableRow>
                        </TableHead>
                        {this.props.onSortEndCB ?
                            <TableBodySortable onSortEnd={this.onSortEnd} useDragHandle={true}
                                               helperClass={'row-is-arrangeable'}>
                                {tableRows.map((row, index) => {
                                    return (<SortableItem index={index}
                                                          key={row.key}
                                                          data={row}/>);
                                })}
                            </TableBodySortable> :
                            <TableBody>
                                {tableRows}
                                {emptyTable && (
                                    <TableRow className={"row-no-data"}>
                                        <TableCell className={"cell-no-data"} colSpan={this.props.columns.length}>
                                            <div>{t('global.no_data')}</div>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>}
                        {this.state.pagination.show && (
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        classes={{spacer: 'spacer', caption: 'caption', toolbar: 'toolbar'}}
                                        className={'TablePagination'}
                                        colSpan={this.props.columns.length}
                                        count={this.state.data.length}
                                        rowsPerPage={this.state.pagination.rowsPerPage}
                                        rowsPerPageOptions={this.state.pagination.rowsPerPageOptions}
                                        page={this.state.pagination.page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={this.props.tablePaginationCustom}
                                    />
                                </TableRow>
                            </TableFooter>
                        )}
                    </>
                )}
            </Table>
        );
    }
}

ErjTable.propTypes = {
    defaultSorted: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    pagination: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    subRow: PropTypes.func,
    className: PropTypes.string,
    minRows: PropTypes.number,
    toggleByRow: PropTypes.func,
    singleSubRow: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSortEndCB: PropTypes.func
};

export default ErjTable;