import React from 'react';

export default () => <svg className="coinsIcon" version="1.1" viewBox="0 0 552 552" xmlns="http://www.w3.org/2000/svg">
    <g id="coin" fill="#7b27da" stroke="#7b27da">
        <path id="Shape"
              d="m254.39 6.8965c-40.671 3.2724-83.028 17.111-118.2 38.652-35.073 21.546-69.192 55.658-90.737 90.718-59.472 97.059-51.129 219.99 21.126 309.34 10.243 12.671 31.265 33.374 43.624 42.982 21.231 16.368 50.281 32.636 74.471 41.506 43.095 15.839 93.8 20.064 139.54 11.619 109.86-20.279 197.21-107.62 217.49-217.45 6.8657-37.704 5.4937-77.414-4.1216-115.11-4.7553-18.483-9.8247-31.683-19.12-50.799-13.307-27.143-28.207-48.05-49.647-70.125-56.722-58.193-134.57-87.76-214.43-81.32zm58.629 18.378c55.245 8.3408 104.36 32.95 142.71 71.602 38.869 38.971 62.745 87.022 71.407 143.31 2.5347 17.001 2.5347 54.915 0 71.817-8.7668 56.292-33.061 105.08-71.512 143.63-38.56 38.442-87.359 62.732-143.66 71.497-16.9 2.5342-54.821 2.5342-71.831 0-56.303-8.6603-104.36-32.526-143.34-71.392-38.869-38.652-63.169-87.126-71.831-143.73-2.5347-16.896-2.5347-54.915 0-71.817 4.86-31.369 14.58-60.831 28.311-85.859 41.834-75.722 114.93-123.04 202.71-131.07 9.8247-0.8429 46.368 0.4242 57.042 2.0108z"
              strokeWidth="12"/>
        <path
            d="m244.7 68.416c-41.864 6.1114-82.526 25.934-113.27 55.473-55.286 52.871-77.019 129.88-57.59 203.27 19.132 72.099 76.821 129.07 149.53 147.7 13.019 3.3064 35.156 6.5084 45.469 6.5084h5.2088v-8.0127h-8.0144c-17.324 0-42.564-5.1085-62.699-12.515-69.104-25.835-118.38-88.72-127.59-162.71-1.5047-12.719-1.2016-39.453 0.60082-52.37 8.1136-56.575 39.56-106.64 86.732-138.19 64.899-43.36 149.53-44.76 216.83-3.6042 24.639 15.017 49.377 39.855 64.402 64.488 17.528 28.739 27.141 59.181 29.544 94.325l0.70008 10.113 6.8127 0.80441v-8.6134c-0.0993-22.832-7.7114-54.271-19.331-79.104-26.64-57.479-81.528-101.33-143.42-114.75-21.029-4.513-53.279-5.7144-73.911-2.8102z"
            strokeWidth="11"/>
    </g>
    <path id="symbol"
          d="m270.03 168.95c0 11.617-0.0993 12.014-2.2047 12.416-10.815 2.2043-14.122 3.202-19.832 6.0069-9.5138 4.5078-18.228 13.116-22.836 22.429-3.5056 7.3076-3.7042 8.112-3.7042 17.624 0 9.3134 0.29779 10.614 3.2026 16.924 5.9089 12.317 19.529 24.435 37.658 33.346l7.7114 3.8027v86.719h-2.2047c-8.1136-0.0993-20.433-5.61-27.742-12.416-6.1074-5.8084-9.7176-12.317-11.316-20.225-1.0031-5.307-1.2016-5.4062-4.4095-5.4062-3.2026 0-3.4064 0.19849-3.4064 3.3064 0 4.7063 3.3071 13.816 6.912 19.024 4.5087 6.7069 13.521 14.317 20.731 17.723 5.2088 2.502 16.927 5.9077 20.731 6.0069 0.40229 0 0.70008 3.4057 0.70008 7.5113v7.5113h8.0144v-14.62l7.5128-1.7028c16.927-3.6042 30.448-13.617 37.355-27.538 3.5056-7.3128 3.7042-8.112 3.7042-17.624 0-9.3134-0.30302-10.614-3.2026-16.924-5.8984-12.416-20.12-25.036-37.747-33.346l-7.6121-3.6042v-43.459c0-49.367-1.1024-44.66 9.3153-41.955 16.927 4.4033 28.646 15.822 31.749 30.74 1.1024 5.8084 1.2016 5.8084 4.7073 5.8084h3.6049l-0.60081-5.307c-1.6039-12.918-12.617-27.136-26.54-34.146-5.8096-2.9042-9.5138-4.0064-19.931-6.2106-2.2047-0.4022-2.304-0.79918-2.304-12.416v-12.014h-8.0144zm0 62.185v42.054l-7.7114-4.1056c-15.825-8.2112-26.342-18.026-31.551-29.538-3.2026-7.3076-3.1034-18.125 0.50155-25.736 3.3071-7.5113 10.318-14.819 17.627-18.726 5.8096-3.1027 14.623-5.9077 18.928-6.0069l2.2047-0.0992zm26.54 63.282c21.535 14.62 28.541 34.046 18.73 52.067-3.1086 5.9077-11.421 13.717-17.727 16.72-5.0103 2.4028-13.824 5.004-17.225 5.004h-2.304v-84.108l6.3112 3.1027c3.4012 1.8073 8.913 5.0145 12.215 7.2135z"
          fill="#38ed96" stroke="#38ed96" strokeWidth="18"/>
</svg>
