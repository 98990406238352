import React from 'react';

export default () => <svg className="crownWithStarsIcon" version="1.1" viewBox="0 0 332 341"
                          xmlns="http://www.w3.org/2000/svg">
    <g id="stars" transform="translate(192)" fill="#24d17e">
        <path id="Shape"
              d="m51.5 0c-7.1108 38.625-12.875 44.389-51.5 51.5 38.625 7.1108 44.389 12.875 51.5 51.5 7.1108-38.625 12.875-44.389 51.5-51.5-38.625-7.1108-44.39-12.875-51.5-51.5z"/>
        <path
            d="m107 73c-4.556 24.75-8.2504 28.444-33 33 24.75 4.5563 28.444 8.2503 33 33 4.556-24.75 8.2504-28.444 33-33-24.75-4.556-28.444-8.25-33-33z"/>
    </g>
    <path id="crown-base"
          d="m237.06 334.61c0 3.5296-3.7597 6.3912-8.3972 6.3912h-165.7c-4.6375 0-8.3972-2.8615-8.3972-6.3912v-13.634c0-3.5296 3.7597-6.3912 8.3972-6.3912h165.7c4.6375 0 8.3972 2.8615 8.3972 6.3912z"
          fill="#24d17e" fillRule="evenodd"/>
    <path id="crown-top"
          d="m293 187.04c0-9.1385-7.4567-16.572-16.624-16.572s-16.623 7.4331-16.623 16.572c0 2.9766 0.80165 5.7646 2.1855 8.1809l-74.187 29.582-28.483-56.785c8.9402-4.557 15.079-13.827 15.079-24.505 0-15.17-12.382-27.51-27.599-27.51s-27.598 12.342-27.598 27.51c0 10.678 6.1378 19.947 15.078 24.505l-28.482 56.785-74.587-29.74c1.3268-2.3795 2.0881-5.1128 2.0881-8.0224 0-9.1385-7.4567-16.572-16.624-16.572-9.1675 0-16.623 7.4331-16.623 16.572 0 6.7144 4.0329 12.497 9.8057 15.097l37.345 92.234c1.282 3.1663 4.3621 5.2378 7.787 5.2378h184.74c3.4563 0 6.5588-2.1116 7.8183-5.3193l36.315-92.45c5.4414-2.7255 9.191-8.3282 9.191-14.8z"
          fill="#c6a328"/>
</svg>