import {callApi} from "../utils/apiUtils";
import {loginSuccess, updateEnercoins} from "../actions/auth";

export const RECEIVED_STORE_PRODUCTS = "RECEIVED_STORE_PRODUCTS";
export const RECEIVED_STORE_WISH_LIST = "RECEIVED_STORE_WISH_LIST";
export const RECEIVED_STORE_ORDERS = "RECEIVED_STORE_ORDERS";
export const RECEIVED_STORE_SHOPPING_CART = "RECEIVED_STORE_SHOPPING_CART";
export const SHOPPING_CART_ADD_ITEM = "SHOPPING_CART_ADD_ITEM";
export const SHOPPING_CART_REMOVE_ITEM = "SHOPPING_CART_REMOVE_ITEM";
export const UPDATE_PRODUCT_WISH = "UPDATE_PRODUCT_WISH";
export const SHOPPING_CART_UPDATE_QUANTITY = "SHOPPING_CART_UPDATE_QUANTITY";
export const ORDER_SUCCEEDED = "ORDER_SUCCEEDED";
export const RECEIVED_STORE_TEAM_CASH = "RECEIVED_STORE_TEAM_CASH";
export const RECEIVED_TEAM_TRANSACTIONS = "RECEIVED_TEAM_TRANSACTIONS";

export function receivedStoreProducts(products) {
    return {type: RECEIVED_STORE_PRODUCTS, payload: {products}};
}

export function receivedStoreWishList(wishList) {
    return {type: RECEIVED_STORE_WISH_LIST, payload: {wishList}};
}

function receivedStoreOrders(orders) {
    return {type: RECEIVED_STORE_ORDERS, payload: {orders}};
}

function receivedStoreShoppingCart(shoppingCart) {
    return {type: RECEIVED_STORE_SHOPPING_CART, payload: {shoppingCart: {cart: shoppingCart}}};
}

function receivedStoreTeamCash(teamCash) {
    return {type: RECEIVED_STORE_TEAM_CASH, payload: {shoppingCart: {teamCash: teamCash}}};
}

export function addItemToShoppingCart(product, orderQuantity) {
    return {type: SHOPPING_CART_ADD_ITEM, payload: {product, orderQuantity}};
}

export function removeItemToShoppingCart(productId) {
    return {type: SHOPPING_CART_REMOVE_ITEM, payload: {productId}};
}

export function updateProductWish(product) {
    return {type: UPDATE_PRODUCT_WISH, payload: product};
}

export function updateShoppingCartQuantity(productId, orderQuantity) {
    return {type: SHOPPING_CART_UPDATE_QUANTITY, payload: {productId, orderQuantity}};
}

export function orderSucceeded(orderStatus) {
    return {type: ORDER_SUCCEEDED, payload: {orderSucceeded: orderStatus}};
}

export function getEmployeeStoreData() {
    return callApi('getEmployeeStoreData', {}, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.data) {
            if (responseData.data.wishList)
                dispatch(receivedStoreWishList(responseData.data.wishList));
            if (responseData.data.orders)
                dispatch(receivedStoreOrders(responseData.data.orders));
            if (responseData.data.shoppingCart)
                dispatch(receivedStoreShoppingCart(responseData.data.shoppingCart));
            if (responseData.data.teamCash)
                dispatch(receivedStoreTeamCash(responseData.data.teamCash));
        }
    });
}

export function updateUserCartList(cartData) {
    return callApi('setEmployeeStoreData', cartData, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {

        }
    });
}

export function toggleProductWish(data) {
    return callApi('toggleProductWish', data, null, (dispatch, responseData) => {
        console.log(responseData);
        if (responseData.status === "SUCCESS") {
            let {data, ...sessionData} = responseData;
            dispatch(loginSuccess(sessionData));
            dispatch(updateProductWish(data));
        }
    });
}

export function buyShoppingCart(data) {
    return callApi('buyShoppingCart', data.cart, null, (dispatch, responseData) => {
        if (responseData.status === "SUCCESS") {
            dispatch(getEmployeeStoreData());
            dispatch(updateEnercoins(data.newAmount));
            dispatch(orderSucceeded(true));
        }
    });
}
