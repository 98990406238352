import {
    DECODE_IMG,
    SAVE_PROFILE_RESPONSE
} from "../actions/account";

const initialState = {
    decodedImg: '',
    response: null
};

function initializeState() {
    return Object.assign({}, initialState);
}

export default function account(state = initializeState(), action = {}) {
    switch (action.type) {
        case DECODE_IMG:
            return {
                ...state,
                decodedImg: action.img
            };
        case SAVE_PROFILE_RESPONSE:
            return {
                ...state,
                response: action.response
            };
        default:
            return state;
    }
}
